import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LatestContacts from '../../../modules/business/statics/LatestContacts';
import {
  useGetFriendsCategoriesQuery,
  useGetFriendsWithCategoriesIdQuery,
} from '../../../redux/features/friends/friendsApi';
import NearbyLocationDiscounts from '../NearbyLocationDiscounts';
import CloseFriends from './CloseFriends';
import NearbyFriends from './NearbyFriends';

const tabs = [
  { id: 'family', label: 'Family' },
  { id: 'work', label: 'Work' },
  { id: 'nearby', label: 'Nearby Friends' },
  { id: 'friends', label: 'Close Friends' },
  { id: 'others', label: 'Others' },
];

const TabsComponent = () => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState({});

  const { data: myFriendCategoriesData } = useGetFriendsCategoriesQuery({
    limit: 5,
    sort: 'desc',
  });
  const {
    data: friendsData,
    refetch,
    isLoading,
  } = useGetFriendsWithCategoriesIdQuery({
    id: activeTab?._id,
  });
  useEffect(() => {
    refetch();
  }, [pathname, activeTab]);
  // console.log("myFriendCategoriesData", myFriendCategoriesData);

  useEffect(() => {
    if (myFriendCategoriesData?.success) {
      setActiveTab(myFriendCategoriesData?.data?.results[0]);
    }
  }, [myFriendCategoriesData]);

  console.log(activeTab);
  //   useEffect(() => {
  //     refetch();
  //   }, [activeTab]);
  // console.log(activeTab);
  return (
    <div className="w-full rounded-lg bg-white p-4 text-black shadow-md dark:bg-dark dark:text-gray-300">
      <div className="flex flex-wrap justify-between gap-2 sm:gap-4">
        {myFriendCategoriesData?.data?.results?.length > 0 &&
          myFriendCategoriesData?.data?.results?.map((tab) => (
            <button
              key={tab._id}
              onClick={() => setActiveTab(tab)}
              className={`
                        min-w-[120px] flex-1 rounded-md px-4 py-2 text-sm font-medium transition-all
                        duration-300 ease-in-out sm:text-base
                        ${
                          activeTab?._id === tab._id
                            ? 'scale-105 transform bg-primary text-white shadow-lg'
                            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                        }
                        `}
            >
              {tab.name}
            </button>
          ))}
        {/* <button
                        // key={tab.id}
                        onClick={() => setActiveTab({name: 'Others', _id: 'othersId'})}
                        className={`
                        flex-1 min-w-[120px] rounded-md py-2 px-4 text-sm sm:text-base font-medium
                        transition-all duration-300 ease-in-out
                        ${activeTab?._id === 'othersId'
                                ? 'bg-primary text-white shadow-lg transform scale-105'
                                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                            }
                        `}
                    >
                        Others
                    </button> */}
      </div>
      <div className="mt-8 rounded-lg bg-white p-6 text-black shadow-md dark:bg-dark dark:text-gray-300">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-300">
            {tabs.find((tab) => tab.id === activeTab)?.label}
          </h2>
          {/* <a href="#" className="text-blue-600 hover:underline">Manage</a> */}
        </div>
        {/*<p className="text-gray-600 dark:text-gray-300 mb-4">*/}
        {/*    Your friends, who you can share exclusively with if you want.*/}
        {/*    /!* <a href="#" className="text-blue-600 hover:underline ml-1">Learn More</a> *!/*/}
        {/*</p>*/}

        {/* {activeTab === 'friends' && <CloseFriends />} */}
        {activeTab === 'friends' && <CloseFriends />}
        {activeTab === 'nearby' && (
          <>
            <NearbyFriends isSingleView={true} limit={100} isTitle={false} />
            <NearbyLocationDiscounts />
          </>
        )}
        {Object?.keys(activeTab || {}) && (
          <LatestContacts
            data={friendsData}
            isTitle={false}
            isLoading={isLoading}
            refetch={refetch}
          />
        )}
        {/* {activeTab === 'others' && <Friends limit={20} isTitle={false} />} */}
      </div>
      <br />
    </div>
  );
};

export default TabsComponent;
