import React from 'react';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';

const StoreReviewPage = () => {
  const content = {
    header: [
      'Date',
      'Store Name',
      'Client Name',
      'Review',
      'Rating',
      'Image',
      'Approved',
      'Actions',
    ],

    body: [
      {
        rows: [
          {
            row: '24.25.24 22:55',
          },
          {
            row: 'Store Name',
          },
          {
            row: (
              <div>
                <img className="h-[40px] w-[40px] border" src="" alt="" />
              </div>
            ),
          },
          {
            row: 'Netrokona',
          },
          {
            row: '4',
          },
          {
            row: 'No image',
          },
          {
            row: '',
          },
          {
            row: (
              <div className="flex items-center gap-3">
                <Button className="-mb-2 !bg-green-400">Approve</Button>
                <Button link={''} className="!bg-red-400">
                  Delete
                </Button>
              </div>
            ),
          },
        ],
      },
    ],
  };

  return (
    <>
      <Card title="Store Reviews">
        <Table content={content} />
      </Card>
    </>
  );
};

export default StoreReviewPage;
