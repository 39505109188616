import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCredentials } from "./redux/features/auth/authSlice";

const GetUserCredential = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("token_link_dashboard");
      try {
        const user = jwtDecode(token) || {};
        if (user?._id) {
          dispatch(setCredentials({ user, token: token }));
        } else {
          dispatch(setCredentials({}));
        }
      } catch (error) {
        dispatch(setCredentials({}));
      }
    }
  }, []);

  return;
};

export default GetUserCredential;
