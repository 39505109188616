import { apiSlice } from "../api/apiSlice";

export const addressApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAddresses: builder.query({
      query: (arg) => ({
        url: `/address?page=${arg?.page}&limit=${arg?.limit}`,
        method: "GET",
      }),
      providesTags: ["address"],
    }),

    getAddress: builder.query({
      query: (id) => ({
        url: `/address/${id}`,
        method: "GET",
      }),
      providesTags: ["address"],
    }),

    createAddress: builder.mutation({
      query: (data) => ({
        url: "/address",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["address"],
    }),

    editAddress: builder.mutation({
      query: ({ id, data }) => ({
        url: `/address/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["address"],
    }),

    deleteAddress: builder.mutation({
      query: (id) => ({
        url: `/address/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["address"],
    }),
  }),
});

export const {
  useGetAddressesQuery,
  useGetAddressQuery,
  useCreateAddressMutation,
  useEditAddressMutation,
  useDeleteAddressMutation,
} = addressApi;
