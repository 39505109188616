import React, { useEffect, useState } from 'react';
// import { FaLocationArrow, FaPen } from "react-icons/fa";
// import Button from "../components/ui/Button";
import toast from 'react-hot-toast';
import {
  FaCalendar,
  FaCar,
  FaChair,
  FaCheckCircle,
  FaCog,
  FaDollarSign,
  FaDoorOpen,
  FaFileAlt,
  FaGasPump,
  FaLink,
  FaLocationArrow,
  FaMapMarkerAlt,
  FaPalette,
  FaPen,
  FaRoad,
  FaRuler,
  FaTachometerAlt,
} from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateCarMutation,
  useEditCarMutation,
  useGetBrandOptionsQuery,
  useGetCardsOptionsQuery,
  useGetSingleCarQuery,
} from '../../redux/features/cars/carsSlice';
import Loading from '../Loading';
import Button from '../ui/Button';
import Card from '../ui/Card';
import InputGroup from '../ui/InputField';
import MultiImageUpload from '../ui/MultiImageUpload';
import SelectOptions from '../ui/SelectOptions';

const CreateCarsPage = () => {
  const [values, setValues] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: singleCarData, refetch: refetchSingleCar } =
    useGetSingleCarQuery(id, { skip: !id });
  const { data: brandOptionsData } = useGetBrandOptionsQuery();
  const { data: cardsOptionsData } = useGetCardsOptionsQuery();
  const [createCar, { isLoading }] = useCreateCarMutation();
  const [updateCar, { isLoading: isEditLoading }] = useEditCarMutation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (singleCarData?.success) {
      const { _id, ...restObj } = singleCarData?.data;
      for (let key in restObj) {
        setValues((prevState) => {
          if (key === 'brand' || key === 'card') {
            return {
              ...prevState,
              [key]: restObj[key]?._id,
            };
          } else {
            return {
              ...prevState,
              [key]: restObj[key],
            };
          }
        });
      }
    }
  }, [singleCarData]);

  const handleSaveCar = async () => {
    const requiredFields = ['model', 'price', 'from', 'km', 'engineSize'];
    for (let field of requiredFields) {
      if (!values[field]) {
        toast.error(`${field} is required`);
        return;
      }
    }
    try {
      let result;
      if (!id) {
        result = await createCar(values);
      } else {
        result = await updateCar({ id, data: values });
      }
      if (result?.data?.success) {
        if (id) {
          toast.success(result?.data?.message);
          refetchSingleCar();
          navigate('/all/cars');
        } else {
          toast.success(result?.data?.message);
          navigate('/all/cars');
        }
      }

      if (result?.error?.status === 400) {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error('Cars Create Failed');
    }
  };

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  return (
    <>
      <Card
        title={!id ? 'Create New Car' : 'Edit Car'}
        content={
          <Button
            link="/cars"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            My Cars
          </Button>
        }
        className="space-y-3"
      >
        <>
          <InputGroup
            name="model"
            placeholder="Car Model"
            icon={<FaPen />}
            value={values.model}
            onChange={onChange}
            isRequired={true}
          />
          <InputGroup
            name="serie"
            placeholder="Serie"
            icon={<FaLocationArrow />}
            value={values.serie}
            onChange={onChange}
          />
          <InputGroup
            name="year"
            placeholder="Year"
            icon={<FaCalendar />}
            value={values.year}
            onChange={onChange}
          />
          <InputGroup
            name="color"
            placeholder="Color"
            icon={<FaPalette />}
            value={values.color}
            onChange={onChange}
          />
          <InputGroup
            name="price"
            placeholder="Price"
            icon={<FaDollarSign />}
            value={values.price}
            onChange={onChange}
            isRequired={true}
          />
          <InputGroup
            name="km"
            placeholder="Kilometers"
            icon={<FaTachometerAlt />}
            value={values.km}
            onChange={onChange}
            isRequired={true}
          />
          <InputGroup
            name="fuelType"
            placeholder="Fuel Type"
            icon={<FaGasPump />}
            value={values.fuelType}
            onChange={onChange}
          />
          <InputGroup
            name="transmission"
            placeholder="Transmission"
            icon={<FaCog />}
            value={values.transmission}
            onChange={onChange}
          />
          <InputGroup
            name="engineSize"
            placeholder="Engine Size"
            icon={<FaRuler />}
            value={values.engineSize}
            onChange={onChange}
            isRequired={true}
          />
          <InputGroup
            name="bodyType"
            placeholder="Body Type"
            icon={<FaCar />}
            value={values.bodyType}
            onChange={onChange}
          />
          <InputGroup
            name="doors"
            placeholder="Doors"
            icon={<FaDoorOpen />}
            value={values.doors}
            onChange={onChange}
          />
          <InputGroup
            name="seats"
            placeholder="Seats"
            icon={<FaChair />}
            value={values.seats}
            onChange={onChange}
          />
          <InputGroup
            name="condition"
            placeholder="Condition"
            icon={<FaCheckCircle />}
            value={values.condition}
            onChange={onChange}
          />
          <InputGroup
            name="driveType"
            placeholder="Drive Type"
            icon={<FaRoad />}
            value={values.driveType}
            onChange={onChange}
          />
          <InputGroup
            name="from"
            placeholder="From"
            icon={<FaMapMarkerAlt />}
            value={values.from}
            onChange={onChange}
            isRequired={true}
          />
          <InputGroup
            name="url"
            placeholder="URL"
            icon={<FaLink />}
            value={values.url}
            onChange={onChange}
          />
          <InputGroup
            name="description"
            placeholder="Description"
            icon={<FaFileAlt />}
            value={values.description}
            onChange={onChange}
          />
          {/* <InputFile
            name="coverImage"
            placeholder="Cover Image"
            icon={<FaImage />}
            value={values.coverImage}
            onChange={onChange}
          />
          <InputFile
            name="galleryImages"
            placeholder="Gallery Images"
            icon={<FaImages />}
            value={values.galleryImages}
            onChange={onChange}
          /> */}
          <MultiImageUpload
            name="galleryImages"
            value={values.galleryImages}
            onChange={onChange}
          />
        </>
        <SelectOptions
          placeholder="Brand Options"
          name={'brand'}
          value={values?.brand}
          onChange={onChange}
          options={brandOptionsData?.data}
          icon={<></>}
        />

        <SelectOptions
          placeholder="Card Options"
          name={'card'}
          value={values?.card}
          onChange={onChange}
          options={cardsOptionsData?.data}
          icon={<></>}
        />

        <Button className="mt-3 w-32 !bg-green-400" onClick={handleSaveCar}>
          {isEditLoading || isLoading ? (
            <Loading variant="button" height="20px" />
          ) : (
            `${!id ? 'Save' : 'Update'} Car`
          )}
        </Button>
      </Card>
    </>
  );
};

export default CreateCarsPage;
