import { BsGlobeEuropeAfrica } from "react-icons/bs";
import { HiUsers } from "react-icons/hi2";
import { IoGlobeOutline } from "react-icons/io5";
import { PiUserRectangleFill } from "react-icons/pi";
import CommonTable from "./components/dashboard/CommonTable";
import DefaultCardLayout from "./components/dashboard/DefaultCardLayout";
import FlashCard from "./components/dashboard/FlashCard";
import MonthlySalesChart from "./components/dashboard/MonthlySalesChart";
import ProfileTypesChart from "./components/dashboard/ProfileTypesChart";
import ActionButton from "./components/dashboard/ActionButton";
import { FaCaretDown } from "react-icons/fa";
import { useGetOrdersQuery } from "../../redux/features/orders/ordersApi";
import { useEffect, useState } from "react";


const sampleOrders = [
    {
        name: 'anwarb@gmail.com',
        address: '',
        phoneNumber: '',
        total: '2 SAR',
        paymentMethod: 'Unknown',
        orderDate: '1 month ago',
        orderStatus: 'Completed'
    },
    {
        name: 'abdo ahmed qassem',
        address: 'ddd',
        phoneNumber: '',
        total: '200 SAR',
        paymentMethod: 'Unknown',
        orderDate: '10 months ago',
        orderStatus: 'Completed'
    },
    {
        name: 'EID ABDULRAHIM',
        address: 'ALRAWDAH STREET',
        phoneNumber: '',
        total: '100 SAR',
        paymentMethod: 'Unknown',
        orderDate: '1 year ago',
        orderStatus: 'Completed'
    },
];

const tableRows = [
    "Name",
    "Address",
    "Phone Number",
    "Total",
    "Payment Method",
    "Order Date",
    "Order Status",
    "Actions",
]

const salesChartData = [
    { month: '10-2021', sales: 0 },
    { month: '11-2021', sales: 1 },
    { month: '12-2021', sales: 0 },
    { month: '01-2022', sales: 0 },
    { month: '02-2022', sales: 0 },
    { month: '03-2022', sales: 0 },
    { month: '04-2022', sales: 0 },
    { month: '05-2022', sales: 0 },
    { month: '06-2022', sales: 0 },
    { month: '07-2022', sales: 0 },
    { month: '08-2022', sales: 1 },
    { month: '09-2022', sales: 0 },
];

const Dashboard = () => {
    const [ordersData, setOrdersData] = useState([]);


    const {data, isSuccess} = useGetOrdersQuery();

    useEffect(() => {
        if(isSuccess && data?.data?.results?.length > 0) {
            // setOrdersData(data.data.results);
            let tableBodyArr = data.data.results.map((order) => { 
                let {
                    _id,
                    wp_name,
                    state,
                    street,
                    zipCode,
                    user,
                    city,
                    status,
                    total,
                    createdAt,
                } = order;
        
                return {
                    // _id: name + ' 1',
                    _id,
                    wp_name,
                    // address: street + ", " + city + ", " + zipCode + ", " + state,
                    address: "",
                    user,
                    city,
                    status, 
                    total: total + 'SAR',
                    createdAt: createdAt?.slice(0, 10),
                }
            });
            setOrdersData(tableBodyArr);
        }
    }, [isSuccess, data])


    const handleAction = (payload) => {
        // console.log(payload);
    }

    function listOfActions(payload) {
        return <div className="flex gap-3">
            <ActionButton
                title={'Action'}
                onClick={() => handleAction(payload)}
                styles={'py-3 px-4 font-semibold'}
                endIcon={<FaCaretDown size={16} className="ml-1 inline" />}
            />
        </div>
    }
    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <FlashCard
                    color="#000"
                    textColor="#fff"
                    icon={<PiUserRectangleFill size={28} />}
                    number="2"
                    title="User Profiles"
                    subtitle="Total User Profiles"
                    breakdown={[
                        { value: 2, label: "Personal" },
                        { value: 0, label: "Business" },
                        { value: 0, label: "Company" },
                    ]}
                />
                <FlashCard
                    color="#B0E531"
                    textColor="#000"
                    icon={<HiUsers size={28} />}
                    number="3"
                    title="User Accounts"
                    subtitle="Total User Accounts"
                    breakdown={[
                        { value: 0, label: "This Week" },
                        { value: 0, label: "This Month" },
                        { value: 2, label: "This Year" },
                    ]}
                />
                <FlashCard
                    color="#2fbfeb"
                    textColor="#000"
                    icon={<IoGlobeOutline size={28} />}
                    number="110"
                    title="Profile Visits"
                    subtitle="Total Profile Visits"
                    breakdown={[
                        { value: 0, label: "This Week" },
                        { value: 7, label: "This Month" },
                        { value: 99, label: "This Year" },
                    ]}
                />
                <FlashCard
                    color="#F1416C"
                    textColor="#fff"
                    icon={<BsGlobeEuropeAfrica size={28} />}
                    number="302"
                    title="INCOME Total Income"
                    subtitle="Total Income"
                    breakdown={[
                        { value: 'More info', label: 'Click to see clients' },
                    ]}
                />
            </div>

            <div className="bg-[#1F2937] p-6 grid lg:grid-cols-2 rounded-lg space-y-5 my-10">
                <div className="flex flex-col gap-1 border-0 lg:border-r-[1px]">
                    <p className="font-bold text-xs text-green-600">3 order</p>
                    <p className="font-normal text-sm text-white uppercase">302 SAR</p>
                    <p className="font-normal text-md uppercase">Total Revenue Sar</p>
                </div>
                <div className="flex flex-col gap-1 lg:pl-6">
                    <p className="font-bold text-xs text-green-600">3 order</p>
                    <p className="font-normal text-sm text-white uppercase">302 SAR</p>
                    <p className="font-normal text-md uppercase">Total Revenue Sar</p>
                </div>
            </div>

            <div className="grid lg:grid-cols-2 gap-6 my-10">
                <DefaultCardLayout title={'Profile Types Chart'}>
                    <ProfileTypesChart data={[85, 10, 5]} />
                </DefaultCardLayout>
                
                <DefaultCardLayout title={'Monthly Sales Counts'}>
                    <MonthlySalesChart data={salesChartData} />
                </DefaultCardLayout>
            </div>

            <DefaultCardLayout
                 title={'Last 10 Orders'}
                border={true}
            >
                <CommonTable 
                data={ordersData} 
                tableHeader={tableRows} 
                actions={listOfActions}
                />
            </DefaultCardLayout>
        </>
    );
};

export default Dashboard;