import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaUser } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import SelectOptions from "../../../components/ui/SelectOptions";
import BusinessLayout from "../../../layout/BusinessLayout";
import { useGetEmployeesOptionsQuery } from "../../../redux/features/employees/employeesApi";
import { useCreateTeamCardMutation } from "../../../redux/features/teamCards/teamCardsApi";

const CreateTeamCardPage = () => {
  const { data } = useGetEmployeesOptionsQuery();
  const [createCard, { isLoading: createLoading }] =
    useCreateTeamCardMutation();

  const [values, setValues] = useState();
  const navigate = useNavigate();

  const onChange = (key, value) => {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const API = createCard(values);
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate("/business/all-cards");
      }
      if (result?.error?.status === 400) {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <BusinessLayout>
      <Card
        title="Create New Team Card"
        content={
          <Button
            link="/business/all-cards"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            Team cards
          </Button>
        }
        className="space-y-3"
      >
        <Card title="Select Employee">
          <SelectOptions
            name="employeeId"
            icon={<FaUser />}
            value={values?.employeeId}
            onChange={onChange}
            options={data?.data}
            placeholder="Select Employee"
          />
        </Card>

        <Button
          onClick={handleSubmit}
          isDisabled={createLoading}
          className="mt-3 !bg-green-400"
        >
          Save
        </Button>
      </Card>
    </BusinessLayout>
  );
};

export default CreateTeamCardPage;
