import React from "react";
import { FaLink, FaPenToSquare, FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import Table from "../../../components/Table";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import BusinessLayout from "../../../layout/BusinessLayout";

const AddressPage = () => {
  const content = {
    header: ["Address Title", "Address", "Created At", "Actions"],

    body: [
      {
        rows: [
          {
            row: "test",
          },
          {
            row: "test",
          },

          {
            row: "1 day ago",
          },

          {
            row: (
              <div className="flex items-center gap-3">
                <Button link={'/individual/edit-addresses'} className="!bg-green-400">
                  <FaPenToSquare />
                </Button>
                <Button className="!bg-red-400">
                  <MdDelete />
                </Button>
                <Button className="!bg-yellow-500">
                  <FaLink />
                </Button>
              </div>
            ),
          },
        ],
      },
    ],
  };

  return (
    <>
      <Card
        title="Addresses"
        content={
          <Button
            link="/individual/create-addresses"
            className="flex items-center gap-2 !bg-green-500"
          >
            <FaPlus /> Add New Address
          </Button>
        }
      >
        <Table content={content} />
      </Card>
    </>
  );
};

export default AddressPage;
