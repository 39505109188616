import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { GoPencil } from 'react-icons/go';
import { MdClose } from 'react-icons/md';
import Loading from '../../../../components/Loading';
import Button from '../../../../components/ui/Button';
import Card from '../../../../components/ui/Card';
import InputField from '../../../../components/ui/InputField';
import InputFile from '../../../../components/ui/InputFile';
import SocialLinks from '../../../../components/ui/SocialLinks';
import { useEditCardMutation } from '../../../../redux/features/cards/cardsApi';

const SocialMedia = ({ data }) => {
  const [socialView, setSocialView] = useState('preview');
  const [values, setValues] = useState(data);
  const [editProfile, { isLoading }] = useEditCardMutation();
  const [extraSocialLinks, setExtraSocialLinks] = useState(
    data?.additionalSocialLinks || []
  );

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  const handleSocialLinks = (key, value, subKey) => {
    const updateValue = extraSocialLinks?.map((item) => {
      if (item?.id === subKey) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });

    setExtraSocialLinks(updateValue);
  };

  const handleNewSection = (type) => {
    const newValue = {
      id: Math.ceil(Math.random() * 1000000),
    };

    if (type === 'socialLinks') {
      setExtraSocialLinks([...extraSocialLinks, newValue]);
    }
  };

  const handleRemove = (id, type) => {
    const updateSocialLinks = extraSocialLinks?.filter(
      (data) => data?.id !== id
    );

    if (type === 'socialLinks') {
      setExtraSocialLinks(updateSocialLinks);
    }
  };

  const handleSubmit = async () => {
    try {
      const formatData = {
        ...values,
        additionalSocialLinks: extraSocialLinks,
      };
      const API = editProfile({ id: data?._id, data: formatData });
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        setSocialView('preview');
      }
    } catch (error) {
      // console.error(error);
    }
  };

  return (
    <div className="space-y-5">
      <div className="flex items-center justify-between">
        <h4 className="text-[12px] font-bold opacity-70 dark:text-white">
          Social Media
        </h4>
        {socialView === 'preview' && (
          <Button
            onClick={() => setSocialView('edit')}
            className="dark:bg-dark-100 flex h-[35px] !w-[80px] items-center justify-center gap-1 bg-white text-[14px] !text-black dark:text-white"
          >
            <GoPencil size={16} className="pb-0.5" />
            <span>Edit</span>
          </Button>
        )}
      </div>
      {socialView === 'edit' ? (
        <div className="space-y-5">
          <Card className="space-y-4 rounded-xl">
            <InputField
              label="Website"
              name="socialMediaLinks"
              subKey="website"
              value={values?.socialMediaLinks?.website}
              onChange={onChange}
              placeholder="Please Enter Your Website Link"
            />
            <InputField
              label="Facebook"
              name="socialMediaLinks"
              subKey="facebook"
              value={values?.socialMediaLinks?.facebook}
              onChange={onChange}
              placeholder="Please Enter Your Facebook Page Link"
            />

            <InputField
              label="Twitter"
              name="socialMediaLinks"
              subKey="twitter"
              value={values?.socialMediaLinks?.twitter}
              onChange={onChange}
              placeholder="Please Enter Your Twitter Page Link"
            />
            <InputField
              label="Youtube"
              name="socialMediaLinks"
              subKey="youtube"
              value={values?.socialMediaLinks?.youtube}
              onChange={onChange}
              placeholder="Please Enter Your Youtube Page Link"
            />

            <InputField
              label="Instagram"
              name="socialMediaLinks"
              subKey="instagram"
              value={values?.socialMediaLinks?.instagram}
              onChange={onChange}
              placeholder="Please Enter Your Instagram Page Link"
            />
            <InputField
              label="Linkedin"
              name="socialMediaLinks"
              subKey="linkedin"
              value={values?.socialMediaLinks?.linkedin}
              onChange={onChange}
              placeholder="Please Enter Your Linkedin Link"
            />

            <InputField
              label="Telegram"
              name="socialMediaLinks"
              subKey="telegram"
              value={values?.socialMediaLinks?.telegram}
              onChange={onChange}
              placeholder="Please Enter Your Telegram Link"
            />
            <InputField
              label="Link"
              name="socialMediaLinks"
              subKey="linkText"
              value={values?.socialMediaLinks?.linkText}
              onChange={onChange}
              placeholder="Please Enter Your link"
            />

            <InputField
              label="Tiktok"
              name="socialMediaLinks"
              subKey="tiktok"
              value={values?.socialMediaLinks?.tiktok}
              onChange={onChange}
              placeholder="Please Enter Your Tiktok Link"
            />

            <InputField
              label="Snapchat"
              name="socialMediaLinks"
              subKey="snapchat"
              value={values?.socialMediaLinks?.snapchat}
              onChange={onChange}
              placeholder="Please Enter Your Snapchat Link"
            />

            <InputField
              label="Thread"
              name="socialMediaLinks"
              subKey="thread"
              value={values?.socialMediaLinks?.thread}
              onChange={onChange}
              placeholder="Please Enter Your Threads Link"
            />

            <InputField
              label="Video Link"
              name="socialMediaLinks"
              subKey="videoLink"
              value={values?.socialMediaLinks?.videoLink}
              onChange={onChange}
              placeholder="Please Enter Your Profile Video (Youtube) Link"
            />
            <InputField
              label="Resume Link"
              name="socialMediaLinks"
              subKey="resumeLink"
              value={values?.socialMediaLinks?.resumeLink}
              onChange={onChange}
              placeholder="Please Enter Your Resume Link"
            />

            <div className="!mt-6 space-y-5 border-t pt-5 dark:border-gray-800">
              <h2 className="text-[18px] font-bold text-black dark:text-white">
                Additional Social links
              </h2>

              <div className="space-y-6">
                {extraSocialLinks?.map((data) => (
                  <div
                    key={data?.id}
                    className="w-full space-y-2 rounded border p-2"
                  >
                    <div className="flex justify-end">
                      <button
                        onClick={() => handleRemove(data?.id, 'socialLinks')}
                        className="text-red-500"
                        type="button"
                      >
                        <MdClose />
                      </button>
                    </div>

                    <InputFile
                      title="Icon"
                      name="icon"
                      subKey={data?.id}
                      value={data?.icon}
                      onChange={handleSocialLinks}
                    />
                    <InputField
                      label="Name"
                      placeholder="Social Name"
                      name="name"
                      subKey={data?.id}
                      value={data?.name}
                      onChange={handleSocialLinks}
                    />
                    <InputField
                      label="Link"
                      placeholder="Link"
                      name="link"
                      subKey={data?.id}
                      value={data?.link}
                      onChange={handleSocialLinks}
                    />
                  </div>
                ))}
              </div>

              <Button
                onClick={() => handleNewSection('socialLinks')}
                className="!bg-black !text-white"
              >
                Add Social
              </Button>
            </div>

            <div className="flex items-center justify-between gap-4 pt-3">
              <button
                disabled={isLoading}
                className="flex !w-full cursor-pointer items-center justify-center gap-1 rounded-lg !bg-primary px-4 py-2 text-white"
                onClick={handleSubmit}
              >
                {isLoading && <Loading height="0vh" variant="button" />}
                Save
              </button>

              <button
                onClick={() => setSocialView('preview')}
                className="dark:bg-dark-100 !w-full rounded-lg border bg-white px-4 py-2 !text-black dark:border-dark dark:text-white"
              >
                Cancel
              </button>
            </div>
          </Card>
        </div>
      ) : (
        <div>
          <SocialLinks
            data={data?.socialMediaLinks}
            additionalSocialLinks={data?.additionalSocialLinks}
          />
        </div>
      )}
    </div>
  );
};

export default SocialMedia;
