import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import {
  removeUpload,
  // useRemoveUploadMutation,
  useUploadMutation,
} from '../../redux/features/common/commonApi';

const MultiImageUpload = ({ name, value = [], onChange }) => {
  const [fileUpload] = useUploadMutation();
  // const [removeFileUpload] = useRemoveUploadMutation();
  const [images, setImages] = useState(value);

  useEffect(() => {
    setImages(value);
  }, [value]);

  const handleFileChange = async (e) => {
    try {
      const selectedFiles = Array.from(e.target.files);
      const uploadedImages = [...images];

      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append('file', file);
        const result = await fileUpload(formData);
        if (result?.data?.success) {
          uploadedImages.push(result.data.data.url);
        }
      }

      setImages(uploadedImages);
      onChange(name, uploadedImages);
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  };

  const handleRemoveImage = async (index) => {
    try {
      // const result = await removeFileUpload(images[index]);
      await removeUpload(images[index]);
      const updatedImages = images.filter((_, i) => i !== index);
      setImages(updatedImages);
      onChange(name, updatedImages);
    } catch (error) {
      console.error('Error during file remove:', error);
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <input
        type="file"
        onChange={handleFileChange}
        accept="image/*"
        multiple
      />
      <div className="flex space-x-2 overflow-x-auto">
        {images.map((image, index) => (
          <div key={index} className="group relative">
            <img
              src={image}
              alt="uploaded"
              className="h-24 w-24 rounded-md border object-cover"
            />
            <button
              className="absolute right-1 top-1 hidden rounded-full bg-red-500 p-1 text-white group-hover:block"
              onClick={() => handleRemoveImage(index)}
            >
              <FaTimes />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiImageUpload;
