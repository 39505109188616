import React from "react";
import toast from "react-hot-toast";
import { FaEdit, FaEye } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { HiDownload } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import Button from "../../../components/ui/Button";
import { useDeleteWifiCodeMutation } from "../../../redux/features/wifi/wifiApi";
import { downloadQRCode } from "../../../utils/downloadImage";

const SingleCard = ({
  data,
  className,
  isAnalytic,
  isEdit,
  viewLink,
  editLink,
  isDelete,
  isView,
  isDownload,
  refetch,
}) => {

    const [deleteWifi] = useDeleteWifiCodeMutation();
    const handleDeleteWifi = async (id) => {
        try {
            const result = await deleteWifi(id);
            if (result) {
                toast.success(result?.data?.message);
                refetch();
            }
        } catch (error) {
            toast.error('Wifi Deleted Failed');
        }
    };

  return (
    <div
      className={`${className} w-full max-w-[350px] space-y-6 rounded-md border bg-white p-6 pt-8 shadow`}
    >
      <div className="mx-auto h-[200px] w-[200px] rounded-md border">
        <img
          src={data?.qrCodeImage}
          alt=""
          className="w-full"
        />
      </div>
      <div className="text-center">
        <h3 className="font-medium">{data?.ssid}</h3>
        <p className="text-[13px]">{data?.user?.role}</p>
        {/* <p className="text-[13px] opacity-80">{data?.id}</p> */}
      </div>
      <div className="flex items-center justify-center gap-2">
        {isAnalytic && (
          <Button
            link="/business/analytics/11"
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-lemon-100 "
          >
            <GoGraph />
          </Button>
        )}

        {isView && (
          <Button link={viewLink} className="flex h-[40px] w-[60px] items-center justify-center  !bg-blue-100 ">
            <FaEye />
          </Button>
        )}

        {isEdit && (
          <Button
            link={editLink}
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-blue-800 "
          >
            <FaEdit />
          </Button>
        )}
        {isDownload && (
          <Button
            // link="/business/analytics/11"
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-yellow-400 "
            onClick={() => downloadQRCode(data?.qrCodeImage)}
          >
            <HiDownload size={20} />
          </Button>
        )}
        {isDelete && (
          <Button
            // link="/business/analytics/11"
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-red-400 "
            onClick={() => handleDeleteWifi(data?._id)}
          >
            <MdDelete size={20} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default SingleCard;
