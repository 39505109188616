import moment from "moment";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import Loading from "../../../components/Loading";
import Table from "../../../components/Table";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import {
    useDeleteStoreReviewMutation,
    useEditStoreReviewMutation,
    useGetStoreReviewsQuery,
} from "../../../redux/features/storeReview/storeReviewsApi";

const StoreReviewPage = () => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });
  const { data, isLoading } = useGetStoreReviewsQuery(paginate);
  const [deleteStoreReview] = useDeleteStoreReviewMutation();
  const [editStoreReview] = useEditStoreReviewMutation();

  const handleDelete = async (id) => {
    try {
      const result = await deleteStoreReview(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const handleStatusUpdate = async (id, status) => {
    try {
      const data = {
        status: status === "pending" ? "approved" : "pending",
      };
      const result = await editStoreReview({ id, data });
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const content = {
    header: [
      "Date",
      "Store Name",
      "Client Name",
      "Review",
      "Rating",
      "Image",
      "Status",
      "Actions",
    ],

    body: data?.data?.results?.map((item) => ({
      rows: [
        {
          row: moment(item?.createdAt).format("DD MMM YYYY"),
        },
        {
          row: item?.store?.store_contact_information?.store_name,
        },

        {
          row: item?.name,
        },
        {
          row: item?.review_text,
        },
        {
          row: item?.rating,
        },

        {
          row: (
            <img
              className="h-[40px] w-[40px] rounded"
              src={item?.image_url}
              alt=""
            />
          ),
        },
        {
          row: (
            <Button
              onClick={() => handleStatusUpdate(item?._id, item?.status)}
              className={` ${
                item?.status === "approved" ? "!bg-blue-400" : "!bg-yellow-400"
              } !px-2 !py-1 capitalize`}
            >
              {item?.status}
            </Button>
          ),
        },
        {
          row: (
            <div className="flex items-center gap-3">
              <Button
                onClick={() => handleDelete(item?._id)}
                className="!bg-red-400"
              >
                <MdDelete />
              </Button>
            </div>
          ),
        },
      ],
    })),
  };

  return (
    <div className="space-y-6">
      <Card title="Store Reviews">
        {isLoading ? (
          <Loading height="60vh" />
        ) : (
          <Table
            content={content}
            pagination={data?.data?.paginate}
            onPaginate={setPaginate}
          />
        )}
      </Card>
    </div>
  );
};

export default StoreReviewPage;
