import React from "react";
import { useSelector } from "react-redux";
import AdminLayout from "../../layout/AdminLayout";
import AgentLayout from "../../layout/AgentLayout";
import IndividualLayout from "../../layout/IndividualLayout";
import StoreLayout from "../../layout/StoreLayout";
import SubscriptionsPage from "../../modules/admin/subscriptions/SubscriptionsPage";
import Dashboard from "../../modules/agents/Dashboard";
import BusinessStatics from "../../modules/business/statics/StaticsPage";
import IndividualStaticsPage from "../../modules/individual/statics/StaticsPage";
import StoreStaticsPage from "../../modules/store/statics/StaticsPage";

const DashboardPage = () => {
  const { user } = useSelector((state) => state?.auth);

  return (
    <div>
      {user?.role === "admin" ? (
        <AdminLayout>
          <SubscriptionsPage />
        </AdminLayout>
      ) : user?.role === "business" ? (
        <BusinessStatics />
      ) : user?.role === "store" ? (
        <StoreLayout>
          <StoreStaticsPage />
        </StoreLayout>
      ) : user?.role === "agent" ? (
        <AgentLayout>
          <Dashboard />
        </AgentLayout>
      ) : user?.role === "individual" ? (
        <IndividualLayout>
          <IndividualStaticsPage />
        </IndividualLayout>
      ) : (
        ""
      )}
    </div>
  );
};

export default DashboardPage;
