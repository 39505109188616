import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { AiFillProduct } from 'react-icons/ai';
import { CiImageOn } from 'react-icons/ci';
import { FaMobileAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import InputField from '../../../components/ui/InputField';
import InputFile from '../../../components/ui/InputFile';
import SelectOptions from '../../../components/ui/SelectOptions';
import TextAreaField from '../../../components/ui/TextAreaField';
import { countryPhoneOptions } from '../../../data/data';
import {
  useCreateContactMutation,
  useEditContactMutation,
} from '../../../redux/features/contacts/contactsApi';
import { useGetFriendsCategoriesQuery } from '../../../redux/features/friends/friendsApi';
import { formatCategories } from '../../../utils/helper';
const ContactForm = ({ data = {} }) => {
  const [createContact] = useCreateContactMutation();
  const [editContact] = useEditContactMutation();
  const { data: myFriendCategoriesData } = useGetFriendsCategoriesQuery({
    limit: 5,
    sort: 'desc',
  });
  const formattedCategories = formatCategories(
    myFriendCategoriesData?.data?.results
  );
  // const [values, setValues] = useState(data);
  const [values, setValues] = useState({
    ...data,
    phone: data?.contactInfo?.phone || { dialCode: '', fullNumber: '' },
    category:
      formattedCategories?.find((cat) => cat.value === data?.categoryId) ||
      null,
  });

  const navigate = useNavigate();

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };
  const haleCategoriesChange = (key, value) => {
    setValues((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const submitData = {
        ...values,
        contactInfo: {
          ...values.contactInfo,
          phone: values?.phone,
        },
        categoryId: values?.category?.value,
        notes: [values?.note],
      };
      const API = data?._id
        ? editContact({ id: data?._id, data: submitData })
        : createContact(submitData);
      const result = await API;
      // const result = await createContact(submitData);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate('/friends');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Card title="Create New Contact" className="space-y-4">
          <InputField
            name="contactInfo"
            subKey="name"
            placeholder="Name"
            icon={<AiFillProduct />}
            value={values?.contactInfo?.name}
            onChange={onChange}
          />

          <InputField
            name="contactInfo"
            subKey="email"
            placeholder="Email"
            type="email"
            icon={<AiFillProduct />}
            value={values?.contactInfo?.email}
            onChange={onChange}
          />
          <SelectOptions
            placeholder="Friend category..."
            name="category"
            icon={<AiFillProduct />}
            value={values?.category?.value}
            onChange={(name, selectedId) => {
              const selectedCategory = formattedCategories?.find(
                (option) => option.value === selectedId
              );
              haleCategoriesChange(name, selectedCategory);
            }}
            options={formattedCategories}
          />
          <div className=" flex w-full items-center gap-3">
            <SelectOptions
              placeholder="Select Country..."
              options={countryPhoneOptions}
              name="phone"
              subKey="dialCode"
              value={values?.phone?.dialCode}
              onChange={onChange}
            />
            <div className="w-full">
              <InputField
                icon={<FaMobileAlt />}
                name="phone"
                subKey="fullNumber"
                placeholder="Mobile Number"
                type="number"
                value={values?.phone?.fullNumber}
                onChange={onChange}
              />
            </div>
          </div>
          <InputField
            name="workInfo"
            subKey="website"
            placeholder="Website"
            icon={<AiFillProduct />}
            value={values?.workInfo?.website}
            onChange={onChange}
          />
          <InputField
            name="workInfo"
            subKey="company"
            placeholder="Company"
            icon={<AiFillProduct />}
            value={values?.workInfo?.company}
            onChange={onChange}
          />
          <InputField
            name="workInfo"
            subKey="jobTitle"
            placeholder="Job Title"
            icon={<AiFillProduct />}
            value={values?.workInfo?.jobTitle}
            onChange={onChange}
          />

          <TextAreaField
            label="Note"
            name="note"
            value={values?.note}
            onChange={onChange}
            placeholder="Note"
          />

          <Card>
            <InputFile
              title="Photo"
              icon={<CiImageOn />}
              name="photoUrl"
              onChange={onChange}
            />
          </Card>

          <Button type="submit" className="!bg-green-400">
            Save
          </Button>
        </Card>
      </form>
    </div>
  );
};

export default ContactForm;
