import React from 'react';
import Card from '../../../components/ui/Card';
import InputGroup from "../../../components/ui/InputField";
import SelectOptions from '../../../components/ui/SelectOptions';
import Button from '../../../components/ui/Button';

const CreateWifiQr = () => {
    return (
        <>
            <Card title="Generate Wifi QR flex">
                <div className="flex-col space-y-5">
                    <InputGroup
                        placeholder="Network SSID :"
                    />
                    <SelectOptions
                        placeholder="Wifi Encryption :"
                    />
                    <InputGroup
                        placeholder="Wifi Encryption :"
                    />
                </div>
                <Button className={'text-blue-700 bg-white'}>Generate WiFi</Button>
            </Card>
        </>
    );
};

export default CreateWifiQr;