import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import { useEditClientMutation, useGetClientsQuery } from "../../redux/features/clients/clientsApi";
import { formatRelativeTime } from "../../utils/formatRelativeTime";
import ActionButton from "./components/dashboard/ActionButton";
import DefaultCardLayout from "./components/dashboard/DefaultCardLayout";

function UserProfile({ url }) {
    return (
      <img
        className="h-14 w-14 rounded-full object-cover object-center"
        alt="image"
        src={url}
      />
    );
  }


const DemoAccounts = () => {
    const [ isLoadingActivation, setIsLoadingActivation ] = useState(null);
    const navigate = useNavigate();
    const [paginate, setPaginate] = useState({
        page: 1,
        limit: 10,
        isDemoAccount: true,
    });
    const { data, isLoading } = useGetClientsQuery(paginate);
    const [filteredOrders, setFilteredOrders] = useState();
    const [activation, { isLoading: isActiveLoading }] = useEditClientMutation();
    const handleSearch = (term) => {
        // Implement search logic here
        // console.log("Searching for:", term);
    };



    const handleFilter = (filters) => {
        // Implement filter logic here
        // console.log("Applying filters:", filters);
    };



    const handleActive = (payload) => {
        // console.log(payload);
    }

    
    const handleActivation = async (payload) => {
        const data = {
            userInformation: {
                isActive: !payload.isActive,
            }
        }

        try {
            setIsLoadingActivation(payload._id);
            let result = await activation({
                id: payload._id,
                data
            });
            if (result?.data?.success) {
                toast.info(result?.data?.message);
            } else {
                toast.error(result?.error?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message);
        }finally{
            setIsLoadingActivation(null);
        }
    };
    

    const content = {
        header: [
            "Name",
            "Email",
            "Posts",
            "Card/Employee",
            "Cars",
            "Created At",
            "Show Profile",
        ],

        body: data?.data?.results?.map((item) => ({
            rows: [
                {
                    row: (
                        <div className="flex items-center gap-4">
                            <UserProfile url={item?.avatar} />
                            <div>
                                <p>{item?.fullName || item?.email}</p>
                                <p className="text-gray-400">{item?.role}</p>
                            </div>
                        </div>
                    ),
                },
                {
                    row: item?.email,
                },
                {
                    row: item?.postCount || '--',
                },
                {
                    row: item?.contacts || 0,
                },
                {
                    row: !item?.card ? (
                        "--"
                    ) : (
                        <div>
                            <p>{item.card || 0} Card</p>
                            <p className="text-gray-400">{item || 0} Car</p>
                        </div>
                    ),
                },

                {
                    row: formatRelativeTime(item?.createdAt),
                },
                {
                    row: (
                        <div className="flex gap-3">
                            <ActionButton
                                onClick={() => handleActivation(item)}
                                title={isLoadingActivation === item?._id ? <Loading variant="button" height="24px" /> : (item?.isActive ? "Deactivate User" : 'Active User')}
                                styles={"h-full px-3 bg-green-400"}
                            />
                        </div>
                    ),
                },
            ],
        })),
    };


    return (
        <>
            <div className="mb-10">
                <DefaultCardLayout
                    title={'Demo Accounts'}
                    border={true}
                >
                    {isLoading ? (
                        <Loading height="70vh" />
                    ) : (
                        <Table
                            content={content}
                            pagination={data?.data?.paginate}
                            onPaginate={setPaginate}
                        />
                    )}
                </DefaultCardLayout>
            </div>
        </>
    );
};

export default DemoAccounts;