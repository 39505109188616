import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import Card from "../../components/ui/Card";
import { useGetMessagesQuery } from "../../redux/features/message/messageApi";
import { useState } from "react";
import { getFullNameByLayer } from "../../utils/func";

const tableRows = [
    "Date",
    "Name",
    "Email",
    "Phone",
    "Subject",
    "Message",
];

const WebMessages = () => {
    const [paginate, setPaginate] = useState({
        page: 1,
        limit: 10,
      });   
    const { user } = useSelector((state) => state?.auth);
    const {data, isLoading} = useGetMessagesQuery({id: user?._id, ...paginate}, {skip: !user?._id});
    

    const content = {
        header: ["Serial Number", "Sender", "Message",],
        body: data?.data?.results?.map((item, i) => ({
            rows: [
                { row: i + 1},
                { row: getFullNameByLayer(item?.sender) || '-- --' },
                { row: item?.message },
            ],
        })),
    };


    return (
        <>
            <Card title="Web Messages">
                {isLoading ? (
                    <Loading height="70vh" />
                ) : (
                    <Table
                        content={content}
                    pagination={data?.data?.paginate}
                    onPaginate={setPaginate}
                    />
                )}
            </Card>
        </>
    );
};

export default WebMessages;