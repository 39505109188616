import { apiSlice } from "../api/apiSlice";

export const employeesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: (arg) => ({
        url: `/employees?page=${arg?.page}&limit=${arg?.limit}&q=${
          arg?.q || ""
        }`,
        method: "GET",
      }),
      providesTags: ["employees"],
    }),

    getEmployee: builder.query({
      query: (id) => ({
        url: `/employees/${id}`,
        method: "GET",
      }),
      providesTags: ["employees"],
    }),

    getEmployeesOptions: builder.query({
      query: () => ({
        url: `/employees/options`,
        method: "GET",
      }),
    }),

    createEmployee: builder.mutation({
      query: (data) => ({
        url: "/employees",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["employees"],
    }),

    editEmployee: builder.mutation({
      query: ({ id, data }) => ({
        url: `/employees/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["employees"],
    }),

    deleteEmployee: builder.mutation({
      query: (id) => ({
        url: `/employees/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["employees"],
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useGetEmployeesOptionsQuery,
  useGetEmployeeQuery,
  useCreateEmployeeMutation,
  useEditEmployeeMutation,
  useDeleteEmployeeMutation,
} = employeesApi;
