import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import Button from "../../components/ui/Button";
import Card from "../../components/ui/Card";
import {
    useDeleteQrCodeMutation,
    useGetQrCodesQuery,
} from "../../redux/features/qrCodes/qrCodesApi";
import DefaultCardLayout from "./components/dashboard/DefaultCardLayout";

const QRPlayground = () => {
    const navigate = useNavigate();
    const [paginate, setPaginate] = useState({
        page: 1,
        limit: 10,
      });    
    const { data, isLoading } = useGetQrCodesQuery({
        page: paginate?.page,
        limit: paginate?.limit,
      });
    const [deleteQrCode] = useDeleteQrCodeMutation();

    const handleDelete = async (id) => {
        try {
            let result = await deleteQrCode(id);
            if (result?.data?.success) {
                toast.success(result?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    };


    const content = {
        header: ["Name", "Content", "QR Category", "QR Sub-Category", "QR", "Date", "Actions"],
        body: data?.data?.results?.map((item) => ({
            rows: [
                { row: item?.name },
                { row: item?.content },
                { row: item?.QRCategory?.name },
                { row: item?.QRSubCategory?.name || 'N/A' },
                {
                    row: (
                        <div>
                            <img src={item?.qrCodeImage} alt="" />
                            {/* <QRCode value={item?.content} size={100} fgColor={item?.color} /> */}
                        </div>
                    ),
                },
                { row: item?.createdAt },
                {
                    row: (
                        <Button
                            onClick={() => handleDelete(item?._id)}
                            className="!bg-red-50 !text-red-400 hover:!bg-red-400 hover:!text-white  "
                        >
                            Delete
                        </Button>
                    ),
                },
            ],
        })),
    };

    return (
        <>
            <DefaultCardLayout
                title={"QR Links"}
                action={
                    <div className="flex gap-3">
                        <button
                            className="rounded bg-[#009ef7] px-4 py-2 text-white"
                            onClick={() => navigate("/agent/qr-create")}
                        >
                            Create New QR
                        </button>
                    </div>
                }
            >
                <Card>
                    {isLoading ? <Loading height="60vh" /> : <Table
                        content={content}
                        pagination={data?.data?.paginate}
                        onPaginate={setPaginate}
                    />}
                </Card>
            </DefaultCardLayout>
        </>
    );
};

export default QRPlayground;
