import React from 'react';
import { useParams } from 'react-router-dom';
import BusinessLayout from '../../../layout/BusinessLayout';
import { useGetSingleContactByIdQuery } from '../../../redux/features/friends/friendsApi';
import Loading from '../../Loading';
import ContactForm from './ContactForm';

const EditContactPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSingleContactByIdQuery(id, { skip: !id });
  return (
    <div>
      <BusinessLayout>
        {isLoading ? (
          <Loading height="70vh" />
        ) : (
          <ContactForm data={data?.data} />
        )}
      </BusinessLayout>
    </div>
  );
};

export default EditContactPage;
