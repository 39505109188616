import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CVForm from './CVForm';
const TemplateSelectionPage = () => {
  const navigate = useNavigate();
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const templates = [
    //{ id: 1, name: 'Template 1', button: '', previewImg: '/path/to/template1.png' },
    //{ id: 2, name: 'Template 2', button: '', previewImg: '/path/to/template2.png' },
    {
      id: 3,
      name: 'Confidence',
      button: 'Use this template',
      previewImg: '/confidence.png',
    },
  ];

  const handleTemplateSelect = (id) => {
    setSelectedTemplate(id);
  };

  const handleFormSubmit = (formData) => {
    navigate(`/template/${selectedTemplate}`, { state: { formData } });
  };

  return (
    <div className="container mx-auto p-12">
      {selectedTemplate ? (
        <>
          {/* <GeocodingExample /> */}
          <h1 className="mb-4 text-2xl font-bold">Fill Your Details</h1>
          <CVForm onSubmit={handleFormSubmit} />
        </>
      ) : (
        <>
          <h1 className="mb-4 text-2xl font-bold">Choose a CV Template</h1>
          <div className=" grid grid-cols-3 gap-1">
            {templates.map((template) => (
              <div
                key={template.id}
                className="h-[550px]  w-[400px] cursor-pointer rounded-lg border bg-gray-200 p-4 hover:shadow-lg"
                onClick={() => handleTemplateSelect(template.id)}
              >
                <img
                  src={template.previewImg}
                  alt={template.name}
                  className="h-[400px] w-full object-fill"
                />
                <h2 className="mt-4 text-center text-lg font-semibold">
                  {template.name}
                </h2>
                <button className="ml-4 mt-2 h-10 w-80 rounded-lg bg-blue-950 text-center text-lg text-white">
                  {template.button}
                </button>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TemplateSelectionPage;
