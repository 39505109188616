import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const ProfileTypesChart = ({ data }) => {
  const chartData = {
    labels: ['Personal', 'Business', 'Company'],
    datasets: [
      {
        data: data,
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
        },
      },
    },
  };

  // <div className="bg-white shadow-md rounded-lg p-4 w-full max-w-2xl mx-auto">
//   </div>
  return (
      <div className="h-64 md:h-96 p-5 lg:p-8">
        <Pie data={chartData} options={options} />
      </div>
  );
};

export default ProfileTypesChart;