import { useState } from "react";
import toast from "react-hot-toast";
import { GoPencil } from "react-icons/go";
import { MdClose } from "react-icons/md";
import Loading from "../../../../components/Loading";
import Button from "../../../../components/ui/Button";
import Card from "../../../../components/ui/Card";
import InputField from "../../../../components/ui/InputField";
import SelectOptions from "../../../../components/ui/SelectOptions";
import { countryPhoneOptions } from "../../../../data/data";
import { useEditCardMutation } from "../../../../redux/features/cards/cardsApi";

const AdditionalInfo = ({ data, store }) => {
  const [viewType, setViewType] = useState("preview");
  const [values, setValues] = useState(data);
  const [editProfile, { isLoading }] = useEditCardMutation();
  const [additionalNumber, setAdditionalNumber] = useState(
    data?.additionalPhones || []
  );
  const [marketplaceInfos, setMarketplaceInfos] = useState(
    data?.marketplaceStores || []
  );
  const [bankInfos, setBankInfos] = useState(data?.bankAccounts || []);

  // additional phones, bank and marketplace store functionality
  const handleNewSection = (type) => {
    const newValue = {
      id: Math.ceil(Math.random() * 1000000),
    };

    if (type === "phone") {
      setAdditionalNumber([...additionalNumber, newValue]);
    }
    if (type === "bank") {
      setBankInfos([...bankInfos, newValue]);
    }
    if (type === "marketplace") {
      setMarketplaceInfos([...marketplaceInfos, newValue]);
    }
  };

  const handleRemove = (id, type) => {
    const updateNumbers = additionalNumber?.filter((data) => data?.id !== id);
    const updateBankInfo = bankInfos?.filter((data) => data?.id !== id);
    const updateMarketplaceInfo = marketplaceInfos?.filter(
      (data) => data?.id !== id
    );

    if (type === "phone") {
      setAdditionalNumber(updateNumbers);
    }
    if (type === "bank") {
      setBankInfos(updateBankInfo);
    }
    if (type === "marketplace") {
      setMarketplaceInfos(updateMarketplaceInfo);
    }
  };

  // additional phone, bank and marketplace functionality
  const handlePhoneChange = (key, value, subKey) => {
    const updateValue = additionalNumber?.map((item) => {
      if (item.id === subKey) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });

    setAdditionalNumber(updateValue);
  };
  const handleBankAccount = (key, value, subKey) => {
    const updateValue = bankInfos?.map((item) => {
      if (item.id === subKey) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });

    setBankInfos(updateValue);
  };
  const handleMarketplace = (key, value, subKey) => {
    const updateValue = marketplaceInfos?.map((item) => {
      if (item.id === subKey) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });

    setMarketplaceInfos(updateValue);
  };

  // SUBMIT FUNCTION
  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitData = {
      ...values,
      additionalPhones: additionalNumber,
      bankAccounts: bankInfos,
      marketplaceStores: marketplaceInfos,
    };
    try {
      const API = editProfile({ id: data?._id, data: submitData });

      const response = await API;
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setViewType("preview");
      }
      if (response?.error?.status === 400) {
        toast.error(response?.error?.data?.message);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="space-y-3">
      <div className="flex w-full items-center justify-between">
        <h4 className="text-[12px] font-bold opacity-70 dark:text-white">
          Additional Information
        </h4>
        {viewType === "preview" && (
          <Button
            onClick={() => setViewType("edit")}
            className="dark:bg-dark-100 flex h-[35px] !w-[80px] items-center justify-center gap-1 bg-white text-[14px] !text-black dark:text-white"
          >
            <GoPencil size={16} className="pb-0.5" />
            <span>Edit</span>
          </Button>
        )}
      </div>

      {viewType === "preview" ? (
        <Card className="rounded-[20px]">
          <div className="space-y-3">
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Additional Phone Number</h4>
              {data?.additionalPhones?.map((d) => (
                <p key={d?.id} className="opacity-70">{`${d?.dialCode ?? ""} ${
                  d?.fullNumber ?? ""
                }`}</p>
              ))}
            </div>
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Bank Account | IBAN</h4>
              {data?.bankAccounts?.map((d) => (
                <div key={d?.id}>
                  <p className="opacity-70">{`Bank Name: ${
                    d?.bankName ?? ""
                  }`}</p>
                  <p className="opacity-70">{`Account Name: ${
                    d?.accountName ?? ""
                  }`}</p>
                  <p className="opacity-70">{`Account No: ${
                    d?.accountNo ?? ""
                  }`}</p>
                </div>
              ))}
            </div>
            {store?.isCardByStoreExist && (
              <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
                <h4 className="font-bold">Marketplace Stores</h4>
                {data?.marketplaceStores?.map((d) => (
                  <div key={d?.id}>
                    <p className="opacity-70">{`Marketplace Name: ${
                      d?.marketplaceName ?? ""
                    }`}</p>
                    <p className="opacity-70">{`Account Store: ${
                      d?.marketplaceStore ?? ""
                    }`}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Card>
      ) : (
        <div className="space-y-5">
          <Card className="space-y-6 rounded-xl">
            <div className="space-y-3">
              <h3 className="font-bold text-black">Additional Phone Number</h3>

              {additionalNumber?.map((data) => (
                <div
                  key={data?.id}
                  className="w-full space-y-2 rounded border p-2"
                >
                  <div className="flex justify-end">
                    <button
                      onClick={() => handleRemove(data?.id, "phone")}
                      className="text-red-500"
                      type="button"
                    >
                      <MdClose />
                    </button>
                  </div>
                  <SelectOptions
                    label="Country"
                    placeholder="Select Country..."
                    options={countryPhoneOptions}
                    name="dialCode"
                    subKey={data?.id}
                    value={data?.dialCode}
                    onChange={handlePhoneChange}
                  />
                  <div className="w-full">
                    <InputField
                      label="Phone"
                      name="fullNumber"
                      placeholder="Mobile Number"
                      type="number"
                      subKey={data?.id}
                      value={data?.fullNumber}
                      onChange={handlePhoneChange}
                    />
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="isWhatsapp"
                      checked={data?.isWhatsapp}
                      subKey={data?.id}
                      value={data?.isWhatsapp}
                      onChange={(e) =>
                        handlePhoneChange(
                          "isWhatsapp",
                          e?.target?.checked,
                          data?.id
                        )
                      }
                    />
                    <label htmlFor="" className="text-[12px]">
                      Whatsapp
                    </label>
                    {/* <button
                      onClick={() => handleRemove(data?.id, "phone")}
                      className="text-red-500"
                      type="button"
                    >
                      <MdClose />
                    </button> */}
                  </div>
                </div>
              ))}

              <Button
                onClick={() => handleNewSection("phone")}
                className="mx-auto !bg-black !text-[12px]"
              >
                Add Phone Number
              </Button>
            </div>

            <div className="space-y-3 border-t pt-5">
              <h3 className="font-bold text-black">Bank Account | IBAN</h3>
              {bankInfos?.map((data) => (
                <div
                  key={data?.id}
                  className="w-full space-y-2 rounded border p-2"
                >
                  <div className="flex justify-end">
                    <button
                      onClick={() => handleRemove(data?.id, "bank")}
                      className="text-red-500"
                      type="button"
                    >
                      <MdClose />
                    </button>
                  </div>
                  <SelectOptions
                    label="Select Bank"
                    placeholder="Select bank..."
                    options={[{ label: "text", value: "text" }]}
                    name="bankName"
                    subKey={data?.id}
                    value={data?.bankName}
                    onChange={handleBankAccount}
                  />

                  <InputField
                    label="Bank Name"
                    placeholder="Bank Account Name"
                    name="accountName"
                    subKey={data?.id}
                    value={data?.accountName}
                    onChange={handleBankAccount}
                  />
                  <InputField
                    label="Bank No"
                    placeholder="IBAN No"
                    name="accountNo"
                    subKey={data?.id}
                    value={data?.accountNo}
                    onChange={handleBankAccount}
                  />
                </div>
              ))}

              <Button
                onClick={() => handleNewSection("bank")}
                className="mx-auto !bg-black !text-[12px]"
              >
                Add Bank Account
              </Button>
            </div>

            {store?.isCardByStoreExist && (
              <div className="space-y-3 border-t pt-5">
                <h3 className="font-bold text-black">Marketplace Stores</h3>
                {marketplaceInfos?.map((data) => (
                  <div
                    key={data?.id}
                    className="w-full space-y-2 rounded border p-2"
                  >
                    <div className="flex justify-end">
                      <button
                        onClick={() => handleRemove(data?.id, "marketplace")}
                        className="text-red-500"
                        type="button"
                      >
                        <MdClose />
                      </button>
                    </div>
                    <SelectOptions
                      label="Select Marketplace"
                      placeholder="Select Marketplace..."
                      options={[{ label: "text", value: "text" }]}
                      name="marketplaceName"
                      subKey={data?.id}
                      value={data?.marketplaceName}
                      onChange={handleMarketplace}
                    />

                    <InputField
                      label="Marketplace Store"
                      placeholder="Marketplace Stores Url"
                      name="marketplaceStore"
                      subKey={data?.id}
                      value={data?.marketplaceStore}
                      onChange={handleMarketplace}
                    />
                  </div>
                ))}

                <Button
                  onClick={() => handleNewSection("marketplace")}
                  className="mx-auto !bg-black !text-[12px]"
                >
                  Add Marketplace Store
                </Button>
              </div>
            )}

            <div className="flex items-center justify-between gap-4 pt-5">
              <button
                disabled={isLoading}
                className="flex !w-full cursor-pointer items-center justify-center gap-1 rounded-lg !bg-primary px-4 py-2 text-white"
                onClick={handleSubmit}
              >
                {isLoading && <Loading height="0vh" variant="button" />}
                Save
              </button>

              <button
                onClick={() => setViewType("preview")}
                className="dark:bg-dark-100 !w-full rounded-lg border bg-white px-4 py-2 !text-black dark:border-dark dark:text-white"
              >
                Cancel
              </button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default AdditionalInfo;
