import React, { useState } from "react";
import Header from "../components/Headers/Header";
import Sidebar from "../components/Sidebar";

const BusinessLayout = ({ children, className }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-[#F1F3F6] dark:bg-[#151521]">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <main>
            <div
              style={{
                minHeight: "calc(100vh - 130px)",
              }}
              className={`${className} mx-auto max-w-screen-2xl p-8`}
            >
              {children}
            </div>
          </main>

          <div className="bg-white px-6 py-4 dark:bg-dark">
            <p className="text-[13px]">
              <b className="text-slate-400">2024©</b> Socalii
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessLayout;
