import React from "react";
import QrCodeForm from "./QrCodeForm";

const QrCodeCreatePage = () => {
  return (
    <div>
      <QrCodeForm />
    </div>
  );
};

export default QrCodeCreatePage;
