import bgWave from '../../../../images/agents/wave-bg-black.svg'

const FlashCard = ({ color, textColor, icon, number, title, subtitle, breakdown }) => {
    // let bgColor = 'bg-['+color+']';

    return (
        <div className={`rounded-lg shadow-sm flex flex-col justify-between h-full bg-contain bg-right bg-no-repeat bg-blend-color-dodge overflow-hidden`} style={{ backgroundImage: `url(${bgWave})`, backgroundColor: color, color: textColor, }}>
            <div className='p-6'>
                <div className="flex justify-between items-center mb-4">
                    <div className={`bg[-${color}] p-5 rounded-full border border-dashed`} style={{borderColor: textColor }}>
                        {icon}
                    </div>
                </div>
                <br />
                <div className="flex items-center gap-4">
                    <span className="text-4xl lg:text-6xl font-bold">{number}</span>
                    <div className="text-sm font-semibold mb-2 w-16 whitespace-pre-wrap">{title}</div>
                </div>
                    {/* <div className="text-xs opacity-75">{subtitle}</div> */}
            </div>
            {breakdown && (
                <div className={`mt-4 grid ${breakdown.length > 1 ? 'grid-cols-3' : 'grid-cols-1'} gap-2 text-xs p-6`} style={{background:'rgba(0, 0, 0, 0.05)'}}>
                    {breakdown.map((item, index) => (
                        <div key={index}>
                            <div className="font-bold text-2xl">{item.value}</div>
                            <div className="opacity-75">{item.label}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
};


export default FlashCard;