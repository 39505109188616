

import React, { useState } from 'react';
import { FaSmile, FaBirthdayCake, FaHeart, FaGift } from 'react-icons/fa';
import { BsSend } from 'react-icons/bs';
import noImg from '../../images/shared/no-image.jpg';
import { useCreateBirthdayWishMutation, useGetRecentBirthdayNotificationsQuery, useGetUpcomingBirthdayNotificationsQuery } from '../../redux/features/notifications/notificationApi';
import toast from 'react-hot-toast';
import { getFullNameByLayer } from '../../utils/func';
import moment from 'moment';



const BirthdayNotificationsPage = () => {
    const [recentBirthdays, setRecentBirthdays] = useState([
        { id: 1, name: 'Md Kader', date: '8 September 1983', age: 41, image: noImg },
        { id: 2, name: 'MD Eliays Hasah', date: '9 September 1999', age: 25, image: noImg },
    ]);
    const [upcomingBirthdays, setUpcomingBirthdays] = useState([
        { id: 3, name: 'Mehedi Hasan', date: '14 September 1998', age: 26, image: noImg },
    ]);


    const { data: recentData } = useGetRecentBirthdayNotificationsQuery();
    const { data: upcomingData } = useGetUpcomingBirthdayNotificationsQuery();




    return (
        <div className="max-w-5xl mx-auto p-4">
            <div className="bg-white dark:bg-dark text-black dark:text-gray-300 rounded-lg shadow-md overflow-hidden mb-4">
                <h2 className="text-xl font-semibold p-4 bg-gray-50 dark:bg-black-300">Recent birthdays</h2>
                <div className="p-4">
                    {recentData?.data?.results?.map(person => (
                        <BirthdayCard key={person._id} person={person} isRecent={true} />
                    ))}
                </div>
            </div>
            <div className="bg-white dark:bg-dark text-black dark:text-gray-300 rounded-lg shadow-md overflow-hidden">
                <h2 className="text-xl font-semibold p-4 bg-gray-50 dark:bg-black-300">Upcoming birthdays</h2>
                <div className="p-4">
                    {upcomingData?.data?.results?.map(person => (
                        <BirthdayCard key={person._id} person={person} isRecent={false} />
                    ))}
                </div>
            </div>
        </div>
    );
};


export default BirthdayNotificationsPage;




const BirthdayCard = ({ person, isRecent }) => {
    const [birthDayData, setBirthDayData] = useState('');

    const [makeWish, { isLoading: isMakeWishLoading }] = useCreateBirthdayWishMutation();

    // console.log(recentData, upcomingData);
    const ButtonData = [
        { icon: FaBirthdayCake, text: (person) => `Happy Birthday, ${person?.firstName?.split(' ')[0]}!` },
        { icon: FaGift, text: 'HBD!' },
        { icon: FaHeart, text: 'Sending love and happy vibes!' }
    ];

    const ButtonData2 = [
        {
            icon: FaBirthdayCake,
            text: (person) => `Happy Birthday, ${person?.firstName?.split(' ')[0]}!`,
        },
        {
            icon: FaHeart,
            text: 'Happy birthday to you!',
        }
    ];


    const handleMakeWish = async (id, content) => {
        const makePayload = {
            receiverUserId: id,
            content: content,
            // videos: null,
            // images: null,            
        }

        try {
            let result = await makeWish(makePayload);

            if (result?.data?.success) {
                toast.success(result?.data?.message);
                setBirthDayData('');
                //   navigate("/post");
            }

            if (result?.error?.status === 400) {
                toast.error(result?.error?.data?.message);
                // setBirthDayData('');w
            }
        } catch (error) {
            toast.error("Birthday wish Failed");
        }
    }

    return (
        <div className="bg-white dark:bg-dark text-black dark:text-gray-300 p-4 rounded-lg shadow mb-4">
            <div className="flex items-center justify-between mb-2">
                <div className="flex items-center">
                    <img src={person.avatar} alt={getFullNameByLayer(person)} className="w-12 h-12 rounded-full mr-3" />
                    <div>
                        <h3 className="font-semibold">{getFullNameByLayer(person)}</h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400">{moment(person?.dateOfBirth).format('DD MMMM YYYY')}</p>
                    </div>
                </div>
                <span className="text-sm text-gray-600 dark:text-gray-400">{moment().diff(moment(person?.dateOfBirth), 'years')} years old</span>
            </div>
            <div className="flex items-center bg-gray-100 rounded-full px-3 py-2 mb-2">
                <input value={birthDayData} onChange={(e) => setBirthDayData(e.target.value)} type="text" placeholder={`Write on ${person?.firstName?.split(' ')[0]}'s profile...`} className="bg-transparent flex-grow outline-none text-sm" />
                <button className="text-gray-400 hover:text-gray-600 ml-2"><BsSend className="w-5 h-5" onClick={() => handleMakeWish(person?._id, birthDayData)} /></button>
            </div>
            {isRecent && (
                <div className="flex flex-wrap gap-2">
                    {ButtonData.map((button, index) => (
                        <button key={index} className="flex items-center bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm" onClick={() => handleMakeWish(person?._id, button.text)}>
                            <button.icon className="mr-1" />
                            {typeof button.text === 'function' ? button.text(person) : button.text}
                        </button>
                    ))}
                </div>
            )}
            {!isRecent && (
                <div className="flex flex-wrap gap-2">
                    {ButtonData2.map((button, index) => (
                        <button
                            key={index}
                            className="flex items-center bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm"
                            onClick={() => handleMakeWish(person?._id, button.text)}
                        >
                            <button.icon className="mr-1" />
                            {typeof button.text === 'function' ? button.text(person) : button.text}
                        </button>
                    ))}
                </div>
            )}
        </div>
    )
};