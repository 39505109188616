import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import StoreHeader from "../components/Headers/StoreHeader";
import Sidebar from "../components/Sidebar";

const StoreLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-[#F1F3F6] dark:bg-[#151521]">
          <StoreHeader
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          <main>
            <div className="mx-auto max-w-screen-2xl p-2">
              {children && (
                <div className="mx-auto max-w-screen-2xl p-2">{children}</div>
              )}
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default StoreLayout;
