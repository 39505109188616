import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import "react-phone-input-2/lib/style.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import GetUserCredential from "./GetUserCredential";
import "./index.css";
import { store } from "./redux/store";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <GetUserCredential />
        <App />
        <Toaster position="top-center" />
        <div id="portal-root"></div>
      </Router>
    </Provider>
  </React.StrictMode>
);
