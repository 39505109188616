import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { useGetCardQuery } from '../../../redux/features/cards/cardsApi';
import ClassicView from './ClassicView';
import ModernView from './modernView/ModernView';

const EditCardPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetCardQuery(id);
  const [viewMode, setViewMode] = useState(
    data?.data?.user?.userMode || 'classic'
  );
  return (
    <div className="space-y-6">
      <div className="mx-auto flex w-[160px] items-center justify-between gap-3 rounded-md bg-white">
        <button
          onClick={() => setViewMode('classic')}
          className={`${
            viewMode === 'classic'
              ? '!bg-green-500 !text-white'
              : '!bg-transparent !text-black'
          } flex w-[80px] justify-center rounded-l-lg px-3 py-2 text-[14px] `}
        >
          Classic
        </button>
        <button
          onClick={() => setViewMode('modern')}
          className={`${
            viewMode === 'modern'
              ? '!bg-green-500 !text-white'
              : '!bg-transparent !text-black'
          } flex w-[80px] justify-center rounded-r-lg px-3 py-2 text-[14px]`}
        >
          Modern
        </button>
      </div>

      {isLoading ? (
        <div className="flex w-full items-center justify-center">
          <Loading height="50vh" />
        </div>
      ) : (
        <>
          {viewMode === 'modern' ? (
            <ModernView card={data?.data} viewMode={viewMode} />
          ) : (
            <ClassicView card={data} viewMode={viewMode} />
          )}
        </>
      )}
    </div>
  );
};

export default EditCardPage;
