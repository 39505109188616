import React from 'react';

import BusinessLayout from '../../../layout/BusinessLayout';

import ContactForm from './ContactForm';
const AddNewContactPage = () => {
  return (
    <BusinessLayout>
      <ContactForm />
    </BusinessLayout>
  );
};

export default AddNewContactPage;
