import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
// import Loading from "../../Loading";
// import Button from "../../ui/Button";
// import InputField from "../../ui/InputField";
import { useEditQrCategoryMutation, useGetSingleQrCategoryQuery } from "../../../redux/features/qrCategoryApi/qrCategoryApi";
import Loading from "../../Loading";
import { useGetFriendsSingleCategoriesQuery, useUpdateFriendsCategoriesMutation } from "../../../redux/features/friends/friendsApi";
import DefaultCardLayout from "../../../modules/agents/components/dashboard/DefaultCardLayout";
import InputField from "../../ui/InputField";
import Button from "../../ui/Button";
// import DefaultCardLayout from "../../../modules/agents/components/dashboard/DefaultCardLayout";
// import DefaultCardLayout from "../../agents/components/dashboard/DefaultCardLayout";

const EditFriendsCategories = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [values, setValues] = useState({});
  const { data, isLoading } = useGetFriendsSingleCategoriesQuery({id});
  const [editCategory, {isLoading: categoryCreateLoading}] = useUpdateFriendsCategoriesMutation();
  
  useEffect(() => {
    if(data?.success){
        setValues({
            name: data?.data?.name, 
            // sortOrder: data?.data?.sortOrder,
        })
    }
  }, [data])

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  const handleSaveCategories = async () => {
    try {
        let result = await editCategory({id, ...values});
        if (result?.data?.success) {
          toast.success(result?.data?.message);
          setValues({});
          navigate('/friends/categories')
        }
      } catch (error) {
          toast.error(error?.response?.data?.message);
      }
  }

  return (
    <>
      <DefaultCardLayout
        title={"Edit QR Category"}

        action={
        <div className="flex gap-3">
          <button
            className="rounded bg-[#009ef7] px-4 py-2 text-white"
            onClick={() => navigate("/friends/categories")}
          >
            Friends Categories List
          </button>
        </div>
        }
      >
          {isLoading ? <Loading height="60vh" /> : 
        <div className="p-8 gap-16">
            <div className="space-y-5">
                <InputField name={'name'} onChange={onChange} value={values?.name} type="text" placeholder={'Category name'} isRequired={true} />
                {/* <InputField name={'sortOrder'} onChange={onChange} value={values?.sortOrder} type="number" placeholder={'Sort order'} isRequired={true} /> */}

                <Button className="mt-3 !bg-green-400" onClick={handleSaveCategories}>
                {categoryCreateLoading ? <Loading height="24px" /> : "Update"}
                </Button>
            </div>
        </div>
          }
      </DefaultCardLayout>
    </>
  );
};

export default EditFriendsCategories;