import React from "react";
import ProductForm from "./ProductForm";

const CreateProductPage = () => {
  return (
    <>
      <ProductForm />
    </>
  );
};

export default CreateProductPage;
