import { apiSlice } from "../api/apiSlice";

export const qrCategoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQrCategories: builder.query({
      query: (arg) => ({
        url: `/qr-categories`,
        method: "GET",
        params: {...arg}
      }),
      providesTags: ["qrCategories"],
    }),

    getQrCategoryOptions: builder.query({
      query: () => ({
        url: `/qr-categories/options`,
        method: "GET",
      }),
    }),

    getSingleQrCategory: builder.query({
      query: (id) => ({
        url: `/qr-categories/${id}`,
        method: "GET",
      }),
      providesTags: ["qrCategories"],
    }),

    createQrCategory: builder.mutation({
      query: (data) => ({
        url: "/qr-categories",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["qrCategories"],
    }),

    editQrCategory: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/qr-categories/${id}`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["qrCategories"],
    }),

    deleteQrCategory: builder.mutation({
      query: (id) => ({
        url: `/qr-categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["qrCategories"],
    }),
  }),
});

export const {
  useGetQrCategoriesQuery,
  useGetQrCategoryOptionsQuery,
  useGetSingleQrCategoryQuery,
  useCreateQrCategoryMutation,
  useEditQrCategoryMutation,
  useDeleteQrCategoryMutation,
} = qrCategoryApi;