import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import SingleCard from '../../../components/SingleCard';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { useGetWifiCodesQuery } from '../../../redux/features/wifi/wifiApi';

const WifiQrPage = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [values, setValues] = useState({});
  const { data, refetch } = useGetWifiCodesQuery({
    qrCategoryId: values?.qrCategoryId,
    qrSubCategoryId: values?.qrSubCategoryId,
  });
  // const { data: qrCategoriesData } = useGetQrCategoryOptionsQuery();
  // const { data: qrSubCategoriesData } = useGetQrSubCategoryOptionsQuery(
  //   { QRCategoryId: values?.qrCategoryId },
  //   { skip: !values?.qrCategoryId }
  // );

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  // console.log(object);
  const handleRefetch = () => {
    refetch();
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  // useEffect(() => {
  //   if (data?.data?.results?.length === 0) {
  //     navigate("/wifi-qr/create");
  //   }
  // }, [data]);

  return (
    <div className="space-y-6">
      <Card
        title="WIFI QR"
        content={
          <Button
            link="/wifi-qr/create"
            className="flex items-center gap-2 !bg-green-500"
          >
            <FaPlus /> Add New
          </Button>
        }
      >
        {/* <div className="flex items-center justify-end gap-4 pb-6">
          <SelectOptions
            placeholder="QR Category"
            name={"qrCategoryId"}
            value={values?.qrCategoryId}
            onChange={onChange}
            options={qrCategoriesData?.data}
          />
          <SelectOptions
            placeholder="QR Sub Category"
            name={"qrSubCategoryId"}
            value={values?.qrSubCategoryId}
            onChange={onChange}
            options={qrSubCategoriesData?.data}
          />
        </div> */}

        <div className="grid justify-center gap-5 md:grid-cols-3 lg:grid-cols-4">
          {data?.data?.results?.map((item, i) => (
            <SingleCard
              key={i}
              // data={{
              //     name: {item?.ssid},
              //     id: "Personal",
              // }}
              data={item}
              refetch={handleRefetch}
              isDelete
              isDownload
            />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default WifiQrPage;
