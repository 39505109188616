import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { useGetProductQuery } from '../../../redux/features/products/productsApi';
import ProductForm from './ProductForm';

const EditProductPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetProductQuery(id);

  return (
    <>
      {isLoading ? (
        <Loading height="70vh" />
      ) : (
        <ProductForm data={data?.data} />
      )}
    </>
  );
};

export default EditProductPage;
