import React from "react";

const TextAreaField = ({
  className,
  label,
  name,
  placeholder,
  rows = "3",
  value,
  onChange,
  subKey = "",
  isRequired = false,
}) => {
  return (
    <div>
      {label && <h3 className="pb-1">{label}</h3>}
      <textarea
        name={name}
        id={name}
        placeholder={placeholder}
        rows={rows}
        value={value}
        onChange={(e) => onChange(name, e?.target?.value, subKey)}
        required={isRequired}
        className={`${className} w-full rounded-lg border bg-transparent px-4 py-2 text-[13px] focus:outline-blue-400 dark:border-black-300`}
      />
    </div>
  );
};

export default TextAreaField;
