import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";

const PrivateRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("token_link_dashboard");

      if (token && token.split(".").length === 3) {
        try {
          const user = jwtDecode(token);
          setUser(user);
        } catch (error) {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!user?._id) {
        navigate("/login");
      } else {
        setIsLoading(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [user, navigate]);

  // Render loading spinner if the status is being checked
  if (isLoading) {
    return <Loading />;
  }

  // If the user is authenticated, render the children
  if (user && user._id) {
    return <>{children}</>;
  }

  // If none of the above, return null
  return null;
};

export default PrivateRoute;
