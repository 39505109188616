import React, { useState } from "react";
import Card from "../../../components/ui/Card";

const colors = [
  { name: "Gray", code: "bg-gray-500" },
  { name: "Red", code: "bg-red-500" },
  { name: "Blue", code: "bg-blue-500" },
  { name: "Orange", code: "bg-orange-500" },
  { name: "Green", code: "bg-green-500" },
  { name: "Lime", code: "bg-lime-500" },
  { name: "Purple", code: "bg-purple-500" },
  { name: "Pink", code: "bg-pink-500" },
];

const ColorSelector = () => {
  const [selectedColor, setSelectedColor] = useState(colors[3].name);

  return (
    <Card title={"Preferred Color"}>
      <div className="flex space-x-2">
        {colors.map((color) => (
          <label
            key={color.name}
            className="flex cursor-pointer flex-col items-center space-y-2"
          >
            <input
              type="radio"
              name="color"
              value={color.name}
              checked={selectedColor === color.name}
              onChange={() => setSelectedColor(color.name)}
            />
            <div
              className={`flex h-12 w-12 items-center justify-center rounded-lg ${color.code} `}
            ></div>
          </label>
        ))}
      </div>
    </Card>
  );
};

export default ColorSelector;
