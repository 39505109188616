import React from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { HiDownload } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import Button from "../../../components/ui/Button";

const SingleCard = ({
  data,
  className,
  isAnalytic,
  isEdit,
  isDelete,
  isView,
  isDownload,
}) => {
  return (
    <div
      className={`${className} w-full max-w-[350px] space-y-6 rounded-md border bg-white p-6 pt-8 shadow`}
    >
      <div className="mx-auto h-[200px] w-[200px] rounded-md border">
        <img
          src="https://admin.linkincard.com/storage/barcodes/cv-632b23e7b0548.png"
          alt=""
        />
      </div>
      <div className="text-center">
        <h3 className="font-medium">{data?.name}</h3>
        <p className="text-[13px]">{data?.job}</p>
        <p className="text-[13px] opacity-80">{data?.id}</p>
      </div>
      <div className="flex items-center justify-center gap-2">
        {isAnalytic && (
          <Button
            link="/business/analytics/11"
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-lemon-100 "
          >
            <GoGraph />
          </Button>
        )}

        {isView && (
          <Button className="flex h-[40px] w-[60px] items-center justify-center  !bg-blue-100 ">
            <FaEye />
          </Button>
        )}

        {isEdit && (
          <Button
            link="/business/card/edit/11"
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-blue-800 "
          >
            <FaEdit />
          </Button>
        )}
        {isDownload && (
          <Button
            link="/business/analytics/11"
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-yellow-400 "
          >
            <HiDownload size={20} />
          </Button>
        )}
        {isDelete && (
          <Button
            link="/business/analytics/11"
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-red-400 "
          >
            <MdDelete size={20} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default SingleCard;
