import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { FaPen } from "react-icons/fa";
import InputFile from "../../../../components/ui/InputFile";
import { useEditCardMutation } from "../../../../redux/features/cards/cardsApi";

const ProfileImage = ({ data }) => {
  const [editProfile] = useEditCardMutation();

  const profileRef = useRef(null);
  const bannerRef = useRef(null);
  const [values, setValues] = useState(data);

  const handleUpload = (type) => {
    if (type === "profile") profileRef.current.click();
    if (type === "banner") bannerRef.current.click();
  };

  const onChange = (key, value) => {
    setValues((prev) => {
      const newValues = {
        ...prev,
        [key]: value,
      };

      // Call handleSubmit with the new values
      handleSubmit(newValues);
      return newValues;
    });
  };

  const handleSubmit = async (updatedValues) => {
    try {
      const API = editProfile({ id: data?._id, data: updatedValues });
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div className="rounded-b-xl bg-white shadow dark:bg-dark">
      <div className="relative">
        <div className="h-[137px] rounded-xl border-2 border-primary bg-gradient-to-b from-blue-50 to-blue-200">
          <img
            className="h-full w-full rounded-xl"
            src={values?.bannerBg}
            alt=""
          />

          <button
            className="absolute bottom-2 right-4 flex items-center justify-center rounded-md bg-white px-2 py-0.5  text-[12px]"
            onClick={() => handleUpload("banner")}
          >
            Upload
          </button>

          <div className="hidden">
            <InputFile
              name="bannerBg"
              value={values?.bannerBg}
              onChange={onChange}
              ref={bannerRef}
            />
          </div>
        </div>
        <div className="absolute -bottom-2 left-2 rounded-full bg-primary">
          <img
            className="h-[116px] w-[116px] rounded-full border border-primary"
            src={values?.avatar}
            alt=""
          />
          <button
            className="absolute bottom-0 right-4 flex h-[24px] w-[24px] items-center justify-center rounded-full bg-white"
            onClick={() => handleUpload("profile")}
          >
            <FaPen size={10} />
          </button>

          <div className="hidden">
            <InputFile
              name="avatar"
              value={values?.avatar}
              onChange={onChange}
              ref={profileRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileImage;
