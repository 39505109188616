import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { useGetAddressQuery } from "../../../redux/features/address/addressApi";
import AddressForm from "./AddressForm";

const EditAddressPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetAddressQuery(id);

  return (
    <>
      {isLoading ? (
        <Loading height="70vh" />
      ) : (
        <AddressForm data={data?.data} />
      )}
    </>
  );
};

export default EditAddressPage;
