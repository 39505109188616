import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FaUserMinus, FaUserPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import noImg from '../../images/shared/no-image.jpg';
import {
  useAcceptFriendRequestMutation,
  useDeleteFriendRequestMutation,
  useGetFriendsCategoriesOptionsQuery,
  useGetUserFriendCountQuery,
  useGetUserMutualFriendsQuery,
  useGetUserPhotosQuery,
  useGetUserVideosQuery,
  useMakeFriendRelationsMutation,
  useMakeFriendRequestMutation,
} from '../../redux/features/friends/friendsApi';
import { useGetUserQuery } from '../../redux/features/users/usersApi';
import { filterArr, getFullNameByLayer } from '../../utils/func';
import Loading from '../Loading';
import CustomReactPlayer from '../ui/CustomReactPlayer';
import SelectOptions from '../ui/SelectOptions';

const SocialProfileViewPage = () => {
  const { id } = useParams();
  const { user: myProfile } = useSelector((state) => state.auth);

  const [activeNav, setActiveNav] = useState('About');
  const [selectedRelation, setSelectedRelation] = useState(null);
  const user = {
    name: 'MD Rubel Mahmud',
    friends: 292,
    mutualFriends: 26,
    coverPhoto: noImg,
    profilePicture: noImg,
    friendAvatars: Array(8).fill(noImg),
  };

  const { data: userData } = useGetUserQuery(id);
  // console.log(userData);

  const { data: userFriendCounts } = useGetUserFriendCountQuery({
    id,
    page: 1,
    limit: 10,
  });
  const { data: mutualFriendsData } = useGetUserMutualFriendsQuery({
    id,
    page: 1,
    limit: 10,
  });
  const { data: userVideos } = useGetUserVideosQuery(
    { id, page: 1, limit: 10 },
    { skip: activeNav !== 'Videos' }
  );
  const { data: userPhotos } = useGetUserPhotosQuery(
    { id, page: 1, limit: 10 },
    { skip: activeNav !== 'Photos' }
  );
  const { data: friendRelationOptions, isError } =
    useGetFriendsCategoriesOptionsQuery(
      {},
      { skip: activeNav !== 'Friend Category' }
    );
  // const { data: userFriendsData } = useGetUserFriendsQuery({ id, page: 1, limit: 10 }, { skip: activeNav !== 'Friends' });
  // console.log("options", friendRelationOptions, activeNav !== 'Friend Category');

  // const navItems = ['Posts', 'About', 'Friends', 'Photos', 'Videos',];
  const navItems = ['About', 'Photos', 'Videos', 'Friend Category'];

  const [makeRequest, { isLoading: isRequestLoading }] =
    useMakeFriendRequestMutation();

  const handleAddFriend = async (id) => {
    const submitData = {
      friendReceiverId: id,
    };
    try {
      const result = await makeRequest(submitData);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail);
    }
  };

  const [deleteRequest, { isLoading: isDeleteLoading }] =
    useDeleteFriendRequestMutation();

  const handleDeleteRequest = async (id) => {
    const submitData = {
      requestId: id,
    };

    try {
      const result = await deleteRequest(submitData);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail);
    }
  };

  //! new
  const [acceptRequest, { isLoading: isAcceptLoading }] =
    useAcceptFriendRequestMutation();
  const [makeRelation, { isLoading: isRelationLoading }] =
    useMakeFriendRelationsMutation();
  // const [deleteRequest, { isLoading: isDeleteLoading }] = useDeleteFriendRequestMutation();

  const handleAcceptRequest = async (id) => {
    const submitData = {
      requestId: id,
    };

    try {
      const result = await acceptRequest(submitData);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail);
    }
  };

  // const handleDeleteRequest = async (id) => {
  //     const submitData = {
  //         requestId: id,
  //     };

  //     try {
  //         const result = await deleteRequest(submitData);
  //         if (result?.data?.success) {
  //             toast.success(result?.data?.message);
  //         } else {
  //             toast.error(result?.error?.data?.message);
  //         }
  //     } catch (error) {
  //         toast.error(error?.response?.data?.detail);
  //     }
  // };

  //! new
  const onRelationChange = async (a, b) => {
    if (!id && !b) return;

    setSelectedRelation(b);
    try {
      const result = await makeRelation({
        friendId: userData?.data?.friend?.requestId,
        friendCategoryId: b,
      });
      if (result?.data) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail);
    }
  };
  return (
    <div className="w-full overflow-hidden rounded-lg bg-white text-black shadow-lg dark:bg-dark dark:text-gray-300">
      {/* Cover Photo */}
      <div className="relative h-80 bg-gray-300">
        <img
          src={userData?.data?.bannerBg || user.coverPhoto}
          alt="Cover"
          className="h-full w-full object-cover"
        />
      </div>

      {/* Profile Info */}
      <div className="relative px-4 py-5">
        <div className="absolute -top-24 left-4 h-40 w-40 overflow-hidden rounded-full border-4 border-white">
          <img
            src={userData?.data?.avatar || user.profilePicture}
            alt={user.name}
            className="h-full w-full object-cover"
          />
        </div>
        <div className="ml-44">
          <h1 className="text-3xl font-bold">
            {getFullNameByLayer(userData?.data) ||
              userData?.data?.fullName ||
              '-- --'}
          </h1>

          <p className="text-gray-600">
            {userFriendCounts?.data?.userFriendsCount} friends ·{' '}
            {userFriendCounts?.data?.mutualFriendsCount} mutual
          </p>

          <div className="mt-2 flex space-x-2">
            {mutualFriendsData?.data?.mutualFriends?.map((avatar, index) => (
              <img
                key={index}
                src={avatar?.image}
                alt="Friend"
                className="h-8 w-8 rounded-full"
              />
            ))}
          </div>
        </div>
        <div className="mt-4 flex justify-end space-x-2 ">
          {id !== myProfile?._id && (
            <>
              {userData?.data?.friend?.isAlreadyFriend ? (
                <button
                  className="flex items-center rounded-md bg-blue-500 px-4 py-2 text-white"
                  onClick={() =>
                    handleDeleteRequest(userData?.data?.friend?.requestId)
                  }
                >
                  <FaUserMinus className="mr-2" />{' '}
                  {isDeleteLoading ? (
                    <Loading variant="button" height="20px" />
                  ) : (
                    'Unfriend'
                  )}
                </button>
              ) : (
                <>
                  {userData?.data?.friend?.isAlreadyConfirmFriendRequestSent ? (
                    <div className="flex items-center gap-3">
                      <button
                        className="flex h-full w-full items-center justify-center rounded bg-red-500 px-2 py-1.5 text-gray-100"
                        onClick={() =>
                          handleDeleteRequest(userData?.data?.friend?.requestId)
                        }
                      >
                        {isDeleteLoading ? (
                          <Loading variant="button" height="20px" />
                        ) : (
                          'Delete'
                        )}
                      </button>
                      <button
                        className="flex h-full w-full items-center justify-center rounded bg-green-600 px-2 py-1.5 text-white-100"
                        onClick={() =>
                          handleAcceptRequest(userData?.data?.friend?.requestId)
                        }
                      >
                        {isAcceptLoading ? (
                          <Loading variant="button" height="20px" />
                        ) : (
                          'Confirm'
                        )}
                      </button>

                      {/* <button className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center" onClick={() => handleConfirmRequest(userData?.data?.friend?.requestId)}>
                                            <FaUserPlus className="mr-2" /> {isRequestLoading ? <Loading variant="button" height="20px" /> : "Confirm"}
                                        </button> */}
                    </div>
                  ) : (
                    <>
                      {userData?.data?.friend?.isAlreadyFriendRequestSent ? (
                        <button
                          className="flex items-center rounded-md bg-red-600 px-4 py-2 text-white"
                          onClick={() =>
                            handleDeleteRequest(
                              userData?.data?.friend?.requestId
                            )
                          }
                        >
                          {' '}
                          {isDeleteLoading ? (
                            <Loading variant="button" height="20px" />
                          ) : (
                            'Cancel Request'
                          )}
                        </button>
                      ) : (
                        <button
                          className="flex items-center rounded-md bg-blue-500 px-4 py-2 text-white"
                          onClick={() => handleAddFriend(id)}
                        >
                          <FaUserPlus className="mr-2" />{' '}
                          {isRequestLoading ? (
                            <Loading variant="button" height="20px" />
                          ) : (
                            'Add friend'
                          )}
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}

          {/* <button className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md flex items-center">
            <FaFacebookMessenger className="mr-2" /> Message
          </button> */}
          {/* <button className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md">
                        <FaEllipsisH />
                    </button> */}
        </div>
      </div>

      {/* Navigation */}
      <div className="border-t border-gray-300 px-4">
        <nav className="flex space-x-1">
          {navItems.map((item, index) => {
            if (
              !userData?.data?.friend?.isAlreadyFriend &&
              item === 'Friend Category'
            ) {
              return null;
            } else {
              return (
                <button
                  onClick={() => setActiveNav(item)}
                  key={index}
                  href="#"
                  className="border-b-2 border-transparent px-4  pb-2 pt-4 text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300"
                >
                  {item}
                </button>
              );
            }
          })}
        </nav>
      </div>

      {/* Content Sections */}
      <div className="grid grid-cols-3 gap-4 p-4">
        {activeNav === 'Photos' && (
          <div className="col-span-3">
            <div className="rounded-lg bg-white p-4 text-black shadow dark:bg-dark dark:text-gray-300">
              <h2 className="mb-2 text-xl font-bold">Photos</h2>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6">
                {filterArr(userPhotos?.data?.photos)?.map((item, i) => (
                  <img src={item} alt="" />
                ))}
                {filterArr(userPhotos?.data?.photos)?.length === 0 &&
                  'No photos available'}
              </div>
              {/* weee need to  Add intros here */}
            </div>
          </div>
        )}

        {activeNav === 'Videos' && (
          <div className="col-span-3">
            <div className="rounded-lg bg-white p-4 text-black shadow dark:bg-dark dark:text-gray-300">
              <h2 className="mb-2 text-xl font-bold">Videos</h2>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6">
                {filterArr(userVideos?.data?.videos)?.map((item, i) => (
                  <CustomReactPlayer key={i} url={item} />
                ))}
                {filterArr(userVideos?.data?.videos)?.length === 0 &&
                  'No videos available'}
              </div>
              {/* weee need to  Add intros here */}
            </div>
          </div>
        )}

        {activeNav === 'About' && (
          <>
            <div className="col-span-3">
              <div className="rounded-lg bg-white p-4 text-black shadow dark:bg-dark dark:text-gray-300">
                <h2 className="mb-2 text-xl font-bold">Job Information</h2>

                <p>
                  <b>Company Name: </b>
                  {userData?.data?.jobInformation?.companyName || 'N/A'}
                </p>
                <p>
                  <b>Position: </b>
                  {userData?.data?.jobInformation?.position || 'N/A'}
                </p>
                {/* weee need to  Add intros here */}
              </div>
            </div>
            {/* <div className="col-span-2">
                        <div className="bg-white shadow rounded-lg p-4">
                            <div className="flex justify-between items-center">
                                <h2 className="font-bold text-xl">Posts</h2>
                                <button className="bg-gray-200 text-gray-700 px-3 py-1 rounded-md text-sm">
                                    Filters
                                </button>
                            </div>
                        </div>
                    </div> */}
          </>
        )}

        {activeNav === 'Friend Category' && (
          <>
            <div className="col-span-3">
              <div className="rounded-lg bg-white p-4 text-black shadow dark:bg-dark dark:text-gray-300">
                <SelectOptions
                  // icon={<TiBusinessCard />}
                  name="friendsCategory"
                  value={selectedRelation}
                  onChange={onRelationChange}
                  placeholder="Select Friend Category"
                  // options={[
                  //     { label: "Family", value: 1 },
                  //     { label: "Work", value: 2 },
                  //     { label: "Nearby", value: 3 },
                  //     { label: "Close Friend", value: 4 },
                  //     { label: "Others", value: 5 },
                  // ]}
                  options={
                    friendRelationOptions?.data?.length > 0
                      ? friendRelationOptions?.data
                      : []
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SocialProfileViewPage;
