import { apiSlice } from '../api/apiSlice';

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/users/auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    // loginAsGest: builder.query({
    //   query: (data) => ({
    //     url: "/products/orders/throughEmail/?email",
    //     method: "GET",
    //   }),
    // }),
    register: builder.mutation({
      query: (data) => ({
        url: '/users/auth/register',
        method: 'POST',
        body: data,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: '/users/forgot-password/send',
        method: 'POST',
        body: data,
      }),
    }),
    verifyResetPassword: builder.mutation({
      query: (data) => ({
        url: '/users/reset-password/verify',
        method: 'POST',
        body: data,
      }),
    }),
    resendOtpPassword: builder.mutation({
      query: (data) => ({
        url: '/users/resend-otp-request/send',
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: '/reset-password',
        method: 'POST',
        body: data,
      }),
    }),
    verifyOTP: builder.mutation({
      query: (data) => ({
        url: '/users/otp-verify',
        method: 'POST',
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: (data) => ({
        url: '/users/auth/logout',
        body: data,
        method: 'POST',
      }),
    }),
    getAllUsers: builder.query({
      query: () => '/users',
    }),

    getProfile: builder.query({
      query: () => '/profile',
    }),

    getMyProfile: builder.query({
      query: () => `/users/my-profile`,
    }),

    getSingleProfile: builder.query({
      query: (id) => `/users/profile/${id}`,
    }),

    loginAsGest: builder.query({
      query: (data) => ({
        url: `/products/orders/throughEmail/?email=${data.email}`,
        method: 'GET',
      }),
    }),
    // users dashboard
    editDashboardUserProfile: builder.mutation({
      query: ({ id, data }) => ({
        url: `/users/dashboard/update-profile/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useVerifyResetPasswordMutation,
  useResendOtpPasswordMutation,
  useResetPasswordMutation,
  useVerifyOTPMutation,
  useGetProfileQuery,
  useGetMyProfileQuery,
  useGetSingleProfileQuery,
  useGetAllUsersQuery,
  useLogoutMutation,
  useLoginAsGestQuery,
  useEditDashboardUserProfileMutation,
} = authApi;
// how to use useLoginAsGestQuery
// const [loginAsGest] = useLoginAsGestQuery();
// const { data, error, isLoading } = loginAsGest({ email: values.email });
