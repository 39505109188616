import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import React, { useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom';
import CustomizationPanel from './CustomizationPanel';
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const CVTemplate3 = () => {
  const location = useLocation();
  const componentRef = useRef();

  const formData = location.state?.formData || {};
  const timeDistribution = formData.timeDistribution || {
    working: 0,
    family: 0,
    coordination: 0,
    entertainment: 0,
    relaxing: 0,
  };

  const personalSkills = formData.personalSkills || {
    leadership: 0,
    communication: 0,
    selfMotivation: 0,
    decisionMaking: 0,
  };

  const [headingColor, setHeadingColor] = useState('text-blue-950');
  const [contentColor, setContentColor] = useState('text-black');
  const [fontFamily, setFontFamily] = useState('font-sans');
  const [textTransform, setTextTransform] = useState('normal-case');

  const chartData = {
    labels: ['Working', 'Family', 'Coordination', 'Entertainment', 'Relaxing'],
    datasets: [
      {
        data: [
          timeDistribution.working,
          timeDistribution.family,
          timeDistribution.coordination,
          timeDistribution.entertainment,
          timeDistribution.relaxing,
        ],
        backgroundColor: [
          '#1E40AF',
          '#60A5FA',
          '#38BDF8',
          '#3B82F6',
          '#93C5FD',
        ],
        hoverBackgroundColor: [
          '#1E3A8A',
          '#3B82F6',
          '#2563EB',
          '#1D4ED8',
          '#60A5FA',
        ],
        borderWidth: 1,
      },
    ],
  };

  const getSkillPercentage = (value) => {
    const total = Object.values(personalSkills).reduce(
      (sum, skill) => sum + skill,
      0
    );
    return total > 0 ? (value / total) * 100 : 0;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
      datalabels: {
        formatter: (value, context) => {
          let sum = 0;
          let dataArr = context.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value / sum) * 100).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
        font: {
          weight: 'bold',
        },
      },
    },
  };

  const generatePdf = async () => {
    const element = componentRef.current;

    // Capture the component as an image using html2canvas
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');

    // Generate PDF using jsPDF
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width, canvas.height], // Fit PDF to component size
    });

    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    pdf.save('download.pdf');
  };
  return (
    <div className="flex gap-10 py-20 pl-8">
      <div className="relative  opacity-15 print:hidden">
        <h1 className="absolute mt-72 translate-x-56 translate-y-72 rotate-45 transform text-9xl text-gray-400">
          Preview
        </h1>
      </div>

      <div
        ref={componentRef}
        className="w-2/3 border bg-white p-4 shadow-lg print:w-full"
      >
        <div className="bg-blue-950 p-4 pl-8 pr-8 text-center">
          <div>
            <h1
              className={`text-5xl font-semibold text-white ${headingColor} ${fontFamily} ${textTransform}`}
            >
              {formData.name || 'John Doe'}
            </h1>
            <div className="flex items-center">
              <span className={`mr-6 flex-1 border-t border-white`}></span>
              <p
                className={`mt-2 text-xl text-white ${contentColor} ${fontFamily} ${textTransform}`}
              >
                {formData.jobTitle || 'Job Title'}
              </p>
              <span className={`ml-6 flex-1 border-t border-white`}></span>
            </div>
          </div>

          <div className="mt-2">
            <p className={`text-sm text-white ${contentColor} ${fontFamily}`}>
              Email: {formData.email || 'john.doe@example.com'}
            </p>
          </div>
        </div>

        <section className=" mt-0 flex pl-8 pr-8 ">
          <img
            src="/dummyImg.jpg"
            alt="user"
            className="mt-4 h-36 w-36 rounded-full border-2 border-blue-950"
          />
          <div className="ml-6 mt-16 flex">
            <p className={`text-lg ${contentColor} ${fontFamily}`}>
              {formData.summary || '123 Example St, City'}
            </p>
          </div>
        </section>

        <div className="flex ">
          <div className="mt-12 w-1/2">
            <section className=" pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform} border-b-2 border-t-2 border-gray-400 text-center`}
              >
                Personal Information
              </h2>
              <div className="mt-4">
                <p className={`text-lg ${contentColor} ${fontFamily}`}>
                  Address: {formData.address || '123 Example St, City'}
                </p>
                <p className={`text-lg ${contentColor} ${fontFamily}`}>
                  Phone: {formData.phone || '+123456789'}
                </p>
              </div>
            </section>

            <section className="mt-8 pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform} border-b-2 border-t-2 border-gray-400 text-center`}
              >
                Educational Background
              </h2>
              <div className="mt-4">
                <p className={`text-lg ${contentColor} ${fontFamily}`}>
                  {formData.education ||
                    'Bachelor of Science in Computer Science - XYZ University, 2020'}
                </p>
              </div>
            </section>

            <section className="mt-8 pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform} border-b-2 border-t-2 border-gray-400 text-center`}
              >
                Work Experience
              </h2>
              <div className="mt-4">
                <p className={`text-lg ${contentColor} ${fontFamily} `}>
                  {formData.experience ||
                    'Software Engineer at ABC Corp (2020 - Present)'}
                </p>
              </div>
            </section>

            <section className="mt-8 pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform} border-b-2 border-t-2 border-gray-400 text-center`}
              >
                Skills
              </h2>
              <div className="mt-4">
                <p className={`text-lg ${contentColor} ${fontFamily} `}>
                  {formData.skills || 'JavaScript, React, Node.js'}
                </p>
              </div>
            </section>

            <section className="mt-8 pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform} border-b-2 border-t-2 border-gray-400 text-center`}
              >
                Certifications
              </h2>
              <div className="mt-4">
                <p className={`text-lg ${contentColor} ${fontFamily}`}>
                  {formData.certifications ||
                    'Certified JavaScript Developer - ABC Certification Authority, 2021'}
                </p>
              </div>
            </section>

            <section className="mt-8 pl-10 pr-10">
              <h2
                className={`text-2xl ${headingColor} ${fontFamily} ${textTransform} border-b-2 border-t-2 border-gray-400 text-center`}
              >
                Personal Skills
              </h2>

              <div className="mt-4">
                {Object.entries(personalSkills).map(([skill, value]) => (
                  <div key={skill} className="mb-4">
                    <div className="flex justify-between">
                      <span className={`text-lg ${contentColor} ${fontFamily}`}>
                        {skill.replace(/([A-Z])/g, ' $1')}
                      </span>
                      <span className={`text-lg ${contentColor} ${fontFamily}`}>
                        {getSkillPercentage(value).toFixed(2)}%
                      </span>
                    </div>
                    <div className="relative h-4 rounded bg-gray-200">
                      <div
                        className={`absolute h-full rounded bg-blue-500`}
                        style={{ width: `${getSkillPercentage(value)}%` }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>

          <div className="mt-12 w-1/2">
            <section className="pl-10 pr-10">
              <h2
                  className={`text-2xl ${headingColor} ${fontFamily} ${textTransform} border-b-2 border-t-2 border-gray-400 text-center`}
              >
                Languages
              </h2>
              <div className="mt-4 text-left">
                <div className={`text-lg ${contentColor} ${fontFamily}`}>
                  {formData.languages ? (
                      formData.languages.map((language, index) => (
                          <p key={index} className="">
                            {language}
                          </p>
                      ))
                  ) : (
                      <p>Select a Language</p>
                    )}
                </div>
              </div>

            </section>

            <section className="mt-8 pl-10 pr-10">
              <h2
                  className={`text-2xl ${headingColor} ${fontFamily} ${textTransform} border-b-2 border-t-2 border-gray-400 text-center `}
              >
                Hobbies
              </h2>
              <div className="mt-4">
                <p className={`text-lg ${contentColor} ${fontFamily}`}>
                  {formData.hobbies || 'Book Reading'}
                </p>
              </div>
            </section>

            <section className="mt-8 pl-10 pr-10">
              <h2
                  className={`text-2xl ${headingColor} ${fontFamily} ${textTransform} border-b-2 border-t-2 border-gray-400 text-center`}
              >
                Time Distribution
              </h2>

              <div className="relative mt-4 h-72 w-96">
                <Doughnut data={chartData} options={options}/>
              </div>
              {/*<div className="mr-16 mt-16">*/}
              {/*    <p className={`text-sm ${contentColor} ${fontFamily}`}>Working: {timeDistribution.working || 0}%</p>*/}
              {/*    <p className={`text-sm ${contentColor} ${fontFamily}`}>Family: {timeDistribution.family || 0}%</p>*/}
              {/*    <p className={`text-sm ${contentColor} ${fontFamily}`}>Co-ordination: {timeDistribution.coordination || 0}%</p>*/}
              {/*    <p className={`text-sm ${contentColor} ${fontFamily}`}>Entertainment: {timeDistribution.entertainment || 0}%</p>*/}
              {/*    <p className={`text-sm ${contentColor} ${fontFamily}`}>Relaxing: {timeDistribution.relaxing || 0}%</p>*/}
              {/*</div>*/}
            </section>
          </div>
        </div>
      </div>

      <CustomizationPanel
          setHeadingColor={setHeadingColor}
          setContentColor={setContentColor}
          setFontFamily={setFontFamily}
          setTextTransform={setTextTransform}
          fontFamily={fontFamily}
          textTransform={textTransform}
          generatePdf={generatePdf}
      />
    </div>
  );
};

export default CVTemplate3;
