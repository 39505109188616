import { apiSlice } from "../api/apiSlice";

export const teamCardsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeamCards: builder.query({
      query: () => ({
        url: "/team-cards",
        method: "GET",
      }),
      providesTags: ["teamCards"],
    }),

    getTeamCard: builder.query({
      query: (id) => ({
        url: `/team-cards/${id}`,
        method: "GET",
      }),
      providesTags: ["teamCards"],
    }),

    createTeamCard: builder.mutation({
      query: (data) => ({
        url: "/team-cards",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["teamCards"],
    }),
  }),
});

export const {
  useGetTeamCardsQuery,
  useGetTeamCardQuery,
  useCreateTeamCardMutation,
} = teamCardsApi;
