import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiFillEye } from 'react-icons/ai';
import { FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import Button from '../../../components/ui/Button';
import SelectOptions from '../../../components/ui/SelectOptions';
import {
  useChangeQRLimitMutation,
  useChangeRoleMutation,
  useDeleteUserMutation,
  useEditUserMutation,
} from '../../../redux/features/users/usersApi';

const UserActions = ({
  data = {},
  isRoleAction,
  isQRLimitAction,
  isButton,
}) => {
  const [values, setValues] = useState({});
  const [roleChange] = useChangeRoleMutation();
  const [qrChange] = useChangeQRLimitMutation();
  const [userDelete] = useDeleteUserMutation();
  const [editUser] = useEditUserMutation();
  // const data = Array.from({ length: 100 }, (v, i) => ({ label: i + 1, value: i + 1 }));
  const qrLimitOptions = Array.from({ length: 100 }, (_, i) => ({
    label: i + 1,
    value: i + 1,
  }));

  useEffect(() => {
    if (Object.values(data)) {
      setValues(data);
    }
  }, [data]);

  const onChange = async (key, value) => {
    if (!value) return;
    await handleRoleChange({
      data: {
        userId: data?.id,
        role: value,
      },
    });
  };
  const onChangeQr = async (key, value) => {
    if (!value) return;
    await handleQRLimitChange({
      id: data?.id,
      data: {
        [key]: value,
      },
    });
  };

  const handleRoleChange = async (data) => {
    try {
      const API = roleChange(data);
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      //   console.error(error);
    }
  };

  const handleQRLimitChange = async (data) => {
    try {
      const API = qrChange(data);

      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      //   console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await userDelete(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
      if (result?.error?.status === 400) {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      //   console.error(error);
    }
  };

  //   const handleBlocked = async () => {
  //     const submitData = {
  //       isBlocked: !data?.isBlocked,
  //     };

  //     try {
  //       const API = editUser({ id: data?.id, data: submitData });

  //       const result = await API;
  //       if (result?.data?.success) {
  //         toast.success(result?.data?.message);
  //       } else {
  //         toast.error(result?.error?.data?.message);
  //       }
  //     } catch (error) {
  //       //   console.error(error);
  //     }
  //   };
  const handleToggle = async () => {
    const submitData = {
      isBlocked: !data?.isBlocked,
    };

    try {
      const API = editUser({ id: data?.id, data: submitData });

      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      //   console.error(error);
    }
  };

  // console.log(values);
  return (
    <div className="flex items-center gap-3">
      {!isQRLimitAction ? null : (
        <SelectOptions
          placeholder="Select limit"
          name="qrCodeLimit"
          value={values?.qrLimit}
          onChange={onChangeQr}
          options={qrLimitOptions}
        />
      )}
      {!isRoleAction ? null : (
        <SelectOptions
          placeholder="Select role"
          name="role"
          value={values?.role}
          onChange={onChange}
          options={[
            { label: 'Admin', value: 'admin' },
            { label: 'Individual', value: 'individual' },
            { label: 'Business', value: 'business' },
            { label: 'Agent', value: 'agent' },
            { label: 'Store', value: 'store' },
          ]}
        />
      )}
      {!isButton ? null : (
        <>
          <Button link={`/user/view/${data?.id}`} className="!bg-green-400 ">
            <AiFillEye />
          </Button>

          <Button link={`/users/edit/${data?.id}`} className="!bg-blue-400">
            <FaRegEdit />
          </Button>

          {/* <Button onClick={handleBlocked} className="!bg-red-600">
            {data?.isBlocked ? <MdOutlineBlock /> : <CgUnblock />}
          </Button> */}
          <label className="relative inline-flex cursor-pointer items-center">
            <input
              type="checkbox"
              checked={data?.isBlocked}
              onChange={handleToggle}
              className="peer sr-only" // hides the checkbox itself, but keeps it accessible for screen readers
            />
            <div className="h-6 w-11 rounded-full bg-gray-300 transition-colors duration-200 ease-in-out peer-checked:bg-blue-600 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-offset-2"></div>
            <span className="absolute left-1  h-5 w-5 transform rounded-full bg-white transition-transform duration-200 ease-in-out peer-checked:translate-x-5"></span>
          </label>

          <Button
            onClick={() => handleDelete(data?.id)}
            className="!bg-red-500"
          >
            <MdDelete />
          </Button>
        </>
      )}
    </div>
  );
};

export default UserActions;
