import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';


mapboxgl.accessToken = 'pk.eyJ1IjoieWV0b2lkZWFzIiwiYSI6ImNsc3VheDYwNjIzbmUyaW1qd2dubTd3OXkifQ.MvK7DVmhFSVE0OY5ow0fUw';

const categories = [
  'All', 'Insurance & Finance', 'Food & Beverage', 'Fashion & Beauty', 
  'Marketing & Communications', 'General Business', 'Real Estate', 'Art & Design', 
  'Construction & Repair Services', 'Health & Pharma', 'Education & Social Services', 
  'Transportation', 'Government & Politics', 'Travel & Hospitality', 'Flowers & Nature', 
  'Entertainment', 'Media', 'Other', 'Embassies & Organizations', 'Car Gallery'
];

const HotSection = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [activeCategory, setActiveCategory] = useState('All');

  useEffect(() => {
    if (map.current) return; 
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [32.8597, 39.9334], 
      zoom: 6
    });
  }, []);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="bg-gray-100 p-4">
        <div className="flex flex-wrap gap-2">
          {categories.map((category) => (
            <button
              key={category}
              className={`px-4 py-2 rounded-full text-sm font-medium ${ 
                activeCategory === category
                  ? 'bg-red-500 text-white'
                  : 'bg-blue-100 text-blue-800 hover:bg-blue-200'
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      <div ref={mapContainer} className="flex-grow" />
      {activeCategory !== 'All' && (
        <div className="bg-red-100 text-red-800 p-4 text-center">
          There is no profile for selected business category
        </div>
      )}
    </div>
  );
};

export default HotSection;