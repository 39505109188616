import React from 'react';
import { useParams } from 'react-router-dom';
import FriendRequest from './friendsPage/FriendRequest';
import FriendYouHaveRequested from './friendsPage/FriendYouHaveRequested';
import NearbyFriends from './friendsPage/NearbyFriends';

const SeeAllFriendsPage = () => {
    const {id} = useParams();
    return (
        <>
            {id === 'got-requests' && <FriendRequest isSingleView={true} limit={20} />}
            <br />
            {id === 'sent-requests' && <FriendYouHaveRequested isSingleView={true} limit={20} />}
            <br />
            {/* {id === 'nearby-friends' && <NearbyFriends isSingleView={true} limit={100} />} */}
        </>
    );
};

export default SeeAllFriendsPage;