import { apiSlice } from "../api/apiSlice";

export const qrCodesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQrCodes: builder.query({
      query: (arg) => ({
        url: "/qr-codes",
        method: "GET",
        params: {...arg}
      }),
      providesTags: ["qrCodes"],
    }),

    getQrCode: builder.query({
      query: (id) => ({
        url: `/qr-codes/${id}`,
        method: "GET",
      }),
      providesTags: ["qrCodes"],
    }),

    createQrCode: builder.mutation({
      query: (data) => ({
        url: "/qr-codes",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["qrCodes"],
    }),

    editQrCode: builder.mutation({
      query: ({ id, data }) => ({
        url: `/qr-codes/${id}`,
        method: "PUT",
        body: data,
      }),
    }),

    deleteQrCode: builder.mutation({
      query: (id) => ({
        url: `/qr-codes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["qrCodes"],
    }),
  }),
});

export const {
  useGetQrCodesQuery,
  useGetQrCodeQuery,
  useCreateQrCodeMutation,
  useEditQrCodesMutation,
  useDeleteQrCodeMutation,
} = qrCodesApi;
