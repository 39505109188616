import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";
import Loading from "../../components/Loading";
import Button from "../../components/ui/Button";
import Card from "../../components/ui/Card";
import InputField from "../../components/ui/InputField";
import SelectOptions from "../../components/ui/SelectOptions";
import TextAreaField from "../../components/ui/TextAreaField";
import {
  useCreateClientMutation,
  useEditClientMutation,
} from "../../redux/features/clients/clientsApi";

const ClientForm = ({ id, data = {} }) => {
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [createClient] = useCreateClientMutation();
  const [editClient, { isLoading }] = useEditClientMutation();
  const [isDemoAccount, setIsDemoAccount] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    if (Object.keys(data)?.length > 0) {
      setValues(data);
    }
  }, [data]);

  const navigate = useNavigate();

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const API =
        Object.keys(data)?.length > 0
          ? editClient({ id, data: values })
          : createClient(values);
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate("/agent/all-clients");
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      // toast.error(result?.error?.data?.message);
    }
  };

  const handleChangeIsDemoAccount = (e) => {
    setValues((prev) => ({
      ...prev,
      userInformation: {
        ...prev.userInformation,
        isDemoAccount: e,
      },
    }));
  };

  return (
    <Card title="Create New Client">
      <form onSubmit={handleSubmit} className="w-full max-w-[600px] space-y-6 ">
        <div className="space-y-6">
          <div>
            <h2 className="text-[20px] font-semibold text-green-400 dark:text-white">
              User Information
            </h2>
            <p className="text-[14px]">
              Fill in the username and password for the client and create user.
            </p>
          </div>

          <div className="space-y-4">
            <SelectOptions
              placeholder=""
              options={[
                { label: "Personal", value: "personal" },
                { label: "Business", value: "business" },
                { label: "Company", value: "company" },
              ]}
              name="userInformation"
              subKey="accountType"
              label="Select Type"
              value={values?.userInformation?.accountType}
              onChange={onChange}
            />
            {/* <InputField
              label="Client Name"
              name="userInformation"
              subKey="fullName"
              placeholder=""
              value={values?.userInformation?.fullName}
              onChange={onChange}
              isRequired={true}
            /> */}
            <InputField
              label="Client First Name"
              name="userInformation"
              subKey="firstName"
              placeholder=""
              value={values?.userInformation?.firstName}
              onChange={onChange}
              isRequired={true}
            />
            <InputField
              label="Client Last Name"
              name="userInformation"
              subKey="lastName"
              placeholder=""
              value={values?.userInformation?.lastName}
              onChange={onChange}
              isRequired={true}
            />
            <InputField
              label="Email"
              name="userInformation"
              subKey="email"
              type="email"
              placeholder=""
              value={values?.userInformation?.email}
              onChange={onChange}
              isRequired={true}
            />
            {id ? null : (
              <InputField
                label="Password"
                name="userInformation"
                subKey="password"
                type="password"
                placeholder=""
                value={values?.userInformation?.password}
                onChange={onChange}
                isRequired={true}
              />
            )}
          </div>
        </div>
        <div className="py-3">
          <hr />
        </div>
        <div className="space-y-6">
          <div>
            <h2 className="text-[20px] font-semibold text-green-400 dark:text-white">
              Order Information
            </h2>
            <p className="text-[14px]">
              Use a permanent address where you can receive mail.
            </p>
          </div>

          <div className="space-y-4">
            <SelectOptions
              placeholder=""
              options={countryOptions}
              name="orderInformation"
              subKey="country"
              label="Country"
              value={values?.orderInformation?.country}
              onChange={onChange}
            />

            <InputField
              label="City"
              name="orderInformation"
              subKey="city"
              placeholder=""
              value={values?.orderInformation?.city}
              onChange={onChange}
            />
            <InputField
              label="State"
              name="orderInformation"
              subKey="state"
              placeholder=""
              value={values?.orderInformation?.state}
              onChange={onChange}
            />
            <TextAreaField
              label="Street Address"
              name="orderInformation"
              subKey="street"
              placeholder=""
              value={values?.orderInformation?.street}
              onChange={onChange}
            />
            <SelectOptions
              placeholder=""
              options={[
                { label: "Processing", value: "processing" },
                { label: "Completed", value: "completed" },
              ]}
              name="orderInformation"
              subKey="status"
              label="Order Status"
              value={values?.orderInformation?.status}
              onChange={onChange}
            />
            <SelectOptions
              placeholder=""
              options={[
                { label: "Credit Card", value: "credit_card" },
                { label: "Cash", value: "cash" },
                { label: "Bank Transfer", value: "bank_transfer" },
              ]}
              name="orderInformation"
              subKey="paymentMethod"
              label="Payment Method"
              value={values?.orderInformation?.paymentMethod}
              onChange={onChange}
            />
            <InputField
              label="Total Payment"
              name="orderInformation"
              subKey="total"
              type="number"
              placeholder=""
              value={values?.orderInformation?.total}
              onChange={onChange}
            />
            <SelectOptions
              placeholder=""
              options={[
                { label: "SAR", value: "SAR" },
                { label: "USD", value: "USD" },
                { label: "EUR", value: "EUR" },
              ]}
              name="orderInformation"
              subKey="currency"
              label="Currency"
              value={values?.orderInformation?.currency}
              onChange={onChange}
            />
            <label className="flex cursor-pointer items-center space-x-2">
              <input
                type="checkbox"
                // checked={isDemoAccount}
                checked={values?.userInformation?.isDemoAccount ? true : false}
                onChange={(e) => handleChangeIsDemoAccount(e.target.checked)}
                className="form-checkbox h-4 w-4 rounded text-blue-600 "
              />
              <span>Demo Account</span>
            </label>
          </div>
        </div>

        <Button type="submit">
          {isLoading ? (
            <Loading variant="button" height="14px"></Loading>
          ) : (
            "Save"
          )}
        </Button>
      </form>
    </Card>
  );
};

export default ClientForm;
