import React from 'react';
import { FaGlobeAmericas as Globe } from "react-icons/fa";
import { FaFacebookF as Facebook } from "react-icons/fa";
import { RiInstagramLine as Instagram } from "react-icons/ri";
import { FaXTwitter as Twitter } from "react-icons/fa6";
import { IoLogoYoutube as Youtube } from "react-icons/io";
import { FaTiktok as Music } from "react-icons/fa6";
import { FaPhoneAlt as Phone } from "react-icons/fa";
import { SiWhatsapp } from "react-icons/si";
import { MdEmail  } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

const ViewCardPage = () => {
  return (
    <div className="max-w-5xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="bg-gradient-to-b from-blue-900 to-blue-700 h-32 rounded-t-lg"></div>
      
      <div className="relative px-4 pt-12 pb-8 text-center">
        <div className="absolute -top-16 left-1/2 transform -translate-x-1/2">
          <div className="w-32 h-32 bg-white rounded-full flex items-center justify-center shadow-md">
            <span className="text-xl font-semibold">LinkinCard</span>
          </div>
        </div>
        
        <h1 className="text-2xl font-bold text-center mt-4">EID ABDULRAHIM</h1>
        
        <div className="flex justify-center space-x-4 mt-4">
          <Phone size={20} />
          <SiWhatsapp size={20} />
          <MdEmail size={20} />
          <FaLocationDot size={20} />
        </div>
        
        <div className="flex justify-center space-x-2 mt-6">
          <button className="bg-green-500 text-white px-4 py-2 rounded">Add to Your Contact</button>
          <button className="bg-gray-800 text-white px-4 py-2 rounded">Profile</button>
          <button className="bg-blue-600 text-white px-4 py-2 rounded">CV</button>
          <button className="bg-green-500 text-white px-4 py-2 rounded">Exchange Contact</button>
        </div>
        
        <button className="bg-gray-800 text-white py-2 px-3 mt-4 rounded">View Store</button>
        
        <div className="mt-6">
          <h2 className="text-lg font-semibold mb-2 text-center">Social Links</h2>
          <div className="flex justify-center space-x-4">
            <Globe size={24} />
            <Facebook size={24} />
            <Twitter size={24} />
            <Instagram size={24} />
            <Youtube size={24} />
            <Music size={24} />
          </div>
        </div>
        
        <div className="mt-6">
          <h2 className="text-lg font-semibold mb-2">Video</h2>
          <div className="bg-gray-200 h-48 flex items-center justify-center">
            <span>Video Placeholder</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCardPage;