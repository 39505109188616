import React from "react";
import { FaUser } from "react-icons/fa";
import Card from "../../../components/ui/Card";
import BusinessLayout from "../../../layout/BusinessLayout";

const SettingsPage = () => {
  const data = [
    {
      id: 1,
      title: "Show Email",
      value: false,
    },
    {
      id: 2,
      title: "Show Phone Number",
      value: false,
    },
    {
      id: 3,
      title: "Show Website Url",
      value: true,
    },
    {
      id: 4,
      title: "Show YouTube Url",
      value: true,
    },
    {
      id: 5,
      title: "Show Instagram Url",
      value: true,
    },
    {
      id: 6,
      title: "Show Facebook Url",
      value: true,
    },

    {
      id: 7,
      title: "Show Tiktok Url",
      value: false,
    },
    {
      id: 8,
      title: "Show Snapchat Url",
      value: false,
    },
    {
      id: 9,
      title: " Show On Map",
      value: false,
    },
  ];
  return (
    <>
      <Card
        content={
          <div className="flex items-center gap-2 text-black dark:text-white ">
            <FaUser />
            <h3>Change Your Settings</h3>
          </div>
        }
        className="space-y-4"
      >
        <h4 className="text-[13px] font-medium">
          You can change the settings of your profile and hide any of your data.
        </h4>
        <div>
          {data?.map((d) => (
            <div className="flex items-center gap-2">
              <input type="checkbox" name="" id="" checked={d?.value} />
              <span className="text-[13px]">{d?.title}</span>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};

export default SettingsPage;
