import React, { useEffect, useRef, useState } from "react";
import { GrSystem } from "react-icons/gr";
import { MdDarkMode, MdLightMode } from "react-icons/md";

const themeOptions = [
  { value: "light", label: "Light", icon: MdLightMode },
  { value: "dark", label: "Dark", icon: MdDarkMode },
  { value: "system", label: "System", icon: GrSystem, iconSize: 21 },
];

const ThemeButton = ({
  onClick,
  icon: Icon,
  label,
  isActive,
  iconSize = 28,
}) => (
  <button
    onClick={onClick}
    className={`flex items-center gap-4 px-3 py-2 text-sm ${
      isActive ? "bg-[#F4F6FA] text-[#009ef7]" : "bg-transparent text-gray-700"
    } w-full rounded-lg text-left hover:bg-gray-100 hover:text-[#009ef7] dark:text-gray-300 dark:hover:bg-gray-700`}
    role="menuitem"
  >
    <Icon
      size={iconSize}
      className={`mr-${label === "System" ? "4 ml-1" : "3"}`}
    />{" "}
    {label}
  </button>
);

const HeaderThemeSwitcher = () => {
  const [theme, setTheme] = useState("system");
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "system";
    setTheme(savedTheme);
    applyTheme(savedTheme);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const applyTheme = (newTheme) => {
    if (
      newTheme === "dark" ||
      (newTheme === "system" &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
      document.documentElement.style.setProperty(
        "--color-table-row-even",
        "#1c1c2a"
      );
    } else {
      document.documentElement.classList.remove("dark");
      document.documentElement.style.setProperty(
        "--color-table-row-even",
        "#f7f9fc"
      );
    }
    localStorage.setItem("theme", newTheme);
  };

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    applyTheme(newTheme);
    setIsOpen(false);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  return (
    <div className="relative" ref={popupRef}>
      <button
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`rounded-full bg-gray-200 p-2 text-gray-600 hover:text-[#009ef7] dark:bg-gray-700  dark:text-[#009ef7]`}
      >
        {theme === "light" && <MdLightMode size={24} />}
        {theme === "dark" && <MdDarkMode size={24} />}
        {theme === "system" && <GrSystem size={20} />}
      </button>
      {isOpen && (
        <div
          className="absolute right-0 mt-5 w-48 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-800"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className="p-3"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {themeOptions.map((option) => (
              <ThemeButton
                key={option.value}
                onClick={() => handleThemeChange(option.value)}
                icon={option.icon}
                label={option.label}
                isActive={theme === option.value}
                iconSize={option.iconSize}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderThemeSwitcher;
