import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetSingleCarQuery } from '../../redux/features/cars/carsSlice';
import Loading from '../Loading';
import CarView from './CarView';

const CarCardViewPage = () => {
  const { id } = useParams();
  const { data: cardData, isLoading } = useGetSingleCarQuery(id);

  const data = {
    name: cardData?.data?.brand?.name,
    model: cardData?.data?.model,
    address: cardData?.data?.from,
    price: cardData?.data?.price,
    position: cardData?.data?.km,
    phone: cardData?.data?.engineSize,
    coverImage: cardData?.data?.galleryImages,
  };
  console.log(data);
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <CarView data={data} link={`/cars/edit/${id}`} variant="car" />
      )}
    </div>
  );
};

export default CarCardViewPage;
