
import { FaAngleDown } from "react-icons/fa";

const DefaultSelect = ({ options, value, onChange, placeholder }) => {
    return (
        <div className="relative inline-block w-full">
            <select
                className="w-full p-2 rounded border border-gray-300 appearance-none bg-white dark:bg-black pr-8 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={value}
                onChange={onChange}
            >
                <option value="">{placeholder}</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <FaAngleDown size={20} />
            </div>
        </div>
    )
};

export default DefaultSelect;