import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaPen } from "react-icons/fa";
import { FaMoneyBills } from "react-icons/fa6";
import { MdAccessTime } from "react-icons/md";
import { TiBusinessCard } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import InputGroup from "../../../components/ui/InputField";
import SelectOptions from "../../../components/ui/SelectOptions";
import {
    useCreateSubscriptionMutation,
    useEditSubscriptionMutation,
} from "../../../redux/features/subscriptions/subscriptionsApi";

const SubscriptionForm = ({ data = {} }) => {
  const [createSubscription] = useCreateSubscriptionMutation();
  const [editSubscription] = useEditSubscriptionMutation();

  const [values, setValues] = useState(data);
  const navigate = useNavigate();

  const onChange = (key, value) => {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const API = data?._id
        ? editSubscription({ id: data?._id, data: values })
        : createSubscription(values);
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate("/admin/subscriptions");
      }else{
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
    //   console.error(error);
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <Card
        title="Create New Subscription"
        content={
          <Button
            link="/admin/subscriptions"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            Subscriptions
          </Button>
        }
        className="space-y-3"
      >
        <SelectOptions
          icon={<FaPen />}
          name="subscriptionType"
          value={values?.subscriptionType}
          onChange={onChange}
          placeholder="Select Subscription Plan"
          isRequired={true}
          options={[
            { label: "Basic", value: "basic" },
            { label: "Standard", value: "standard" },
            { label: "Premium", value: "premium" },
          ]}
        />

        <InputGroup
          icon={<FaMoneyBills />}
          name="monthlyPrice"
          value={values?.monthlyPrice}
          onChange={onChange}
          placeholder="MonThly Price"
          type="number"
        />

        <InputGroup
          name="yearlyPrice"
          value={values?.yearlyPrice}
          onChange={onChange}
          placeholder="Yearly Price"
          type="number"
          icon={<FaMoneyBills />}
        />

        <SelectOptions
          icon={<MdAccessTime />}
          name="trail"
          value={values?.trail}
          onChange={onChange}
          placeholder="Select trail time"
          options={[
            { label: "1 week", value: "1 week" },
            { label: "2 weeks", value: "2 weeks" },
            { label: "3 weeks", value: "3 weeks" },
            { label: "4 weeks", value: "4 weeks" },
          ]}
        />

        <SelectOptions
          icon={<TiBusinessCard />}
          name="cardLimitNum"
          value={values?.cardLimitNum}
          onChange={onChange}
          placeholder="Select card number"
          options={[
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
          ]}
        />

        <div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="businessCardScanner"
              checked={values?.businessCardScanner}
              onChange={(e) =>
                onChange("businessCardScanner", e?.target?.checked)
              }
            />
            <label htmlFor="" className="text-[14px]">
              Business Card Scanner
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="contactBookExport"
              checked={values?.contactBookExport}
              onChange={(e) =>
                onChange("contactBookExport", e?.target?.checked)
              }
            />
            <label htmlFor="" className="text-[14px]">
              Contact Book Export
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="downloadProfileQrCode"
              checked={values?.downloadProfileQrCode}
              onChange={(e) =>
                onChange("downloadProfileQrCode", e?.target?.checked)
              }
            />
            <label htmlFor="" className="text-[14px]">
              Download Profile Qr Code
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="customizeQrCodeStyle"
              checked={values?.customizeQrCodeStyle}
              onChange={(e) =>
                onChange("customizeQrCodeStyle", e?.target?.checked)
              }
            />
            <label htmlFor="" className="text-[14px]">
              Customize Qr Code Style
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="hideDotBranding"
              checked={values?.hideDotBranding}
              onChange={(e) => onChange("hideDotBranding", e?.target?.checked)}
            />
            <label htmlFor="" className="text-[14px]">
              Hide Dot Branding
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="addLogoIcon"
              checked={values?.addLogoIcon}
              onChange={(e) => onChange("addLogoIcon", e?.target?.checked)}
            />
            <label htmlFor="" className="text-[14px]">
              Add Logo Icon
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="profileInsights"
              checked={values?.profileInsights}
              onChange={(e) => onChange("profileInsights", e?.target?.checked)}
            />
            <label htmlFor="" className="text-[14px]">
              Profile Insights
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="exchangeContactStorage"
              checked={values?.exchangeContactStorage}
              onChange={(e) =>
                onChange("exchangeContactStorage", e?.target?.checked)
              }
            />
            <label htmlFor="" className="text-[14px]">
              Exchange Contact Storage
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="exchangeContactInfo"
              checked={values?.exchangeContactInfo}
              onChange={(e) =>
                onChange("exchangeContactInfo", e?.target?.checked)
              }
            />
            <label htmlFor="" className="text-[14px]">
              Exchange ContactInfo
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="addContactsManuallyInContactBook"
              checked={values?.addContactsManuallyInContactBook}
              onChange={(e) =>
                onChange("addContactsManuallyInContactBook", e?.target?.checked)
              }
            />
            <label htmlFor="" className="text-[14px]">
              Add Contacts Manually In Contact Book
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="unlimitedLinks"
              checked={values?.unlimitedLinks}
              onChange={(e) => onChange("unlimitedLinks", e?.target?.checked)}
            />
            <label htmlFor="" className="text-[14px]">
              Unlimited Links
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="customProfileColor"
              checked={values?.customProfileColor}
              onChange={(e) =>
                onChange("customProfileColor", e?.target?.checked)
              }
            />
            <label htmlFor="" className="text-[14px]">
              Custom Profile Color
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="customIconStyle"
              checked={values?.customIconStyle}
              onChange={(e) => onChange("customIconStyle", e?.target?.checked)}
            />
            <label htmlFor="" className="text-[14px]">
              Custom Icon Style
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="customProfileTheme"
              checked={values?.customProfileTheme}
              onChange={(e) =>
                onChange("customProfileTheme", e?.target?.checked)
              }
            />
            <label htmlFor="" className="text-[14px]">
              Custom Profile Theme
            </label>
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="customLinkTitles"
              checked={values?.customLinkTitles}
              onChange={(e) => onChange("customLinkTitles", e?.target?.checked)}
            />
            <label htmlFor="" className="text-[14px]">
              Custom Link Titles
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="customizeContactCardDownload"
              checked={values?.customizeContactCardDownload}
              onChange={(e) =>
                onChange("customizeContactCardDownload", e?.target?.checked)
              }
            />
            <label htmlFor="" className="text-[14px]">
              Customize Contact Card Download
            </label>
          </div>
        </div>

        <Button type="submit" className="mt-3 !bg-green-400">
          Save
        </Button>
      </Card>
    </form>
  );
};

export default SubscriptionForm;
