import React from "react";

const PreviewImg = ({ src = "" }) => {
  return (
    <>
      {src && (
        <img
          className="mb-2 h-[70px] w-[70px] rounded-full border"
          src={src}
          alt="preview"
        />
      )}
    </>
  );
};

export default PreviewImg;
