import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserQuery } from '../../redux/features/users/usersApi';
import Loading from '../Loading';
import CardClassicView from '../ui/CardClassicView';
import CardView from '../ui/CardView';

const UserViewPage = () => {
  const { id } = useParams();
  const { data: cardData, isLoading, refetch } = useGetUserQuery(id);

  const phone = `${cardData?.data?.phone?.dialCode ?? ''} ${
    cardData?.data?.phone?.fullNumber ?? ''
  }`;

  const whatsapp = `${cardData?.data?.whatsapp?.dialCode ?? ''} ${
    cardData?.data?.whatsapp?.fullNumber ?? ''
  }`;

  const data = {
    // name: `${cardData?.data?.firstName || ''} ${
    //   cardData?.data?.lastName || ''
    // }`,
    name: cardData?.data?.businessName,
    image: cardData?.data?.avatar,
    bannerImg: cardData?.data?.bannerBg,
    // position: `${
    //   (cardData?.data?.position || cardData?.data?.jobInformation?.position) ??
    //   ''
    // }`,
    position: cardData?.data?.slogan,
    education: cardData?.data?.education,
    // skills: cardData?.data?.skills,
    // address: cardData?.data?.address,
    email: cardData?.data?.email,
    // phone: `${phone !== ' ' ? phone : ''}`,
    // additionalPhones: cardData?.data?.additionalPhones,
    whatsapp: `${whatsapp !== ' ' ? whatsapp : ''}`,
    // fax: cardData?.data?.user?.faxNumber,
    // bio: cardData?.data?.user?.bio,
    social: cardData?.data?.socialMediaLinks,
    additionalSocialLinks: cardData?.data?.additionalSocialLinks,
    youtubeVideo: cardData?.data?.socialMediaLinks?.videoLink,
    bankAccounts: cardData?.data?.bankAccounts,
    store: cardData?.data?.store,
  };

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : cardData?.data?.userMode === 'classic' ? (
        <CardClassicView data={data} link={`/card/edit/${id}`} variant="user" />
      ) : (
        <CardView data={data} link={`/card/edit/${id}`} variant="user" />
      )}
    </div>
  );
};

export default UserViewPage;
