import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { MdOutlineClose } from "react-icons/md";

const DialogModal = ({
  width = "800",
  title,
  children,
  onClose,
  isBack = true,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleClose = () => {
    setIsMounted(false);
    setTimeout(onClose, 500);
  };

  const modalContent = (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(4px)",
        zIndex: "999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
        padding: "20px",
      }}
    >
      <div
        style={{
          maxWidth: `${width}px`,
          width: "100%",
          maxHeight: "90vh",
          overflowY: "auto",
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "20px",
          position: "relative",
        }}
        className="z-10 dark:bg-dark"
      >
        <div className="flex items-center justify-between">
          <h3 className="text-[18px] font-bold text-black dark:text-white">
            {title}
          </h3>
          {isBack && (
            <button onClick={handleClose}>
              <MdOutlineClose size={20} />
            </button>
          )}
        </div>
        <div>
          {React.Children.map(children, (child) =>
            React.isValidElement(child)
              ? React.cloneElement(child, { handleClose })
              : child
          )}
        </div>
      </div>
    </div>
  );

  if (!isMounted) {
    return null;
  }

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById("portal-root")
  );
};

export default DialogModal;

// import React, { useEffect, useState } from "react";
// import ReactDOM from "react-dom";
// import { MdOutlineClose } from "react-icons/md";

// const DialogModal = ({
//   width = "800",
//   title,
//   children,
//   onClose,
//   isBack = true,
// }) => {
//   const [isMounted, setIsMounted] = useState(false);
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     setIsMounted(true);
//     setIsVisible(true);
//     return () => {
//       setIsMounted(false);
//     };
//   }, []);

//   const handleClose = () => {
//     setIsVisible(false);
//     setTimeout(onClose, 500);
//   };

//   const modalContent = (
//     <div
//       style={{
//         height: "100vh",
//         backgroundColor: "rgba(0, 0, 0, 0.4)",
//         backdropFilter: "blur(4px)",
//         position: "fixed",
//         top: "0",
//         left: "0",
//         width: "100%",
//         zIndex: "999",
//       }}
//     >
//       <div
//         style={{
//           maxWidth: `${width}px`,
//           position: "fixed",
//           left: "50%",
//           top: "50%",
//           transform: "translate(-50%, -50%)",
//         }}
//         className="z-10 w-full space-y-4 rounded-xl bg-white p-5 dark:bg-dark"
//       >
//         <div className="flex items-center justify-between">
//           <h3 className="text-[18px] font-bold text-black dark:text-white">
//             {title}
//           </h3>
//           {isBack && (
//             <button onClick={handleClose}>
//               <MdOutlineClose size={20} />
//             </button>
//           )}
//         </div>
//         <div>
//           {React.Children.map(children, (child) =>
//             React.isValidElement(child)
//               ? React.cloneElement(child, { handleClose })
//               : child
//           )}
//         </div>
//       </div>
//     </div>
//   );

//   if (!isMounted) {
//     return null;
//   }

//   return ReactDOM.createPortal(
//     modalContent,
//     document.getElementById("portal-root")
//   );
// };

// export default DialogModal;
