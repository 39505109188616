import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Headers/Header";
import Sidebar from "../components/Sidebar";

const IndividualLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-[#F1F3F6] dark:bg-[#151521]">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <main className="flex h-full flex-col">
            <div className="p-2 lg:p-10">
              {children && (
                <div className="mx-auto max-w-screen-2xl p-2">{children}</div>
              )}
              <Outlet />
            </div>
            <div className="mt-auto bg-white px-6 py-4 dark:bg-[#1e1e2d]">
              <p>
                <b className="text-slate-400">2024©</b> Socalii
              </p>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default IndividualLayout;
