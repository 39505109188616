import { apiSlice } from "../api/apiSlice";

export const qrSubCategoryApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getQrSubCategories: builder.query({
            query: (arg) => ({
                url: `/qr-sub-categories`,
                method: "GET",
                params: { ...arg }
            }),
            providesTags: ["qrSubCategories"],
        }),

        getSingleQrSubCategory: builder.query({
            query: (id) => ({
                url: `/qr-sub-categories/${id}`,
                method: "GET",
            }),
            providesTags: ["qrSubCategories"],
        }),

        getQrSubCategoryOptions: builder.query({
            query: (arg) => ({
                url: `/qr-sub-categories/options`,
                method: "GET",
                params: { ...arg }
            }),
        }),

        createQrSubCategory: builder.mutation({
            query: (data) => ({
                url: "/qr-sub-categories",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["qrSubCategories"],
        }),

        editQrSubCategory: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/qr-sub-categories/${id}`,
                method: "PUT",
                body: { ...data },
            }),
            invalidatesTags: ["qrSubCategories"],
        }),

        deleteQrSubCategory: builder.mutation({
            query: (id) => ({
                url: `/qr-sub-categories/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["qrSubCategories"],
        }),
    }),
});

export const {
    useGetQrSubCategoriesQuery,
    useGetQrSubCategoryOptionsQuery,
    useGetSingleQrSubCategoryQuery,
    useCreateQrSubCategoryMutation,
    useEditQrSubCategoryMutation,
    useDeleteQrSubCategoryMutation,
} = qrSubCategoryApi;