import React from 'react';

const DefaultCardLayout = ({ title, action, children }) => {
    return (
        <>
            <div className="bg-white shadow-current rounded-lg overflow-hidden text-black dark:text-white dark:bg-[#1e1e2d]">
                <div className='border-b border-slate-200 dark:border-slate-700 p-6 flex items-center justify-between'>
                    <h2 className="text-xl font-normal">{title}</h2>
                    {action ? action : null}
                </div>
                {children}
            </div>
        </>
    );
};

export default DefaultCardLayout;