import { useEffect, useState } from "react";
import { useGetOrdersQuery } from "../../redux/features/orders/ordersApi";
import CommonTable from "./components/dashboard/CommonTable";
import DefaultCardLayout from "./components/dashboard/DefaultCardLayout";
import SearchFilterComponent from "./components/dashboard/SearchFilterComponent";

const sampleOrders = [
  {
    currency: "SAR",
    order_count: "3 orders",
    total_order: "302 SAR",
    commission_rate: "%30",
    total_commission: "90.6 SAR",
  },
];
const tableRows = [
  "Currency",
  "Order Count",
  "Total Order",
  "Commission Rate",
  "Total Commission",
];

const sampleOrders2 = [
  {
    wp_id: "7406",
    user: "anwarb@gmail.com",
    country: "SA - jeddah",
    status: "Completed",
    total: "2 SAR",
    ordered_at: "16.06.2024 14:35",
  },
  {
    wp_id: "7406",
    user: "anwarb@gmail.com",
    country: "SA - jeddah",
    status: "Completed",
    total: "2 SAR",
    ordered_at: "16.06.2024 14:35",
  },
  {
    wp_id: "7406",
    user: "anwarb@gmail.com",
    country: "SA - jeddah",
    status: "Completed",
    total: "2 SAR",
    ordered_at: "16.06.2024 14:35",
  },
];
const tableRows2 = [
  "WP_ID",
  "User",
  "Country",
  "Status",
  "Total",
  "Ordered at",
];

const OrdersReport = () => {
  const [filteredOrders, setFilteredOrders] = useState();
  const [ordersData, setOrdersData] = useState([]);

  const { data, isSuccess } = useGetOrdersQuery();

  useEffect(() => {
    if (isSuccess && data?.data?.results?.length > 0) {
      // setOrdersData(data.data.results);
      let tableBodyArr = data.data.results.map((order) => {
        let { _id, wpId, user, city, status, total, createdAt } = order;

        return {
          // _id: name + ' 1',
          _id,
          wpId,
          user,
          city,
          status,
          total: total + "SAR",
          createdAt: createdAt?.slice(0, 10),
        };
      });
      setOrdersData(tableBodyArr);
    }
  }, [isSuccess, data]);

  const handleSearch = (term) => {
    // Implement search logic here
    // console.log("Searching for:", term);
  };

  const handleFilter = (filters) => {
    // Implement filter logic here
    // console.log("Applying filters:", filters);
  };

  return (
    <>
      <div className="mb-10">
        <DefaultCardLayout title={"Order totals and Commissions"} border={true}>
          <CommonTable data={sampleOrders} tableHeader={tableRows} />
        </DefaultCardLayout>
      </div>

      <div className="my-10">
        <DefaultCardLayout title={"Order Details"} border={true}>
          <div className="p-6">
            <SearchFilterComponent
              onSearch={handleSearch}
              onFilter={handleFilter}
              initialdata={filteredOrders}
              initialOrders={ordersData}
            />
          </div>
          <CommonTable data={ordersData} tableHeader={tableRows2} />
        </DefaultCardLayout>
      </div>
    </>
  );
};

export default OrdersReport;
