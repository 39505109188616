import React from "react";
import { useParams } from "react-router-dom";
import { useGetTeamCardQuery } from "../../redux/features/teamCards/teamCardsApi";
import Loading from "../Loading";
import CardView from "../ui/CardView";

const TeamCardViewPage = () => {
  const { id } = useParams();
  const { data: cardData, isLoading } = useGetTeamCardQuery(id);

  const phone = `${cardData?.data?.employee?.phone?.dialCode ?? ""} ${
    cardData?.data?.employee?.phone?.fullNumber ?? ""
  }`;

  const data = {
    name: cardData?.data?.employee?.name,
    image: cardData?.data?.employee?.imgUrl,
    bannerImg: cardData?.data?.employee?.bannerBg,
    position: `${cardData?.data?.employee?.job_position ?? ""}`,
    address: cardData?.data?.employee?.address,
    email: cardData?.data?.employee?.email,
    phone: `${phone !== " " ? phone : ""}`,
    additionalPhones: cardData?.data?.user?.additionalPhones,
    fax: cardData?.data?.user?.faxNumber,
    bio: cardData?.data?.user?.bio,
    social: cardData?.data?.user?.socialMediaLinks,
    additionalSocialLinks: cardData?.data?.user?.additionalSocialLinks,
    youtubeVideo: cardData?.data?.user?.socialMediaLinks?.videoLink,
    bankAccounts: cardData?.data?.user?.bankAccounts,
  };

  return (
    <div>
      {isLoading ? <Loading /> : <CardView data={data} variant="team-cards" />}
    </div>
  );
};

export default TeamCardViewPage;
