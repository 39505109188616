import { apiSlice } from "../api/apiSlice";

export const subscriptionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptions: builder.query({
      query: () => ({
        url: "/subscriptions/plans",
        method: "GET",
      }),
      providesTags: ["subscriptions"],
    }),

    getSubscription: builder.query({
      query: (id) => ({
        url: `/subscriptions/plans/${id}`,
        method: "GET",
      }),
      providesTags: ["subscriptions"],
    }),

    createSubscription: builder.mutation({
      query: (data) => ({
        url: "/subscriptions/plans",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["subscriptions"],
    }),

    editSubscription: builder.mutation({
      query: ({ id, data }) => ({
        url: `/subscriptions/plans/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["subscriptions"],
    }),

    deleteSubscription: builder.mutation({
      query: (id) => ({
        url: `/subscriptions/plans/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["subscriptions"],
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
  useGetSubscriptionQuery,
  useCreateSubscriptionMutation,
  useEditSubscriptionMutation,
  useDeleteSubscriptionMutation,
} = subscriptionsApi;
