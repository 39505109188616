import { apiSlice } from '../api/apiSlice';

export const ordersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => ({
        url: '/orders',
        method: 'GET',
      }),
    }),

    getOrder: builder.query({
      query: (id) => ({
        url: `/orders/${id}`,
        method: 'GET',
      }),
    }),

    getOrderByOrderId: builder.query({
      query: (id) => ({
        url: `/products/order/${id}`,
        method: 'GET',
      }),
    }),

    createOrder: builder.mutation({
      query: (data) => ({
        url: '/orders',
        method: 'POST',
        body: data,
      }),
    }),

    editOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `/orders/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useCreateOrderMutation,
  useEditOrderMutation,
  useGetOrderByOrderIdQuery,
} = ordersApi;

// how to use useGetOrderByOrderIdQuery
