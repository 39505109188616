import React from "react";
import { FaPhone, FaShare } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { Link } from "react-router-dom";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import Posts from "../posts/Posts";

const HomePage = () => {
  return (
    <div>
      {/* header  */}
      <div className="flex items-center justify-between bg-white py-4 px-5 shadow relative z-99">
        <h2 className="text-[24px] font-bold text-blue-100">LinkinCard</h2>
        <div>
          <Link
            to=""
            className=" border-b-2 border-green-500 px-3 pb-2 text-[14px] font-bold text-black"
          >
            Home
          </Link>
          <Link
            to="/individual/posts"
            className=" px-3 pb-2 text-[14px] font-bold text-gray-500"
          >
            Posts
          </Link>
          <Link
            to="/individual/statics"
            className=" px-3 pb-2 text-[14px] font-bold text-gray-500"
          >
            Dashboard
          </Link>
          <Link
            to=""
            className=" px-3 pb-2 text-[14px] font-bold text-gray-500"
          >
            Logout
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-[400px_minmax(auto,_1fr)_300px] gap-4 space-y-6 px-6">
        <div className="pt-6">
          <div className="rounded-lg bg-white shadow-lg">
            <div className="relative bg-gray-100 ">
              <img
                src="https://admin.linkincard.com/storage/3962/conversions/OFFECIAL-LOGO-mini.jpg"
                alt="Sudaturk Logo"
                className="mb-2 h-auto rounded-t-md border"
              />
              <img
                src="https://admin.linkincard.com/storage/3963/conversions/s1-mini.jpg"
                alt="Small Logo"
                className="absolute bottom-[-20px] left-[50%] h-auto w-[70px] translate-x-[-50%]"
              />
            </div>

            <div className="space-y-4 px-3 pt-8 pb-4">
              <div className=" text-center">
                <h1 className="text-xl font-bold text-gray-800">
                  Sudaturk Trading & Consulting Services
                </h1>
                <p className="text-sm opacity-70">
                  Sudaturk Trading & Consulting Services Co.Ltd
                </p>
              </div>

              <div className="flex justify-center gap-3 py-2">
                <Button className="!bg-lemon-100">View Card</Button>
                <Button className="!bg-black">Edit Profile</Button>
                <Button className="border border-black !bg-white !text-black">
                  Nearby Contacts
                </Button>
              </div>

              <Button className="w-full border border-black !bg-white !text-black">
                Nearby Contacts
              </Button>

              <div className="rounded border ">
                <Button className="flex w-full items-center  gap-2 rounded-none !rounded-b border !bg-white !text-black">
                  <FaPhone className="text-lemon-100" />
                  <span> +96876916565</span>
                </Button>
                <Button className="flex w-full items-center  gap-2 rounded-none !rounded-b border !bg-white !text-black">
                  <MdOutlineMail size={18} className="text-lemon-100" />
                  <span> linkincard@suda-turk.com</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Posts />
        <div>
          <Card>
            <img
              className="mx-auto"
              src="https://admin.linkincard.com/storage/barcodes/cv-632b23e7b0548.png"
              alt=""
            />
            <div className="flex justify-center">
              <Button className="flex items-center gap-1 !bg-lemon-100 !px-3 !py-1">
                <FaShare />
                <span>Share</span>
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
