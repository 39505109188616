import React, { useState } from 'react';
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import InputGroup from "../../../components/ui/InputField";
import SelectOptions from '../../../components/ui/SelectOptions';
import { useCreateWifiCodeMutation } from '../../../redux/features/wifi/wifiApi';

const encryptionOptions = [
    {
        value: 'wep',
        label: 'WEP'
    },
    {
        value: 'wpa',
        label: 'WPA'
    },
]

const CreateWifiQr = () => {
    const [values, setValues] = useState({});
    const navigate = useNavigate();

    const onChange = (key, value, subKey) => {
        if (subKey) {
            setValues((prev) => {
                const v = prev?.[key] || {};
                return {
                    ...prev,
                    [key]: {
                        ...v,
                        [subKey]: value,
                    },
                };
            });
        } else {
            setValues((prev) => {
                return { ...prev, [key]: value };
            });
        }
    };

    const [createWifi] = useCreateWifiCodeMutation();

    const handleCreateWifi = async () => {
        try {
            const result = await createWifi(values);
            if(result) {
                navigate('/individual/wifi-qr');
            }else{
                toast.error(result?.error?.data?.message);
            }
        } catch (error) {
            // console.log(error);
        }
    };

    return (
        <>
            <Card title="Generate Wifi QR flex">
                <div className="flex-col space-y-5">
                    <InputGroup
                        name={'ssid'}
                        value={values?.ssid}
                        onChange={onChange}
                        placeholder="Network SSID :"
                    />
                    <SelectOptions
                        placeholder="Wifi Encryption :"
                        name={'encryption'}
                        value={values?.encryption}
                        onChange={onChange}
                        options={encryptionOptions}
                    />
                    <InputGroup
                        name={'password'}
                        value={values?.password}
                        onChange={onChange}
                        placeholder="Network Password :"
                    />
                    <p className='text-xs'>We'll never share your Network Password with anyone else.</p>
                </div>

                <Button className={'bg-green-600 mt-5'} onClick={handleCreateWifi}>Generate WiFi</Button>
            </Card>
        </>
    );
};

export default CreateWifiQr;