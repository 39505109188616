import { apiSlice } from "../api/apiSlice";

export const settingsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: () => ({
        url: "/settings",
        method: "GET",
      }),
      providesTags: ["settings"],
    }),

    updateSettings: builder.mutation({
      query: (data) => ({
        url: `/settings`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["settings"]
    }),
  }),
});

export const {
    useGetSettingsQuery,
    useUpdateSettingsMutation,
} = settingsApi;
