import React, { useState } from "react";
import Card from "../../../components/ui/Card";

const colors = [
  { name: "Gray", code: "#6b7280" },
  { name: "Red", code: "#ef4444" },
  { name: "Blue", code: "#3b82f6" },
  { name: "Orange", code: "#f97316" },
  { name: "Green", code: "#22c55e" },
  { name: "Lime", code: "#84cc16" },
  { name: "Purple", code: "#a855f7" },
  { name: "Pink", code: "#ec4899" },
];

const ColorSelector = ({ name, value = "", onChange }) => {
  const [selectedColor, setSelectedColor] = useState(value);

  const handleChange = (value) => {
    setSelectedColor(value);
    onChange(name, value);
  };

  return (
    <Card title={"Preferred Color"}>
      <div className="flex space-x-2">
        {colors.map((color) => (
          <label
            key={color.name}
            className="flex cursor-pointer flex-col items-center space-y-2"
          >
            <input
              type="radio"
              name="color"
              value={color.name}
              checked={selectedColor === color?.code}
              onChange={() => handleChange(color?.code)}
            />
            <div
              style={{
                background: color?.code,
              }}
              className={`rounded-lg] flex h-12 w-12 items-center justify-center `}
            ></div>
          </label>
        ))}
      </div>
    </Card>
  );
};

export default ColorSelector;
