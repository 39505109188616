import React from "react";
import { FaEye } from "react-icons/fa";
import { FaPenToSquare, FaPlus } from "react-icons/fa6";
import { HiDownload } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import Table from "../../../components/Table";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import BusinessLayout from "../../../layout/BusinessLayout";

const MyStorePage = () => {
  const content = {
    header: [
      "Store Name",
      "Store Logo",
      "Address",
      "Mobile",
      "Preview",
      "Actions",
    ],

    body: [
      {
        rows: [
          {
            row: "Store Name",
          },
          {
            row: (
              <div>
                <img className="h-[40px] w-[40px] border" src="" alt="" />
              </div>
            ),
          },
          {
            row: "Netrokona",
          },
          {
            row: "+3583121286800",
          },
          {
            row: (
              <Button>
                <FaEye />
              </Button>
            ),
          },
          {
            row: (
              <div className="flex items-center gap-3">
                <Button link={'/individual/update-store'} className="!bg-green-400 -mb-2">
                  <FaPenToSquare />
                </Button>
                <Button link={''} className="!bg-red-400">
                  <MdDelete />
                </Button>
              </div>
            ),
          },
        ],
      },
    ],
  };

  return (
    <>
      <Card
        title="Store Barcode"
        content={
          <Button className="!bg-green-500">
            <HiDownload size="16" />
          </Button>
        }
      >
        <img
          className="h-[150px] w-[150px]"
          src="https://admin.linkincard.com/storage/barcodes/cv-66a4d88792289.png"
          alt=""
        />
      </Card>
      <Card
        title="Store"
        content={
          <Button link="/individual/create-store" className="!bg-green-500">
            <FaPlus />
          </Button>
        }
      >
        <Table content={content} />
      </Card>
    </>
  );
};

export default MyStorePage;
