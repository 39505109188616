import React, { useState } from 'react';
import { FaEye } from 'react-icons/fa6';
import Loading from '../../../components/Loading';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { useGetOrderListsQuery } from '../../../redux/features/products/productsApi';

const OrderPage = () => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });
  const { data, isLoading } = useGetOrderListsQuery(paginate);

  const content = {
    header: ['User Email', 'User Phone', 'User Address', 'Tracking ID',
      'Order Date', 'Actions'],

    body: data?.data?.map((item) => ({
      rows: [
        { row: item?.buyerEmail },
        { row: item?.buyerPhoneNumber },
        { row: item.buyerAdress },
        { row: item?.trackingOrderId },
        { row: new Date(item?.createdAt).toLocaleDateString() },
        {
          row: (
            <Button
              link={`/order/${item?._id}`}
              className="flex h-[40px] w-[60px] items-center justify-center  !bg-blue-100 "
            >
              <FaEye />
            </Button>
          ),
        },
      ],
    })),
  };
  return (
    <div className="space-y-6">
      <Card title="Orders List">
        {isLoading ? (
          <Loading height="60vh" />
        ) : (
          <Table
            content={content}
            pagination={data?.data?.paginate}
            onPaginate={setPaginate}
          />
        )}
      </Card>
    </div>
  );
};

export default OrderPage;
