import { apiSlice } from "../api/apiSlice";

export const messageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMessages: builder.query({
      query: ({id, ...rest}) => ({
        url: `/messages/?receiverId=${id}`,
        method: "GET",
        params: {...rest},
      }),
      providesTags: ["messages"],
    }),
  }),
});

export const {
    useGetMessagesQuery,
} = messageApi;
