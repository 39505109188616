import React from "react";
import { FaVideo } from "react-icons/fa";
import { FaImage, FaPenToSquare } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { RiShareFill } from "react-icons/ri";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import TextAreaField from "../../../components/ui/TextAreaField";

const Posts = () => {
  return (
    <div className="mx-auto w-full max-w-[800px] space-y-5">
      <Card className="space-y-3">
        <div className="flex gap-2">
          <img
            className="h-[40px] w-[40px] rounded-full"
            src="https://admin.linkincard.com/storage/3963/conversions/s1-mini.jpg"
            alt=""
          />
          <div className="w-full">
            <TextAreaField placeholder="What is your mind" />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Button className="!bg-black px-5 py-3 !text-gray-200">
              <FaImage />
            </Button>
            <Button className="!bg-black px-5 py-3 !text-gray-200">
              <FaVideo />
            </Button>
          </div>
          <Button className="!bg-black px-4 py-2 !text-gray-200">Post</Button>
        </div>
      </Card>
      <div className="space-y-4">
        {[1, 2, 3, 4]?.map((item) => (
          <Card
            key={item}
            contentStyle="w-full"
            content={
              <div className="flex w-full items-center justify-between">
                <div className="flex w-full gap-2">
                  <img
                    className="h-[30px] w-[30px] rounded-full"
                    src="https://admin.linkincard.com/storage/3963/conversions/s1-mini.jpg"
                    alt=""
                  />
                  <div>
                    <h4 className="text-[13px] font-bold leading-none text-black">
                      Sudaturk Trading & Consulting Services
                    </h4>
                    <p className="text-[13px] opacity-80">4 months ago</p>
                  </div>
                </div>

                <div className="flex items-center justify-end gap-2">
                  <Button className="flex items-center gap-1 border !bg-white !px-2 !py-1 text-[10px] font-normal !text-black">
                    <RiShareFill />
                    <span>Share</span>
                  </Button>
                  <Button className=" border !bg-blue-50 !px-3 !py-2 text-[10px] font-normal !text-blue-500">
                    <FaPenToSquare size={16} />
                  </Button>
                  <Button className=" border !bg-red-50 !px-3 !py-2 text-[10px] font-normal !text-red-500">
                    <MdDelete size={16} />
                  </Button>
                </div>
              </div>
            }
          >
            <img
              src="https://admin.linkincard.com/storage/posts/332.png"
              alt=""
            />
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Posts;
