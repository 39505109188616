import { Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useGetOrderThroughEmailQuery } from "../../redux/features/products/productsApi";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import loginImg from "../../images/login/login-bg.jpg";
import logo from "../../images/logo/socalii-black-log.png";

const GuestLoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const LoginAsGuest = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const { data, error, refetch, isFetching } = useGetOrderThroughEmailQuery(email, {
    skip: !email,
  });

  const handleGuestSubmit = async (values, { setSubmitting, resetForm }) => {
    setEmail(values.email);
    localStorage.setItem("guestEmail", values.email);


    const response = await refetch();

    if (response?.data?.success) {
      localStorage.setItem("orders", JSON.stringify(response.data.data));
      toast.success(response.data.message || "Order retrieved successfully.");
      navigate("/orders");
    } else {
      toast.error(error?.data?.message || "Unable to retrieve orders.");
    }

    setSubmitting(false);
    resetForm();
  };

  return (
      <div className="flex min-h-screen bg-gray-100">
        <div className="grid w-full lg:grid-cols-2">
          <div className="flex items-center justify-center border">
            <div className="mx-auto max-w-lg p-8">
              <div className="pb-4">
                <img className="mx-auto h-12 w-auto" src={logo} alt="logo" />
              </div>
              <h2 className="mb-6 bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-center text-2xl font-semibold text-transparent">
                Login As Guest User
              </h2>

              <p className="mb-8 text-center text-gray-600">
                Welcome! As a guest, you can easily track your order online. Please make sure to enter the
                same email address you provided during your order to access the latest updates. And if you have an individual account, login as individual.
              </p>

              <Formik
                  initialValues={{ email: "" }}
                  validationSchema={GuestLoginSchema}
                  onSubmit={handleGuestSubmit}
              >
                {({ errors, touched, isSubmitting }) => (
                    <Form className="space-y-4">
                      <div className="relative">
                        <Field
                            name="email"
                            type="text"
                            placeholder="Enter username/email"
                            className={`w-full rounded-md border p-3 pl-3 pr-10 ${
                                errors.email && touched.email ? "border-red-500" : ""
                            }`}
                        />
                        <span className="absolute right-3 top-3 text-gray-400">@</span>
                        {errors.email && touched.email && (
                            <div className="mt-1 text-sm text-red-500">{errors.email}</div>
                        )}
                      </div>

                      <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="rounded-md bg-gray-800 p-3 text-white transition duration-300 hover:bg-gray-700"
                            disabled={isSubmitting}
                        >
                          {isSubmitting ? "Signing in..." : "Login As Guest"}
                        </button>
                      </div>
                    </Form>
                )}
              </Formik>

              <p className="mt-4 text-center">
                Already have an account?{" "}
                <Link to="/login" className="text-orange-500 hover:underline">
                  Login
                </Link>
              </p>
              <p className="mt-4 text-center">
                You don't have an account?{" "}
                <Link to="/register" className="text-orange-500 hover:underline">
                  Register
                </Link>
              </p>

              <div className="mt-8 flex justify-center space-x-4">
                <SocialIcon Icon={FaFacebookF} color="bg-lime-500 text-black" />
                <SocialIcon Icon={FaInstagram} color="bg-lime-500 text-black" />
                <SocialIcon Icon={FaTwitter} color="bg-lime-500 text-black" />
                <SocialIcon Icon={FaYoutube} color="bg-lime-500 text-black" />
              </div>
            </div>
          </div>
          <div
              className="overflow-hidden border-8 border-lime-500 bg-green-500"
              style={{
                background: `url(${loginImg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
          ></div>
        </div>
      </div>
  );
};

const SocialIcon = ({ Icon, color }) => (
    <a
        href="#"
        className={`${color} rounded-lg p-2 text-black transition duration-300 hover:opacity-80`}
    >
      <Icon />
    </a>
);

export default LoginAsGuest;
