import React from 'react';

import AdditionalInfo from './AdditionalInfo';
import ContactInfo from './ContactInfo';
import ProfileBio from './ProfileBio';
import ProfileImage from './ProfileImage';
import SocialMedia from './SocialMedia';

const ModernView = ({ card, viewMode }) => {
  return (
    <div className="mx-auto max-w-[480px] space-y-6 rounded-lg border p-5 shadow">
      <ProfileImage data={card?.user} />
      <ProfileBio data={card?.user} viewMode={viewMode} />
      <ContactInfo data={card?.user} viewMode={viewMode} />
      <AdditionalInfo data={card?.user} store={card?.store} />
      <SocialMedia data={card?.user} />
    </div>
  );
};

export default ModernView;
