import React from "react";
import BusinessLayout from "../../../layout/BusinessLayout";
import EmployeeForm from "./EmployeeForm";

const EmployeeCreatePage = () => {
  return (
    <BusinessLayout>
      <EmployeeForm />
    </BusinessLayout>
  );
};

export default EmployeeCreatePage;
