import React from "react";
import {
  FaFacebookF,
  FaPhone,
  FaYoutube,
} from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";


const StoreSection = ({ data }) => {
  const {facebook_url, youtube_url } =
    data?.social_media_links || {};

  const {fullNumber} = data?.phone || {};
  const {whatsapp_num} = data || {};

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <h3
          style={{ color: data?.color }}
          className="text-center text-[28px] capitalize"
        >
          {data?.store_contact_information?.store_name}
        </h3>
        <img
          className="h-[400px] w-full rounded-lg border"
          src={data?.store_cover}
          alt="cover"
        />
        <p className="text-center">{data?.store_description}</p>
      </div>
      <div className="flex items-center justify-center gap-3">
        {fullNumber && (
          <Link
            to={fullNumber}
            target="_blank"
            style={{ color: data?.color }}
            className="flex h-[40px] w-[40px] items-center justify-center rounded border "
          >
            <FaPhone size={16} />
          </Link>
        )}

        {whatsapp_num && (
          <Link
            to={whatsapp_num}
            target="_blank"
            style={{ color: data?.color }}
            className="flex h-[40px] w-[40px] items-center justify-center rounded border "
          >
            <IoLogoWhatsapp size={16} />
          </Link>
        )}
        {facebook_url && (
          <Link
            to={facebook_url}
            target="_blank"
            style={{ color: data?.color }}
            className="flex h-[40px] w-[40px] items-center justify-center rounded border "
          >
            <FaFacebookF size={16} />
          </Link>
        )}
          {youtube_url && (
              <Link
                  to={youtube_url}
                  target="_blank"
                  style={{ color: data?.color }}
                  className="flex h-[40px] w-[40px] items-center justify-center rounded border "
              >
                  <FaYoutube size={16} />
              </Link>
          )}


      </div>
    </div>
  );
};

export default StoreSection;
