import React, { useState } from 'react';
import toast from "react-hot-toast";
import { Link } from 'react-router-dom';
import noImg from '../../../images/shared/no-image.jpg';
import { useAcceptFriendRequestMutation, useDeleteFriendRequestMutation, useGetMyFriendRequestsQuery } from '../../../redux/features/friends/friendsApi';
import { getFullName } from '../../../utils/func';
import Loading from '../../Loading';
import Pagination from '../../ui/Pagination';
import SearchBar from '../../ui/SearchBar';


const FriendRequest = ({ isSingleView = false, limit= 10 }) => {
    const [searchText, setSearchText] = useState('');
    const [paginate, setPaginate] = useState({
        page: 1,
        limit: limit,
    });
    const [currentUsingId, setCurrentUsingId] = useState(null);
    const people = [
        { name: 'User 1 name', mutualFriends: 2, image: noImg },
        { name: 'User 2 name', mutualFriends: 1, image: noImg },
        { name: 'User 3 name', mutualFriends: 3, image: noImg },
        { name: 'User 4 name', mutualFriends: 1, image: noImg },
    ];

    const { data: myFriendRequestData } = useGetMyFriendRequestsQuery({ ...paginate, q: searchText });
    
    const [acceptRequest, { isLoading: isAcceptLoading }] = useAcceptFriendRequestMutation();
    const [deleteRequest, { isLoading: isDeleteLoading }] = useDeleteFriendRequestMutation();

    const handleAcceptRequest = async (id) => {
        setCurrentUsingId(id);
        const submitData = {
            requestId: id,
        };

        try {
            const result = await acceptRequest(submitData);
            if (result?.data?.success) {
                toast.success(result?.data?.message);
            } else {
                toast.error(result?.error?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.detail);
        } finally {
            setCurrentUsingId(id);
        }
    };

    const handleDeleteRequest = async (id) => {
        const submitData = {
            requestId: id,
        };

        try {
            const result = await deleteRequest(submitData);
            if (result?.data?.success) {
                toast.success(result?.data?.message);
            } else {
                toast.error(result?.error?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.detail);
        }
    };

    return (
        <div className="bg-white dark:bg-dark text-black dark:text-gray-300 p-4 rounded-lg shadow-md">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Friend Requests</h2>
                {isSingleView ? null : <Link to={"/friends/all/got-requests"} className="text-blue-600 hover:underline">See all</Link>}
            </div>
            <div className="max-w-md p-3 ps-0 shadow-current mb-4">
                <SearchBar onSearch={(e)=>setSearchText(e)} debounceTime={1500} />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {myFriendRequestData?.data?.results?.map((person, index) => (
                    <div key={index} className="bg-white dark:bg-dark text-black dark:text-gray-300 rounded-lg border border-gray-200 overflow-hidden">
                        <Link to={`/social/profile/${person?.friend?._id}`}><img src={person?.friend?.avatar || noImg} alt={getFullName(person)} className="w-full h-32 object-cover" /></Link>
                        <div className="p-4">
                            <Link to={`/social/profile/${person?.friend?._id}`}><h3 className="font-semibold text-lg mb-1 cursor-pointer">{getFullName(person) || '-- --'}</h3></Link>
                            {/* <p className="text-gray-600 text-sm mb-3">{person.mutualFriends} mutual friend{person.mutualFriends !== 1 && 's'}</p> */}
                            <button className="w-full bg-blue-100 text-blue-600 py-1 px-2 rounded mb-2 flex items-center justify-center" onClick={() => handleAcceptRequest(person?.requestId)}>
                                {isAcceptLoading ? <Loading variant="button" height="20px" /> : "Confirm"}
                            </button>
                            <button className="w-full bg-gray-100 text-gray-600 py-1 px-2 rounded flex items-center justify-center" onClick={() => handleDeleteRequest(person?.requestId)}>
                                {isDeleteLoading ? <Loading variant="button" height="20px" /> : "Delete"}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <br />
            {myFriendRequestData?.data?.paginate?.totalCount > 10 && (
                <Pagination pagination={myFriendRequestData?.data?.paginate} onPaginate={setPaginate} />
            )}
        </div>
    );
};

export default FriendRequest;