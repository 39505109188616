import { BASE_URL } from '../../../constants/constants';
import { apiSlice } from '../api/apiSlice';

export const commonApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    upload: builder.mutation({
      query: (data) => ({
        url: '/upload?access_token=shkjasgdjasdgajksdgjsagd',
        method: 'POST',
        body: data,
      }),
    }),

    // removeUpload: builder.mutation({
    //   query: (url) => ({
    //     url: `/upload/remove?url=${url}&access_token=shkjasgdjasdgajksdgjsagd`,
    //     method: 'DELETE',
    //   }),
    // }),
  }),
});
// CALL DELET API TO REMOVE file using fetch using simple function

export const { useUploadMutation, useRemoveUploadMutation } = commonApi;
export const removeUpload = async (url) => {
  try {
    const response = await fetch(
      `${BASE_URL}/upload/remove?url=${url}&access_token=shkjasgdjasdgajksdgjsagd`,
      {
        method: 'DELETE',
      }
    );
    return response.json();
  } catch (error) {
    throw error;
  }
};
// import { apiSlice } from '../api/apiSlice';

// export const commonApi = apiSlice.injectEndpoints({
//   endpoints: (builder) => ({
//     upload: builder.mutation({
//       query: (data) => ({
//         url: '/upload',
//         method: 'POST',
//         body: data,
//       }),
//     }),

//     removeUpload: builder.mutation({
//       query: (url) => ({
//         url: `/upload/remove?url=${encodeURIComponent(url)}`, // Encode the URL for safety
//         method: 'DELETE',
//       }),
//     }),
//   }),
// });

// export const { useUploadMutation, useRemoveUploadMutation } = commonApi;
