import React from "react";
import Table from "../../../components/Table";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import SingleCard from "../cards/SingleCard";

const CarsPage = () => {
  const content = {
    header: [
      "Image",
      "Brand",
      "Model",
      "Year",
      "Price",
      "Offers",
      "Total",
      "Views",
      "Actions",
    ],

    body: [
      {
        rows: [
          {
            row: "test",
          },
          {
            row: "test",
          },
          {
            row: "",
          },
          {
            row: "",
          },
          {
            row: "",
          },
          {
            row: "",
          },
          {
            row: "",
          },

          {
            row: "",
          },

          {
            row: (
              <div className="flex items-center gap-3">
                <Button className="!bg-green-400">
                  {/* <FaPenToSquare /> */}
                  Edit
                </Button>
                <Button className="!bg-red-400">
                  Delete
                  {/* <MdDelete /> */}
                </Button>
              </div>
            ),
          },
        ],
      },
    ],
  };

  return (
    <>
      <Card
        title="Car List"
        content={
          <div className="flex items-center gap-3">
            <Button
              link="/business/cars/create"
              className="flex items-center gap-2 !bg-blue-500 !py-1.5"
            >
              Add New Car
            </Button>
            <Button className="flex items-center gap-2 !bg-green-500 !py-1.5">
              View Gallery Page
            </Button>
          </div>
        }
      >
        <div className="mb-16 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {Array(4)
            .fill(0)
            .map((item, i) => (
              <SingleCard
                key={i}
                data={{ name: "cv-6503365e1d247", id: "Aion any 12345" }}
              />
            ))}
        </div>

        <Table content={content} />
      </Card>
    </>
  );
};

export default CarsPage;
