import { apiSlice } from "../api/apiSlice";

export const cardSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUsersOptions: builder.query({
            query: () => ({
                url: "/users/options",
                method: "GET",
            }),
            providesTags: ["usersOptions"],
        }),

        getCards: builder.query({
            query: () => ({
                url: "/cards",
                method: "GET",
            }),
            providesTags: ["cards"],
        }),

        getCardQrReGenerate: builder.query({
            query: (id) => ({
                url: `/cards/qr-re-generate/${id}`,
                method: "GET",
            }),
        }),

        getSingleCard: builder.query({
            query: (id) => ({
                url: `/cards/${id}`,
                method: "GET",
            }),
        }),

        createCard: builder.mutation({
            query: (data) => ({
                url: "/cards",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["wifiCodes"],
        }),

        editCard: builder.mutation({
            query: ({ id, data }) => ({
                url: `/cards/${id}`,
                method: "PUT",
                body: data,
            }),
        }),

        deleteCard: builder.mutation({
            query: (id) => ({
                url: `/cards/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["cards"],
        }),
    }),
});

export const {
    useGetUsersOptionsQuery,
    useGetCardQrReGenerateQuery,
    useGetCardsQuery,
    useGetSingleCardQuery,
    useCreateCardMutation,
    useEditCardMutation,
    useDeleteCardMutation,
} = cardSlice;
