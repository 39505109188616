import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import InputGroup from "../../../components/ui/InputField";
import SelectOptions from "../../../components/ui/SelectOptions";
import { useCreateWifiCodeMutation } from "../../../redux/features/wifi/wifiApi";

const encryptionOptions = [
  {
    value: "wep",
    label: "WEP",
  },
  {
    value: "wpa",
    label: "WPA",
  },
];

const CreateWifiQr = () => {
  const [values, setValues] = useState({});
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("");

  // const { data: qrCategoriesData } = useGetQrCategoryOptionsQuery();
  // const { data: qrSubCategoriesData } = useGetQrSubCategoryOptionsQuery(
  //   { QRCategoryId: selectedCategory },
  //   { skip: !selectedCategory }
  // );

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
    if (key === "qrCategoryId") {
      setSelectedCategory(value);
    }
  };

  const [createWifi, { isLoading }] = useCreateWifiCodeMutation();

  const handleCreateWifi = async () => {
    if (
      !values?.ssid ||
      !values?.password ||
      !values?.encryption
      // !values?.qrCategoryId
    )
      return;
    // || !values?.QRSubCategoryId
    try {
      const result = await createWifi(values);
      if (result) {
        toast.success(result?.data?.message);
        navigate("/wifi-qr");
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      //   console.log(error);
    }
  };

  return (
    <>
      <Card title="Generate Wifi QR flex">
        <div className="flex-col space-y-5">
          <InputGroup
            name={"ssid"}
            value={values?.ssid}
            onChange={onChange}
            placeholder="Network SSID :"
          />
          <SelectOptions
            placeholder="Wifi Encryption :"
            name={"encryption"}
            value={values?.encryption}
            onChange={onChange}
            options={encryptionOptions}
          />
          {/* <SelectOptions
            placeholder="QR Category :"
            name={"qrCategoryId"}
            value={values?.qrCategoryId}
            onChange={onChange}
            options={qrCategoriesData?.data}
          />
          <SelectOptions
            placeholder="QR Sub Category :"
            name={"qrSubCategoryId"}
            value={values?.qrSubCategoryId}
            onChange={onChange}
            options={qrSubCategoriesData?.data}
          /> */}
          <InputGroup
            name={"password"}
            value={values?.password}
            onChange={onChange}
            placeholder="Network Password :"
          />
          <p className="text-xs">
            We'll never share your Network Password with anyone else.
          </p>
        </div>

        <Button className={"mt-5 w-32 bg-green-600"} onClick={handleCreateWifi}>
          {isLoading ? (
            <Loading variant="button" height="14px" />
          ) : (
            "Generate WiFi"
          )}
        </Button>
      </Card>
    </>
  );
};

export default CreateWifiQr;
