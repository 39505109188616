import React from "react";
import { FaEdit } from "react-icons/fa";
import { HiUsers } from "react-icons/hi2";
import { IoDesktop } from "react-icons/io5";
import { RiContactsBook3Fill } from "react-icons/ri";
import { useGetAnalyticsQuery } from "../../../redux/features/staticAnalytics/staticAnalyticsApi";

const StaticsCards = () => {
  const { data, isLoading } = useGetAnalyticsQuery();

  return (
    <div className="flex items-center justify-between gap-4">
      <div className="grid h-[250px] w-[270px] rounded-md bg-lemon-100 p-5">
        <div className="flex h-[80px] w-[80px] items-center justify-start text-black">
          <IoDesktop size="30" className="text-black" />
        </div>

        <div className="mt-auto flex flex-col gap-3 text-black">
          <h2 className="text-[60px] font-medium ">{data?.data?.viewCount}</h2>
          <h2>Profiles Views</h2>
        </div>
      </div>
      <div className="grid h-[250px] w-[270px] rounded-md bg-black p-5">
        <div className="flex h-[80px] w-[80px] items-center border-black text-white">
          <HiUsers size="30" />
        </div>

        <div className="mt-auto flex flex-col gap-3 text-white">
          <h2 className="text-[60px] font-medium ">
            {data?.data?.messageCount ?? "0"}
          </h2>
          <h2>HiUsers</h2>
        </div>
      </div>
      <div className="grid h-[250px] w-[270px] rounded-md bg-yellow-400 p-5">
        <div className="flex h-[80px] w-[80px] items-center text-white">
          <FaEdit size="30" />
        </div>
        <div className="mt-auto flex flex-col gap-3 text-white">
          <h2 className="text-[60px] font-medium ">{data?.data?.postCount}</h2>
          <h2>Posts</h2>
        </div>
      </div>
      <div className="grid h-[250px] w-[270px] rounded-md bg-danger-100 p-5">
        <div className="flex h-[80px] w-[80px] items-center text-white">
          <RiContactsBook3Fill size="30" />
        </div>
        <div className="mt-auto flex flex-col gap-3 text-white">
          <h2 className="text-[60px] font-medium ">
            {data?.data?.addressCount}
          </h2>
          <h2>Addresses</h2>
        </div>
      </div>
    </div>
  );
};

export default StaticsCards;
