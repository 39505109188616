import React from "react";
import SingleCard from "./SingleCard";

const CardsPage = () => {
  return (
    <>
      <SingleCard
        data={{ name: "abdo ahmed qassem", id: "Personal" }}
        isEdit
        isAnalytic
        isView
      />
    </>
  );
};

export default CardsPage;
