import { apiSlice } from "../api/apiSlice";

export const brandsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrands: builder.query({
      query: (arg) => ({
        url: `/brands`,
        method: "GET",
        params: {...arg}
      }),
      providesTags: ["brands"],
    }),

    getBrandOptions: builder.query({
      query: () => ({
        url: `/brands/options`,
        method: "GET",
      }),
    }),

    getSingleBrand: builder.query({
      query: (id) => ({
        url: `/brands/${id}`,
        method: "GET",
      }),
      providesTags: ["brands"],
    }),

    createBrand: builder.mutation({
      query: (data) => ({
        url: "/brands",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["brands"],
    }),

    editBrand: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/brands/${id}`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["brands"],
    }),

    deleteBrand: builder.mutation({
      query: (id) => ({
        url: `/brands/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["brands"],
    }),
  }),
});

export const {
  useGetBrandsQuery,
  useGetBrandOptionsQuery,
  useGetSingleBrandQuery,
  useCreateBrandMutation,
  useEditBrandMutation,
  useDeleteBrandMutation,
} = brandsApi;