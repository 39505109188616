import moment from "moment";
import React, { useState } from "react";
import Loading from "../../../components/Loading";
import Table from "../../../components/Table";
import Card from "../../../components/ui/Card";
import { useGetMyPostsQuery } from "../../../redux/features/posts/PostsApi";

const RecentPosts = () => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });

  const { data, isLoading } = useGetMyPostsQuery(paginate);

  const content = {
    header: ["Post Content", "Updated At"],

    body: data?.data?.results?.map((item) => ({
      rows: [
        {
          row: item?.content,
        },
        {
          row: moment(item?.updatedAt).fromNow(),
        },
      ],
    })),
  };

  return (
    <Card title="My Recent Posts">
      <div className="p-5">
        {isLoading ? (
          <Loading height="20vh" />
        ) : (
          <Table
            content={content}
            pagination={data?.data?.paginate}
            onPaginate={setPaginate}
          />
        )}
      </div>
    </Card>
  );
};

export default RecentPosts;
