import React, { useState } from 'react';
import BusinessLayout from '../../../layout/BusinessLayout';
import { useGetContactsQuery } from '../../../redux/features/contacts/contactsApi';
// import ExternalContacts from './ExternalContacts';
import LatestContacts from './LatestContacts';
import RecentPosts from './RecentPosts';
import StaticsCards from './StaticsCards';

const StaticsPage = () => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });

  const { data, isLoading, refetch } = useGetContactsQuery(paginate);

  return (
    <BusinessLayout className="space-y-6 p-8">
      <StaticsCards />
      <LatestContacts data={data} isLoading={isLoading} refetch={refetch} />
      {/* <ExternalContacts /> */}
      <RecentPosts />
    </BusinessLayout>
  );
};

export default StaticsPage;
