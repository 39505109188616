import { ErrorMessage, Field, Form, Formik } from "formik";
import QRCode from "qrcode.react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useGetQrCategoryOptionsQuery } from "../../redux/features/qrCategoryApi/qrCategoryApi";
import { useCreateQrCodeMutation } from "../../redux/features/qrCodes/qrCodesApi";
import { useGetQrSubCategoryOptionsQuery } from "../../redux/features/qrSubCategories/qrSubCategoryApi";
import DefaultCardLayout from "./components/dashboard/DefaultCardLayout";

const CreateQR = () => {
  const navigate = useNavigate();
  const [qrCode, setQRCode] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");

  const { data: qrCategoriesData } = useGetQrCategoryOptionsQuery();
  const { data: qrSubCategoriesData } = useGetQrSubCategoryOptionsQuery(
    { QRCategoryId: selectedCategory },
    { skip: !selectedCategory }
  );

  const [createQrCode] = useCreateQrCodeMutation();

  const initialValues = {
    name: "",
    content: "",
    color: "#000000",
    category: "",
    subCategory: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("QR Name is required"),
    content: Yup.string().required("QR Content is required"),
    color: Yup.string().required("Color is required"),
    category: Yup.string().required("QR Category is required"),
    subCategory: Yup.string().required("QR Sub Category is required"),
  });

  const generateQRCode = async (values, { setStatus }) => {
    const selectedValues = { ...values };
    selectedValues.QRCategoryId = values?.category;
    selectedValues.QRSubCategoryId = values?.subCategory;
    delete selectedValues?.category;
    delete selectedValues?.subCategory;
    if (values.content) {
      try {
        let result = await createQrCode(selectedValues);
        if (result?.data?.success) {
          toast.success(result?.data?.message);
          navigate("/agent/qr-links");
          // resetForm();
        }
      } catch (error) {
        // console.error("Qr create failed:", error);
      }
      setQRCode(
        <QRCode value={values.content} size={200} fgColor={values.color} />
      );
      setStatus({ success: "Generated successfully." });
    } else {
      setStatus({ error: "Please enter QR content." });
    }
  };

  const handleCreateNewQR = async (values, resetForm) => {
    resetForm();
    setQRCode(null);
  };

  return (
    <DefaultCardLayout
      title={"Create New QR"}
      action={
        <button
          className="rounded bg-[#009ef7] px-4 py-2 text-white"
          onClick={() => navigate("/agent/qr-links")}
        >
          QR Links
        </button>
      }
    >
      <div className="p-8">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={generateQRCode}
        >
          {({ values, status, resetForm, setFieldValue }) => (
            <Form className="space-y-4">
              {!qrCode ? (
                <>
                  <div className="flex [&>*:nth-child(1)]:w-[22%] lg:[&>*:nth-child(1)]:w-[20%]">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      QR Title
                    </label>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-sm text-red-500"
                  />

                  <div className="flex [&>*:nth-child(1)]:w-[22%] lg:[&>*:nth-child(1)]:w-[20%]">
                    <label
                      htmlFor="category"
                      className="block text-sm font-medium text-gray-700"
                    >
                      QR Category
                    </label>
                    <Field
                      as="select"
                      id="category"
                      name="category"
                      className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm"
                      onChange={(e) => {
                        setFieldValue("category", e.target.value);
                        setSelectedCategory(e.target.value);
                        setFieldValue("subCategory", "");
                      }}
                    >
                      <option value="">Select a category</option>
                      {qrCategoriesData?.data?.map((category) => (
                        <option key={category.value} value={category.value}>
                          {category.label}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ErrorMessage
                    name="category"
                    component="div"
                    className="text-sm text-red-500"
                  />

                  <div className="flex [&>*:nth-child(1)]:w-[22%] lg:[&>*:nth-child(1)]:w-[20%]">
                    <label
                      htmlFor="subCategory"
                      className="block text-sm font-medium text-gray-700"
                    >
                      QR Sub Category
                    </label>
                    <Field
                      as="select"
                      id="subCategory"
                      name="subCategory"
                      className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm"
                      disabled={!selectedCategory}
                    >
                      <option value="">Select a sub-category</option>
                      {qrSubCategoriesData?.data?.map((subCategory) => (
                        <option
                          key={subCategory.value}
                          value={subCategory.value}
                        >
                          {subCategory.label}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ErrorMessage
                    name="subCategory"
                    component="div"
                    className="text-sm text-red-500"
                  />

                  <div className="flex [&>*:nth-child(1)]:w-[22%] lg:[&>*:nth-child(1)]:w-[20%]">
                    <label
                      htmlFor="color"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Color
                    </label>
                    <Field
                      id="color"
                      name="color"
                      type="color"
                      className="ml-2 mt-1 block h-10 w-16 rounded-md border border-gray-300 p-0 shadow-sm"
                    />
                    <div className="w-full"></div>
                  </div>
                  <ErrorMessage
                    name="color"
                    component="div"
                    className="text-sm text-red-500"
                  />

                  <div className="flex [&>*:nth-child(1)]:w-[22%] lg:[&>*:nth-child(1)]:w-[20%]">
                    <label
                      htmlFor="content"
                      className="block text-sm font-medium text-gray-700"
                    >
                      QR Content
                    </label>
                    <Field
                      as="textarea"
                      id="content"
                      name="content"
                      className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm"
                      rows="4"
                    />
                  </div>
                  <ErrorMessage
                    name="content"
                    component="div"
                    className="text-sm text-red-500"
                  />

                  <button
                    type="submit"
                    className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-600"
                  >
                    Generate QR Code
                  </button>
                </>
              ) : (
                <>
                  <div className="mt-4">
                    {qrCode}
                    <button
                      onClick={() => handleCreateNewQR(values, resetForm)}
                      className="mt-8 rounded bg-gray-800 px-4 py-2 text-white hover:bg-gray-700"
                    >
                      Create New
                    </button>
                  </div>
                </>
              )}

              {status && (
                <div
                  className={`mt-4 rounded p-2 ${
                    status.success
                      ? "bg-green-100 text-green-700"
                      : "bg-red-100 text-red-700"
                  }`}
                >
                  {status.success || status.error}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </DefaultCardLayout>
  );
};

export default CreateQR;
