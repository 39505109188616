import React from 'react';
import { FaEdit, FaEye, FaShare } from 'react-icons/fa';
import { GoGraph } from 'react-icons/go';
import { HiDownload } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import { downloadQRCode } from '../../../utils/downloadImage';

const SingleCard = ({
  data,
  className,
  isDelete,
  deleteFunc = () => {},
  isDownload,
  shareLink,
  editLink,
  analyticLink,
  viewLink,
  loading,
}) => {
  return (
    <div
      className={`${className} w-full max-w-[400px] space-y-6 rounded-md border bg-white p-6 pt-8 shadow dark:border-black-300 dark:bg-dark`}
    >
      <div className="mx-auto h-[200px] w-[200px] rounded-md border">
        <img className="h-full w-full" src={data?.image} alt="" />
      </div>
      <div className="text-center dark:text-white ">
        <h3 className="font-medium">Name: {data?.name || 'Not Available'}</h3>
        <p className="text-[13px]">{data?.job}</p>
        <p className="text-[13px] opacity-80">User Name: {data?.username}</p>
      </div>
      <div className="flex items-center justify-center gap-2">
        {analyticLink && (
          <Button
            link={analyticLink}
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-lemon-100 "
          >
            <GoGraph />
          </Button>
        )}


        {viewLink && (
          <Button
            link={viewLink}
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-blue-100 "
          >
            <FaEye />
          </Button>
        )}

        {editLink && (
          <Button
            link={editLink}
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-blue-800 "
          >
            <FaEdit />
          </Button>
        )}
        {isDownload && (
          <Button
            onClick={() => downloadQRCode(data?.image)}
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-yellow-400 "
          >
            <HiDownload size={20} />
          </Button>
        )}
        {isDelete && (
          <Button
            onClick={deleteFunc}
            className="flex h-[40px] w-[60px] items-center justify-center  !bg-red-400 "
          >
            {loading === 'delete' ? null : <MdDelete size={20} />}
            {loading === 'delete' ? (
              <Loading variant="button" height="22px" />
            ) : null}
          </Button>
        )}
        {shareLink && (
            <Button
                onClick={shareLink}
                className="flex h-[40px] w-[60px] items-center justify-center  !bg-gray-600 "
            >
              <FaShare />
            </Button>
        )}
      </div>
    </div>
  );
};

export default SingleCard;
