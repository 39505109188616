import React, { useEffect, useState } from "react";
import { useGetCardsQuery } from "../../../redux/features/cards/cardsSlice";
import SingleCard from "./SingleCard";

const CardsPage = () => {
  // const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const { data, refetch, isSuccess } = useGetCardsQuery();
  // console.log(object);
  const handleRefetch = () => {
    refetch();
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  useEffect(() => {
    if (isSuccess && data?.data?.results?.length === 0) {
      // navigate('/individual/wifi-qr/create')
    }
  }, [isSuccess]);

  return (
    <>
      <div className="grid justify-center gap-5 md:grid-cols-3 lg:grid-cols-4">
        {data?.data?.results?.map((item, i) => (
          <SingleCard
            key={i}
            data={{ qrCodeImage: item?.qrImage, _id: item?._id }}
            isEdit
            isAnalytic
            viewLink={`/individual/card/view/${item?.user}`}
            editLink={`/individual/card/edit/${item?.user}`}
            isView
          />
        ))}
      </div>
    </>
  );
};

export default CardsPage;
