import React from "react";
import { FaSort } from "react-icons/fa";
import { MdOutlineCategory } from "react-icons/md";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import InputGroup from "../../../components/ui/InputField";
import BusinessLayout from "../../../layout/BusinessLayout";

const EditCategoriesPage = () => {
  return (
    <>
      <Card
        title="Edit New Category"
        content={
          <Button
            link="/individual/product-categories"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            Back To Categories
          </Button>
        }
        className="space-y-5"
      >
        <InputGroup
          name="category_name"
          placeholder="Category name (ENGLISH)"
        />
        <InputGroup
          name="category_name"
          placeholder="Category Name(TIGRINYA)"
        />
        <InputGroup
          name="category_name"
          placeholder="Category Name(TURKISH)"
        />
        <InputGroup
          name="category_name"
          placeholder="Category Name(GERMAN)"
        />
        <InputGroup
          name="category_name"
          placeholder="Category Name(FRENCH)"
        />
        <InputGroup
          name="category_name"
          placeholder="Category Name(ARABIC)"
        />
        <InputGroup
          name="category_name"
          placeholder="Category Name(AMHARIC)"
        />
        <InputGroup
          name="order"
          type="number"
          placeholder="Sort Order"
        //   icon={<FaSort />}
        />

        <Button className="mt-3 !bg-green-400">Save Category</Button>
      </Card>
    </>
  );
};

export default EditCategoriesPage;
