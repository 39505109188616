import { apiSlice } from '../api/apiSlice';

export const productsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (arg) => ({
        url: `/products?page=${arg?.page}&limit=${arg?.limit}`,
        method: 'GET',
      }),
      providesTags: ['products'],
    }),

    getProductsByStoreIdAndUserId: builder.query({
      query: (arg) => ({
        url: `/products/public?storeId=${arg?.storeId}&userId=${arg?.userId}`,
        method: 'GET',
      }),
    }),
    getProduct: builder.query({
      query: (id) => ({
        url: `/products/${id}`,
        method: 'GET',
      }),
      providesTags: ['products'],
    }),

    createProduct: builder.mutation({
      query: (data) => ({
        url: '/products',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['products'],
    }),

    editProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `/products/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['products'],
    }),

    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/products/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['products'],
    }),

    confirmOrder: builder.mutation({
      query: (data) => ({
        url: '/products/confirm/order',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['products'],
    }),

        getOrderLists: builder.query({
            query: (id) => ({
                url: "/products/order/List",
                method: "GET",
            }),
            providesTags: ["products"],
        }),


      getOrderThroughEmail: builder.query({
          query: (email) => ({
              url: "/products/orders/throughEmail",
              method: "GET",
              params: { email },
          }),
          providesTags: ["products"],
      }),

    getIndividualOrders: builder.query({
      query: ({ email }) => ({
        url: `/products/Individual/orders?email=${email}`,
        method: "GET",
      }),
      providesTags: ["products"],
    }),

    }),


});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useCreateProductMutation,
  useEditProductMutation,
  useDeleteProductMutation,
  useGetProductsByStoreIdAndUserIdQuery,
  useConfirmOrderMutation,
  useGetOrderListsQuery,
  useGetOrderThroughEmailQuery,
  useGetIndividualOrdersQuery,
} = productsApi;
