import React from "react";
import { MdDelete } from "react-icons/md";
import Table from "../../../components/Table";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import BusinessLayout from "../../../layout/BusinessLayout";

const InterestsPage = () => {
  const content = {
    header: ["Post", "Name", "Email", "Phone", "Message", "Actions"],

    body: [
      {
        rows: [
          {
            row: "test",
          },
          {
            row: "test",
          },

          {
            row: "test",
          },
          {
            row: "test",
          },
          {
            row: "test",
          },

          {
            row: (
              <div className="flex items-center gap-3">
                <Button className="!bg-red-400">
                  <MdDelete />
                </Button>
              </div>
            ),
          },
        ],
      },
    ],
  };

  return (
    <>
      <Card title="Interests List">
        <Table content={content} />
      </Card>
    </>
  );
};

export default InterestsPage;
