import { Dialog, DialogPanel } from "@headlessui/react";
import { useState } from "react";
import toast from "react-hot-toast";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import {
  useResendOtpPasswordMutation,
  useVerifyResetPasswordMutation,
} from "../../redux/features/auth/authApi";
import { validateEmail } from "../../utils/func";

function ForgotPasswordOtpModal({
  otpModalOpen,
  setOtpModalOpen,
  identifier,
  setIdentifier,
  startTimer,
  resetTimer,
  timeRemaining,
  formatTime,
  otp,
  setOtp,
}) {
  const navigate = useNavigate();
  const [otpRequest, setOtpRequest] = useState(false);
  const [verifyResetPassword, { isLoading }] = useVerifyResetPasswordMutation();
  const [resendOtpPassword, { isLoading: isResendLoading }] =
    useResendOtpPasswordMutation();

  const handleResendOtp = async () => {
    try {
      if (!identifier?.email) {
        setOtpModalOpen(false);
        return toast.error("Session is expired!");
      }
      if (!validateEmail(identifier?.email)) {
        return toast.error("Please enter a valid email!");
      }

      setOtpRequest(true);
      const result = await resendOtpPassword({ ...identifier });
      setOtpRequest(false);
      if (result?.data?.success) {
        setOtp("");
        toast.success(result?.data?.message);
        startTimer();
      } else {
        setOtpRequest(false);
        toast.error("OTP resend failed!");
        navigate("/login");
      }
    } catch (error) {
      setOtpRequest(false);
      toast.error("OTP resend failed!");
    }
  };

  const handleResetPasswordOtpVerify = async () => {
    try {
      if (!otp) {
        return toast.error("Please enter your OTP code!");
      }

      if (identifier?.password !== identifier?.password2) {
        toast.error("Password and confirm password does not matched!");
        return 0;
      }
      setOtpRequest(true);

      const result = await verifyResetPassword({ ...identifier, code: otp });
      setOtpRequest(false);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate("/");
        setOtpModalOpen(false);
        setOtpRequest(false);
      } else {
        resetTimer();
        setOtpRequest(false);
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      resetTimer();
      setOtpRequest(false);
      toast.error(error.response?.data?.message);
    }
  };
  const onChangeIdentifier = (key, value) => {
    setIdentifier((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  return (
    <>
      {otpModalOpen && (
        <Dialog
          static
          open={otpModalOpen}
          onClose={() => {}}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/30" />
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            <DialogPanel className="w-full space-y-4 rounded-md bg-white p-12 md:w-[700px]">
              <div className="rounded-md bg-white">
                <p className="text-center text-xl font-bold">
                  Enter the 6-digit code:
                </p>
                <div className=" mx-auto mt-8">
                  <div className="flex justify-center">
                    <OTPInput
                      value={otp || ""}
                      onChange={(val) => {
                        setOtp(val);
                      }}
                      numInputs={6}
                      inputType="tel"
                      renderSeparator={<span className="mx-1" />}
                      renderInput={(props) => <input {...props} />}
                      inputStyle={{
                        width: "60px",
                        marginBottom: "15px",
                        height: "40px",
                        border: "1px solid #ddd",
                        // borderRadius: '4px',
                        backgroundColor: "transparent",
                        outline: "none",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    />
                  </div>
                </div>

                <div className="mb-5 flex items-center justify-center gap-4">
                  <div className="mt-2">
                    <label htmlFor="password" className="text-sm">
                      New Password{" "}
                    </label>
                    <input
                      type="password"
                      placeholder="New password"
                      id="password"
                      value={identifier.password || ""}
                      onChange={(e) =>
                        onChangeIdentifier("password", e.target.value)
                      }
                      className="w-full  rounded-md   border  border-gray-100 px-2  py-1 placeholder-slate-400 outline-gray-200"
                    />
                  </div>
                  <div className="mt-2">
                    <label htmlFor="password2" className="text-sm">
                      Confirm Password{" "}
                    </label>
                    <input
                      type="password"
                      placeholder="Confirm password"
                      id="password2"
                      value={identifier.password2 || ""}
                      onChange={(e) =>
                        onChangeIdentifier("password2", e.target.value)
                      }
                      className="w-full  rounded-md   border  border-gray-100 px-2  py-1 placeholder-slate-400 outline-gray-200"
                    />
                  </div>
                </div>
                <div className="mt-2 px-2">
                  <div className="mt-4 flex items-center justify-between">
                    <p className="text-gray-700">
                      Time Remaining: {formatTime(timeRemaining)}
                    </p>
                    <button
                      className={
                        timeRemaining > 0
                          ? "cursor-not-allowed text-gray-400"
                          : "cursor-pointer text-gray-700"
                      }
                      onClick={handleResendOtp}
                      disabled={timeRemaining > 0 || otpRequest}
                    >
                      {isResendLoading ? "Loading..." : "Resend OTP"}
                    </button>
                  </div>
                  <div className="mt-4">
                    {isLoading ? (
                      <div className="mx-auto block w-full cursor-wait rounded-lg bg-black px-4 py-2 text-white">
                        <div className="h-7 w-full text-center">
                          <p>Loading...</p>
                        </div>
                      </div>
                    ) : (
                      <button
                        className={`mx-auto block ${
                          otp?.length == 6
                            ? "cursor-pointer bg-black"
                            : "cursor-not-allowed bg-gray-400"
                        } w-full rounded-lg px-4 py-2 text-white`}
                        onClick={handleResetPasswordOtpVerify}
                        disabled={otp.length !== 6}
                      >
                        Reset Password
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </>
  );
}

export default ForgotPasswordOtpModal;
