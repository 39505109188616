
// import { saveAuth } from '@/store/slice/authSlice';
// import { resendEmailOtpApi, resetEmailVerifyApi } from '@/utils/instance';
// import { Dialog } from '@headlessui/react';
import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';
import toast from "react-hot-toast";
import OTPInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useResendOtpPasswordMutation, useVerifyOTPMutation } from '../../redux/features/auth/authApi';
import { validateEmail } from '../../utils/func';
// import { Dialog } from '@headlessui/react';
// import Headless from '@headlessui/react';
import DialogModal from '../../components/ui/DialogModal';
import { setCredentials } from '../../redux/features/auth/authSlice';



function OtpModal({ otpModalOpen, setOtpModalOpen, identifier, startTimer, resetTimer, timeRemaining, formatTime, otp, setOtp, onClose }) {

    const navigate = useNavigate()
    const [otpRequest, setOtpRequest] = useState(false);
    const [isResending, setIsResending] = useState(false);

    const [verifyOTPMutation, { isLoading }] = useVerifyOTPMutation();
    const [resendOtpPassword, { isLoading: isResendLoading }] = useResendOtpPasswordMutation();

    const dispatch = useDispatch()



    const handleResendOtp = async () => {
        try {
            if (!identifier?.email) {
                setOtpModalOpen(false)
                return toast.error('Session is expired!');
            }
            if (!validateEmail(identifier?.email)) {
                return toast.error('Please enter a valid email!');
            }

            setIsResending(true);
            const res = await resendOtpPassword({ email: identifier?.email });
            setIsResending(false);
            if (res?.data?.success) {
                setOtp('');
                toast.success(res?.data?.message);
                startTimer();
            } else {
                setIsResending(false);
                toast.error('OTP resend failed!');
                navigate.push('/login');
            }
        } catch (error) {
            // console.log(error)
            setIsResending(false);
            toast.error('OTP resend failed!');
        }
    };

    const handleOtpVerify = async () => {
        if (!otp) {
            return toast.error('Please enter your OTP code!');
        }
        setOtpRequest(true);
        try {
            const res = await verifyOTPMutation({ code: otp });
            if(res?.data?.success){
                    resetTimer();
                    const { token } = res?.data?.data;
                    const user = token ? jwtDecode(token) : {};
                    dispatch(setCredentials({ user, token }));
                    onClose();
                    setOtpModalOpen(false);
                    navigate("/login");
                    toast.success(res?.data?.message);
            }else{
                toast.error(res?.error?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    };


    return (
        <>
            {otpModalOpen && (
                <DialogModal onClose={() => { }} isBack={false} title="OTP Verification" width="500">
                    <div className="bg-white rounded-md">
                        <p className="text-xl font-bold text-center">
                            Enter the 6-digit code:
                        </p>
                        <div className=" mx-auto mt-8">
                            <div className="flex justify-center">
                                <OTPInput
                                    value={otp || ''}
                                    onChange={(val) => {
                                        setOtp(val)
                                    }}
                                    numInputs={6}
                                    inputType="tel"
                                    renderSeparator={<span className="mx-1" />}
                                    renderInput={(props) => <input {...props} />}
                                    inputStyle={{
                                        width: '60px',
                                        marginBottom: '15px',
                                        height: '40px',
                                        border: '1px solid #ddd',
                                        // borderRadius: '4px',
                                        backgroundColor: 'transparent',
                                        outline: 'none',
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}
                                />
                            </div>

                        </div>

                        <div className="px-2">
                            <div className="flex items-center justify-between mt-4">
                                <p className="text-gray-700">
                                    Time Remaining: {formatTime(timeRemaining)}
                                </p>
                                <button
                                    className={
                                        timeRemaining > 0
                                            ? 'text-gray-400 cursor-not-allowed'
                                            : 'text-gray-700 cursor-pointer'
                                    }
                                    onClick={handleResendOtp}
                                    disabled={timeRemaining > 0 || isResending}
                                >
                                    {isResending ? 'Loading...' : 'Resend OTP'}
                                </button>
                            </div>
                            <div className="mt-4">
                                {isLoading ? (
                                    <div className="mx-auto block bg-black text-white px-4 py-2 w-full rounded-lg cursor-wait">
                                        <div className="w-full h-7 text-center">
                                            <p>Loading...</p>
                                        </div>
                                    </div>
                                ) : (
                                    <button
                                        className={`mx-auto block ${otp?.length == 6 ? 'bg-black cursor-pointer' : 'bg-gray-400 cursor-not-allowed'} text-white px-4 py-2 w-full rounded-lg`}
                                        onClick={handleOtpVerify}
                                        disabled={otp.length !== 6}
                                    >
                                        Verify
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </DialogModal>
            )}
        </>
    );
}

export default OtpModal;
