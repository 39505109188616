import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../../constants/constants';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    // baseUrl: "http://backend.srv565545.hstgr.cloud/api/v1.0",
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: [
    'qrCodes',
    'employees',
    'stores',
    'products',
    'address',
    'storeReviews',
    'users',
    'cards',
    'businessCards',
    'teamCards',
    'subscriptions',
    'contacts',
    'clients',
    'externalContacts',
    'getSingleUser',
  ],

  endpoints: () => ({}),
});
