import React from 'react';
import { FaPenToSquare, FaPlus } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';

const ProductCategoriesPage = () => {
  const content = {
    header: ['Category Name', 'Created At', 'Actions'],

    body: [
      {
        rows: [
          {
            row: 'Category Name',
          },
          {
            row: '1 day ago',
          },

          {
            row: (
              <div className="flex items-center gap-3">
                <Button
                  link={'/individual/edit-categories'}
                  className="!bg-green-400"
                >
                  <FaPenToSquare />
                </Button>
                <Button className="!bg-red-400">
                  <MdDelete />
                </Button>
              </div>
            ),
          },
        ],
      },
    ],
  };

  return (
    <>
      <Card
        title="Categories"
        content={
          <Button
            link="/individual/create-categories"
            className="flex items-center gap-2 !bg-green-500"
          >
            <FaPlus /> Add Categories
          </Button>
        }
      >
        <Table content={content} />
      </Card>
    </>
  );
};

export default ProductCategoriesPage;
