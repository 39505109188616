import { apiSlice } from "../api/apiSlice";

export const storesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStores: builder.query({
      query: ({ page, limit }) => ({
        url: `/stores?page=${page}&limit=${limit}`,
        method: "GET",
      }),
      providesTags: ["stores"],
    }),

    getMyStores: builder.query({
      query: ({ page, limit }) => ({
        url: `/stores/my-store?page=${page}&limit=${limit}`,
        method: "GET",
      }),
      providesTags: ["stores"],
    }),

    getStore: builder.query({
      query: (id) => ({
        url: `/stores/${id}`,
        method: "GET",
      }),
      providesTags: ["stores"],
    }),

    getStoreOptions: builder.query({
      query: (id) => ({
        url: `/stores/options`,
        method: "GET",
      }),
      providesTags: ["storeOptions"],
    }),

    createStore: builder.mutation({
      query: (data) => ({
        url: "/stores",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["stores"],
    }),

    editStore: builder.mutation({
      query: ({ id, data }) => ({
        url: `/stores/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["stores"],
    }),

    deleteStore: builder.mutation({
      query: (id) => ({
        url: `/stores/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["stores"],
    }),
  }),
});

export const {
  useGetStoresQuery,
  useGetMyStoresQuery,
  useGetStoreQuery,
  useCreateStoreMutation,
  useEditStoreMutation,
  useDeleteStoreMutation,
  useGetStoreOptionsQuery,
} = storesApi;
