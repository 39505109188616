import React from 'react';
import { FaEye, FaFax, FaLocationArrow, FaPen } from 'react-icons/fa';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { LuKeyRound, LuWarehouse } from 'react-icons/lu';
import PhoneInput from 'react-phone-input-2';
import { Link, useParams } from 'react-router-dom';
import Card from '../../../components/ui/Card';
import InputGroup from '../../../components/ui/InputField';
import InputFile from '../../../components/ui/InputFile';
import { useGetSingleProfileQuery } from '../../../redux/features/auth/authApi';

const EditCardPage = () => {
  const { id } = useParams();
  const { data } = useGetSingleProfileQuery(id);

  return (
    <div className="flex gap-4">
      <div className="w-full max-w-[380px] space-y-6 ">
        <Card
          className="space-y-6 px-6"
          content={
            <div className="space-y-2">
              <h3 className="text-[18px] font-bold text-black">
                Sudaturk Trading & Consulting Services Digital Card
              </h3>
              <Link
                to=""
                className="flex h-[30px] w-[50px] items-center justify-center rounded-md bg-lemon-100 text-white"
              >
                <FaEye />
              </Link>
            </div>
          }
        >
          <div className="rounded-lg border pb-2 text-center">
            <img
              className="w-full"
              src="https://admin.linkincard.com/storage/barcodes/cv-632b23e7b0548.png"
              alt=""
            />
            <h4 className="text-[13px]">Linkincard Profile QR</h4>
            <button className="mt-1 rounded-md bg-blue-700 px-4 py-1 text-[12px] text-white">
              Download in better quality
            </button>
          </div>
          <div className="rounded-lg border pb-6 text-center">
            <div className="flex justify-end p-1">
              <button className=" flex h-[20px] items-center  rounded-lg bg-lemon-100 px-2 text-[12px] text-white ">
                new
              </button>
            </div>
            <div className="px-5">
              <img
                className="w-full"
                src="https://admin.linkincard.com/storage/5860/offline-qr-cv-632b23e7b0548.png"
                alt=""
              />
              <div className=" pt-3">
                <h4 className="text-[13px] leading-none">Offline QR</h4>
                <p className="text-[13px] text-lemon-100">Regenerate</p>
              </div>
            </div>
          </div>
          <div className="space-y-1 rounded-lg border p-5">
            <h4 className="text-[14px] text-danger">
              Before you generate your Offline QR read below.
            </h4>
            <p className="text-[12px] leading-4">
              Offline QR is only used to generate your vcard in qr. So even your
              contacts doesnt have internet connection they can add you as
              contact to their mobile. When you generate your qr it will put
              your contact information in to qr code. The information in offline
              QR is not dynamic information. It means that when your contact
              information change you should generate your QR again. If you plan
              to print your Offline QR please be sure you filled all your
              contact information in your Profile Edit screen.
            </p>
          </div>
        </Card>
        <Card title="Choosed Promo Picture">
          <img
            src="https://admin.linkincard.com/storage/3962/OFFECIAL-LOGO.jpg"
            alt=""
          />
        </Card>
        <Card title="Profile Image">
          <img src="https://admin.linkincard.com/storage/3963/s1.jpg" alt="" />
        </Card>
      </div>
      <div className="w-full space-y-6">
        <Card title="Account Information" className="space-y-3">
          <InputGroup
            icon={<FaPen size={14} />}
            placeholder="Please Enter Your Full Name"
          />
          <InputGroup
            icon={<HiOutlineMailOpen size={14} />}
            placeholder="Please Enter Your Email"
          />
          <InputGroup
            icon={<LuKeyRound size={14} />}
            placeholder="Please Enter Your Password"
            type="password"
          />
          <InputFile label="Promo Photo" />
          <InputFile label="Profile Image" />
        </Card>
        <Card title="Contact Information" className="space-y-3">
          <PhoneInput
            country={''}
            value=""
            onChange={() => {}}
            placeholder="Enter your Number"
          />
          <PhoneInput
            country={'w'}
            value=""
            onChange={() => {}}
            placeholder="Please Enter Your WhatsApp Number"
          />

          <InputGroup
            icon={<FaLocationArrow size={14} />}
            placeholder="Please Enter Your Address"
          />
          <InputGroup
            icon={<FaFax size={14} />}
            placeholder="Please Enter Your Fax Number"
          />
        </Card>
        <Card title="Job Information" className="space-y-3">
          <InputGroup
            icon={<LuWarehouse size={14} />}
            placeholder="Please Enter Your Company Name"
          />
        </Card>
      </div>
    </div>
  );
};

export default EditCardPage;
