import React, { useEffect } from 'react';
import { HiUsers } from 'react-icons/hi';
import { LuUserSquare2 } from 'react-icons/lu';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import Table from '../../../components/Table';
import Card from '../../../components/ui/Card';
import { useGetAnalyticsQuery } from '../../../redux/features/contacts/contactsApi';
import { formatDataForAnalyticsTable } from '../../../utils/helper';

const AnalyticsPage = () => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const pramsCardType = searchParams.get('cardType');
  const { id } = useParams();
  const { data: analyticsData, refetch } = useGetAnalyticsQuery({
    id: id,
    type: pramsCardType,
  });

  const content = {
    header: ['IP', 'City', 'Total Visits', 'First Visit', 'Last Visit'],
    body: formatDataForAnalyticsTable(analyticsData?.data?.data),
  };
  useEffect(() => {
    refetch();
  }, [pathname]);

  // sum all the totalVisits keys from analyticsData.data.data array
  const totalVisits = analyticsData?.data?.data?.reduce(
    (acc, curr) => acc + curr?.totalVisits,
    0
  );
  return (
    <div className="space-y-6 p-8">
      <div className="flex items-center justify-between gap-4">
        <div className="relative grid h-[215px] w-full rounded-md bg-[#00000091] p-5">
          <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full border border-dashed border-gray-500 bg-black text-white">
            <LuUserSquare2 size="30" />
          </div>

          <div className="flex items-center gap-3 text-white">
            <h2 className="text-[48px] font-bold ">
              {analyticsData?.data?.data?.length}
            </h2>
            <h2 className="font-bold">Visitors</h2>
          </div>

          <div className="absolute right-0 top-0 z-10 overflow-hidden opacity-80">
            <img
              className="h-[215px]"
              src="https://admin.linkincard.com/assets/media/svg/shapes/wave-bg-black.svg"
              alt=""
            />
          </div>
        </div>
        <div className="relative grid h-[215px] w-full rounded-md bg-[#77a900] p-5">
          <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full border border-dashed border-gray-500 bg-lemon-100 text-black">
            <HiUsers size="30" />
          </div>

          <div className="flex items-center gap-3 text-black">
            <h2 className="text-[48px] font-bold ">{totalVisits}</h2>
            <h2 className="font-bold">Views </h2>
          </div>

          <div className="absolute right-0 top-0 z-10 overflow-hidden opacity-80">
            <img
              className="h-[215px]"
              src="https://admin.linkincard.com/assets/media/svg/shapes/wave-bg-black.svg"
              alt=""
            />
          </div>
        </div>
      </div>

      <Card title="Visitors Details">
        <Table content={content} />
      </Card>
    </div>
  );
};

export default AnalyticsPage;
