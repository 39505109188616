import React from "react";
import { AiFillProduct } from "react-icons/ai";
import { CiImageOn } from "react-icons/ci";
import { FaPen, FaSort, FaYoutube } from "react-icons/fa";
import { GrCurrency } from "react-icons/gr";
import { IoVideocamOutline } from "react-icons/io5";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import InputField from "../../../components/ui/InputField";
import InputFile from "../../../components/ui/InputFile";
import SelectOptions from "../../../components/ui/SelectOptions";
import TextAreaField from "../../../components/ui/TextAreaField";

const UpdateStorePage = () => {
  return (
    <>
      <Card
        title="Update Product"
        content={
          <Button
            link="/business/products"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            Back To Products
          </Button>
        }
        className="space-y-4"
      >
        <SelectOptions icon={<FaPen />} placeholder="Select Category..." />
        <InputField
          name="name"
          placeholder="Product Name"
          icon={<AiFillProduct />}
        />

        <TextAreaField label="Description" placeholder="Product Description" />

        <InputField
          name="price"
          placeholder="Product Price"
          type="number"
          icon={<GrCurrency />}
        />
        <InputField
          name="video"
          placeholder="Video Link"
          icon={<FaYoutube />}
        />
        <InputField
          name="sort"
          type="number"
          placeholder="Sort Order"
          icon={<FaSort />}
        />

        <Card>
          <InputFile title="Cover Image" icon={<CiImageOn />} />
        </Card>
        <Card>
          <InputFile title="Video" icon={<IoVideocamOutline />} />
        </Card>
        <Card>
          <InputFile title="Others Image" icon={<CiImageOn />} />
        </Card>

        <Button className="!bg-green-400">Save</Button>
      </Card>
    </>
  );
};

export default UpdateStorePage;
