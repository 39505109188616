import QRCode from 'qrcode.react';
import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const TableHeader = ({ children }) => (
  <th className="px-4 py-3 text-left text-sm font-thin text-gray-600 dark:bg-transparent dark:text-white">
    {children}
  </th>
);

const TableCell = ({ children }) => (
  <td className="px-4 py-3 text-sm text-gray-800 dark:text-white ">
    {children}
  </td>
);

const QRCell = ({ content, color }) => (
  <td className="px-4 py-3 text-sm text-gray-800 dark:text-white ">
        <QRCode
          value={content}
          size={100}
          fgColor={color}
        />
  </td>
);




const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <>
    <div className="flex items-center justify-between p-6">
      <div>
        <select
          className="rounded bg-[#F8FAFB] px-4 py-3 text-sm dark:bg-transparent"
          value={10}
          onChange={() => {}}
        >
          <option value={10} className="dark:bg-black">
            10
          </option>
          <option value={20} className="dark:bg-black">
            20
          </option>
          <option value={50} className="dark:bg-black">
            50
          </option>
        </select>
        <span className="ml-2 text-sm text-gray-600">
          Showing {currentPage} to {totalPages} of {totalPages} records
        </span>
      </div>
      <div className="flex items-center">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="rounded p-1 hover:bg-gray-200 disabled:opacity-50"
        >
          <FaAngleLeft size={20} />
        </button>
        <span className="mx-2 rounded bg-blue-500 px-3 py-1 text-white">
          {currentPage}
        </span>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="rounded p-1 hover:bg-gray-200 disabled:opacity-50"
        >
          <FaAngleRight size={20} />
        </button>
      </div>
    </div>
    <div className="flex items-center">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="rounded p-1 hover:bg-gray-200 disabled:opacity-50"
      >
        <FaAngleLeft size={20} />
      </button>
      <span className="mx-2 rounded bg-blue-500 px-3 py-1 text-white">
        {currentPage}
      </span>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="rounded p-1 hover:bg-gray-200 disabled:opacity-50"
      >
        <FaAngleRight size={20} />
      </button>
    </div>
  </>
);

const CommonTable = ({ data, border = false, tableHeader, actions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / 10);

  return (
    <>
      <div className="overflow-x-auto p-6">
        <div
          className={`${
            border ? "border dark:border-slate-600" : "border-none"
          } rounded-lg`}
        >
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 dark:border dark:border-slate-600 dark:bg-transparent">
                {tableHeader.map((header, index) => (
                  <TableHeader key={index}>{header}</TableHeader>
                ))}
              </tr>
            </thead>
            <tbody className="dark:[&>*:nth-child(even)]:bg-transparent dark:[&>*:nth-child(odd)]:bg-[#1C1C2A]">
              {data.map((order, index) => (
                <tr key={index} className="">
                  
                  {Object.keys(order).map((item, j) => item === '_id' ? null : <TableCell key={j}>{order[item]}</TableCell>
                  )}

                  {!actions ? null : <TableCell>
                    {actions(order)}
                  </TableCell>}
                </tr>
              ))}
            </tbody>
          </table>

          {data?.length === 0 ? (
            <div className="bg-slate-100 p-6 text-center dark:bg-transparent">
              <p>No data available in table</p>
            </div>
          ) : null}
        </div>
      </div>
      {data?.length > 10 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      ) : null}
    </>
  );
};

export default CommonTable;
