import React from 'react';
import Table from '../../../components/Table';
import Card from '../../../components/ui/Card';

const SingleOrderView = ({ content, paginate, setPaginate }) => {
  return (
    <div className="space-y-6">
      <Card title="Orders List">
        <Table
          content={content}
          //   pagination={data?.data?.paginate}
          //   onPaginate={setPaginate}
        />
      </Card>
    </div>
  );
};

export default SingleOrderView;
