import { apiSlice } from "../api/apiSlice";

export const wifiApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWifiCodes: builder.query({
      query: (arg) => ({
        url: "/wifi",
        method: "GET",
        params: arg,
      }),
      providesTags: ["wifiCodes"],
    }),

    getWifiCode: builder.query({
      query: (id) => ({
        url: `/wifi/${id}`,
        method: "GET",
      }),
      providesTags: ["wifiCodes"],
    }),

    createWifiCode: builder.mutation({
      query: (data) => ({
        url: "/wifi",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["wifiCodes"],
    }),

    editWifiCode: builder.mutation({
      query: ({ id, data }) => ({
        url: `/wifi/${id}`,
        method: "PUT",
        body: data,
      }),
    }),

    deleteWifiCode: builder.mutation({
      query: (id) => ({
        url: `/wifi/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["wifiCodes"],
    }),
  }),
});

export const {
  useGetWifiCodeQuery,
  useGetWifiCodesQuery,
  useCreateWifiCodeMutation,
  useEditWifiCodeMutation,
  useDeleteWifiCodeMutation,
} = wifiApi;
