import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetEmployeeQuery } from '../../redux/features/employees/employeesApi';
import Loading from '../Loading';
import CardView from '../ui/CardView';

const EmployeeCardViewPage = () => {
  const { id } = useParams();
  const { data: cardData, isLoading } = useGetEmployeeQuery(id);

  const phone = `${cardData?.data?.phone?.dialCode ?? ''} ${
    cardData?.data?.phone?.fullNumber ?? ''
  }`;

  const data = {
    name: `${cardData?.data?.firstName} ${cardData?.data?.lastName}`,
    image: cardData?.data?.imgUrl,
    bannerImg: cardData?.data?.bannerBg,
    position: cardData?.data?.job_position,
    address: cardData?.data?.user?.bussinesInfo?.businessName,
    email: cardData?.data?.user?.bussinesInfo?.email,
    //whatsapp: `${whatsapp !== ' ' ? whatsapp : ''}`,
    // phone: `${phone !== ' ' ? phone : ''}`,
    additionalPhones: cardData?.data?.additionalPhones,
    social: cardData?.data?.user?.bussinesInfo?.socialMediaLinks,
    youtubeVideo: cardData?.data?.user?.bussinesInfo?.socialMediaLinks.videoLink,
    bankAccounts: cardData?.data?.user?.bankAccounts,

  };


  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <CardView
          data={data}
          link={`/business/employees/edit/${id}`}
          variant="employee"
        />
      )}
    </div>
  );
};

export default EmployeeCardViewPage;
