import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateExternalContactMutation } from "../../redux/features/externalContacts/externalContactsApi";
import Button from "../ui/Button";
import InputField from "../ui/InputField";
import InputFile from "../ui/InputFile";
import TextAreaField from "../ui/TextAreaField";

const ExchangeModal = ({ handleClose }) => {
  const [values, setValues] = useState({});
  const [createExternalContact] = useCreateExternalContactMutation();
  //   console.log(error, status);
  const { id } = useParams();
  const navigate = useNavigate();

  const onChange = (key, value) => {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // SUBMIT FUNCTION
  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitData = {
      ...values,
      cardId: id,
    };
    try {
      const result = await createExternalContact(submitData);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        handleClose();
        navigate("/");
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <InputField
        label="Name"
        name="name"
        value={values?.name}
        onChange={onChange}
        isRequired={true}
      />
      <InputField
        label="Email"
        name="email"
        value={values?.email}
        onChange={onChange}
        isRequired={true}
      />
      <InputField
        label="Phone Number"
        name="phone"
        value={values?.phone}
        onChange={onChange}
        isRequired={true}
        type="number"
      />
      <TextAreaField
        label="Note"
        name="note"
        value={values?.note}
        onChange={onChange}
        isRequired={true}
      />
      <InputFile
        title="Attachment"
        name="attachment"
        value={values?.attachment}
        onChange={onChange}
        isRequired={true}
      />

      <Button type="submit" className="mt-3 w-full !bg-black">
        Exchange
      </Button>
    </form>
  );
};

export default ExchangeModal;
