import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaPenToSquare, FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import Table from "../../../components/Table";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import {
    useDeleteCategoriesMutation,
    useGetCategoriesQuery,
} from "../../../redux/features/categories/categoryApi";
import { formatRelativeTime } from "../../../utils/formatRelativeTime";

const ProductCategoriesPage = () => {
  const navigate = useNavigate();
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });
  const { data, isLoading, isSuccess } = useGetCategoriesQuery(paginate);
  const [deleteCategory] = useDeleteCategoriesMutation();

  const handleDeleteCategories = async (id) => {
    try {
      const result = await deleteCategory(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
      toast.error("Delete failed!" || error?.response?.data?.detail);
    }
  };

  const content = {
    header: ["Category Name", "Created At", "Actions"],

    body: data?.data?.results?.map((item) => ({
      rows: [
        {
          row: item?.title?.en || "Category Name",
        },

        {
          row: formatRelativeTime(item?.createdAt),
        },

        {
          row: (
            <div className="flex items-center gap-3">
              <Button
                className="!block !bg-green-400"
                link={`/product-categories/edit/${item?._id}`}
              >
                <FaPenToSquare />
              </Button>
              <Button
                className="!bg-red-400"
                onClick={() => handleDeleteCategories(item?._id)}
              >
                <MdDelete />
              </Button>
            </div>
          ),
        },
      ],
    })),
  };

  return (
    <div className="space-y-6">
      <Card
        title="Categories"
        content={
          <Button
            link="/product-categories/create"
            className="flex items-center gap-2 !bg-green-500"
          >
            <FaPlus /> Add Categories
          </Button>
        }
      >
        {isLoading ? (
          <Loading height="70vh" />
        ) : (
          <Table
            content={content}
            pagination={data?.data?.paginate}
            onPaginate={setPaginate}
          />
        )}
      </Card>
    </div>
  );
};

export default ProductCategoriesPage;
