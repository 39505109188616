import React from "react";
import toast from "react-hot-toast";
import { FaPenToSquare, FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import Table from "../../../components/Table";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import {
    useDeleteSubscriptionMutation,
    useGetSubscriptionsQuery,
} from "../../../redux/features/subscriptions/subscriptionsApi";

const SubscriptionsPage = () => {
  const { data, isLoading } = useGetSubscriptionsQuery();
  const [deleteSubscription] = useDeleteSubscriptionMutation();

  const handleDelete = async (id) => {
    try {
      const result = await deleteSubscription(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }else{
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
    //   console.error(error);
    }
  };

  const content = {
    header: [
      "Subscription Name",
      "Monthly Price",
      "Yearly Price",
      "Trail Time",
      "Actions",
    ],

    body: data?.data?.map((item) => ({
      rows: [
        {
          row: <span className="capitalize">{item?.subscriptionType}</span>,
        },
        {
          row: <span className="capitalize"> ${item?.monthlyPrice}</span>,
        },

        {
          row: <span className="capitalize"> $ {item?.yearlyPrice}</span>,
        },
        {
          row: item?.trail,
        },

        {
          row: (
            <div className="flex items-center gap-3">
              <Button
                link={`/admin/subscriptions/edit/${item?._id}`}
                className="!block !bg-green-400"
              >
                <FaPenToSquare />
              </Button>
              <Button
                onClick={() => handleDelete(item?._id)}
                className="!bg-red-400"
              >
                <MdDelete />
              </Button>
            </div>
          ),
        },
      ],
    })),
  };

  return (
    <>
      <Card
        title="Subscriptions"
        content={
          <Button
            link="/admin/subscriptions/create"
            className="flex items-center gap-2 !bg-green-500"
          >
            <FaPlus /> Add New Subscription
          </Button>
        }
      >
        <Table content={content} />
      </Card>
    </>
  );
};

export default SubscriptionsPage;
