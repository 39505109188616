import { useState } from 'react';
import toast from 'react-hot-toast';
import { GoPencil } from 'react-icons/go';
import Loading from '../../../../components/Loading';
import Button from '../../../../components/ui/Button';
import Card from '../../../../components/ui/Card';
import InputField from '../../../../components/ui/InputField';
import SelectOptions from '../../../../components/ui/SelectOptions';
import { countryPhoneOptions } from '../../../../data/data';
import { useEditCardMutation } from '../../../../redux/features/cards/cardsApi';

const ContactInfo = ({ data, viewMode }) => {
  const [viewType, setViewType] = useState('preview');
  const [values, setValues] = useState(data);
  const [editProfile, { isLoading }] = useEditCardMutation();

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  const handleSubmit = async () => {
    const newValues = { ...values, userMode: viewMode };
    try {
      const API = editProfile({ id: data?._id, data: newValues });
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        setViewType('preview');
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  return (
    <div className="space-y-3">
      <div className="flex w-full items-center justify-between">
        <h4 className="text-[12px] font-bold opacity-70 dark:text-white">
          Contact Information
        </h4>
        {viewType === 'preview' && (
          <Button
            onClick={() => setViewType('edit')}
            className="dark:bg-dark-100 flex h-[35px] !w-[80px] items-center justify-center gap-1 bg-white text-[14px] !text-black dark:text-white"
          >
            <GoPencil size={16} className="pb-0.5" />
            <span>Edit</span>
          </Button>
        )}
      </div>

      {viewType === 'preview' ? (
        <Card className="rounded-[20px]">
          <div className="space-y-3">
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Phone</h4>
              <p className="opacity-70">{`${data?.phone?.dialCode} ${data?.phone?.fullNumber}`}</p>
            </div>
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Whatsapp</h4>
              <p className="opacity-70">{`${data?.whatsapp?.dialCode} ${data?.whatsapp?.fullNumber}`}</p>
            </div>

            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Address</h4>
              <p className="opacity-70"> {data?.address}</p>
            </div>
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Fax Number</h4>
              <p className="opacity-70"> {data?.faxNumber}</p>
            </div>
          </div>
        </Card>
      ) : (
        <div className="space-y-5">
          <Card className="space-y-4 rounded-xl">
            <div className=" flex w-full items-center gap-3">
              <SelectOptions
                label="Country"
                placeholder="Select Country..."
                options={countryPhoneOptions}
                name="phone"
                subKey="dialCode"
                value={values?.phone?.dialCode}
                onChange={onChange}
              />
              <div className="w-full">
                <InputField
                  label="Phone"
                  name="phone"
                  subKey="fullNumber"
                  placeholder="Mobile Number"
                  type="number"
                  value={values?.phone?.fullNumber}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className=" flex w-full items-center gap-3">
              <SelectOptions
                label="Country"
                placeholder="Select Country..."
                options={countryPhoneOptions}
                name="whatsapp"
                subKey="dialCode"
                value={values?.whatsapp?.dialCode}
                onChange={onChange}
              />
              <div className="w-full">
                <InputField
                  label="Whatsapp"
                  name="whatsapp"
                  subKey="fullNumber"
                  placeholder="Whatsapp Number"
                  type="number"
                  value={values?.whatsapp?.fullNumber}
                  onChange={onChange}
                />
              </div>
            </div>

            <InputField
              label="Address"
              placeholder="Please Enter Your Address"
              name="address"
              value={values?.address}
              onChange={onChange}
            />
            <InputField
              label="Fax Number"
              name="faxNumber"
              value={values?.faxNumber}
              onChange={onChange}
              placeholder="Please Enter Your Fax Number"
            />

            <div className="flex items-center justify-between gap-4">
              <button
                disabled={isLoading}
                className="flex !w-full cursor-pointer items-center justify-center gap-1 rounded-lg !bg-primary px-4 py-2 text-white"
                onClick={handleSubmit}
              >
                {isLoading && <Loading height="0vh" variant="button" />}
                Save
              </button>

              <button
                onClick={() => setViewType('preview')}
                className="dark:bg-dark-100 !w-full rounded-lg border bg-white px-4 py-2 !text-black dark:border-dark dark:text-white"
              >
                Cancel
              </button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default ContactInfo;
