import React from "react";
import Card from "../../../components/ui/Card";
import personalImg from '../../../images/store/personal.png'
import companyImg from '../../../images/store/company.png'
import businessImg from '../../../images/store/business.png'
import { Link } from "react-router-dom";


const ProfileQuestionPage = () => {

    return (
        <>
            <Card
                title="Please Choose Your Profile Type"
            >
                <div className="flex gap-3 flex-wrap">
                    <Link to={'/store/create-card/?type=personal'}><img src={personalImg} className="w-22" alt="" /></Link>
                    <Link to={'/store/create-card/?type=business'}><img src={businessImg} className="w-22" alt="" /></Link>
                    <Link to={'/store/create-card/?type=company'}><img src={companyImg} className="w-22" alt="" /></Link>
                </div>
            </Card>
        </>
    );
};

export default ProfileQuestionPage;
