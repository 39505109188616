import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import loginImg from "../../images/login/login-bg.jpg";
import logo from "../../images/logo/socalii-black-log.png";
import { useForgotPasswordMutation } from "../../redux/features/auth/authApi";
import ForgotPasswordOtpModal from "./ForgotPasswordOtpModal";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const ForgetPasswordPage = () => {
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [identifier, setIdentifier] = useState({});
  const [otp, setOtp] = useState("");
  const initialTime = 10 * 60; // 10 minutes in seconds
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning]);

  const startTimer = () => {
    setOtp("");
    setTimeRemaining(initialTime);
    setOtpModalOpen(true);
    setIsRunning(true);
  };

  const resetTimer = () => {
    setOtp("");
    setIsRunning(false);
    setTimeRemaining(0);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIdentifier({ email: values?.email });
    try {
      let result = await forgotPassword(values);
      if (result?.data?.success) {
        startTimer();
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setSubmitting(false);
    }
    // resetForm();
  };

  const onClose = () => {
    setOtpModalOpen(false);
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      <div className="grid w-full lg:grid-cols-2">
        <div className="flex items-center justify-center border">
          <div className="mx-auto max-w-lg p-8">
            <div className="pb-4">
              <img className="mx-auto h-12 w-auto" src={logo} alt="logo" />
            </div>
            {/* <h1 className="text-4xl font-bold text-center mb-6">
                            Linkin<span className="font-black">Card</span>
                        </h1> */}

            <h2 className="mb-6 bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-center text-2xl font-semibold text-transparent">
              Login to your dashboard
            </h2>

            <p className="mb-8 text-center text-gray-600">
              If you don't have a Socalii yet, do yourself a favour, showcase a
              fuller and more impressive picture of who you are and make better
              connections.
            </p>

            <Formik
              initialValues={{ email: "" }}
              validationSchema={LoginSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form className="space-y-4">
                  <div className="relative">
                    <Field
                      name="email"
                      type="text"
                      placeholder="Enter username/email"
                      className={`w-full rounded-md border p-3 pl-3 pr-10 ${
                        errors.email && touched.email ? "border-red-500" : ""
                      }`}
                    />
                    <span className="absolute right-3 top-3 text-gray-400">
                      @
                    </span>
                    {errors.email && touched.email && (
                      <div className="mt-1 text-sm text-red-500">
                        {errors.email}
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-between">
                    <button
                      type="submit"
                      className="rounded-md bg-gray-800 p-3 text-white transition duration-300 hover:bg-gray-700"
                      disabled={isLoading}
                    >
                      {isLoading ? "Sending in..." : "Send Password Reset Link"}
                    </button>
                    <p className="mt-4 text-center">
                      <Link to="/login" className="text-black underline">
                        Back to login
                      </Link>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="mt-8 flex justify-center space-x-4">
              <SocialIcon Icon={FaFacebookF} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaInstagram} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaTwitter} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaYoutube} color="bg-lime-500 text-black" />
            </div>
          </div>
        </div>
        <div
          className="overflow-hidden border-8 border-lime-500 bg-green-500 bg-cover bg-center"
          style={{ background: `url(${loginImg})` }}
        ></div>
      </div>

      {/* otp open modal */}
      {!otpModalOpen ? null : (
        <ForgotPasswordOtpModal
          otpModalOpen={otpModalOpen}
          setOtpModalOpen={setOtpModalOpen}
          identifier={identifier}
          setIdentifier={setIdentifier}
          startTimer={startTimer}
          resetTimer={resetTimer}
          formatTime={formatTime}
          timeRemaining={timeRemaining}
          otp={otp}
          setOtp={setOtp}
          onClose={onClose}
        />
      )}
    </div>
  );
};

const SocialIcon = ({ Icon, color }) => (
  <a
    href="#"
    className={`${color} rounded-lg p-2 text-black transition duration-300 hover:opacity-80`}
  >
    <Icon />
  </a>
);

export default ForgetPasswordPage;
