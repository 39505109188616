import { apiSlice } from '../api/apiSlice';

export const categoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (arg) => ({
        url: `/categories?page=${arg?.page}&limit=${arg?.limit}&sort=desc`,
        method: 'GET',
      }),
      providesTags: ['categories'],
    }),

    getSingleCategory: builder.query({
      query: (id) => ({
        url: `/categories/${id}`,
        method: 'GET',
      }),
      providesTags: ['categories'],
    }),

    getCategoryOptions: builder.query({
      query: ({ lang = 'en' }) => ({
        url: `/categories/options?lang=${lang}`,
        method: 'GET',
      }),
    }),

    getCategoriesByUserId: builder.query({
      query: (userId) => ({
        url: `/categories/options-by-userId/${userId}`,
        method: 'GET',
      }),
    }),

    createCategories: builder.mutation({
      query: (data) => ({
        url: '/categories',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['categories'],
    }),

    editCategories: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/categories/${id}`,
        method: 'PUT',
        body: { ...data },
      }),
    }),

    deleteCategories: builder.mutation({
      query: (id) => ({
        url: `/categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['categories'],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoryOptionsQuery,
  useGetSingleCategoryQuery,
  useGetCategoriesByUserIdQuery,
  useCreateCategoriesMutation,
  useEditCategoriesMutation,
  useDeleteCategoriesMutation,
} = categoryApi;
