import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetWifiCodesQuery } from "../../../redux/features/wifi/wifiApi";
import SingleCard from "../cards/SingleCard";


const WifiQrPage = () => {
    const navigate = useNavigate();
    const [showSuccess, setShowSuccess] = useState(false);
    const { data, refetch, isSuccess } = useGetWifiCodesQuery();
    const handleRefetch = () => {
        refetch();
        setShowSuccess(true);
        setTimeout(() => {
            setShowSuccess(false);
        }, 5000)
    }

    useEffect(() => {
        if(isSuccess && (data?.data?.results?.length === 0)){
            navigate('/individual/wifi-qr/create')
        }
    }, [isSuccess]);
    
    return (
        <>
            {<div className="bg-green-50 border border-green-400 text-green-400 p-4 rounded-lg mb-5">
                <p>Wifi QR Generated Successfully</p>
            </div>}
            <div className="grid md:grid-cols-3 lg:grid-cols-4 justify-center gap-5">
                {data?.data?.results?.map((item, i) =>
                    <SingleCard
                        key={i}
                        // data={{
                        //     name: {item?.ssid},
                        //     id: "Personal",
                        // }}
                        data={item}
                        refetch={handleRefetch}
                        isDelete
                        isDownload
                    />
                )}
            </div>
        </>
    );
};

export default WifiQrPage;
