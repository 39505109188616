
import { useState } from "react";
import toast from "react-hot-toast";
import { FaTimes, FaUserPlus } from 'react-icons/fa';
import { Link } from "react-router-dom";
import noImg from '../../../images/shared/no-image.jpg';
import { useGetMyProfileQuery } from "../../../redux/features/auth/authApi";
import { useDeleteFriendRequestMutation, useGetNearbyUsersQuery, useMakeFriendRequestMutation } from "../../../redux/features/friends/friendsApi";
import { getFullName } from "../../../utils/func";
import Loading from "../../Loading";
import Pagination from "../../ui/Pagination";

const NearbyFriends = ({isSingleView=false, limit= 10, isTitle=true }) => {
    const [paginate, setPaginate] = useState({
        page: 1,
        limit: limit,
    });
    const [currentUsingId, setCurrentUsingId] = useState(null);
    const [isRemoveLoading, setIsRemoveLoading] = useState(false);
    const people = [
        { name: 'User 1 name', mutualFriends: 2, image: noImg },
        { name: 'User 2 name', mutualFriends: 1, image: noImg },
        { name: 'User 3 name', mutualFriends: 3, image: noImg },
        { name: 'User 4 name', mutualFriends: 1, image: noImg },
    ];
    const {data: myData} = useGetMyProfileQuery();

    const {data: usersData, refetch} = useGetNearbyUsersQuery({lat:myData?.data?.location?.lat || 0, long: myData?.data?.location?.long || 0, distance:50000, ...paginate}, {skip: !myData?.data?._id});

    const [makeRequest, {isLoading: isRequestLoading}] = useMakeFriendRequestMutation();
    const [deleteRequest, {isLoading: isDeleteLoading}] = useDeleteFriendRequestMutation();

    const handleAddFriend = async (id) => {
        setCurrentUsingId(id);
        const submitData = {
            friendReceiverId: id,
        };
        
        try {
            const result = await makeRequest(submitData);
            if (result?.data?.success) {
                toast.success(result?.data?.message);
                refetch();
            } else {
                toast.error(result?.error?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.detail);
        } finally {
            setCurrentUsingId(null);
        }
    };
    
    
    const handleDeleteRequest = async (id) => {
        const submitData = {
            requestId: id,
        };
        
        try {
            const result = await deleteRequest(submitData);
            if (result?.data?.success) {
                toast.success(result?.data?.message);
                refetch();
            } else {
                toast.error(result?.error?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.detail);
        }
    };

    // console.log(usersData);

    return (
        <div className="bg-white dark:bg-dark text-black dark:text-gray-300 p-4 rounded-lg shadow-md">
            {isTitle && <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Nearby Friends</h2>
                {/* {isSingleView ? null : <Link to={"/friends/all/nearby-friends"} className="text-blue-600 hover:underline">See all</Link>} */}
            </div>}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {usersData?.data?.nearbyUsers?.length > 0 ? usersData?.data?.nearbyUsers?.map((person, index) => (
                    <div key={index} className="bg-white dark:bg-dark text-black dark:text-gray-300 rounded-lg border border-gray-200 overflow-hidden">
                    <Link to={`/social/profile/${person?._id}`}><img src={person?.avatar || noImg} alt={getFullName(person)} className="w-full h-32 object-cover cursor-pointer" /></Link>
                        <div className="p-4">
                        <Link to={`/social/profile/${person?._id}`}><h3 className="font-semibold text-lg mb-1 cursor-pointer">{ getFullName(person) || person?.fullName || "-- --"}</h3></Link>
                            <p className="text-gray-600 text-sm mb-3">{person?.mutualFriendCount} mutual friend{person?.mutualFriendCount !== 1 && 's'}</p>
                            {!person?.friend?.isAlreadyFriendRequestSent ? <button className="w-full bg-blue-100 text-blue-600 py-1 px-2 rounded mb-2 flex items-center justify-center" onClick={() => handleAddFriend(person?._id)}>
                                <FaUserPlus className="mr-1" />
                                { (isRequestLoading && currentUsingId === person?._id) ? <Loading variant="button" height="20px" /> : "Add friend" }
                            </button>
                            :
                            <button className="w-full bg-gray-100 text-gray-600 py-1 px-2 rounded flex items-center justify-center" onClick={() => handleDeleteRequest(person?.friend?.requestId)}>
                                <FaTimes className="mr-1" />
                                { (isDeleteLoading && currentUsingId === person?._id) ? <Loading variant="button" height="20px" /> : "Cancel" }
                            </button>}
                        </div>
                    </div>
                )) : <p>Not found</p>}
            </div>
            {usersData?.data?.paginate?.totalCount > 10 && (
                <Pagination pagination={usersData?.data?.paginate} onPaginate={setPaginate} />
            )}
        </div>
    );
};

export default NearbyFriends;