import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaCopy, FaFacebookF, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { RiMessengerFill } from 'react-icons/ri';
import { SiMessenger } from 'react-icons/si';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import DialogModal from '../../../components/ui/DialogModal';
import Pagination from '../../../components/ui/Pagination';
import useDebounce from '../../../hooks/useDebounce';
import BusinessLayout from '../../../layout/BusinessLayout';
import {
  useDeleteEmployeeMutation,
  useGetEmployeesQuery,
} from '../../../redux/features/employees/employeesApi';
import SingleCard from '../cards/SingleCard';

const EmployeesPage = () => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
    q: '',
  });

  const { data, isLoading } = useGetEmployeesQuery(paginate);
  const [deleteEmployee] = useDeleteEmployeeMutation();
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input, 500);
  const [isOpenModal, setIsModalOpen] = useState(false);
  const [urlToCopy, setUrlToCopy] = useState('');

  useEffect(() => {
    setPaginate((prev) => ({
      ...prev,
      q: debouncedInput,
    }));
  }, [debouncedInput]);

  const handleDelete = async (id) => {
    try {
      const result = await deleteEmployee(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const shareButtons = [
    {
      icon: <FaWhatsapp />,
      color: '#25D366',
      platform: 'whatsapp',
      url: (url) =>
        `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
    },
    {
      icon: <MdEmail />,
      color: '#000000',
      platform: 'email',
      url: (url) => `mailto:?body=${encodeURIComponent(url)}`,
    },
    {
      icon: <FaFacebookF />,
      color: '#1877F2',
      platform: 'facebook',
      url: (url) =>
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`,
    },
    {
      icon: <RiMessengerFill />,
      color: '#0078FF',
      platform: 'messenger',
      url: (url) => `fb-messenger://share/?link=${encodeURIComponent(url)}`,
    },
    {
      icon: <SiMessenger />,
      color: '#FFA500',
      platform: 'sms',
      url: (url) => `sms:?body=${encodeURIComponent(url)}`,
    },
    {
      icon: <FaTwitter />,
      color: '#1DA1F2',
      platform: 'twitter',
      url: (url) =>
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
    },
  ];

  const openShareModal = (url) => {
    setUrlToCopy(url);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <BusinessLayout className="space-y-6">
      <Card className="flex items-center justify-between !p-4 !pb-2">
        <input
          type="search"
          placeholder="Search with employee name"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="w-full max-w-[350px] rounded-lg border bg-transparent px-3 py-2 text-[14px] focus:outline-blue-400"
        />
        <p className="text-[14px]">
          {data?.data?.paginate?.totalCount} employees found
        </p>
      </Card>
      {isLoading ? (
        <Loading height="60vh" />
      ) : (
        <div>
          <div className="grid grid-cols-3 gap-5 ">
            {data?.data?.results?.map((data) => {
              const image = data?.qrImage;
              return (
                <SingleCard
                  key={data?._id}
                  data={{
                    name: `${data?.firstName} ${data?.lastName}`,
                    job: data?.job_position,
                    image: image,
                    id: data?._id,
                    username: data?.email,
                  }}
                  shareLink={() =>
                    openShareModal(
                      window.location.origin + `/employee/view/${data?._id}`
                    )
                  }
                  className="!max-w-[400px]"
                  isDelete
                  deleteFunc={() => handleDelete(data?._id)}
                  editLink={`/cards/employees/edit/${data?._id}`}
                  analyticLink={`/analytics/${data?._id}?cardType=employee`}
                  viewLink={`/employee/view/${data?._id}?username=${data?.email}`}
                />
              );
            })}
            <div className="flex h-[420px] w-full max-w-[400px] items-center justify-center rounded-lg border-2  border-dashed border-gray-300 p-4">
              <Button
                link="/cards/employees/create"
                className="flex flex-col items-center !bg-white !text-gray-400 transition duration-300 ease-in-out hover:!bg-gray-100 hover:!text-gray-600"
              >
                <FaPlus size={52} />
              </Button>
            </div>
          </div>

          {data?.data?.paginate?.totalCount > 10 && (
            <Pagination
              pagination={data?.data?.paginate}
              onPaginate={setPaginate}
            />
          )}
        </div>
      )}

      {isOpenModal && (
        <DialogModal onClose={closeModal} title="Share My Profile" width="500">
          <div className="flex flex-col items-center gap-4 py-8">
            <div className="flex flex-wrap gap-4">
              {shareButtons.map((button) => (
                <button
                  key={button.platform}
                  onClick={() => window.open(button.url(urlToCopy), '_blank')}
                  className="rounded-full p-2 transition-transform hover:scale-110"
                  style={{ backgroundColor: button.color }}
                >
                  {React.cloneElement(button.icon, {
                    color: 'white',
                    size: 24,
                  })}
                </button>
              ))}
            </div>
            <input
              type="text"
              value={urlToCopy}
              readOnly
              className="w-full rounded-md border p-2 text-center"
              style={{ maxWidth: '400px', height: '80px' }}
            />
            <button
              onClick={() => navigator.clipboard.writeText(urlToCopy)}
              className="flex items-center gap-1 rounded-md bg-blue-500 px-4 py-2 text-white transition-transform hover:scale-105"
            >
              <FaCopy size={16} />
              Copy Link
            </button>
          </div>
          <div className="flex justify-end pt-3">
            <button
              className="rounded bg-dark px-3 py-2 text-[14px] text-white"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </DialogModal>
      )}
    </BusinessLayout>
  );
};

export default EmployeesPage;
