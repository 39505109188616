export const countryPhoneOptions = [
  { label: "Afghanistan (+93)", value: "+93" },
  { label: "Albania (+355)", value: "+355" },
  { label: "Algeria (+213)", value: "+213" },
  { label: "Andorra (+376)", value: "+376" },
  { label: "Angola (+244)", value: "+244" },
  { label: "Antigua and Barbuda (+1-268)", value: "+1-268" },
  { label: "Argentina (+54)", value: "+54" },
  { label: "Armenia (+374)", value: "+374" },
  { label: "Australia (+61)", value: "+61" },
  { label: "Austria (+43)", value: "+43" },
  { label: "Azerbaijan (+994)", value: "+994" },
  { label: "Bahamas (+1-242)", value: "+1-242" },
  { label: "Bahrain (+973)", value: "+973" },
  { label: "Bangladesh (+880)", value: "+880" },
  { label: "Barbados (+1-246)", value: "+1-246" },
  { label: "Belarus (+375)", value: "+375" },
  { label: "Belgium (+32)", value: "+32" },
  { label: "Belize (+501)", value: "+501" },
  { label: "Benin (+229)", value: "+229" },
  { label: "Bhutan (+975)", value: "+975" },
  { label: "Bolivia (+591)", value: "+591" },
  { label: "Bosnia and Herzegovina (+387)", value: "+387" },
  { label: "Botswana (+267)", value: "+267" },
  { label: "Brazil (+55)", value: "+55" },
  { label: "Brunei (+673)", value: "+673" },
  { label: "Bulgaria (+359)", value: "+359" },
  { label: "Burkina Faso (+226)", value: "+226" },
  { label: "Burundi (+257)", value: "+257" },
  { label: "Cabo Verde (+238)", value: "+238" },
  { label: "Cambodia (+855)", value: "+855" },
  { label: "Cameroon (+237)", value: "+237" },
  { label: "Canada (+1)", value: "+1" },
  { label: "Central African Republic (+236)", value: "+236" },
  { label: "Chad (+235)", value: "+235" },
  { label: "Chile (+56)", value: "+56" },
  { label: "China (+86)", value: "+86" },
  { label: "Colombia (+57)", value: "+57" },
  { label: "Comoros (+269)", value: "+269" },
  { label: "Congo (Congo-Brazzaville) (+242)", value: "+242" },
  { label: "Congo (Congo-Kinshasa) (+243)", value: "+243" },
  { label: "Costa Rica (+506)", value: "+506" },
  { label: "Croatia (+385)", value: "+385" },
  { label: "Cuba (+53)", value: "+53" },
  { label: "Cyprus (+357)", value: "+357" },
  { label: "Czech Republic (+420)", value: "+420" },
  { label: "Denmark (+45)", value: "+45" },
  { label: "Djibouti (+253)", value: "+253" },
  { label: "Dominica (+1-767)", value: "+1-767" },
  { label: "Dominican Republic (+1-809)", value: "+1-809" },
  { label: "Ecuador (+593)", value: "+593" },
  { label: "Egypt (+20)", value: "+20" },
  { label: "El Salvador (+503)", value: "+503" },
  { label: "Equatorial Guinea (+240)", value: "+240" },
  { label: "Eritrea (+291)", value: "+291" },
  { label: "Estonia (+372)", value: "+372" },
  { label: "Eswatini (+268)", value: "+268" },
  { label: "Ethiopia (+251)", value: "+251" },
  { label: "Fiji (+679)", value: "+679" },
  { label: "Finland (+358)", value: "+358" },
  { label: "France (+33)", value: "+33" },
  { label: "Gabon (+241)", value: "+241" },
  { label: "Gambia (+220)", value: "+220" },
  { label: "Georgia (+995)", value: "+995" },
  { label: "Germany (+49)", value: "+49" },
  { label: "Ghana (+233)", value: "+233" },
  { label: "Greece (+30)", value: "+30" },
  { label: "Grenada (+1-473)", value: "+1-473" },
  { label: "Guatemala (+502)", value: "+502" },
  { label: "Guinea (+224)", value: "+224" },
  { label: "Guinea-Bissau (+245)", value: "+245" },
  { label: "Guyana (+592)", value: "+592" },
  { label: "Haiti (+509)", value: "+509" },
  { label: "Honduras (+504)", value: "+504" },
  { label: "Hungary (+36)", value: "+36" },
  { label: "Iceland (+354)", value: "+354" },
  { label: "India (+91)", value: "+91" },
  { label: "Indonesia (+62)", value: "+62" },
  { label: "Iran (+98)", value: "+98" },
  { label: "Iraq (+964)", value: "+964" },
  { label: "Ireland (+353)", value: "+353" },
  { label: "Israel (+972)", value: "+972" },
  { label: "Italy (+39)", value: "+39" },
  { label: "Jamaica (+1-876)", value: "+1-876" },
  { label: "Japan (+81)", value: "+81" },
  { label: "Jordan (+962)", value: "+962" },
  { label: "Kazakhstan (+7)", value: "+7" },
  { label: "Kenya (+254)", value: "+254" },
  { label: "Kiribati (+686)", value: "+686" },
  { label: "Kuwait (+965)", value: "+965" },
  { label: "Kyrgyzstan (+996)", value: "+996" },
  { label: "Laos (+856)", value: "+856" },
  { label: "Latvia (+371)", value: "+371" },
  { label: "Lebanon (+961)", value: "+961" },
  { label: "Lesotho (+266)", value: "+266" },
  { label: "Liberia (+231)", value: "+231" },
  { label: "Libya (+218)", value: "+218" },
  { label: "Liechtenstein (+423)", value: "+423" },
  { label: "Lithuania (+370)", value: "+370" },
  { label: "Luxembourg (+352)", value: "+352" },
  { label: "Madagascar (+261)", value: "+261" },
  { label: "Malawi (+265)", value: "+265" },
  { label: "Malaysia (+60)", value: "+60" },
  { label: "Maldives (+960)", value: "+960" },
  { label: "Mali (+223)", value: "+223" },
  { label: "Malta (+356)", value: "+356" },
  { label: "Marshall Islands (+692)", value: "+692" },
  { label: "Mauritania (+222)", value: "+222" },
  { label: "Mauritius (+230)", value: "+230" },
  { label: "Mexico (+52)", value: "+52" },
  { label: "Micronesia (+691)", value: "+691" },
  { label: "Moldova (+373)", value: "+373" },
  { label: "Monaco (+377)", value: "+377" },
  { label: "Mongolia (+976)", value: "+976" },
  { label: "Montenegro (+382)", value: "+382" },
  { label: "Morocco (+212)", value: "+212" },
  { label: "Mozambique (+258)", value: "+258" },
  { label: "Myanmar (+95)", value: "+95" },
  { label: "Namibia (+264)", value: "+264" },
  { label: "Nauru (+674)", value: "+674" },
  { label: "Nepal (+977)", value: "+977" },
  { label: "Netherlands (+31)", value: "+31" },
  { label: "New Zealand (+64)", value: "+64" },
  { label: "Nicaragua (+505)", value: "+505" },
  { label: "Niger (+227)", value: "+227" },
  { label: "Nigeria (+234)", value: "+234" },
  { label: "North Korea (+850)", value: "+850" },
  { label: "North Macedonia (+389)", value: "+389" },
  { label: "Norway (+47)", value: "+47" },
  { label: "Oman (+968)", value: "+968" },
  { label: "Pakistan (+92)", value: "+92" },
  { label: "Palau (+680)", value: "+680" },
  { label: "Palestine (+970)", value: "+970" },
  { label: "Panama (+507)", value: "+507" },
  { label: "Papua New Guinea (+675)", value: "+675" },
  { label: "Paraguay (+595)", value: "+595" },
  { label: "Peru (+51)", value: "+51" },
  { label: "Philippines (+63)", value: "+63" },
  { label: "Poland (+48)", value: "+48" },
  { label: "Portugal (+351)", value: "+351" },
  { label: "Qatar (+974)", value: "+974" },
  { label: "Romania (+40)", value: "+40" },
  { label: "Russia (+7)", value: "+7" },
  { label: "Rwanda (+250)", value: "+250" },
  { label: "Saint Kitts and Nevis (+1-869)", value: "+1-869" },
  { label: "Saint Lucia (+1-758)", value: "+1-758" },
  { label: "Saint Vincent and the Grenadines (+1-784)", value: "+1-784" },
  { label: "Samoa (+685)", value: "+685" },
  { label: "San Marino (+378)", value: "+378" },
  { label: "Sao Tome and Principe (+239)", value: "+239" },
  { label: "Saudi Arabia (+966)", value: "+966" },
  { label: "Senegal (+221)", value: "+221" },
  { label: "Serbia (+381)", value: "+381" },
  { label: "Seychelles (+248)", value: "+248" },
  { label: "Sierra Leone (+232)", value: "+232" },
  { label: "Singapore (+65)", value: "+65" },
  { label: "Slovakia (+421)", value: "+421" },
  { label: "Slovenia (+386)", value: "+386" },
  { label: "Solomon Islands (+677)", value: "+677" },
  { label: "Somalia (+252)", value: "+252" },
  { label: "South Africa (+27)", value: "+27" },
  { label: "South Korea (+82)", value: "+82" },
  { label: "South Sudan (+211)", value: "+211" },
  { label: "Spain (+34)", value: "+34" },
  { label: "Sri Lanka (+94)", value: "+94" },
  { label: "Sudan (+249)", value: "+249" },
  { label: "Suriname (+597)", value: "+597" },
  { label: "Sweden (+46)", value: "+46" },
  { label: "Switzerland (+41)", value: "+41" },
  { label: "Syria (+963)", value: "+963" },
  { label: "Taiwan (+886)", value: "+886" },
  { label: "Tajikistan (+992)", value: "+992" },
  { label: "Tanzania (+255)", value: "+255" },
  { label: "Thailand (+66)", value: "+66" },
  { label: "Timor-Leste (+670)", value: "+670" },
  { label: "Togo (+228)", value: "+228" },
  { label: "Tonga (+676)", value: "+676" },
  { label: "Trinidad and Tobago (+1-868)", value: "+1-868" },
  { label: "Tunisia (+216)", value: "+216" },
  { label: "Turkey (+90)", value: "+90" },
  { label: "Turkmenistan (+993)", value: "+993" },
  { label: "Tuvalu (+688)", value: "+688" },
  { label: "Uganda (+256)", value: "+256" },
  { label: "Ukraine (+380)", value: "+380" },
  { label: "United Arab Emirates (+971)", value: "+971" },
  { label: "United Kingdom (+44)", value: "+44" },
  { label: "United States (+1)", value: "+1" },
  { label: "Uruguay (+598)", value: "+598" },
  { label: "Uzbekistan (+998)", value: "+998" },
  { label: "Vanuatu (+678)", value: "+678" },
  { label: "Vatican City (+379)", value: "+379" },
  { label: "Venezuela (+58)", value: "+58" },
  { label: "Vietnam (+84)", value: "+84" },
  { label: "Yemen (+967)", value: "+967" },
  { label: "Zambia (+260)", value: "+260" },
  { label: "Zimbabwe (+263)", value: "+263" },
];

export const countryCurrencyOptions = [
  { label: "Afghanistan (AFN)", value: "afn" },
  { label: "Albania (ALL)", value: "all" },
  { label: "Algeria (DZD)", value: "dzd" },
  { label: "Andorra (EUR)", value: "eur" },
  { label: "Angola (AOA)", value: "aoa" },
  { label: "Antigua and Barbuda (XCD)", value: "xcd" },
  { label: "Argentina (ARS)", value: "ars" },
  { label: "Armenia (AMD)", value: "amd" },
  { label: "Australia (AUD)", value: "aud" },
  { label: "Austria (EUR)", value: "eur" },
  { label: "Azerbaijan (AZN)", value: "azn" },
  { label: "Bahamas (BSD)", value: "bsd" },
  { label: "Bahrain (BHD)", value: "bhd" },
  { label: "Bangladesh (BDT)", value: "bdt" },
  { label: "Barbados (BBD)", value: "bbd" },
  { label: "Belarus (BYN)", value: "byn" },
  { label: "Belgium (EUR)", value: "eur" },
  { label: "Belize (BZD)", value: "bzd" },
  { label: "Benin (XOF)", value: "xof" },
  { label: "Bhutan (BTN)", value: "btn" },
  { label: "Bolivia (BOB)", value: "bob" },
  { label: "Bosnia and Herzegovina (BAM)", value: "bam" },
  { label: "Botswana (BWP)", value: "bwp" },
  { label: "Brazil (BRL)", value: "brl" },
  { label: "Brunei (BND)", value: "bnd" },
  { label: "Bulgaria (BGN)", value: "bgn" },
  { label: "Burkina Faso (XOF)", value: "xof" },
  { label: "Burundi (BIF)", value: "bif" },
  { label: "Cabo Verde (CVE)", value: "cve" },
  { label: "Cambodia (KHR)", value: "khr" },
  { label: "Cameroon (XAF)", value: "xaf" },
  { label: "Canada (CAD)", value: "cad" },
  { label: "Central African Republic (XAF)", value: "xaf" },
  { label: "Chad (XAF)", value: "xaf" },
  { label: "Chile (CLP)", value: "clp" },
  { label: "China (CNY)", value: "cny" },
  { label: "Colombia (COP)", value: "cop" },
  { label: "Comoros (KMF)", value: "kmf" },
  { label: "Congo (Congo-Brazzaville) (XAF)", value: "xaf" },
  { label: "Congo (Congo-Kinshasa) (CDF)", value: "cdf" },
  { label: "Costa Rica (CRC)", value: "crc" },
  { label: "Croatia (HRK)", value: "hrk" },
  { label: "Cuba (CUP)", value: "cup" },
  { label: "Cyprus (EUR)", value: "eur" },
  { label: "Czech Republic (CZK)", value: "czk" },
  { label: "Denmark (DKK)", value: "dkk" },
  { label: "Djibouti (DJF)", value: "djf" },
  { label: "Dominica (XCD)", value: "xcd" },
  { label: "Dominican Republic (DOP)", value: "dop" },
  { label: "Ecuador (USD)", value: "usd" },
  { label: "Egypt (EGP)", value: "egp" },
  { label: "El Salvador (USD)", value: "usd" },
  { label: "Equatorial Guinea (XAF)", value: "xaf" },
  { label: "Eritrea (ERN)", value: "ern" },
  { label: "Estonia (EUR)", value: "eur" },
  { label: "Eswatini (SZL)", value: "szl" },
  { label: "Ethiopia (ETB)", value: "etb" },
  { label: "Fiji (FJD)", value: "fjd" },
  { label: "Finland (EUR)", value: "eur" },
  { label: "France (EUR)", value: "eur" },
  { label: "Gabon (XAF)", value: "xaf" },
  { label: "Gambia (GMD)", value: "gmd" },
  { label: "Georgia (GEL)", value: "gel" },
  { label: "Germany (EUR)", value: "eur" },
  { label: "Ghana (GHS)", value: "ghs" },
  { label: "Greece (EUR)", value: "eur" },
  { label: "Grenada (XCD)", value: "xcd" },
  { label: "Guatemala (GTQ)", value: "gtq" },
  { label: "Guinea (GNF)", value: "gnf" },
  { label: "Guinea-Bissau (XOF)", value: "xof" },
  { label: "Guyana (GYD)", value: "gyd" },
  { label: "Haiti (HTG)", value: "htg" },
  { label: "Honduras (HNL)", value: "hnl" },
  { label: "Hungary (HUF)", value: "huf" },
  { label: "Iceland (ISK)", value: "isk" },
  { label: "India (INR)", value: "inr" },
  { label: "Indonesia (IDR)", value: "idr" },
  { label: "Iran (IRR)", value: "irr" },
  { label: "Iraq (IQD)", value: "iqd" },
  { label: "Ireland (EUR)", value: "eur" },
  { label: "Israel (ILS)", value: "ils" },
  { label: "Italy (EUR)", value: "eur" },
  { label: "Jamaica (JMD)", value: "jmd" },
  { label: "Japan (JPY)", value: "jpy" },
  { label: "Jordan (JOD)", value: "jod" },
  { label: "Kazakhstan (KZT)", value: "kzt" },
  { label: "Kenya (KES)", value: "kes" },
  { label: "Kiribati (AUD)", value: "aud" },
  { label: "Kuwait (KWD)", value: "kwd" },
  { label: "Kyrgyzstan (KGS)", value: "kgs" },
  { label: "Laos (LAK)", value: "lak" },
  { label: "Latvia (EUR)", value: "eur" },
  { label: "Lebanon (LBP)", value: "lbp" },
  { label: "Lesotho (LSL)", value: "lsl" },
  { label: "Liberia (LRD)", value: "lrd" },
  { label: "Libya (LYD)", value: "lyd" },
  { label: "Liechtenstein (CHF)", value: "chf" },
  { label: "Lithuania (EUR)", value: "eur" },
  { label: "Luxembourg (EUR)", value: "eur" },
  { label: "Madagascar (MGA)", value: "mga" },
  { label: "Malawi (MWK)", value: "mwk" },
  { label: "Malaysia (MYR)", value: "myr" },
  { label: "Maldives (MVR)", value: "mvr" },
  { label: "Mali (XOF)", value: "xof" },
  { label: "Malta (EUR)", value: "eur" },
  { label: "Marshall Islands (USD)", value: "usd" },
  { label: "Mauritania (MRU)", value: "mru" },
  { label: "Mauritius (MUR)", value: "mur" },
  { label: "Mexico (MXN)", value: "mxn" },
  { label: "Micronesia (USD)", value: "usd" },
  { label: "Moldova (MDL)", value: "mdl" },
  { label: "Monaco (EUR)", value: "eur" },
  { label: "Mongolia (MNT)", value: "mnt" },
  { label: "Montenegro (EUR)", value: "eur" },
  { label: "Morocco (MAD)", value: "mad" },
  { label: "Mozambique (MZN)", value: "mzn" },
  { label: "Myanmar (MMK)", value: "mmk" },
  { label: "Namibia (NAD)", value: "nad" },
  { label: "Nauru (AUD)", value: "aud" },
  { label: "Nepal (NPR)", value: "npr" },
  { label: "Netherlands (EUR)", value: "eur" },
  { label: "New Zealand (NZD)", value: "nzd" },
  { label: "Nicaragua (NIO)", value: "nio" },
  { label: "Niger (XOF)", value: "xof" },
  { label: "Nigeria (NGN)", value: "ngn" },
  { label: "North Korea (KPW)", value: "kpw" },
  { label: "North Macedonia (MKD)", value: "mkd" },
  { label: "Norway (NOK)", value: "nok" },
  { label: "Oman (OMR)", value: "omr" },
  { label: "Pakistan (PKR)", value: "pkr" },
  { label: "Palau (USD)", value: "usd" },
  { label: "Palestine (ILS)", value: "ils" },
  { label: "Panama (PAB)", value: "pab" },
  { label: "Papua New Guinea (PGK)", value: "pgk" },
  { label: "Paraguay (PYG)", value: "pyg" },
  { label: "Peru (PEN)", value: "pen" },
  { label: "Philippines (PHP)", value: "php" },
  { label: "Poland (PLN)", value: "pln" },
  { label: "Portugal (EUR)", value: "eur" },
  { label: "Qatar (QAR)", value: "qar" },
  { label: "Romania (RON)", value: "ron" },
  { label: "Russia (RUB)", value: "rub" },
  { label: "Rwanda (RWF)", value: "rwf" },
  { label: "Saint Kitts and Nevis (XCD)", value: "xcd" },
  { label: "Saint Lucia (XCD)", value: "xcd" },
  { label: "Saint Vincent and the Grenadines (XCD)", value: "xcd" },
  { label: "Samoa (WST)", value: "wst" },
  { label: "San Marino (EUR)", value: "eur" },
  { label: "Sao Tome and Principe (STN)", value: "stn" },
  { label: "Saudi Arabia (SAR)", value: "sar" },
  { label: "Senegal (XOF)", value: "xof" },
  { label: "Serbia (RSD)", value: "rsd" },
  { label: "Seychelles (SCR)", value: "scr" },
  { label: "Sierra Leone (SLL)", value: "sll" },
  { label: "Singapore (SGD)", value: "sgd" },
  { label: "Slovakia (EUR)", value: "eur" },
  { label: "Slovenia (EUR)", value: "eur" },
  { label: "Solomon Islands (SBD)", value: "sbd" },
  { label: "Somalia (SOS)", value: "sos" },
  { label: "South Africa (ZAR)", value: "zar" },
  { label: "South Korea (KRW)", value: "krw" },
  { label: "South Sudan (SSP)", value: "ssp" },
  { label: "Spain (EUR)", value: "eur" },
  { label: "Sri Lanka (LKR)", value: "lkr" },
  { label: "Sudan (SDG)", value: "sdg" },
  { label: "Suriname (SRD)", value: "srd" },
  { label: "Sweden (SEK)", value: "sek" },
  { label: "Switzerland (CHF)", value: "chf" },
  { label: "Syria (SYP)", value: "syp" },
  { label: "Taiwan (TWD)", value: "twd" },
  { label: "Tajikistan (TJS)", value: "tjs" },
  { label: "Tanzania (TZS)", value: "tzs" },
  { label: "Thailand (THB)", value: "thb" },
  { label: "Timor-Leste (USD)", value: "usd" },
  { label: "Togo (XOF)", value: "xof" },
  { label: "Tonga (TOP)", value: "top" },
  { label: "Trinidad and Tobago (TTD)", value: "ttd" },
  { label: "Tunisia (TND)", value: "tnd" },
  { label: "Turkey (TRY)", value: "try" },
  { label: "Turkmenistan (TMT)", value: "tmt" },
  { label: "Tuvalu (AUD)", value: "aud" },
  { label: "Uganda (UGX)", value: "ugx" },
  { label: "Ukraine (UAH)", value: "uah" },
  { label: "United Arab Emirates (AED)", value: "aed" },
  { label: "United Kingdom (GBP)", value: "gbp" },
  { label: "United States (USD)", value: "usd" },
  { label: "Uruguay (UYU)", value: "uyu" },
  { label: "Uzbekistan (UZS)", value: "uzs" },
  { label: "Vanuatu (VUV)", value: "vuv" },
  { label: "Vatican City (EUR)", value: "eur" },
  { label: "Venezuela (VES)", value: "ves" },
  { label: "Vietnam (VND)", value: "vnd" },
  { label: "Yemen (YER)", value: "yer" },
  { label: "Zambia (ZMW)", value: "zmw" },
  { label: "Zimbabwe (ZWL)", value: "zwl" },
];
