import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useState } from 'react';

// Replace with your Mapbox access token
mapboxgl.accessToken =
  'pk.eyJ1IjoieWV0b2lkZWFzIiwiYSI6ImNsc3VheDYwNjIzbmUyaW1qd2dubTd3OXkifQ.MvK7DVmhFSVE0OY5ow0fUw';

const LocationMap = ({ locations }) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    const initMap = () => {
      const mapContainer = document.getElementById('map');
      const map = new mapboxgl.Map({
        container: mapContainer,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [50, 25],
        zoom: 3,
      }).addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
        })
      );

      setMap(map);
    };

    initMap();

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, []);

  useEffect(() => {
    //   .setLngLat([location.longitude, location.latitude])
    // console.log('object locations', locations);
    if (map) {
      locations?.forEach((location) => {
        if (location?.location?.lat && location?.location?.long) {
          const marker = new mapboxgl.Marker({
            color: 'red',
          })
            .setLngLat([
              Number(location?.location?.long),
              Number(location?.location?.lat),
            ])
            .addTo(map);

          const popup = new mapboxgl.Popup({
            offset: 25,
            className: 'bg-white shadow-lg rounded-lg p-4',
          }).setHTML(`
                    <div>
                    <h3 class="text-lg font-bold">${location?.firstName} ${location?.lastName}</h3>
                    </div>
                    `);
          //   <p class="text-gray-600">${location?.details}</p>

          marker.setPopup(popup);
        }
      });
    }
  }, [map, locations]);

  return (
    <div className="container mx-auto mt-6 h-[600px] w-full">
      <div id="map" className="h-full w-full"></div>
    </div>
  );
};

export default LocationMap;
