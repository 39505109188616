import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import TextAreaField from "../../../components/ui/TextAreaField";
import {
    useEditPostMutation,
    useGetSinglePostQuery,
} from "../../../redux/features/posts/PostsApi";

const EditPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const { data } = useGetSinglePostQuery(id);

  useEffect(() => {
    if (data) {
      setValue(data?.data?.content);
    }
  }, [data]);

  const handleChangePost = (key, value) => {
    setValue(value);
  };

  const [updatePost, {isLoading}] = useEditPostMutation();

  const handleUpdatePost = async (id) => {
    try {
      const result = await updatePost({ id, content: value });
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate(-1);
    }
    } catch (error) {
    // console.log(error);
    toast.error(error?.response?.data?.message);
    }
  };
  return (
    <Card>
      <TextAreaField value={value} onChange={handleChangePost} />
      <div className="mt-5 flex justify-end">
        <Button className={'w-36'} onClick={() => handleUpdatePost(id)}>{isLoading ? <Loading variant="button" height="20px" /> : "Update Post"}</Button>
      </div>
    </Card>
  );
};

export default EditPost;
