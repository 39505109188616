import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DefaultSelect from "./DefaultSelect";
import { getDateRange } from "../../../../utils/func";

const SearchFilterComponent = ({
  onSearch,
  onFilter,
  initialOrders = [],
  extraFilters = null,
  isExtraFilter = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const [currency, setCurrency] = useState("");
  const [timeRange, setTimeRange] = useState("");
  const [orders, setOrders] = useState([]);
  const [extraFilterOpen, setExtraFilterOpen] = useState(false);

  const debouncedSearch = useCallback(
    debounce(function (term) {
      onSearch(term);
    }, 2000),
    [onSearch]
  );
  // debounce((term) => {
  //   onSearch(term);
  // }, 5000),
  // [onSearch]

  useEffect(() => {
    setOrders(initialOrders);
  }, []);

  useEffect(() => {
    debouncedSearch(searchTerm);
    return () => debouncedSearch.cancel();
  }, [searchTerm, debouncedSearch]);

  useEffect(() => {
    let obj = {};
    if(timeRange){
        obj['startDate'] = getDateRange(timeRange)?.startDate;
        obj['endDate'] = getDateRange(timeRange)?.endDate;
    }
    if(status){
        obj.status = status;
    }
    if(currency){
        obj.currency = currency;
    }
    if(Object.keys(obj)?.length > 0 ){
        onFilter(obj);
    }
    // onFilter({ status, currency, ...getDateRange(timeRange) });
  }, [status, currency, timeRange]);

  
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "cancelled", label: "Cancelled" },
  ];

  const currencyOptions = [
    { value: "usd", label: "USD" },
    { value: "eur", label: "EUR" },
    { value: "gbp", label: "GBP" },
  ];

  const timeRangeOptions = [
    { value: 7, label: "Last 7 days" },
    { value: 30, label: "Last 30 days" },
    { value: 90, label: "Last 90 days" },
  ];

  return (
    <div className="relative rounded-lg bg-gray-100 p-5 shadow-sm dark:bg-black lg:p-8">
      <div className="mb-4 flex flex-wrap gap-4">
        <div className="flex-grow">
          <div className="relative">
            <input
              type="text"
              placeholder="Search name"
              className="w-full rounded border border-gray-300 p-2 pl-5 pr-4 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-black"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            {/* <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} /> */}
          </div>
        </div>
        <div className="w-40">
          <DefaultSelect
            options={statusOptions}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            placeholder="All Statuses"
          />
        </div>
        <div className="w-40">
          <DefaultSelect
            options={currencyOptions}
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            placeholder="All Currencies"
          />
        </div>
        <div className="w-40">
          <DefaultSelect
            options={timeRangeOptions}
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
            placeholder="All time"
          />
        </div>
      </div>
      <div className="flex items-center gap-5">
        <p className="text-sm text-gray-600 dark:text-gray-400">
          Total <span className="font-semibold">{orders.length}</span> orders
          found
        </p>
        {!isExtraFilter ? null : (
          <>
            {!extraFilterOpen ? (
              <Link
                className="underline"
                onClick={() => setExtraFilterOpen(!extraFilterOpen)}
              >
                show extra filters
              </Link>
            ) : (
              <Link
                className="underline"
                onClick={() => setExtraFilterOpen(!extraFilterOpen)}
              >
                hide extra filters
              </Link>
            )}
          </>
        )}
      </div>

      <>{extraFilters && extraFilterOpen ? extraFilters : null}</>
    </div>
  );
};

export default SearchFilterComponent;
