import React, { useEffect, useState } from 'react';
import { FaSortDown, FaGlobe } from "react-icons/fa";
import axios from 'axios';


const languages = [
  { code: 'ar', name: 'Arabic', flag: '🇸🇦' },
  { code: 'en', name: 'English', flag: '🇺🇸' },
  { code: 'tr', name: 'Turkey', flag: '🇹🇷' },
  { code: 'fr', name: 'French', flag: '🇫🇷' },
  { code: 'ru', name: 'Russia', flag: '🇷🇺' },
  { code: 'sv', name: 'Sweden', flag: '🇸🇪' },
  { code: 'fa', name: 'Iran', flag: '🇮🇷' },
  { code: 'am', name: 'Amharik', flag: '🇪🇹' },
];

const LanguageSelector = ({ onSelectLanguage }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

//   const handleLanguageSelect = (language) => {
//     setSelectedLanguage(language);
//     setIsOpen(false);
//     onSelectLanguage(language.code);
//   };

const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[1]); // Default to English
  const [elements, setElements] = useState([]);

  useEffect(() => {
    const textNodes = [];
    const walk = document.createTreeWalker(document.body, NodeFilter.SHOW_TEXT, null, false);
    let node;
    while (node = walk.nextNode()) {
      if (node.textContent.trim() !== '') {
        textNodes.push(node);
      }
    }
    setElements(textNodes);
  }, []);

  const translateText = async (text, targetLang) => {
    try {
      const response = await axios.get(`https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=en|${targetLang}`);
      return response.data.responseData.translatedText;
    } catch (error) {
    //   console.error('Translation error:', error);
      return text; 
    }
  };

  const handleLanguageSelect = async (language) => {
    setSelectedLanguage(language);
    setIsOpen(false);

    if (language.code !== 'en') {
      for (let element of elements) {
        const originalText = element.textContent;
        const translatedText = await translateText(originalText, language.code);
        element.textContent = translatedText;
      }
    } else {
      // Reset to original English text
      window.location.reload();
    }
  };


  return (
    <>

    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex items-center justify-center w-full rounded-md border-0 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          onClick={() => setIsOpen(!isOpen)}
        >
          <FaGlobe className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
          {selectedLanguage.name}
          <FaSortDown className="ml-2 -mt-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {languages.map((language) => (
              <button
                key={language.code}
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                role="menuitem"
                onClick={() => handleLanguageSelect(language)}
              >
                <span className="mr-2">{language.flag}</span>
                {language.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>


    </>
  );
};

export default LanguageSelector;