import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import logo from '../../../images/logo/socalii-black-log.png';
import { useGetNearbyContactsQuery } from '../../../redux/features/contacts/contactsApi';
import LocationMap from '../components/LocationMap';

const ContactMapsPage = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [profileData, setProfileData] = useState({});

  const isPostView = pathname.includes('/posts/view/' + id);

  // const { user } = useSelector((state) => state?.auth);
  const { data } = useGetNearbyContactsQuery();

  return (
    <>
      <div>
        {/* header  */}
        <div className="relative z-99 flex items-center justify-between bg-white px-5 py-4 shadow">
          <Button link={'/home'} className={'!cursor-pointer !bg-transparent'}>
            <img className="mx-auto h-8 w-auto" src={logo} alt="logo" />
            {/* <h2 className="cursor-auto text-[24px] font-bold text-blue-100">
              LinkinCard
            </h2> */}
          </Button>
          {isPostView ? (
            <Button
              //   onClick={() => setPostUserId(profileData?._id)}
              className={'!bg-transparent text-lg font-semibold !text-black'}
            >
              <h2 className="cursor-pointer underline">
                {profileData?.jobInformation?.position || profileData?.fullName}
              </h2>
            </Button>
          ) : (
            <div>
              <Link
                to="/home"
                className=" border-b-2 border-red-600 px-3 pb-2 text-[14px] font-bold text-red-600"
              >
                Home
              </Link>
            </div>
          )}
        </div>

        <LocationMap
          // locations={[
          //     {
          //         latitude: 24.4764,
          //         longitude: 54.3773,
          //         name: 'Sheikh Zayed Grand Mosque',
          //         details: 'One of the largest mosques in the world, located in Abu Dhabi, UAE'
          //     },
          //     {
          //         latitude: 25.2048,
          //         longitude: 55.2708,
          //         name: 'Burj Khalifa',
          //         details: 'The tallest building in the world, located in Dubai, UAE'
          //     },
          //     {
          //         latitude: 30.0444,
          //         longitude: 31.2357,
          //         name: 'Great Pyramids of Giza',
          //         details: 'The last remaining wonder of the ancient world, located in Giza, Egypt'
          //     },
          //     {
          //         latitude: 21.4267,
          //         longitude: 39.8262,
          //         name: 'Masjid al-Haram',
          //         details: 'The largest and one of the holiest mosques in the world, located in Mecca, Saudi Arabia'
          //     }
          // ]}
          locations={data?.data}
        />
      </div>
    </>
  );
};

export default ContactMapsPage;
