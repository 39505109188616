import React, { useState } from "react";
import toast from "react-hot-toast";
import { BiSolidQuoteLeft } from "react-icons/bi";
import { IoShareSocialSharp } from "react-icons/io5";
import { TiMessages } from "react-icons/ti";
import { Link } from "react-router-dom";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import DialogModal from "../../../components/ui/DialogModal";
import InputField from "../../../components/ui/InputField";
import InputFile from "../../../components/ui/InputFile";
import SelectOptions from "../../../components/ui/SelectOptions";
import TextAreaField from "../../../components/ui/TextAreaField";
import demoImage from "../../../images/shared/no-image.jpg";
import {
    useCreateStoreReviewMutation,
    useGetStoreReviewsQuery,
} from "../../../redux/features/storeReview/storeReviewsApi";
import { useTranslation } from 'react-i18next';

const ReviewSection = ({ storeData }) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsModalOpen] = useState();
  const [values, setValues] = useState({});

  const { data: reviewData } = useGetStoreReviewsQuery();
  const [createStoreReview, { isLoading: createLoading }] =
    useCreateStoreReviewMutation();

  const approvedReviews = reviewData?.data?.results?.filter(
    (item) => item?.status === "approved"
  );

  const onChange = (key, value) => {
    setValues((prev) => ({
      ...prev,
      [key]: value,
      store: storeData?._id,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const API = createStoreReview(values);
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        setIsModalOpen(false);
        setValues({});
      }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
    }
  };

  //   modal functionality
  const onOpenModal = () => {
    setIsModalOpen(true);
  };
  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="space-y-4">
        <div className="grid grid-cols-3 gap-3">
          {approvedReviews?.map((review) => (
            <Card key={review?._id} className="grid h-full space-y-3">
              <div className="space-y-3">
                <img
                  className="mx-auto max-h-[150px]"
                  src={review?.image_url ?? demoImage}
                  alt=""
                />
                <span
                  style={{ color: storeData?.color }}
                  className="flex justify-center "
                >
                  <BiSolidQuoteLeft size={32} />
                </span>
                <Link
                  to=""
                  className="flex justify-center text-center font-medium text-black hover:underline"
                >
                  {review?.review_text}
                </Link>
                <h4 className="text-center opacity-90">{review?.name}</h4>
              </div>
              <div className="flex items-end justify-center">
                <Button className="mx-auto flex items-center justify-center space-x-1 border !bg-white !text-black hover:!bg-black hover:!text-white">
                  <IoShareSocialSharp />
                  <span>Share</span>
                </Button>
              </div>
            </Card>
          ))}
        </div>
        <div className="space-y-2">
          <Link className="flex justify-center text-center underline" to="">
            {t('viewAllReviews')}
          </Link>
          <button
              onClick={onOpenModal}
              style={{background: storeData?.color}}
              className="mx-auto flex w-[150px] items-center justify-center gap-2 rounded py-2 px-3 text-[14px] text-white"
          >
            <TiMessages/>
            <span>{t('addReview')}</span>
          </button>
        </div>
      </div>

      {isOpenModal && (
        <DialogModal onClose={onClose} title="Add Review To Store">
          <form onSubmit={handleSubmit} className="space-y-3">
            {/* <InputField
              label="Your Name"
              name="name"
              value={values?.name}
              onChange={onChange}
              isRequired={true}
            /> */}
            <InputField
              label="Your First Name"
              name="firstName"
              value={values?.firstName}
              onChange={onChange}
              isRequired={true}
            />
            <InputField
              label="Your Last Name"
              name="lastName"
              value={values?.lastName}
              onChange={onChange}
              isRequired={true}
            />
            <SelectOptions
              name="rating"
              placeholder="Select a rating"
              value={values?.rating}
              onChange={onChange}
              options={[
                {
                  label: 5,
                  value: 5,
                },
                {
                  label: 4,
                  value: 4,
                },
                {
                  label: 3,
                  value: 3,
                },
                {
                  label: 2,
                  value: 2,
                },
                {
                  label: 1,
                  value: 1,
                },
              ]}
            />
            <TextAreaField
              label="Your Review"
              name="review_text"
              value={values?.review_text}
              onChange={onChange}
              isRequired={true}
            />
            <InputFile title="Add Image" name="image_url" onChange={onChange} />

            <div className="pt-3">
              <button
                style={{ background: storeData?.color }}
                className="rounded px-3 py-2 text-[14px] text-white"
                type="submit"
                disable={createLoading}
              >
                Send Your Review
              </button>
            </div>
          </form>
        </DialogModal>
      )}
    </>
  );
};

export default ReviewSection;
