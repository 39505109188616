
const ToggleSwitch = ({ label, isChecked, onChange }) => (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only"
          checked={isChecked}
          onChange={onChange}
        />
        <div className={`block w-10 h-6 rounded-full ${isChecked ? 'bg-green-400' : 'bg-gray-300'}`}></div>
        <div className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${isChecked ? 'transform translate-x-4' : ''}`}></div>
      </div>
      <div className="ml-3 text-xs font-medium text-gray-700 dark:text-gray-400">{label}</div>
    </label>
  );

  
export default ToggleSwitch;