import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaPenToSquare, FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import Table from "../../../components/Table";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import { useDeleteQrSubCategoryMutation, useGetQrSubCategoriesQuery } from "../../../redux/features/qrSubCategories/qrSubCategoryApi";

const QrSubCategories = () => {
  const navigate = useNavigate();
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });

  const { data, isLoading } = useGetQrSubCategoriesQuery({
    page: paginate?.page,
    limit: paginate?.limit,
  });

  const [deleteQrCategory] = useDeleteQrSubCategoryMutation();

  const handleDelete = async (id) => {
    try {
      const result = await deleteQrCategory(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
    //   console.error(error);
    }
  };



  const content = {
    header: ["Name", "Sort Order", "QR Category", "Actions"],

    body: data?.data?.results?.map((item) => ({
      rows: [
        {
          row: item?.name,
        },
        {
            row: item?.sortOrder,
        },
        {
          row: item?.QRCategory?.name,
        },
        {
            row: (
              <div className="flex items-center gap-3">
                <Button
                  link={`/qr-sub-categories/edit/${item?._id}`}
                  className="!block !bg-green-400 "
                >
                  <FaPenToSquare/>
                </Button>
                <Button
                  onClick={() => handleDelete(item?._id)}
                  className="!bg-red-400"
                >
                  <MdDelete/>
                </Button>
              </div>
            ),
          },
      ],
    })),
  };

  return (
    <>
      <Card title="QR Sub Categories" content={<button
            className="rounded bg-[#009ef7] px-4 py-2 text-white flex items-center gap-2"
            onClick={() => navigate("/qr-sub-categories/create")}
          >
            <FaPlus /> Create QR Sub Category
          </button>
          }>
        {isLoading ? (
          <Loading height="70vh" />
        ) : (
          <Table

            content={content}
            pagination={data?.data?.paginate}
            onPaginate={setPaginate}
          />
        )}
      </Card>
    </>
  );
};

export default QrSubCategories;
