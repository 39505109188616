const downloadQRCode = (qrCodeImage) => {
    const base64Data = qrCodeImage.split(',')[1];

    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'qrcode.png');

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
};


export {
    downloadQRCode,
}