import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { CiGlobe } from 'react-icons/ci';
import {
  FaFacebook,
  FaInstagram,
  FaLocationArrow,
  FaMobileAlt,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { GrCurrency } from 'react-icons/gr';
import { HiOfficeBuilding } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import InputGroup from '../../../components/ui/InputField';
import InputFile from '../../../components/ui/InputFile';
import SelectOptions from '../../../components/ui/SelectOptions';
import TextAreaField from '../../../components/ui/TextAreaField';
import {
  countryCurrencyOptions,
  countryPhoneOptions,
} from '../../../data/data';
import {
  useCreateStoreMutation,
  useEditStoreMutation,
} from '../../../redux/features/stores/storesApi';
import ColorSelector from './ColorSelector';

const StoreForm = ({ data = {} }) => {
  const [contactInfo, setContactInfo] = useState(
    data?.store_contact_information ?? {}
  );
  const [socialLinks, setSocialLinks] = useState(
    data?.social_media_links ?? {}
  );
  const [otherValues, setOthersValues] = useState({
    store_description: data?.store_description ?? '',
    color: data?.color ?? '',
    store_logo: data?.store_logo ?? '',
    store_cover: data?.store_cover ?? '',
  });

  // APIS
  const [createStore, { isLoading }] = useCreateStoreMutation();
  const [editStore, { isLoading: isEditLoading }] = useEditStoreMutation();
  const navigate = useNavigate();

  // ON CHANGE FUNCTIONS
  const handleContactInfoChange = (key, value) => {
    setContactInfo((prev) => ({
      ...prev,
      phone: {
        ...prev.phone,
        ...(key === 'country'
          ? { dialCode: value }
          : key === 'phone'
          ? { fullNumber: value }
          : {}),
      },
      ...(key !== 'phone' && key !== 'country' ? { [key]: value } : {}),
    }));
  };

  const handleSocialLinks = (key, value) => {
    setSocialLinks((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleOtherValue = (key, value) => {
    setOthersValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // SUBMIT FUNCTION
  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = {
      ...otherValues,
      store_contact_information: contactInfo,
      social_media_links: socialLinks,
    };
    try {
      const API = data?._id
        ? editStore({ id: data?._id, data: values })
        : createStore(values);
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate('/stores');
      } else {
        console.log(result?.error?.data?.message);
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card title="Create New Store" className="space-y-4">
        <Card title="Store Logo">
          {otherValues?.store_logo && (
            <img
              className="mb-2 h-[70px] w-[70px] rounded-full border"
              src={otherValues?.store_logo}
              alt="store logo"
            />
          )}
          <InputFile name="store_logo" onChange={handleOtherValue} />
        </Card>
        <Card title="Store Cover Image">
          {otherValues?.store_cover && (
            <img
              className="mb-2 h-[70px] w-[70px] rounded-full border"
              src={otherValues?.store_cover}
              alt="store cover"
            />
          )}
          <InputFile name="store_cover" onChange={handleOtherValue} />
        </Card>
        <div className="grid grid-cols-2 gap-4">
          <Card title="Store Contact Information" className=" space-y-3">
            <InputGroup
              icon={<HiOfficeBuilding />}
              name="store_name"
              value={contactInfo?.store_name}
              onChange={handleContactInfoChange}
              placeholder="Store Name (Required)"
              isRequired={true}
            />
            <InputGroup
              icon={<FaMobileAlt />}
              name="email"
              value={contactInfo?.email}
              onChange={handleContactInfoChange}
              placeholder="Email Address"
              isRequired={true}
            />
            <SelectOptions
              icon={<CiGlobe />}
              placeholder="Select Country..."
              options={countryPhoneOptions}
              name="country"
              value={contactInfo?.phone?.dialCode}
              onChange={handleContactInfoChange}
            />
            <InputGroup
              icon={<FaMobileAlt />}
              name="phone"
              placeholder="Mobile Number"
              type="number"
              value={contactInfo?.phone?.fullNumber}
              onChange={handleContactInfoChange}
              isRequired={true}
            />
            <InputGroup
              icon={<FaWhatsapp />}
              name="whatsapp_num"
              placeholder="Whatsapp Number"
              type="number"
              value={contactInfo?.whatsapp_num}
              onChange={handleContactInfoChange}
              isRequired={true}
            />
            <InputGroup
              icon={<FaLocationArrow size={14} />}
              name="full_address"
              placeholder="Full Address (Required)"
              value={contactInfo?.full_address}
              onChange={handleContactInfoChange}
            />
            <SelectOptions
              icon={<GrCurrency />}
              placeholder="Select Currency..."
              name="currency"
              options={countryCurrencyOptions}
              value={contactInfo?.currency}
              onChange={handleContactInfoChange}
            />
          </Card>
          <Card title="Social Media Links" className="space-y-3">
            <InputGroup
              icon={<FaFacebook />}
              name="facebook_url"
              placeholder="Facebook Page Link"
              value={socialLinks?.facebook_url}
              onChange={handleSocialLinks}
              isRequired={true}
            />
            <InputGroup
              icon={<FaYoutube />}
              name="youtube_url"
              placeholder="Youtube Channel Link"
              value={socialLinks?.youtube_url}
              onChange={handleSocialLinks}
              isRequired={true}
            />

          </Card>
        </div>
        <ColorSelector
          name="color"
          value={otherValues?.color}
          onChange={handleOtherValue}
        />
        <Card title="Store Description">
          <TextAreaField
            name="store_description"
            value={otherValues?.store_description}
            onChange={handleOtherValue}
            placeholder="Enter Description"
          />
        </Card>
        <Button type="submit" className="!bg-green-400">
          {isLoading || isEditLoading ? (
            <Loading variant="button" height="22px" />
          ) : (
            'Save'
          )}
        </Button>
      </Card>
    </form>
  );
};

export default StoreForm;
