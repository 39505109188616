import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Loading from "../../../components/Loading";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import {
  useEditCategoriesMutation,
  useGetSingleCategoryQuery,
} from "../../../redux/features/categories/categoryApi";

const validationSchema = Yup.object().shape({
  category_name_english: Yup.string().required(
    "English category name is required"
  ),
  category_name_tigriya: Yup.string(),
  category_name_turkish: Yup.string(),
  category_name_german: Yup.string(),
  category_name_french: Yup.string(),
  category_name_arabic: Yup.string(),
  category_name_amharic: Yup.string(),
  sortOrder: Yup.number()
    .required("Sort order is required")
    .min(0, "Sort order must be non-negative"),
});

const EditCategoriesPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: categoryData,
    isLoading,
    isError,
  } = useGetSingleCategoryQuery(id);

  const [updateCategory] = useEditCategoriesMutation();
  const [initialValues, setInitialValues] = useState({
    category_name_english: "",
    category_name_tigriya: "",
    category_name_turkish: "",
    category_name_german: "",
    category_name_french: "",
    category_name_arabic: "",
    category_name_amharic: "",
    sortOrder: 0,
  });

  useEffect(() => {
    if (categoryData) {
      let { data } = categoryData;
      setInitialValues({
        category_name_english: data?.title?.en || "",
        category_name_tigriya: data?.title?.ti || "",
        category_name_turkish: data?.title?.tr || "",
        category_name_german: data?.title?.de || "",
        category_name_french: data?.title?.fr || "",
        category_name_arabic: data?.title?.ar || "",
        category_name_amharic: data?.title?.am || "",
        sortOrder: data?.sortOrder || 0,
      });
    }
  }, [categoryData]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const categoryData = {
      id,
      title: {
        en: values.category_name_english,
        ti: values.category_name_tigriya,
        tr: values.category_name_turkish,
        de: values.category_name_german,
        fr: values.category_name_french,
        ar: values.category_name_arabic,
        am: values.category_name_amharic,
      },
      sortOrder: values.sortOrder,
    };

    try {
      const result = await updateCategory(categoryData);
      if (result?.data?.success) {
        toast.success("Category updated successfully");
        navigate("/product-categories");
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating the category");
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading category data</div>;

  return (
    <>
      <Card
        title="Edit Category"
        content={
          <Button
            link="/product-categories"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            Back To Categories
          </Button>
        }
        className="space-y-3"
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="space-y-3">
              {[
                { name: "english", label: "ENGLISH" },
                { name: "tigriya", label: "TIGRIYA" },
                { name: "turkish", label: "TURKISH" },
                { name: "german", label: "GERMAN" },
                { name: "french", label: "FRENCH" },
                { name: "arabic", label: "ARABIC" },
                { name: "amharic", label: "AMHARIC" },
              ].map((lang) => (
                <div key={lang.name}>
                  <Field
                    name={`category_name_${lang.name}`}
                    type="text"
                    placeholder={`Category Name (${lang.label})`}
                    className="w-full rounded border px-2 py-2.5"
                  />
                  {errors[`category_name_${lang.name}`] &&
                    touched[`category_name_${lang.name}`] && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors[`category_name_${lang.name}`]}
                      </div>
                    )}
                </div>
              ))}

              <Field
                name="sortOrder"
                type="number"
                placeholder="Sort Order"
                className="w-full rounded border p-2"
              />
              {errors.sortOrder && touched.sortOrder && (
                <div className="text-sm text-red-500">{errors.sortOrder}</div>
              )}

              <Button
                type="submit"
                className="mt-3 w-36 !bg-green-400"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Loading variant="button" height="20px" />
                ) : (
                  "Update Category"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
};

export default EditCategoriesPage;
