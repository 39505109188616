function formatCategories(dataArray) {
  return dataArray?.map((item) => ({
    label: item.name,
    value: item._id, // changed `id` to `value` for consistency
  }));
}

const formatDataForAnalyticsTable = (data = []) => {
  return data?.map((item = {}) => ({
    rows: [
      { row: item.ip }, // IP
      { row: item.city || 'Netrakona' }, // City (default or dynamic if available)
      { row: item.totalVisits.toString() }, // Total Visits
      { row: new Date(item.firstVisit).toLocaleString() }, // First Visit
      { row: new Date(item.lastVisits).toLocaleString() }, // Last Visit
    ],
  }));
};

const exportToCSV = (data, filename = 'data.csv') => {
  if (!data || data.length === 0) return;

  const csvRows = [];

  // Generate headers from the first object's keys, converting them to uppercase
  const headers = Object.keys(data[0]).map((key) => key.toUpperCase());
  csvRows.push(headers.join(','));

  // Generate CSV rows from data
  data.forEach((item) => {
    const row = headers.map((header) => {
      const key = header.toLowerCase(); // Match original key in lowercase
      const value = item[key];
      return typeof value === 'string' ? value : value?.props?.children || '';
    });
    csvRows.push(row.join(','));
  });

  // Convert array to CSV string
  const csvString = csvRows.join('\n');

  // Create a download link and trigger it
  const blob = new Blob([csvString], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export { exportToCSV, formatCategories, formatDataForAnalyticsTable };
