import React from "react";

import Card from "../../../components/ui/Card";
import InputGroup from "../../../components/ui/InputField";
import InputFile from "../../../components/ui/InputFile";
import {FaPen, FaPhoneAlt} from "react-icons/fa";
import {HiOutlineMailOpen} from "react-icons/hi";
import BusinessLayout from "../../../layout/BusinessLayout";


const CreateUserPage = () => {


    return (
        <BusinessLayout>


        <form >
            <Card
                title="Create New Card"

                className="space-y-6"
            >
                <div className="space-y-5 px-4 pt-2">
                    <Card title="Personal Information" className="space-y-3">
                        <div className="space-y-3">
                            {/*{ (*/}
                            {/*    <img*/}
                            {/*        src={imgUrl}*/}
                            {/*        alt=""*/}
                            {/*        className="h-[80px] w-[80px] rounded-full border"*/}
                            {/*    />*/}
                            {/*)}*/}
                            <InputFile
                                name="imgUrl"
                                label="Profile Image"
                                //onChange={onChange}
                            />
                        </div>

                        <InputGroup
                            icon={<FaPen size={14}/>}
                            name="firstName"
                            placeholder="First Name"
                            //value={values?.firstName}
                            //onChange={onChange}
                            isRequired={true}
                        />

                        <InputGroup
                            icon={<FaPen size={14}/>}
                            name="lastName"
                            placeholder="Last Name"
                            //value={values?.lastName}
                            //onChange={onChange}
                            isRequired={true}
                        />

                        <InputGroup
                            name="email"
                            type="email"
                            icon={<HiOutlineMailOpen size={14}/>}
                            placeholder="Email"
                            //value={values?.email}
                            //onChange={onChange}
                            isRequired={true}
                        />
                        <InputGroup
                            name="phone"
                            icon={<FaPhoneAlt size={14}/>}
                            placeholder="Phone Number"
                            //value={values?.phone?.fullNumber}
                            //onChange={onChange}
                            isRequired={true}
                        />
                    </Card>
                </div>


            </Card>
        </form>
        </BusinessLayout>
    );
};

export default CreateUserPage;