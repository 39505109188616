import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import DefaultCardLayout from '../../../modules/agents/components/dashboard/DefaultCardLayout';
import { useCreateFriendsCategoriesMutation } from '../../../redux/features/friends/friendsApi';
import Loading from '../../Loading';
import Button from '../../ui/Button';
import InputField from '../../ui/InputField';
// import DefaultCardLayout from "../../agents/components/dashboard/DefaultCardLayout";

const CreateFriendCategories = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [createCategories, { isLoading: categoryCreateLoading }] =
    useCreateFriendsCategoriesMutation();

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  const handleSaveCategories = async () => {
    if (Object.values(values)?.length === 0) return;

    try {
      let result = await createCategories(values);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        setValues({});
        navigate('/friends/categories');
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <DefaultCardLayout
        title={'Create Friends Category'}
        action={
          <div className="flex gap-3">
            <button
              className="rounded bg-[#009ef7] px-4 py-2 text-white"
              onClick={() => navigate('/friends/categories')}
            >
              Friends Categories List
            </button>
          </div>
        }
      >
        <div className="gap-16 p-8">
          <div className="space-y-5">
            <InputField
              name={'name'}
              onChange={onChange}
              value={values?.name}
              type="text"
              placeholder={'Category name'}
              isRequired={true}
            />
            {/* <InputField name={'sortOrder'} onChange={onChange} value={values?.sortOrder} type="number" placeholder={'Category value (close-friend)'} isRequired={true} /> */}

            <Button
              className="mt-3 !bg-green-400"
              onClick={handleSaveCategories}
            >
              {categoryCreateLoading ? <Loading height="24px" /> : 'Create'}
            </Button>
          </div>
        </div>
      </DefaultCardLayout>
    </>
  );
};

export default CreateFriendCategories;
