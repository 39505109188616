import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaUser } from "react-icons/fa";
import Loading from "../../../components/Loading";
import Card from "../../../components/ui/Card";
import {
    useGetSettingsQuery,
    useUpdateSettingsMutation,
} from "../../../redux/features/settings/settingsApi";

const SettingsPage = () => {
  const [checkboxes, setCheckboxes] = useState({});

  const { data: settingsData, isLoading, isFetching } = useGetSettingsQuery();
  const [updateSettings] = useUpdateSettingsMutation();

  useEffect(() => {
    if (settingsData?.success) {
      setCheckboxes(settingsData?.data);
    }
  }, [settingsData]);

  const handleUpdate = async (payload) => {
    try {
      let result = await updateSettings(payload);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
      toast.error("Settings updating failed!");
    }
  };
  const handleCheckboxChange = async (key) => {
    setCheckboxes((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
    await handleUpdate({ [key]: !checkboxes[key] });
  };

  const formatTitle = (key) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .replace("Url", "URL")
      .replace("show", "");
  };

  return (
    <div className="space-y-6">
      <Card
        content={
          <div className="flex items-center gap-2 text-black dark:text-white ">
            <FaUser />
            <h3>Change Your Settings</h3>
          </div>
        }
        className="space-y-4"
      >
        <h4 className="text-[13px] font-medium">
          You can change the settings of your profile and hide any of your data.
        </h4>
        {isLoading ? (
          <Loading height="40vh" />
        ) : (
          <div>
            {Object?.keys(checkboxes)?.length > 0 ?
              Object?.entries(checkboxes)?.map(([key, value]) => (
                <div key={key} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id={key}
                    checked={value}
                    onChange={() => handleCheckboxChange(key)}
                  />
                  <label htmlFor={key} className="text-[13px]">
                    {formatTitle(key)}
                  </label>
                </div>
              )) : "N/A"}
          </div>
        )}
      </Card>
    </div>
  );
};

export default SettingsPage;
