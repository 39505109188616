import React from 'react';
import {
  FaExternalLinkSquareAlt,
  FaFacebook,
  FaGlobe,
  FaTelegramPlane,
  FaTwitter,
} from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import {  FaWhatsapp } from 'react-icons/fa';
import {  FaYoutube } from 'react-icons/fa';
import { FaSnapchat, FaTiktok } from 'react-icons/fa6';
import { FiExternalLink } from 'react-icons/fi';
import { IoLogoInstagram } from 'react-icons/io5';
import { RiFileUserLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import CustomReactPlayer from './CustomReactPlayer';

const SocialLinks = ({ data, additionalSocialLinks }) => {
  function formatUrl(url = '') {
    return url.startsWith('http') ? url : `http://${url}`;
  }

  return (
      <div className="space-y-3">
        {data?.youtube && <CustomReactPlayer url={data?.youtube} />}



          <div className="px-4 bg-gray-100 border-b border-l border-r border-gray-200 rounded-md">

              <h3 className="text-lg font-bold text-center mb-6 ">Social Links</h3>


              <div className="flex justify-center mb-4 gap-4">
                  {data?.whatsapp && (
                      <a
                          href={`https://wa.me/${data.whatsapp}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center w-12 h-12 border-2 border-gray-300 rounded-full"
                      >
                          <FaWhatsapp size={24} className="text-green-500" />
                      </a>
                  )}
                  {data?.email && (
                      <a
                          href={`mailto:${data.email}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center w-12 h-12 border-2 border-gray-300 rounded-full"
                      >
                          <MdEmail size={24} className="text-red-500" />
                      </a>
                  )}
                  {data?.videoLink && (
                      <a
                          href={formatUrl(data.videoLink)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center w-12 h-12 border-2 border-gray-300 rounded-full"
                      >
                          <FaYoutube size={24} className="text-red-600" />
                      </a>
                  )}
                  {data?.website && (
                      <a
                          href={formatUrl(data.website)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center w-12 h-12 border-2 border-gray-300 rounded-full"
                      >
                          <FaGlobe size={24} className="text-blue-500" />
                      </a>
                  )}
                  {data?.facebook && (
                      <a
                          href={formatUrl(data.facebook)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center w-12 h-12 border-2 border-gray-300 rounded-full"
                      >
                          <FaFacebook size={24} className="text-blue-700" />
                      </a>
                  )}
                  {data?.twitter && (
                      <a
                          href={formatUrl(data.twitter)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center w-12 h-12 border-2 border-gray-300 rounded-full"
                      >
                          <FaTwitter size={24} className="text-sky-400" />
                      </a>
                  )}
                  {data?.instagram && (
                      <a
                          href={formatUrl(data.instagram)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center w-12 h-12 border-2 border-gray-300 rounded-full"
                      >
                          <IoLogoInstagram size={24} className="text-pink-600" />
                      </a>
                  )}
                  {data?.snapchat && (
                      <a
                          href={formatUrl(data.snapchat)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center w-12 h-12 border-2 border-gray-300 rounded-full"
                      >
                          <FaSnapchat size={24} className="text-yellow-500" />
                      </a>
                  )}
                  {data?.telegram && (
                      <a
                          href={formatUrl(data.telegram)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center w-12 h-12 border-2 border-gray-300 rounded-full"
                      >
                          <FaTelegramPlane size={24} className="text-blue-400" />
                      </a>
                  )}
                  {data?.tiktok && (
                      <a
                          href={formatUrl(data.tiktok)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center w-12 h-12 border-2 border-gray-300 rounded-full"
                      >
                          <FaTiktok size={24} className="text-black" />
                      </a>
                  )}
              </div>
          </div>

        <div className="space-y-3">
          {additionalSocialLinks?.map((d) => (
              <>
                {d?.name && (
                    <Link
                        key={d?.id}
                        // to={d?.link}
                        to={formatUrl(d?.link)}
                        target="_blank"
                        className="flex items-center gap-4 rounded-md bg-white p-2 "
                    >
                      <div
                          className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-primary text-2xl text-white">
                        {d?.icon ? (
                            <img className="rounded-full p-1" src={d?.icon} alt=""/>
                        ) : (
                            <FaExternalLinkSquareAlt size={16}/>
                        )}
                      </div>
                      <div>
                        <h3 className="text-[14px] font-bold capitalize text-black">
                          {d?.name}
                        </h3>
                        <p className="text-[12px] text-black ">{d?.link}</p>
                      </div>
                    </Link>
                )}
              </>
          ))}
        </div>
      </div>
  );
};

export default SocialLinks;
