import React from 'react';
import { FaMapMarkerAlt, FaCompass } from 'react-icons/fa';

const locations = [
  {
    name: "Cozy Coffee Shop",
    address: "123 Main St, Anytown, USA",
    discount: "20% off any coffee drink",
    distance: 0.3
  },
  {
    name: "Tasty Burger Joint",
    address: "456 Oak Ave, Anytown, USA",
    discount: "Free fries with any burger",
    distance: 0.7
  },
  {
    name: "Bookworm's Paradise",
    address: "789 Elm St, Anytown, USA",
    discount: "15% off all books",
    distance: 1.2
  },
  {
    name: "Fitness First Gym",
    address: "101 Pine Rd, Anytown, USA",
    discount: "50% off first month membership",
    distance: 1.5
  }
];

const LocationCard = ({ name, address, discount, distance }) => (
  <div className="bg-white dark:bg-dark text-black dark:text-gray-300 rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
    <h2 className="text-xl font-bold mb-2 ">{name}</h2>
    <p className="text-gray-600 dark:text-gray-300 mb-3 flex items-center">
      <FaMapMarkerAlt className="mr-2" />
      {address}
    </p>
    <p className="text-blue-600 font-semibold mb-3">{discount}</p>
    <p className="text-gray-500 dark:text-gray-400 text-sm flex items-center">
      <FaCompass className="mr-2" />
      {distance} miles away
    </p>
  </div>
);

const NearbyLocationDiscounts = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center text-blue-600 mb-8">
        Nearby Socalii Discounts Locations
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {locations.map((location, index) => (
          <LocationCard key={index} {...location} />
        ))}
      </div>
    </div>
  );
};

export default NearbyLocationDiscounts;