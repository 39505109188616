import React, { useState } from "react";
import Loading from "../../../components/Loading";
import Card from "../../../components/ui/Card";
import BusinessLayout from "../../../layout/BusinessLayout";
import { useGetTeamCardsQuery } from "../../../redux/features/teamCards/teamCardsApi";
import SingleCard from "./SingleCard";

const TeamCardsPage = () => {
  const { data, isLoading } = useGetTeamCardsQuery();
  const [showImg, setShowImage] = useState(false);

  return (
    <BusinessLayout>
      <Card
        title="Team Cards"
        content={
          <button
            onClick={() => setShowImage(!showImg)}
            className="rounded bg-blue-100 px-3 py-1 text-white"
          >
            {showImg ? "Hidden image" : "Show Image"}
          </button>
        }
      >
        {isLoading ? (
          <Loading height="60vh" />
        ) : (
          <div className="grid grid-cols-3 gap-5 px-2">
            {data?.data?.results?.map((data) => (
              <SingleCard
                key={data?._id}
                data={{
                  name: data?.employee?.name,
                  id: data?._id,
                  image: showImg ? data?.employee?.imgUrl : data?.qrImage,
                  job: data?.employee?.job_position,
                }}
                viewLink={`/team/view/${data?._id}`}
                analyticLink={`/analytics/${data?._id}`}
              />
            ))}
          </div>
        )}
      </Card>
    </BusinessLayout>
  );
};

export default TeamCardsPage;
