import React, { useEffect } from 'react';
import { FaEye } from 'react-icons/fa6';
import Loading from '../../../components/Loading';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { useGetIndividualOrdersQuery } from "../../../redux/features/products/productsApi";

const MyStorePage = () => {
    const storedEmail = localStorage.getItem("individualEmail");


    const { data, error, isLoading } = useGetIndividualOrdersQuery(
        { email: storedEmail },
        { skip: !storedEmail }
    );

    const orders = data?.data || [];

    const content = {
        header: ['User Email', 'User Phone', 'User Address', 'Tracking ID', 'Order Date', 'Actions'],
        body: orders.map((item) => ({
            rows: [
                { row: item.buyerEmail },
                { row: item.buyerPhoneNumber },
                { row: item.buyerAdress },
                { row: item.trackingOrderId },
                { row: new Date(item.createdAt).toLocaleDateString() },
                {
                    row: (
                        <Button
                            link={`/my-product/${item._id}`}
                            className="flex h-[40px] w-[60px] items-center justify-center !bg-blue-100"
                        >
                            <FaEye />
                        </Button>
                    ),
                },
            ],
        })),
    };

    return (
        <div className="space-y-6">
            <Card title="Orders List">
                {isLoading ? (
                    <Loading height="60vh" />
                ) : error ? (
                    <p>Error loading orders: {error?.data?.message || "Unknown error"}</p>
                ) : (
                    <Table
                        content={content}
                        pagination={null}
                    />
                )}
            </Card>
        </div>
    );
};

export default MyStorePage;