import noImg from '../../../images/shared/no-image.jpg';
import { FaUserPlus, FaTimes } from 'react-icons/fa';
const CloseFriends = () => {
    const closeFriends = [
        { id: 1, name: 'Abdullah Khan', image: noImg },
        { id: 2, name: 'Sarah Smith', image: noImg },
        { id: 3, name: 'Michael Johnson', image: noImg },
    ];

    return (
        // <div className="bg-white p-6 rounded-lg shadow-md max-w-2xl mx-auto">

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {closeFriends.map((friend) => (
                <div key={friend.id} className="bg-gray-100 dark:bg-dark text-black dark:text-gray-300 rounded-lg p-4 flex items-center justify-between">
                    <div className="flex items-center">
                        <img src={friend.image} alt={friend.name} className="w-12 h-12 rounded-full mr-3" />
                        <span className="font-medium text-gray-800 dark:text-gray-300">{friend.name}</span>
                    </div>
                    <button className="text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-400">
                        <FaTimes />
                    </button>
                </div>
            ))}
        </div>
        // </div>
    );
};

export default CloseFriends;