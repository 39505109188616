import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { AiFillTikTok, AiOutlineGlobal } from 'react-icons/ai';
import { BsFillThreadsFill } from 'react-icons/bs';
import {
  FaExternalLinkAlt,
  FaExternalLinkSquareAlt,
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaLocationArrow,
  FaMobileAlt,
  FaPen,
  FaSnapchatSquare,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { FaTelegram, FaUserLarge } from 'react-icons/fa6';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { LuWarehouse } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import InputGroup from '../../../components/ui/InputField';
import InputFile from '../../../components/ui/InputFile';
import SelectOptions from '../../../components/ui/SelectOptions';
import { countryPhoneOptions } from '../../../data/data';
import { useCreateBussinessCardMutation } from '../../../redux/features/cards/cardsApi';
import { isValidEmail } from '../../../utils/validater';
const CreateCardPage = ({ card }) => {
  //const { data, isLoading } = useGetUserOptionsQuery();
  const [createCard, { isLoading: createLoading }] =
    useCreateBussinessCardMutation();
  // format the data so that the lable key should not have '' value , from array sikip those object that do not have name value
  //const options = data?.data?.filter((option) => option.label !== '') || [];

  const [values, setValues] = useState();
  const navigate = useNavigate();
  const [extraSocialLinks, setExtraSocialLinks] = useState(
    card?.data?.user?.additionalSocialLinks || []
  );

  // const onChange = (key, value, sub) => {
  //
  //   console.log(key, value);
  //   setValues((prev) => ({
  //     ...prev,
  //     [key]: value,
  //   }));
  // };

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  const handleSubmit = async () => {
    if (!isValidEmail(values?.email)) {
      toast.error('Please enter valid email');
      return;
    }
    try {
      // const { latitude, longitude } = await getCoordinates(values.address);
      // if (!latitude || !longitude) {
      //   toast.error('Please enter valid address');
      //   return;
      // }
      // const data = {
      //   ...values,
      //   location: {
      //     lat: latitude,
      //     long: longitude,
      //   },
      //   pointLocation: {
      //     type: 'Point',
      //     coordinates: [longitude, latitude],
      //   },
      // };
      const API = createCard(values);
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate('/cards');
      }
      if (result?.error?.status === 400) {
        console.log(result);
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleNewSection = (type) => {
    const newValue = {
      id: Math.ceil(Math.random() * 1000000),
    };

    if (type === 'socialLinks') {
      setExtraSocialLinks([...extraSocialLinks, newValue]);
    }
  };
  const handleSocialLinks = (key, value, subKey) => {
    const updateValue = extraSocialLinks?.map((item) => {
      if (item?.id === subKey) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });

    setExtraSocialLinks(updateValue);
  };

  const handleRemove = (id, type) => {
    const updateSocialLinks = extraSocialLinks?.filter(
      (data) => data?.id !== id
    );

    if (type === 'socialLinks') {
      setExtraSocialLinks(updateSocialLinks);
    }
  };

  // useEffect(() => {
  //   const fetchCoordinates = async () => {
  //     if (values?.address?.length > 5) {
  //       try {
  //         const { latitude, longitude } = await getCoordinates(values.address);
  //         if (latitude && longitude) {
  //           setValues((prev) => {
  //             return {
  //               ...prev,
  //               // location: {
  //               //   type: 'Point',
  //               //   coordinates: [longitude, latitude],
  //               // },
  //               location: {
  //                 lat: latitude,
  //                 long: longitude,
  //               },
  //               pointLocation: {
  //                 type: 'Point',
  //                 coordinates: [longitude, latitude],
  //               },
  //             };
  //           });
  //         }
  //       } catch (error) {
  //         toast.error(error);
  //       }
  //     }
  //   };

  //   fetchCoordinates();
  // }, [values?.address]);

  return (
    <>
      <Card
        title="Create New Card"
        content={
          <Button
            link="/cards"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            Cards
          </Button>
        }
        className="space-y-3"
      >
        {/*<Card title="Select User">*/}
        {/*  <SelectOptions*/}
        {/*    name="userId"*/}
        {/*    icon={<FaUser />}*/}
        {/*    value={values?.userId}*/}
        {/*    onChange={onChange}*/}
        {/*    options={options}*/}
        {/*    placeholder="Select card user"*/}
        {/*  />*/}
        {/*</Card>*/}
        <Card title="Account Information" className="space-y-3">
          <InputGroup
            icon={<FaPen size={14} />}
            placeholder="Please Enter Your First Name"
            name="firstName"
            value={values?.firstName}
            onChange={onChange}
          />
          <InputGroup
            icon={<FaPen size={14} />}
            placeholder="Please Enter Your Last Name"
            name="lastName"
            value={values?.lastName}
            onChange={onChange}
          />
          <InputGroup
            icon={<FaPen size={14} />}
            placeholder="Please Enter Your User Name"
            name="username"
            value={values?.username}
            onChange={onChange}
          />
          <InputGroup
            icon={<HiOutlineMailOpen size={14} />}
            placeholder="Please Enter Your Email"
            name="email"
            value={values?.email}
            onChange={onChange}
          />
        </Card>

        <Card title="Contact Information" className="space-y-3">
          <div className=" flex w-full items-center gap-3">
            <SelectOptions
              placeholder="Select Country..."
              options={countryPhoneOptions}
              name="phone"
              subKey="dialCode"
              value={values?.phone?.dialCode}
              onChange={onChange}
            />
            <div className="w-full">
              <InputGroup
                icon={<FaMobileAlt />}
                name="phone"
                subKey="fullNumber"
                placeholder="Mobile Number"
                type="number"
                value={values?.phone?.fullNumber}
                onChange={onChange}
                disabled={!values?.phone?.dialCode}
              />
            </div>
          </div>
          <div className=" flex w-full items-center gap-3">
            <SelectOptions
              placeholder="Select Country..."
              options={countryPhoneOptions}
              name="whatsapp"
              subKey="dialCode"
              value={values?.whatsapp?.dialCode}
              onChange={onChange}
            />
            <div className="w-full">
              <InputGroup
                icon={<FaMobileAlt />}
                name="whatsapp"
                subKey="fullNumber"
                placeholder="Whatsapp Number"
                type="number"
                value={values?.whatsapp?.fullNumber}
                onChange={onChange}
              />
            </div>
          </div>

          {/* <InputGroup
            icon={<FaLocationArrow size={14} />}
            placeholder="Please Enter Your Address"
            name="address"
            value={values?.address}
            onChange={onChange}
          /> */}
          <div className="flex items-center gap-7">
            <InputGroup
              icon={<FaLocationArrow size={14} />}
              placeholder="Latitude"
              name="location"
              subKey="lat"
              value={values?.location?.lat}
              onChange={onChange}
            />
            <InputGroup
              icon={<FaLocationArrow size={14} />}
              placeholder="Longitude"
              name="location"
              subKey="long"
              value={values?.location?.long}
              onChange={onChange}
            />
          </div>
        </Card>

        <Card title="Business Information" className="space-y-3">
          <InputGroup
            icon={<LuWarehouse size={14} />}
            placeholder="Please Enter Your Company Name"
            name="jobInformation"
            subKey="companyName"
            value={values?.jobInformation?.companyName}
            onChange={onChange}
          />
          <InputGroup
            icon={<LuWarehouse size={14} />}
            placeholder="Position (Business Title)"
            name="jobInformation"
            subKey="position"
            value={values?.jobInformation?.position}
            onChange={onChange}
          />
        </Card>

        <Card title="Social Media Links" className="space-y-3">
          <InputGroup
            icon={<AiOutlineGlobal size={14} />}
            name="socialMediaLinks"
            subKey="website"
            value={values?.socialMediaLinks?.website}
            onChange={onChange}
            placeholder="Please Enter Your Website Link"
          />
          <InputGroup
            icon={<FaFacebook size={14} />}
            name="socialMediaLinks"
            subKey="facebook"
            value={values?.socialMediaLinks?.facebook}
            onChange={onChange}
            placeholder="Please Enter Your Facebook Page Link"
          />

          <InputGroup
            icon={<FaTwitter size={14} />}
            name="socialMediaLinks"
            subKey="twitter"
            value={values?.socialMediaLinks?.twitter}
            onChange={onChange}
            placeholder="Please Enter Your Twitter Page Link"
          />
          <InputGroup
            icon={<FaYoutube size={14} />}
            name="socialMediaLinks"
            subKey="youtube"
            value={values?.socialMediaLinks?.youtube}
            onChange={onChange}
            placeholder="Please Enter Your Youtube Page Link"
          />

          <InputGroup
            icon={<FaInstagramSquare size={14} />}
            name="socialMediaLinks"
            subKey="instagram"
            value={values?.socialMediaLinks?.instagram}
            onChange={onChange}
            placeholder="Please Enter Your Instagram Page Link"
          />
          <InputGroup
            icon={<FaLinkedin size={14} />}
            name="socialMediaLinks"
            subKey="linkedin"
            value={values?.socialMediaLinks?.linkedin}
            onChange={onChange}
            placeholder="Please Enter Your Linkedin Link"
          />

          <InputGroup
            icon={<FaTelegram size={14} />}
            name="socialMediaLinks"
            subKey="telegram"
            value={values?.socialMediaLinks?.telegram}
            onChange={onChange}
            placeholder="Please Enter Your Telegram Link"
          />
          <InputGroup
            icon={<FaExternalLinkAlt size={14} />}
            name="socialMediaLinks"
            subKey="linkText"
            value={values?.socialMediaLinks?.linkText}
            onChange={onChange}
            placeholder="Please Enter Your link"
          />

          <InputGroup
            icon={<AiFillTikTok size={14} />}
            name="socialMediaLinks"
            subKey="tiktok"
            value={values?.socialMediaLinks?.tiktok}
            onChange={onChange}
            placeholder="Please Enter Your Tiktok Link"
          />

          <InputGroup
            icon={<FaSnapchatSquare size={14} />}
            name="socialMediaLinks"
            subKey="snapchat"
            value={values?.socialMediaLinks?.snapchat}
            onChange={onChange}
            placeholder="Please Enter Your Snapchat Link"
          />

          <InputGroup
            icon={<BsFillThreadsFill size={14} />}
            name="socialMediaLinks"
            subKey="thread"
            value={values?.socialMediaLinks?.thread}
            onChange={onChange}
            placeholder="Please Enter Your Threads Link"
          />

          <InputGroup
            icon={<FaYoutube size={14} />}
            name="socialMediaLinks"
            subKey="videoLink"
            value={values?.socialMediaLinks?.videoLink}
            onChange={onChange}
            placeholder="Please Enter Your Profile Video (Youtube) Link"
          />
          <InputGroup
            icon={<FaUserLarge size={14} />}
            name="socialMediaLinks"
            subKey="resumeLink"
            value={values?.socialMediaLinks?.resumeLink}
            onChange={onChange}
            placeholder="Please Enter Your Resume Link"
          />

          <div className="!mt-6 space-y-5 border-t pt-5 dark:border-gray-800">
            <h2 className="text-[18px] font-bold text-black dark:text-white">
              Additional Social links
            </h2>

            <div className="space-y-6">
              {extraSocialLinks?.map((data) => (
                <div key={data?.id} className="space-y-3 rounded border p-2">
                  <div className="flex justify-end">
                    <button
                      onClick={() => handleRemove(data?.id, 'socialLinks')}
                      className="text-red-500"
                      type="button"
                    >
                      {/*<MdClose />*/}
                    </button>
                  </div>
                  <InputFile
                    label="Icon"
                    name="icon"
                    subKey={data?.id}
                    value={data?.icon}
                    onChange={handleSocialLinks}
                  />
                  <InputGroup
                    icon={<FaExternalLinkSquareAlt size={14} />}
                    placeholder="Social Name"
                    name="name"
                    subKey={data?.id}
                    value={data?.name}
                    onChange={handleSocialLinks}
                  />
                  {/*<InputGroup*/}
                  {/*    icon={<IoIosLink size={14} />}*/}
                  {/*    placeholder="Link"*/}
                  {/*    name="link"*/}
                  {/*    subKey={data?.id}*/}
                  {/*    value={data?.link}*/}
                  {/*    onChange={handleSocialLinks}*/}
                  {/*/>*/}
                </div>
              ))}
            </div>

            <Button onClick={() => handleNewSection('socialLinks')}>
              Add Social
            </Button>
          </div>
        </Card>

        <Button
          onClick={handleSubmit}
          isDisabled={createLoading}
          className="mt-3 !bg-green-400"
        >
          {createLoading ? <Loading variant="button" height="20px" /> : 'Save'}
        </Button>
      </Card>
    </>
  );
};

export default CreateCardPage;
