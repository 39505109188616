import moment from "moment";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaLink, FaLinkSlash, FaPenToSquare, FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import Loading from "../../../components/Loading";
import Table from "../../../components/Table";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import {
    useDeleteAddressMutation,
    useEditAddressMutation,
    useGetAddressesQuery,
} from "../../../redux/features/address/addressApi";

const AddressPage = () => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });
  const { data, isLoading } = useGetAddressesQuery(paginate);
  const [deleteAddress] = useDeleteAddressMutation();
  const [editAddress] = useEditAddressMutation();

  const handleDelete = async (id) => {
    try {
      const result = await deleteAddress(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const handleDefaultToggle = async (id, value) => {
    try {
      const data = {
        isSetDefaultAddress: !value,
      };

      const result = await editAddress({ id, data });
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const content = {
    header: ["Address Title", "Address", "Created At", "Actions"],

    body: data?.data?.results?.map((item) => ({
      rows: [
        {
          row: item?.title,
        },
        {
          row: item?.address,
        },

        {
          row: moment(item?.createdAt)?.fromNow(),
        },

        {
          row: (
            <div className="flex items-center gap-3">
              <Button
                link={`/addresses/edit/${item?._id}`}
                className="!block !bg-green-400"
              >
                <FaPenToSquare />
              </Button>
              <Button
                onClick={() => handleDelete(item?._id)}
                className="!bg-red-400"
              >
                <MdDelete />
              </Button>
              <Button
                onClick={() =>
                  handleDefaultToggle(item?._id, item?.isSetDefaultAddress)
                }
                className="!bg-yellow-500"
              >
                {item?.isSetDefaultAddress ? <FaLink /> : <FaLinkSlash />}
              </Button>
            </div>
          ),
        },
      ],
    })),
  };

  return (
    <div className="space-y-6">
      <Card
        title="Addresses"
        content={
          <Button
            link="/addresses/create"
            className="flex items-center gap-2 !bg-green-500"
          >
            <FaPlus /> Add New Address
          </Button>
        }
      >
        {isLoading ? (
          <Loading height="70vh" />
        ) : (
          <Table
            content={content}
            pagination={data?.data?.paginate}
            onPaginate={setPaginate}
          />
        )}
      </Card>
    </div>
  );
};

export default AddressPage;
