import React, { useEffect, useState } from 'react';
import { BsGlobe2, BsGlobeCentralSouthAsia } from 'react-icons/bs';
import { HiUsers } from 'react-icons/hi';
import { LuUserSquare2 } from 'react-icons/lu';
import { getAnalyticsOfDashboard } from '../../../utils/func';

const StaticsCards = () => {
  // const { data } = useGetAnalyticsQuery();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAnalyticsOfDashboard();
      setData(data);
    };

    fetchData();

    return () => {
      setData(null);
    };
  }, []);

  return (
    <div className="flex items-center justify-between gap-4">
      <div className="grid h-[250px] w-[270px] rounded-md bg-black p-5">
        <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full border border-dashed text-white">
          <LuUserSquare2 size="30" />
        </div>

        <div className="flex items-center gap-3 text-white">
          <h2 className="text-[60px] font-medium ">{data?.data?.profile}</h2>
          <h2>Profiles</h2>
        </div>
      </div>
      <div className="grid h-[250px] w-[270px] rounded-md bg-lemon-100 p-5">
        <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full border border-dashed border-black text-black">
          <HiUsers size="30" />
        </div>

        <div className="flex items-center gap-3 text-black">
          <h2 className="text-[60px] font-medium ">
            {data?.data?.contactCount}
          </h2>
          <h2>Contacts</h2>
        </div>
      </div>
      <div className="grid h-[250px] w-[270px] rounded-md bg-blue-100 p-5">
        <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full border border-dashed border-black text-black">
          <BsGlobe2 size="30" />
        </div>
        <div className="flex items-center gap-3 text-black">
          <h2 className="text-[60px] font-medium ">{data?.data?.postCount}</h2>
          <h2>Posts</h2>
        </div>
      </div>
      <div className="grid h-[250px] w-[270px] rounded-md bg-danger-100 p-5">
        <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full border border-dashed text-white">
          <BsGlobeCentralSouthAsia size="30" />
        </div>
        <div className="flex items-center gap-3 text-white">
          <h2 className="text-[60px] font-medium ">
            {data?.data?.addressCount}
          </h2>
          <h2>Addresses</h2>
        </div>
      </div>
    </div>
  );
};

export default StaticsCards;
