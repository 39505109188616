import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { useGetStoreQuery } from "../../../redux/features/stores/storesApi";
import StoreForm from "./StoreForm";

const EditStorePage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetStoreQuery(id);

  return (
    <>
      {isLoading ? <Loading height="70vh" /> : <StoreForm data={data?.data} />}
    </>
  );
};

export default EditStorePage;
