import React from "react";
import { FaLocationArrow, FaPen } from "react-icons/fa";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import InputGroup from "../../../components/ui/InputField";
import BusinessLayout from "../../../layout/BusinessLayout";

const CreateAddressPage = () => {
  return (
    <>
      <Card
        title="Create New Address"
        content={
          <Button
            link="/individual/addresses"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            My Addresses
          </Button>
        }
        className="space-y-3"
      >
        <InputGroup name="title" placeholder="Address Title" icon={<FaPen />} />
        <InputGroup
          name="address"
          placeholder="Address"
          icon={<FaLocationArrow />}
        />

        <Button className="mt-3 !bg-green-400">Save Address</Button>
      </Card>
    </>
  );
};

export default CreateAddressPage;
