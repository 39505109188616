import React from "react";
import SubscriptionForm from "./SubscriptionForm";

const CreateSubscriptionPage = () => {
  return (
    <>
      <SubscriptionForm />
    </>
  );
};

export default CreateSubscriptionPage;
