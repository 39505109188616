import React from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { useGetStoreQuery } from '../../../redux/features/stores/storesApi';
import ProductSection from './ProductSection';
import ReviewSection from './ReviewSection';
import StoreSection from './StoreSection';
import LanguageSelector from "../../../LanguageSelector";
import { useTranslation } from 'react-i18next';

const StorePreviewPage = ({
                            addToCart,
                            cart,
                            isPublic,
                            quantities,
                            removeFromCart,
                          }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: storeData, isLoading } = useGetStoreQuery(id);
  const data = storeData?.data || {};
  const buyerEmail = localStorage.getItem('buyerEmail');
  const navigate = useNavigate();

  const handelViewOrder = () => {
    navigate('/login-as-guest');
  };

  return (
      <>
        {isLoading ? (
            <Loading />
        ) : (
            <div className="space-y-6">
              <div className="flex items-center justify-between bg-white py-4 shadow-sm">
                <div className="mx-auto flex w-full max-w-[1000px] items-center justify-between">
                  <img
                      className="h-[40px] w-[40px] border"
                      src={data?.store_logo}
                      alt=""
                  />
                  <div className="flex items-center gap-5">
                    {isPublic && (
                        <Link
                            to={`/cart?storeId=${data?.user}`}
                            className="flex items-center text-lg"
                        >
                          <FaShoppingCart size={24} />
                          <span className="ml-2">{t('cart')} ({cart?.length})</span>
                        </Link>
                    )}
                    {buyerEmail && (
                        <button
                            onClick={handelViewOrder}
                            className="ms-10 flex items-center text-lg"
                        >
                          <span className="ml-2">{t('viewOrders')}</span>
                        </button>
                    )}

                    <LanguageSelector />
                  </div>
                </div>
              </div>
              <div className="mx-auto w-full max-w-[1000px] space-y-6 pb-6">
                <StoreSection data={data} />
                <ReviewSection storeData={data} />
                <ProductSection
                    storeData={data}
                    addToCart={addToCart}
                    removeFromCart={removeFromCart}
                    quantities={quantities}
                    isPublic={isPublic}
                />
                <div className="pt-6 text-center text-[12px] opacity-70">
                  <p>© {t('allRightsReserved')}. {t('poweredBy', { provider: 'Linkincard' })}</p>
                </div>
              </div>
            </div>
        )}
      </>
  );
};

export default StorePreviewPage;
