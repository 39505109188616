import React from "react";
import AddressForm from "./AddressForm";

const CreateAddressPage = () => {
  return (
    <>
      <AddressForm />
    </>
  );
};

export default CreateAddressPage;
