import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FaPlus } from 'react-icons/fa6';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { useGetQrCategoryOptionsQuery } from '../../../redux/features/qrCategoryApi/qrCategoryApi';
import {
  useDeleteQrCodeMutation,
  useGetQrCodesQuery,
} from '../../../redux/features/qrCodes/qrCodesApi';
import { useGetQrSubCategoryOptionsQuery } from '../../../redux/features/qrSubCategories/qrSubCategoryApi';
import SingleCard from '../cards/SingleCard';

const QrCodePage = () => {
  const [values, setValues] = useState({});
  const { data } = useGetQrCodesQuery({
    QRCategoryId: values?.qrCategoryId,
    QRSubCategoryId: values?.qrSubCategoryId,
  });
  const [deleteQrCode] = useDeleteQrCodeMutation();
  const { data: qrCategoriesData } = useGetQrCategoryOptionsQuery();
  const { data: qrSubCategoriesData } = useGetQrSubCategoryOptionsQuery(
    { QRCategoryId: values?.qrCategoryId },
    { skip: !values?.qrCategoryId }
  );

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await deleteQrCode(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <div className="space-y-6">
      <Card
        title="QR Code"
        content={
          <Button
            link="/qr-codes/create"
            className="flex items-center gap-2 !bg-green-500"
          >
            <FaPlus /> Add New
          </Button>
        }
      >
        {/* <div className="flex items-center justify-end gap-4 pb-6">
          <SelectOptions
            placeholder="QR Category"
            name={"qrCategoryId"}
            value={values?.qrCategoryId}
            onChange={onChange}
            options={qrCategoriesData?.data}
          />
          <SelectOptions
            placeholder="QR Sub Category"
            name={"qrSubCategoryId"}
            value={values?.qrSubCategoryId}
            onChange={onChange}
            options={qrSubCategoriesData?.data}
          />
        </div> */}

        <div className="grid justify-center gap-5 md:grid-cols-3 lg:grid-cols-4">
          {data?.data?.results?.map((item, i) => (
            <SingleCard
              key={item?._id}
              data={{
                image: item?.qrCodeImage,
                name: item?.name,
              }}
              deleteFunc={() => handleDelete(item?._id)}
              isDownload={true}
              isDelete={true}
            />
            // <SingleCard
            //   key={i}
            //   // data={{
            //   //     name: {item?.ssid},
            //   //     id: "Personal",
            //   // }}
            //   data={item}
            //   refetch={handleRefetch}
            //   isDelete
            //   isDownload
            // />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default QrCodePage;
