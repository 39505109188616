import emailjs from '@emailjs/browser';
import {
  BASE_URL,
  MAP_API_KEY,
  orderTemplateId,
  publicId,
  serviceId,
} from '../constants/constants';
export function hasExistArr(arr) {
  return Array.isArray(arr) && arr.filter(Boolean).length > 0;
}

export function filterArr(arr) {
  return Array.isArray(arr) ? arr.filter(Boolean) : [];
}

export function validateEmail(email) {
  var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function getDateRange(days) {
  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setDate(startDate.getDate() - Number(days));

  return {
    startDate: startDate.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0],
  };
}

export function getCurrentLocation(successCallback, errorCallback) {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        successCallback({ latitude, longitude });
      },
      function (error) {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            errorCallback('User denied the request for Geolocation.');
            break;
          case error.POSITION_UNAVAILABLE:
            errorCallback('Location information is unavailable.');
            break;
          case error.TIMEOUT:
            errorCallback('The request to get user location timed out.');
            break;
          case error.UNKNOWN_ERROR:
            errorCallback('An unknown error occurred.');
            break;
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  } else {
    errorCallback('Geolocation is not supported by this browser.');
  }
}

export function getFullName(person) {
  return `${person?.friend?.firstName || '----'} ${
    person?.friend?.lastName || '----'
  }`;
}

export function getFullNameByLayer(person) {
  return `${person?.firstName || '----'} ${person?.lastName || '----'}`;
}

export const getCoordinates = async (address) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${MAP_API_KEY}`
    );
    const jsonResponse = await response.json();
    if (jsonResponse.results.length > 0) {
      const { lat, lng } = jsonResponse.results[0].geometry.location;
      return { latitude: lat, longitude: lng };
    } else {
      return { error: 'Location not found' };
    }
  } catch (error) {
    return { error: error.message };
  }
};

export const handelSendOrderToEmail = async (orderData = {}) => {
  try {
    const response = await emailjs.send(
      serviceId,
      orderTemplateId,
      orderData,
      publicId
    );
    if (response.status === 200) {
      console.info('Message sent successfully!');
    }
    return response;
  } catch (error) {
    return error;
  }
};

const getAnalyticsOfDashboard = async () => {
  const token = localStorage.getItem('token_link_dashboard');
  try {
    const response = await fetch(`${BASE_URL}/analytics/dashboard`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error while fetching analytics', error);
  }
};

export { getAnalyticsOfDashboard };
