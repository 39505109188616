import { apiSlice } from "../api/apiSlice";

export const staticsAnalyticApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnalytics: builder.query({
      query: () => ({
        url: "/analytics/dashboard",
        method: "GET",
      }),
      providesTags: ["staticAnalytics"],
    }),
  }),
});

export const { useGetAnalyticsQuery } = staticsAnalyticApi;
