import { Field, Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import {
  FaEye,
  FaEyeSlash,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list';
import * as Yup from 'yup';
import { countryPhoneOptions } from '../../data/data';
import loginImg from '../../images/login/login-bg.jpg';
import logo from '../../images/logo/socalii-black-log.png';
import { useRegisterMutation } from '../../redux/features/auth/authApi';
import OtpModal from './OtpModal';

import { City, Country } from 'country-state-city';
import SelectOptions from '../../components/ui/SelectOptions';
import { getCoordinates } from '../../utils/func';

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  username: Yup.string()
    .min(6, 'Username must be at least 6 characters')
    .matches(
      /^(?!.*(\w)\1{2})[a-zA-Z0-9]+$/,
      'Username must contain only letters and numbers with no repetition'
    )
    .required('Username is required'),
  gender: Yup.string()
    .oneOf(['male', 'female'], 'Please select a gender')
    .required('Gender is required'),
  role: Yup.string()
    .oneOf(['business', 'individual'], 'Please select a gender')
    .required('Gender is required'),
  dateOfBirth: Yup.date().required('Date of Birth is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
  country: Yup.string().required('Country is required'),
  city: Yup.string().required('City is required'),
  dialCode: Yup.string().required('Dial Code is required'),
  mobileNumber: Yup.string().required('Mobile Number is required'),
  rememberMe: Yup.boolean(),
});

const RegisterPage = () => {
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [otpRequest, setOtpRequest] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const countryList = Country.getAllCountries().filter(
      (country) => country.name !== 'Israel'
    );
    setCountries(countryList);
  }, []);

  // otp started
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  // const [identifier, setIdentifier] = useState({ role: "individual", is_news_notify: true })
  const [identifier, setIdentifier] = useState({});
  const [otp, setOtp] = useState('');
  const initialTime = 0.3 * 60; // 10 minutes in seconds
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning]);

  const startTimer = () => {
    setOtp('');
    setTimeRemaining(initialTime);
    setOtpModalOpen(true);
    setIsRunning(true);
  };

  const resetTimer = () => {
    setOtp('');
    setIsRunning(false);
    setTimeRemaining(0);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const onChangeHandler = (key, value) => {
    setIdentifier((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  // otp end

  // redux mutations
  const [registerMutation, { isLoading, isSuccess }] = useRegisterMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const registerValues = { ...values };
    delete registerValues.rememberMe;
    delete registerValues.passwordConfirmation;
    // registerValues.role = 'individual';
    // registerValues.role = 'admin';
    const phone = {
      fullNumber: values.mobileNumber,
      dialCode: values.dialCode,
    };
    const location = {
      country: values.country,
      city: values.city,
    };
    registerValues.phone = phone;
    registerValues.dateOfBirth = '2023-09-30';
    registerValues.location = location;
    delete registerValues.city;
    delete registerValues.country;
    delete registerValues.mobileNumber;

    setIdentifier({ email: values?.email });
    // console.log(registerValues);
    const address = `${registerValues?.location?.city}, ${registerValues?.location?.country}`;
    try {
      const { latitude, longitude } = await getCoordinates(address);
      if (!latitude || !longitude) {
        toast.error('Invalid address');
        return;
      }
      registerValues.location = {
        ...registerValues.location,
        lat: latitude,
        long: longitude,
      };
      let result = await registerMutation(registerValues);
      //   console.log('result of register', result);
      if (result?.data?.success) {
        if (result?.data?.data?.isEmailVerified) {
          toast.success('Registered successfully');
          navigate('/login');
        } else {
          toast.success(result?.data?.message);
          startTimer();
        }

        // const { token } = result?.data?.data;
        // const user = token ? jwtDecode(token) : {};
        // if (!Object.keys(user)?.length > 0) {
        //   toast.info("OTP send successfully please verify your OTP!");
        // } else {
        //   dispatch(setCredentials({ user, token }));
        //   navigate("/");
        // }
      } else {
        toast.error(result?.error?.data?.message);
      }

      //   if (result?.error?.status === 400) {
      //     toast.error(result?.error?.data?.message);
      //   }
    } catch (error) {
      //   console.error("Login failed:", error);
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setSubmitting(false);
      setIsRequest(false);
    }
    resetForm();
  };

  const onClose = () => {
    setOtpModalOpen(false);
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* {!isSuccess ?  */}
      <div className="grid w-full lg:grid-cols-2">
        <div className="flex items-center justify-center border">
          <div className="mx-auto max-w-lg p-8">
            <div className="pb-4">
              <img className="mx-auto h-12 w-auto" src={logo} alt="logo" />
            </div>
            {/* <h1 className="mb-6 text-center text-4xl font-bold">
                            Linkin<span className="font-black">Card</span>
                        </h1> */}

            <h2 className="mb-6 bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-center text-2xl font-semibold text-transparent">
              Create Free 30 day Trial Account
            </h2>

            <p className="mb-8 text-center text-gray-600">
              Create your 30 days free trial account to get started with
              Socalii. No credit card required.
            </p>

            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                username: '',
                gender: '',
                dateOfBirth: '',
                email: '',
                password: '',
                passwordConfirmation: '',
                country: '',
                city: '',
                mobileNumber: '',
                dialCode: '',
                role: '',
                rememberMe: false,
              }}
              validationSchema={RegisterSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting, setFieldValue, values }) => (
                <Form className="space-y-4">
                  <div className="relative">
                    <Field
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      className={`w-full rounded-md border p-3 ${
                        errors.firstName && touched.firstName
                          ? 'border-red-500'
                          : ''
                      }`}
                    />
                    {errors.firstName && touched.firstName && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                  <div className="relative">
                    <Field
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      className={`w-full rounded-md border p-3 ${
                        errors.lastName && touched.lastName
                          ? 'border-red-500'
                          : ''
                      }`}
                    />
                    {errors.lastName && touched.lastName && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors.lastName}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <Field
                      name="username"
                      type="text"
                      placeholder="Username"
                      className={`w-full rounded-md border p-3 ${
                        errors.username && touched.username
                          ? 'border-red-500'
                          : ''
                      }`}
                    />
                    {errors.username && touched.username && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors.username}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <Field
                      as="select"
                      name="gender"
                      className={`w-full rounded-md border p-3 ${
                        errors.gender && touched.gender ? 'border-red-500' : ''
                      }`}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Field>
                    {errors.gender && touched.gender && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors.gender}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <Field
                      name="dateOfBirth"
                      type="date"
                      className={`w-full rounded-md border p-3 ${
                        errors.dateOfBirth && touched.dateOfBirth
                          ? 'border-red-500'
                          : ''
                      }`}
                    />
                    {errors.dateOfBirth && touched.dateOfBirth && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors.dateOfBirth}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <Field
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      className={`w-full rounded-md border p-3 ${
                        errors.email && touched.email ? 'border-red-500' : ''
                      }`}
                    />
                    {errors.email && touched.email && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors.email}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <Field
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter password"
                      className={`w-full rounded-md border p-3 pr-10 ${
                        errors.password && touched.password
                          ? 'border-red-500'
                          : ''
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-3 text-gray-400"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                    {errors.password && touched.password && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors.password}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <Field
                      name="passwordConfirmation"
                      type={showPasswordConfirmation ? 'text' : 'password'}
                      placeholder="Password Confirmation"
                      className={`w-full rounded-md border p-3 pr-10 ${
                        errors.passwordConfirmation &&
                        touched.passwordConfirmation
                          ? 'border-red-500'
                          : ''
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setShowPasswordConfirmation(!showPasswordConfirmation)
                      }
                      className="absolute right-3 top-3 text-gray-400"
                    >
                      {showPasswordConfirmation ? <FaEyeSlash /> : <FaEye />}
                    </button>
                    {errors.passwordConfirmation &&
                      touched.passwordConfirmation && (
                        <div className="mt-1 text-xs text-red-500">
                          {errors.passwordConfirmation}
                        </div>
                      )}
                  </div>

                  <div className="relative">
                    <Field
                      as="select"
                      name="country"
                      className={`w-full rounded-md border p-3 ${
                        errors.country && touched.country
                          ? 'border-red-500'
                          : ''
                      }`}
                      onChange={(e) => {
                        const selectedCountry = e.target.value;
                        setFieldValue('country', selectedCountry);
                        const countryCities =
                          City.getCitiesOfCountry(selectedCountry);
                        setCities(countryCities);
                        setFieldValue('city', '');
                      }}
                    >
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country.isoCode} value={country.isoCode}>
                          {country.name}
                        </option>
                      ))}
                    </Field>
                    {errors.country && touched.country && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors.country}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <Field
                      as="select"
                      name="city"
                      className={`w-full rounded-md border p-3 ${
                        errors.city && touched.city ? 'border-red-500' : ''
                      }`}
                    >
                      <option value="">Select City</option>
                      {cities.map((city) => (
                        <option key={city.name} value={city.name}>
                          {city.name}
                        </option>
                      ))}
                    </Field>
                    {errors.city && touched.city && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors.city}
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="flex items-center">
                      <div className="  w-56">
                        <SelectOptions
                          placeholder="Select Country code..."
                          options={countryPhoneOptions}
                          name="dialCode"
                          subKey="dialCode"
                          value={values.dialCode}
                          onChange={(name, dialCode) => {
                            setFieldValue(name, dialCode);
                          }}
                          className={'h-[51px]'}
                          isOnlyCountryCodeSelector
                        />
                      </div>
                      <div className="w-full">
                        <Field
                          name="mobileNumber"
                          type="number"
                          placeholder="Mobile Number"
                          disabled={!values.dialCode}
                          className={`w-full rounded-md border p-3 ${
                            errors.mobileNumber && touched.mobileNumber
                              ? 'border-red-500'
                              : ''
                          }`}
                        />
                      </div>{' '}
                    </div>
                    {errors.mobileNumber && touched.mobileNumber && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors.mobileNumber}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <Field
                      as="select"
                      name="role"
                      className={`w-full rounded-md border p-3 ${
                        errors.role && touched.role ? 'border-red-500' : ''
                      }`}
                    >
                      <option value="">Select Role</option>
                      <option value="business">Business</option>
                      <option value="individual">Individual</option>
                    </Field>
                    {errors.role && touched.role && (
                      <div className="mt-1 text-xs text-red-500">
                        {errors.role}
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Field
                        type="checkbox"
                        id="rememberMe"
                        name="rememberMe"
                        className="mr-2"
                      />
                      <label
                        htmlFor="rememberMe"
                        className="text-sm text-gray-600"
                      >
                        Remember Me
                      </label>
                    </div>

                    <button
                      type="submit"
                      className="rounded-md bg-gray-800 p-3 text-white transition duration-300 hover:bg-gray-700"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Processing...' : 'Register'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <p className="mt-4 text-center">
              You already have an account?{' '}
              <Link to="/login" className="text-orange-500 hover:underline">
                Login
              </Link>
            </p>

            <div className="mt-8 flex justify-center space-x-4">
              <SocialIcon Icon={FaFacebookF} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaInstagram} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaTwitter} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaYoutube} color="bg-lime-500 text-black" />
            </div>
          </div>
        </div>
        <div
          className="overflow-hidden border-8 border-lime-500 bg-green-500"
          style={{
            background: `url(${loginImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></div>
      </div>
      {/* 
      <div className="bg-[#1a202c] flex w-full text-center justify-center items-center p-5"><h3>OTP send successfully to your email please verify your OTP!</h3></div>
      */}
      {/* otp open modal */}
      {!otpModalOpen ? null : (
        <OtpModal
          otpModalOpen={otpModalOpen}
          setOtpModalOpen={setOtpModalOpen}
          identifier={identifier}
          setIdentifier={setIdentifier}
          startTimer={startTimer}
          resetTimer={resetTimer}
          formatTime={formatTime}
          timeRemaining={timeRemaining}
          otp={otp}
          setOtp={setOtp}
          onClose={onClose}
        />
      )}
    </div>
  );
};

const SocialIcon = ({ Icon, color }) => (
  <a
    href="#"
    className={`${color} rounded-lg p-2 text-black transition duration-300 hover:opacity-80`}
  >
    <Icon />
  </a>
);

export default RegisterPage;
