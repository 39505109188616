import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { FaLocationDot, FaSquarePhone } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../constants/constants';
import {
  useCreateAnalyticsMutation,
  useCreateContactMutation,
} from '../../redux/features/contacts/contactsApi';
import { useGetFriendsCategoriesQuery } from '../../redux/features/friends/friendsApi';
import ExchangeModal from '../pages/ExchangeModal';
import DialogModal from './DialogModal';
import SocialLinks from './SocialLinks';

const CardClassicView = ({ data, link, variant }) => {
  const [createContact] = useCreateContactMutation();
  const [createAnalytic] = useCreateAnalyticsMutation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalType, setModalType] = useState('');

  const downloadLink = `${BASE_URL}/${variant}/vcf-download/${id}`;
  const { data: myFriendCategoriesData } = useGetFriendsCategoriesQuery({
    limit: 5,
    sort: 'desc',
  });

  const onClose = () => {
    setModalType('');
  };

  const goBack = () => {
    navigate(-1);
  };

  const localStorageUserId = localStorage.getItem('userId');
  const token = localStorage.getItem('token_link_dashboard');

  const handleSubmit = async (data = {}) => {
    function separateCountryCode(phone) {
      // Use regex to match country code and the rest of the number
      const match = phone.match(/^\+(\d+)\s(.+)/);

      if (match) {
        const countryCode = match[1];
        const phoneNumber = match[2];
        return { countryCode, phoneNumber };
      } else {
        return { error: 'Invalid phone number format' };
      }
    }
    try {
      const result = separateCountryCode(data?.phone);

      const submitData = {
        contactInfo: {
          name: data?.name,
          phone: {
            fullNumber: result.countryCode,
            dialCode: result.phoneNumber,
          },
          email: data?.email,
        },
        categoryId: myFriendCategoriesData?.data?.results[0]?._id,
      };
      const API = createContact(submitData);
      const ApiResult = await API;
      // const result = await createContact(submitData);
      if (ApiResult?.data?.success) {
        toast.success(ApiResult?.data?.message);
        navigate('/friends');
      } else {
        toast.error(ApiResult.error?.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error);
    }
  };

  useEffect(() => {
    createAnalytic({ cardId: id, type: variant });
  }, []);
  //   console.log(
  //     token !== 'undefined' && variant === 'user' && id !== localStorageUserId
  //   );
  return (
    <div className="mx-auto w-full max-w-[800px] p-6">
      <div className="overflow-hidden rounded-lg bg-white shadow-lg">
        <div className="relative h-[300px] w-full bg-gradient-to-b from-blue-50 to-blue-200">
          <img
            src={data?.bannerImg}
            alt=""
            className="h-full w-full object-cover"
          />
          <div className="absolute -bottom-[60px] left-1/2 -translate-x-1/2 transform rounded-full bg-primary p-2">
            <img
              src={data?.image}
              alt="Profile"
              className="h-[120px] w-[120px] rounded-full border-4 border-white object-cover"
            />
          </div>
        </div>

        <div className="pb-6 pt-20 text-center">
          <h2 className="text-2xl font-bold">{data?.name}</h2>
          <p className="text-gray-500">{data?.position}</p>
          <p className="text-gray-500">{data?.model}</p>

          <div className="mt-4">
            {/*<p className="text-sm text-gray-500">{data?.email}</p>*/}
            <p className="text-sm text-gray-500">{data?.phone}</p>
            {/*<p className="text-sm text-gray-500">{data?.whatsapp}</p>*/}
            <p className="text-sm text-gray-500">{data?.fax}</p>
            <p className="text-sm text-gray-500">{data?.education}</p>
            <p className="text-sm text-gray-500">{data?.address}</p>
          </div>
        </div>

        <div className="flex justify-center gap-6 p-2 text-center">
          <Link to={`tel:${data?.phone}`}>
            <FaPhoneAlt size={24} />
          </Link>
          <Link to={`https://wa.me/${data?.whatsapp}`}>
            <FaWhatsapp size={24} />
          </Link>
          <Link to={`mailto:${data?.email}`}>
            <MdEmail size={24} />
          </Link>
          <Link
            to={`https://www.google.com/maps?q=${data?.location}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLocationDot size={24} />
          </Link>
        </div>

        <div className="flex items-center justify-center gap-3 py-4">
          <button
            onClick={() => setModalType('addContact')}
            className="rounded-lg bg-lemon-100 px-3 py-1 text-[12px] font-bold text-black"
          >
            Add to Your Contact
          </button>
          <button
            onClick={() => setModalType('exchangeContact')}
            className="rounded-lg bg-green-500 px-3 py-1 text-[10px] font-bold text-black"
          >
            Exchange Contact
          </button>
          {/* {data?.store?.length > 0 && (
            <button
              onClick={() =>
                navigate(`/stores/public-view/${data.store[0]._id}`)
              }
              className="rounded-lg bg-lemon-100 px-3 py-1 text-[12px] font-bold text-black"
            >
              View Store
            </button>
          )} */}
          {data?.store?.length > 1 ? (
            // Render a dropdown if there are multiple stores
            <div className="dropdown">
              <select
                onChange={(e) =>
                  navigate(`/stores/public-view/${e.target.value}`)
                }
                className="rounded-lg bg-lemon-100 px-3 py-1 text-[12px] font-bold text-black"
              >
                <option value="" disabled selected>
                  Select a Store
                </option>
                {data.store.map((store) => (
                  <option key={store._id} value={store._id}>
                    {store?.store_contact_information?.store_name}{' '}
                    {/* Replace 'name' with the property representing the store's display name */}
                  </option>
                ))}
              </select>
            </div>
          ) : data?.store?.length === 1 ? (
            // Render the button if there is exactly one store
            <button
              onClick={() =>
                navigate(`/stores/public-view/${data.store[0]._id}`)
              }
              className="rounded-lg bg-lemon-100 px-3 py-1 text-[12px] font-bold text-black"
            >
              View Store
            </button>
          ) : null}
        </div>

        <div className="mt-4 p-3 text-center">
          <div className="flex justify-center gap-6">
            {data?.facebook && (
              <a
                href={data?.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook
                  size={24}
                  className="text-blue-600 hover:text-blue-800"
                />
              </a>
            )}
            {data?.twitter && (
              <a href={data?.twitter} target="_blank" rel="noopener noreferrer">
                <FaTwitter
                  size={24}
                  className="text-blue-400 hover:text-blue-600"
                />
              </a>
            )}
            {data?.instagram && (
              <a
                href={data?.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram
                  size={24}
                  className="text-pink-600 hover:text-pink-800"
                />
              </a>
            )}
            {data?.linkedin && (
              <a
                href={data?.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin
                  size={24}
                  className="text-blue-700 hover:text-blue-900"
                />
              </a>
            )}
            {data?.youtube && (
              <a href={data?.youtube} target="_blank" rel="noopener noreferrer">
                <FaYoutube
                  size={24}
                  className="text-red-600 hover:text-red-800"
                />
              </a>
            )}
          </div>
        </div>

        {/*{data?.youtubeVideo && (*/}
        {/*    <div*/}
        {/*        style={{*/}
        {/*            position: 'relative',*/}
        {/*            paddingBottom: '56.25%',*/}
        {/*            height: 0,*/}
        {/*            overflow: 'hidden',*/}
        {/*            maxWidth: '100%',*/}
        {/*            background: '#000',*/}
        {/*            borderRadius: '10px',*/}
        {/*            marginTop: '20px', // Space before the video*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        <iframe*/}
        {/*            title="YouTube video"*/}
        {/*            src={`https://www.youtube.com/embed/${data?.youtubeVideo}`}*/}
        {/*            style={{*/}
        {/*                position: 'absolute',*/}
        {/*                top: 0,*/}
        {/*                left: 0,*/}
        {/*                width: '100%',*/}
        {/*                height: '100%',*/}
        {/*            }}*/}
        {/*            frameBorder="0"*/}
        {/*            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
        {/*            allowFullScreen*/}
        {/*        ></iframe>*/}
        {/*    </div>*/}
        {/*)}*/}

        {modalType === 'addContact' && (
          <DialogModal
            title="Add to Your Contact"
            width="450"
            onClose={onClose}
          >
            <div className="flex items-center justify-center gap-4">
              {token !== 'undefined' &&
              variant === 'user' &&
              id !== localStorageUserId ? (
                <div>
                  <div className="flex items-center justify-center pt-4">
                    <button
                      onClick={() => handleSubmit(data)}
                      className="text-green-500"
                    >
                      <FaSquarePhone size={60} />
                    </button>
                  </div>
                  <p className="pt-2 text-center text-[12px] font-bold text-black">
                    Add Contact
                  </p>
                </div>
              ) : (
                <div>
                  <div className="flex items-center justify-center pt-4">
                    <Link
                      to={
                        variant === 'users' && token == 'undefined'
                          ? '/login'
                          : '/friends'
                      }
                      className="text-green-500"
                    >
                      <FaSquarePhone size={60} />
                    </Link>
                  </div>
                  <p className="pt-2 text-center text-[12px] font-bold text-black">
                    Add Contact
                  </p>
                </div>
              )}

              <div>
                <div className="flex items-center justify-center pt-4">
                  <Link to={downloadLink} className="text-green-500">
                    <FaSquarePhone size={60} />
                  </Link>
                </div>
                <p className="pt-2 text-center text-[12px] font-bold text-black">
                  Phone Contact
                </p>
              </div>
            </div>
          </DialogModal>
        )}

        {modalType === 'exchangeContact' && (
          <DialogModal
            title="Exchange Your Contact"
            width="500"
            onClose={onClose}
          >
            <ExchangeModal />
          </DialogModal>
        )}
      </div>
      <SocialLinks
        //data={data?.social}
        data={{
          ...data?.social,
          email: data?.email,
          whatsapp: data?.whatsapp,
        }}
        additionalSocialLinks={data?.additionalSocialLinks}
      />
    </div>
  );
};

export default CardClassicView;
