import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaLocationArrow, FaPen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import InputGroup from "../../../components/ui/InputField";
import {
    useCreateAddressMutation,
    useEditAddressMutation,
} from "../../../redux/features/address/addressApi";

const AddressForm = ({ data = {} }) => {
  const [createAddress, {isLoading}] = useCreateAddressMutation();
  const [editAddress, {isLoading: isEditLoading}] = useEditAddressMutation();

  const [values, setValues] = useState(data);
  const navigate = useNavigate();

  const onChange = (key, value) => {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
    //   if(Object.keys(values)?.length === 0) return;
    if(!values?.address || !values?.title) return;
    try {
      const API = data?._id
        ? editAddress({ id: data?._id, data: values })
        : createAddress(values);
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate("/addresses");
      }else{
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card
        title={`${data?._id ? "Edit" : "Create New"} Address`}
        content={
          <Button
            link="/addresses"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            My Addresses
          </Button>
        }
        className="space-y-3"
      >
        <InputGroup
          name="title"
          value={values?.title}
          onChange={onChange}
          placeholder="Address Title"
          icon={<FaPen />}
        />
        <InputGroup
          name="address"
          placeholder="Address"
          value={values?.address}
          onChange={onChange}
          icon={<FaLocationArrow />}
        />

        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            name="isSetDefaultAddress"
            checked={values?.isSetDefaultAddress}
            onChange={(e) =>
              onChange("isSetDefaultAddress", e?.target?.checked)
            }
          />
          <label>Set Default Address</label>
        </div>

        <Button  type="submit" className="mt-3 !bg-green-400 w-36">
          {isLoading || isEditLoading ? <Loading variant="button" height="20px" /> : "Save Address"}
        </Button>
      </Card>
    </form>
  );
};

export default AddressForm;
