import { apiSlice } from '../api/apiSlice';

export const contactsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query({
      query: ({ page, limit, categoryId }) => ({
        // url: `/contacts?page=${page}&limit=${limit}`,
        url: `/contacts?page=${page}&limit=${limit}&category=${categoryId}`,
        method: 'GET',
      }),
      providesTags: ['contacts'],
    }),

    getNearbyContacts: builder.query({
      query: () => ({
        url: `/users/nearby-locations?role=business`,
        method: 'GET',
      }),
      //   providesTags: ["contacts"],
    }),

    getContact: builder.query({
      query: (id) => ({
        url: `/contacts/${id}`,
        method: 'GET',
      }),
      providesTags: ['contacts'],
    }),

    createContact: builder.mutation({
      query: (data) => ({
        url: '/contacts',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['contacts'],
    }),

    editContact: builder.mutation({
      query: ({ id, data }) => ({
        url: `/contacts/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),

    deleteContact: builder.mutation({
      query: (id) => ({
        url: `/contacts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['contacts'],
    }),

    createAnalytics: builder.mutation({
      query: (data) => ({
        url: '/analytics/postVisitors',
        method: 'POST',
        body: data,
      }),
    }),

    // get anaylatics by id and type of card
    getAnalytics: builder.query({
      query: (data) => ({
        url: `/analytics/visitors/${data.id}/${data.type}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetContactsQuery,
  useGetContactQuery,
  useCreateContactMutation,
  useEditContactMutation,
  useDeleteContactMutation,
  useGetNearbyContactsQuery,
  useCreateAnalyticsMutation,
  useGetAnalyticsQuery,
} = contactsApi;
// how to use useGetAnalyticsQuery
// import { useGetAnalyticsQuery } from '../../redux/features/contacts/contactsApi';
//  const { data: analyticsData } = useGetAnalyticsQuery({ id: id, type: 'card' });
