import React from 'react';
import SingleCard from '../cards/SingleCard';

const WifiQrPage = () => {
  return (
    <>
      <div className="mb-5 rounded-lg border border-green-400 bg-green-50 p-4 text-green-400">
        <p>Wifi QR Generated Successfully</p>
      </div>
      <SingleCard
        data={{
          name: 'info - Wifi QR',
          id: 'Personal',
        }}
        isDelete
        isDownload
      />
    </>
  );
};

export default WifiQrPage;
