import React from 'react';
import {
  FaCamera,
  FaFax,
  FaLocationArrow,
  FaPen,
  FaWhatsapp,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { LuKeyRound, LuWarehouse } from 'react-icons/lu';
import { MdApartment } from 'react-icons/md';
import PhoneInput from 'react-phone-input-2';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import InputGroup from '../../../components/ui/InputField';
import InputFile from '../../../components/ui/InputFile';
import SelectOptions from '../../../components/ui/SelectOptions';
import TextAreaField from '../../../components/ui/TextAreaField';

const CreateCardPage = () => {
  return (
    <Card title="My Profile" className="space-y-3">
      <Card title="Profile Picture" className="space-y-3">
        <div className="flex h-36 w-36 items-center justify-center overflow-hidden rounded-full border object-cover object-center">
          <img src="" alt="" />
          <FaCamera size={60} className="text-gray-400" />
        </div>
        <div className="mt-5 flex items-center justify-center">
          <Button>Browse</Button>
        </div>
      </Card>
      <Card title="Promo Picture" className="space-y-3">
        <InputFile />
      </Card>
      <div className="grid space-x-5 md:grid-cols-5">
        <div className="space-y-5 md:col-span-3">
          <Card title="Account Information" className="space-y-3">
            <InputGroup
              icon={<FaPen size={14} />}
              placeholder="Please Enter Your Full Name"
            />
            <InputGroup
              icon={<HiOutlineMailOpen size={14} />}
              placeholder="Please Enter Your Email"
            />
            <InputGroup
              icon={<LuKeyRound size={14} />}
              placeholder="Please Enter Your Password"
              type="password"
            />

            {/* <InputFile label="Profile Image" /> */}
          </Card>

          <Card title="Contact Information" className="space-y-3">
            <PhoneInput
              country={''}
              value=""
              onChange={() => {}}
              placeholder="Enter your Number"
            />
            <PhoneInput
              country={'w'}
              value=""
              onChange={() => {}}
              placeholder="Whatsapp number without 0 ( Required )"
            />

            <InputGroup
              icon={<FaWhatsapp size={14} />}
              placeholder="Whatsapp number without 0"
            />
            <InputGroup
              icon={<FaLocationArrow size={14} />}
              placeholder="Please Enter Your Address ( Required )"
            />
            <InputGroup
              icon={<FaFax size={14} />}
              placeholder="Please Enter Your Fax Number"
            />
          </Card>
        </div>
        <div className="space-y-5 md:col-span-2">
          <Card title="Job Information" className="space-y-3">
            <InputGroup
              icon={<LuWarehouse size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <SelectOptions
              icon={'Company Type'}
              placeholder="Please Enter Your Company Name"
            />
          </Card>
          <Card title="Job Information" className="space-y-3">
            <InputGroup
              icon={<LuWarehouse size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <SelectOptions
              icon={'Company Type'}
              placeholder="Please Enter Your Company Name"
            />
          </Card>

          <Card title="Social Media Information" className="space-y-3">
            <InputGroup
              icon={<FaXTwitter size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <InputGroup
              icon={<FaXTwitter size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <InputGroup
              icon={<FaXTwitter size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <InputGroup
              icon={<FaXTwitter size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <InputGroup
              icon={<FaXTwitter size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <InputGroup
              icon={<FaXTwitter size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <InputGroup
              icon={<FaXTwitter size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <InputGroup
              icon={<FaXTwitter size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <InputGroup
              icon={<FaXTwitter size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <InputGroup
              icon={<FaXTwitter size={14} />}
              placeholder="Please Enter Your Company Name"
            />
            <InputGroup
              icon={<FaXTwitter size={14} />}
              placeholder="Please Enter Your Company Name"
            />
          </Card>
        </div>
      </div>
      <div className="w-full space-y-6">
        <Card title="Summary" className="space-y-3">
          <TextAreaField
            icon={<MdApartment size={14} />}
            placeholder="Please Enter Your Company Name"
          />
        </Card>
      </div>

      <Button className={'w-full bg-green-600'}>Save</Button>
    </Card>
  );
};

export default CreateCardPage;
