import { apiSlice } from "../api/apiSlice";

export const interestApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInterests: builder.query({
      query: ({page, limit}) => ({
        url: "/interests",
        method: "GET",
        params: {page, limit}
      }),
      providesTags: ["interests"],
    }),

    getSingleInterest: builder.query({
      query: (id) => ({
        url: `/interests/${id}`,
        method: "GET",
      }),
      providesTags: ["interests"],
    }),

    createInterest: builder.mutation({
      query: (data) => ({
        url: "/interests",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["interests"],
    }),

    editInterests: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/interests/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["interests"],
    }),

    deleteInterests: builder.mutation({
      query: (id) => ({
        url: `/interests/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["myPosts"],
    }),
  }),
});

export const {
    useGetInterestsQuery,
    useGetSingleInterestQuery,
    useCreateInterestMutation,
    useDeleteInterestsMutation,
    useEditInterestsMutation,
} = interestApi;
