import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiFillProduct } from 'react-icons/ai';
import { CiImageOn } from 'react-icons/ci';
import { FaPen, FaSort, FaYoutube } from 'react-icons/fa';
import { GrCurrency } from 'react-icons/gr';
import { IoVideocamOutline } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import InputField from '../../../components/ui/InputField';
import InputFile from '../../../components/ui/InputFile';
import MultiImageUpload from '../../../components/ui/MultiImageUpload';
import PreviewImg from '../../../components/ui/PreviewImg';
import SelectOptions from '../../../components/ui/SelectOptions';
import TextAreaField from '../../../components/ui/TextAreaField';
import { useGetCategoryOptionsQuery } from '../../../redux/features/categories/categoryApi';
import {
  useCreateProductMutation,
  useEditProductMutation,
} from '../../../redux/features/products/productsApi';
import { useGetStoreOptionsQuery } from '../../../redux/features/stores/storesApi';

const ProductForm = ({ data = {} }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState(data);
  const [createProduct, { isLoading }] = useCreateProductMutation();
  const [editProduct, { isLoading: isEditLoading }] = useEditProductMutation();
  const { pathname } = useLocation();

  const [storeId, setStoreId] = useState(data?.store?._id);

  const { data: categoriesOptions, refetch: refetchCategoryOptions } =
    useGetCategoryOptionsQuery({
      lang: 'en',
    });

  const { data: storeOptions, refetch: refetchStoreOptions } =
    useGetStoreOptionsQuery();

  useEffect(() => {
    refetchCategoryOptions();
    refetchStoreOptions();
  }, [pathname]);

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  const haleCategoriesChange = (key, value) => {
    setValues((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleStoreChange = (key, value) => {
    // setValues((prev) => {
    //     return { ...prev, [key]: value };
    // });
    setStoreId(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const API = data?._id
        ? editProduct({ id: data?._id, data: { ...values, storeId } })
        : createProduct({ ...values, storeId });
      const result = await API;
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        navigate('/products');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card
        title={`${data?._id ? 'Edit' : 'Create New'} Product`}
        content={
          <Button
            link="/products"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            Back To Products
          </Button>
        }
        className="space-y-4"
      >
        <SelectOptions
          icon={<FaPen />}
          placeholder="Select Category..."
          options={categoriesOptions?.data}
          name="categories"
          value={values?.categories?._id}
          onChange={(name, selectedId) => {
            const selectedCategory = categoriesOptions?.data?.find(
              (option) => option._id === selectedId
            );
            haleCategoriesChange(name, selectedCategory);
          }}
        />
        <SelectOptions
          icon={<FaPen />}
          placeholder="Select Store..."
          options={storeOptions?.data}
          name="storeId"
          value={storeId}
          onChange={handleStoreChange}
        />
        <InputField
          name="title"
          subKey="en"
          placeholder="Product Name (ENGLISH)"
          icon={<AiFillProduct />}
          value={values?.title?.en}
          onChange={onChange}
          isRequired={true}
        />

        <InputField
          name="title"
          subKey="ar"
          placeholder="Product Name (ARABIC)"
          icon={<AiFillProduct />}
          value={values?.title?.ar}
          onChange={onChange}
        />

        <TextAreaField
          label="Description"
          name="description"
          subKey="en"
          placeholder="Product Description (ENGLISH)"
          value={values?.description?.en}
          onChange={onChange}
        />
        <TextAreaField
          label="Description"
          name="description"
          subKey="ar"
          placeholder="Product Description (ARABIC)"
          value={values?.description?.ar}
          onChange={onChange}
        />

        <InputField
          name="price"
          placeholder="Product Price"
          type="number"
          icon={<GrCurrency />}
          value={values?.price}
          onChange={onChange}
          isRequired={true}
        />
        <InputField
          name="videoUrl"
          placeholder="Video Link"
          icon={<FaYoutube />}
          value={values?.videoUrl}
          onChange={onChange}
        />
        <InputField
          name="sortOrder"
          type="number"
          placeholder="Sort Order"
          icon={<FaSort />}
          value={values?.sortOrder}
          onChange={onChange}
        />

        <Card>
          <PreviewImg src={values?.coverImgUrl} />
          <InputFile
            title="Cover Image"
            icon={<CiImageOn />}
            name="coverImgUrl"
            onChange={onChange}
          />
        </Card>
        <Card>
          <InputFile
            title="Video"
            icon={<IoVideocamOutline />}
            name="videoUrl"
            onChange={onChange}
            fileTypes="video/*"
          />
        </Card>
        <Card>
          {/* <PreviewImg src={values?.otherImgUrl} />
          <InputFile
            title="Others Image"
            icon={<CiImageOn />}
            name="otherImgUrl"
            onChange={onChange}
          /> */}
          <MultiImageUpload
            name="otherImgUrl"
            value={values.otherImgUrl}
            onChange={onChange}
          />
        </Card>

        <Button type="submit" className="!bg-green-400">
          {isEditLoading || isLoading ? (
            <Loading variant="button" height="20px" />
          ) : (
            'Save'
          )}
        </Button>
      </Card>
    </form>
  );
};

export default ProductForm;
