import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en/translation.json";
import ar from "./locales/ar/translation.json";

const resources = {
    en: { translation: en },
    ar: { translation: ar }
};

i18n
    .use(initReactI18next) // Initializes react-i18next
    .use(LanguageDetector) // Automatically detects language
    .init({
        resources,
        fallbackLng: "en",
        debug: true, // Enable this to see initialization logs in the console
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
