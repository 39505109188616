import React from "react";
import Card from "../../../components/ui/Card";
import BusinessLayout from "../../../layout/BusinessLayout";
import Table from "../../../components/Table";
import { FaEye } from "react-icons/fa";
import { FaPenToSquare, FaPlus } from "react-icons/fa6";
import { HiDownload } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import Button from "../../../components/ui/Button";

const StoreReviewPage = () => {

    const content = {
        header: [
          "Date",
          "Store Name",
          "Client Name",
          "Review",
          "Rating",
          "Image",
          "Approved",
          "Actions",
        ],
    
        body: [
          {
            rows: [
              {
                row: "24.25.24 22:55",
              },
              {
                row: "Store Name",
              },
              {
                row: (
                  <div>
                    <img className="h-[40px] w-[40px] border" src="" alt="" />
                  </div>
                ),
              },
              {
                row: "Netrokona",
              },
              {
                row: "4",
              },
              {
                row: "No image",
              },
              {
                row: '',
              },
              {
                row: (
                  <div className="flex items-center gap-3">
                    <Button className="!bg-green-400 -mb-2">
                      Approve
                    </Button>
                    <Button link={''} className="!bg-red-400">
                      Delete
                    </Button>
                  </div>
                ),
              },
            ],
          },
        ],
      };

  return (
    <>
      <Card title="Store Reviews">
        <Table content={content} />
      </Card>
    </>
  );
};

export default StoreReviewPage;
