
const ActionButton = ({ onClick, styles, title, startIcon, endIcon }) => {
    // let propStyle = styles && styles?.length > 0 ? styles.join(' ') : (styles || '');
    return (
        <div className="relative">
            <button
                onClick={onClick}
                className={"flex items-center justify-between gap-2 rounded px-2 py-1.5 text-white bg-[#009ef7] hover:bg-[#009ef7] focus:outline-none focus:ring-2 focus:ring-[#009ef7] focus:ring-opacity-50" + ' ' + styles}
            >
                {startIcon ? startIcon : null}
                {title}
                {endIcon ? endIcon : null}
            </button>
        </div>
    );
}

export default ActionButton;