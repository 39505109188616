import { apiSlice } from "../api/apiSlice";

export const clientsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query({
      query: (arg) => ({
        url: `/users/clients`,
        // url: `/users/clients?page=${arg?.page}&limit=${arg?.limit}`,
        method: "GET",
        params: {...arg}
      }),
      providesTags: ["clients"],
    }),

    getClient: builder.query({
      query: (id) => ({
        url: `/users/profile/${id}`,
        method: "GET",
      }),
      providesTags: ["clients"],
    }),

    createClient: builder.mutation({
      query: (data) => ({
        url: "/users/clients/create-profile",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["clients"],
    }),

    editClient: builder.mutation({
      query: ({ id, data }) => ({
        url: `/users/clients/update-profile/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["clients"],
    }),

    deleteClient: builder.mutation({
      query: (id) => ({
        url: `/users/clients/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["clients"],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientQuery,
  useCreateClientMutation,
  useEditClientMutation,
  useDeleteClientMutation,
} = clientsApi;
