import React from "react";
import { CiGlobe } from "react-icons/ci";
import {
  FaFacebook,
  FaInstagram,
  FaLocationArrow,
  FaMobileAlt,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { GrCurrency } from "react-icons/gr";
import { HiOfficeBuilding } from "react-icons/hi";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import InputGroup from "../../../components/ui/InputField";
import InputFile from "../../../components/ui/InputFile";
import SelectOptions from "../../../components/ui/SelectOptions";
import TextAreaField from "../../../components/ui/TextAreaField";
import BusinessLayout from "../../../layout/BusinessLayout";
import ColorSelector from "./ColorSelector";

const CreateStorePage = () => {
  return (
    <>
      <Card title="Create New Store" className="space-y-4">
        <Card title="Store Logo">
          <InputFile />
        </Card>
        <Card title="Store Cover Image">
          <InputFile />
        </Card>
        <div className="grid grid-cols-2 gap-4">
          <Card title="Store Contact Information" className=" space-y-3">
            <InputGroup
              icon={<HiOfficeBuilding />}
              name="name"
              placeholder="Store Name (Required)"
            />
            <SelectOptions icon={<CiGlobe />} placeholder="Select Country..." />
            <InputGroup
              icon={<FaMobileAlt />}
              name="mobile"
              placeholder="Mobile Number"
              type="number"
            />
            <InputGroup
              icon={<FaWhatsapp />}
              name="whatsapp"
              placeholder="Whatsapp Number"
              type="number"
            />
            <InputGroup
              icon={<FaLocationArrow size={14} />}
              name="address"
              placeholder="Full Address (Required)"
            />
            <SelectOptions
              icon={<GrCurrency />}
              placeholder="Select Currency..."
            />
          </Card>
          <Card title="Social Media Links" className="space-y-3">
            <InputGroup
              icon={<CiGlobe />}
              name="website"
              placeholder="Website Link"
            />
            <InputGroup
              icon={<FaFacebook />}
              name="facebook"
              placeholder="Facebook Page Link"
            />
            <InputGroup
              icon={<FaTwitter />}
              name="website"
              placeholder="Twitter Page Link"
            />
            <InputGroup
              icon={<FaYoutube />}
              name="youtube"
              placeholder="Youtube Channel Link"
            />
            <InputGroup
              icon={<FaInstagram />}
              name="instagram"
              placeholder="Instagram Page Link"
            />
          </Card>
        </div>
        <ColorSelector />
        <Card title="Store Description">
          <TextAreaField name="" placeholder="Enter Description" />
        </Card>
        <Button className="!bg-green-400">Save</Button>
      </Card>
    </>
  );
};

export default CreateStorePage;
