import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const CVForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    jobTitle: '',
    address: '',
    summary: '',
    phone: '',
    email: '',
    education: '',
    experience: '',
    skills: '',
    certifications: '',
    languages: [''],
    hobbies: '',
  });

  const [timeDistribution, setTimeDistribution] = useState({
    working: 0,
    family: 0,
    coordination: 0,
    entertainment: 0,
    relaxing: 0,
  });

  const [personalSkills, setPersonalSkills] = useState({
    leadership: 0,
    communication: 0,
    selfMotivation: 0,
    decisionMaking: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    const newValue = parseInt(value, 10);

    const otherValuesSum = Object.keys(timeDistribution)
      .filter((key) => key !== name)
      .reduce((sum, key) => sum + timeDistribution[key], 0);

    if (newValue + otherValuesSum > 100) {
      setTimeDistribution({
        ...timeDistribution,
        [name]: 100 - otherValuesSum,
      });
    } else {
      setTimeDistribution({ ...timeDistribution, [name]: newValue });
    }
  };

  const handleSkillChange = (e) => {
    const { name, value } = e.target;
    setPersonalSkills({ ...personalSkills, [name]: parseInt(value, 10) });
  };

  const handleLanguageChange = (index, value) => {
    const newLanguages = [...formData.languages];
    newLanguages[index] = value;
    setFormData({ ...formData, languages: newLanguages });
  };

  const addLanguageField = () => {
    setFormData({ ...formData, languages: [...formData.languages, ''] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const totalPercentage = Object.values(timeDistribution).reduce(
      (total, value) => total + value,
      0
    );
    if (totalPercentage !== 100) {
      alert('The total percentage must equal 100%.');
      return;
    }
    onSubmit({ ...formData, timeDistribution, personalSkills });
  };

  const chartData = {
    labels: ['Working', 'Family', 'Coordination', 'Entertainment', 'Relaxing'],
    datasets: [
      {
        data: [
          timeDistribution.working,
          timeDistribution.family,
          timeDistribution.coordination,
          timeDistribution.entertainment,
          timeDistribution.relaxing,
        ],
        backgroundColor: [
          '#1E40AF',
          '#60A5FA',
          '#38BDF8',
          '#3B82F6',
          '#93C5FD',
        ],
        hoverBackgroundColor: [
          '#1E3A8A',
          '#3B82F6',
          '#2563EB',
          '#1D4ED8',
          '#60A5FA',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
      datalabels: {
        formatter: (value, context) => {
          let sum = 0;
          let dataArr = context.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          let percentage = ((value / sum) * 100).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
        font: {
          weight: 'bold',
        },
      },
    },
  };

  return (
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Form Fields */}
        <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            className="w-full rounded border p-2"
            required
        />
        <input
            type="text"
            name="jobTitle"
            placeholder="Job Title"
            value={formData.jobTitle}
            onChange={handleChange}
            className="w-full rounded border p-2"
            required
        />
        <input
            type="text"
            name="summary"
            placeholder="Summary"
            value={formData.summary}
            onChange={handleChange}
            className="w-full rounded border p-2"
            required
        />
        <input
            type="text"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleChange}
            className="w-full rounded border p-2"
            required
        />
        <input
            type="text"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full rounded border p-2"
            required
        />
        <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full rounded border p-2"
            required
        />
        <input
            type="text"
            name="education"
            placeholder="Education"
            value={formData.education}
            onChange={handleChange}
            className="w-full rounded border p-2"
            required
        />
        <input
            type="text"
            name="experience"
            placeholder="Experience"
            value={formData.experience}
            onChange={handleChange}
            className="w-full rounded border p-2"
            required
        />
        <input
            type="text"
            name="skills"
            placeholder="Skills"
            value={formData.skills}
            onChange={handleChange}
            className="w-full rounded border p-2"
            required
        />
        <input
            type="text"
            name="certifications"
            placeholder="Certifications"
            value={formData.certifications}
            onChange={handleChange}
            className="w-full rounded border p-2"
        />
        <div className="space-y-2">
          <label className="font-bold">Languages</label>
          {formData.languages.map((language, index) => (
              <div key={index} className="flex items-center space-x-2">
                <input
                    type="text"
                    placeholder="Language"
                    value={language}
                    onChange={(e) => handleLanguageChange(index, e.target.value)}
                    className="w-full rounded border p-2"
                />
                {index === formData.languages.length - 1 && (
                    <button
                        type="button"
                        onClick={addLanguageField}
                        className="rounded bg-green-500 p-2 text-white"
                    >
                      +
                    </button>
                )}
              </div>
          ))}
        </div>
        <input
            type="text"
            name="hobbies"
            placeholder="Hobbies"
            value={formData.hobbies}
            onChange={handleChange}
            className="w-full rounded border p-2"
        />

        <div className="flex space-x-4">
          <div className="w-1/2 space-y-4">
            <div className="font-bold">
              Time Distribution (Total must equal 100%)
            </div>
            {[
              'working',
              'family',
              'coordination',
              'entertainment',
              'relaxing',
            ].map((category) => (
                <div key={category}>
                  <label>
                    {category.charAt(0).toUpperCase() + category.slice(1)}:{' '}
                    {timeDistribution[category]}%
                  </label>
                  <input
                      type="range"
                      name={category}
                      min="0"
                      max="100"
                      value={timeDistribution[category]}
                      onChange={handleTimeChange}
                      className="w-full"
                  />
                </div>
            ))}
          </div>

          <div className="w-1/2">
            <div className="ml-10 font-bold">Graphical Representation</div>

            <div className="relative mt-12 h-64 w-96">
              <Doughnut data={chartData} options={options}/>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="font-bold">
            Personal Skills (Select percentage for each)
          </div>
          {[
            'leadership',
            'communication',
            'selfMotivation',
            'decisionMaking',
          ].map((skill) => (
              <div key={skill}>
                <label>
                  {skill.charAt(0).toUpperCase() + skill.slice(1)}:{' '}
                  {personalSkills[skill]}%
                </label>
                <input
                    type="range"
                    name={skill}
                    min="0"
                    max="100"
                    value={personalSkills[skill]}
                    onChange={handleSkillChange}
                    className="w-full"
                />
              </div>
          ))}
        </div>

        <button type="submit" className="rounded bg-blue-500 p-2 text-white">
          Preview & Download
        </button>
      </form>
  );
};

export default CVForm;
