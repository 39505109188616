import React from "react";
import BusinessLayout from "../../../layout/BusinessLayout";
import Posts from "./Posts";

const PostsPage = () => {
  return (
    <>
      <Posts />
    </>
  );
};

export default PostsPage;
