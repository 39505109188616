import React from 'react';
import { FaMobileAlt } from 'react-icons/fa';
import Button from '../../../components/ui/Button';
import InputGroup from '../../../components/ui/InputField';
import SelectOptions from '../../../components/ui/SelectOptions';
import { countryPhoneOptions } from '../../../data/data';
const UserFilters = ({ setFiltrationData, filtrationData }) => {
  const onChange = (key, value, subKey) => {
    if (subKey) {
      setFiltrationData((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setFiltrationData((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };
  return (
    <div className="my-10">
      <h1>Filter users </h1>
      {/* filter by user  */}

      <div className="flex  flex-wrap items-center gap-4">
        <InputGroup
          placeholder="Search by first name"
          value={filtrationData?.firstName}
          name={'firstName'}
          onChange={onChange}
        />
        <InputGroup
          placeholder="Search by last name"
          value={filtrationData?.lastName}
          name={'lastName'}
          onChange={onChange}
        />

        <div className="  flex  items-center gap-2">
          <div className="w-[150px]">
            <SelectOptions
              placeholder="Select Country..."
              options={countryPhoneOptions}
              name="phone"
              subKey="dialCode"
              value={filtrationData?.phone?.dialCode}
              onChange={onChange}
            />
          </div>

          <div className="">
            <InputGroup
              icon={<FaMobileAlt />}
              name="phone"
              subKey="fullNumber"
              placeholder="Mobile Number"
              type="number"
              value={filtrationData?.phone?.fullNumber}
              onChange={onChange}
              disabled={!filtrationData?.phone?.dialCode}
            />
          </div>
        </div>
        <InputGroup
          placeholder="Search by country"
          value={filtrationData?.country}
          name={'country'}
          onChange={onChange}
        />
        <InputGroup
          placeholder="Search by username"
          value={filtrationData?.username}
          name={'username'}
          onChange={onChange}
        />
        {filtrationData?.role !== 'individual' && (
          <InputGroup
            placeholder="Search by company"
            value={filtrationData?.company}
            name={'company'}
            onChange={onChange}
          />
        )}

        <InputGroup
          placeholder="Search by email"
          value={filtrationData?.email}
          name={'email'}
          onChange={onChange}
        />
        <SelectOptions
          placeholder="Select Role..."
          name="role"
          onChange={onChange}
          value={filtrationData?.role}
          options={[
            { value: 'admin', label: 'Admin' },
            { value: 'individual', label: 'Individual' },
            { value: 'business', label: 'Business' },
          ]}
        />
        <Button
          className={' bg-gray-600'}
          onClick={() => setFiltrationData({})}
        >
          Clear filters
        </Button>
      </div>
    </div>
  );
};

export default UserFilters;
