import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaEye } from "react-icons/fa";
import { FaPenToSquare, FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import Table from "../../../components/Table";
import Button from "../../../components/ui/Button";
import Card from "../../../components/ui/Card";
import {
  useDeleteStoreMutation,
  useGetMyStoresQuery,
} from "../../../redux/features/stores/storesApi";

const MyStorePage = () => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });
  const { data, isLoading } = useGetMyStoresQuery(paginate);

  const navigate = useNavigate();
  const [deleteStore] = useDeleteStoreMutation();

  const handleDelete = async (id) => {
    try {
      const result = await deleteStore(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const content = {
    header: [
      "Store Name",
      "Store Logo",
      "Address",
      "Mobile",
      "Preview",
      "Actions",
    ],

    body: data?.data?.results?.map((item) => ({
      rows: [
        {
          row: item?.store_contact_information?.store_name,
        },
        {
          row: (
              <div>
                {item?.store_logo ? (
                    <img
                        className="h-[40px] w-[40px] border"
                        src={item?.store_logo}
                        alt=""
                    />
                ) : (
                    "N/A"
                )}
              </div>
          ),
        },
        {
          row: item?.store_contact_information?.full_address,
        },
        {
          row: (
              <span>
              {item?.store_contact_information?.phone?.dialCode}{" "}
                {item?.store_contact_information?.phone?.fullNumber}{" "}
            </span>
          ),
        },
        {
          row: (
              <Button
                  className="!mt-2 !inline-block"
                  link={`/stores/view/${item?._id}`}
              >
                <FaEye />
              </Button>
          ),
        },
        {
          row: (
              <div className="flex items-center gap-3">
                <Button
                    link={`/stores/edit/${item?._id}`}
                    className="!block !bg-green-400"
                >
                  <FaPenToSquare />
                </Button>
                <Button
                    onClick={() => handleDelete(item?._id)}
                    className="!bg-red-400"
                >
                  <MdDelete />
                </Button>
              </div>
          ),
        },
      ],
    })),
  };

  return (
      <div className="space-y-6">
          <Card
              title="Store Barcode"
              // content={
              //   <Button className="!bg-green-500">
              //     <HiDownload size="16" />
              //   </Button>
              // }
          >
              {/*<div className="flex flex-wrap gap-4">*/}
              {/*  {data?.data?.results?.map((item) => (*/}
              {/*      <img className="h-[150px] w-[150px]" src={item?.qrImage} alt="" />*/}

              {/*  ))}*/}
              {/*</div>*/}
              <div className="flex flex-wrap gap-4">
                  {data?.data?.results?.map((item) => (
                      <div key={item._id} className="text-center">
                          <img className="h-[150px] w-[150px]" src={item?.qrImage} alt=""/>
                          <p className="mt-2 text-sm font-semibold">{item?.store_contact_information?.store_name || "Store Name"}</p>
                      </div>
                  ))}
              </div>
          </Card>
          <Card
              title="My Stores"
              content={
                  <Button link="/stores/create" className="!bg-green-500">
                      <FaPlus/>
                  </Button>
              }
          >
              {isLoading ? (
                  <Loading height="70vh"/>
              ) : (
                  <Table
                      content={content}
                      pagination={data?.data?.paginate}
                  onPaginate={setPaginate}
              />
          )}
        </Card>
      </div>
  );
};

export default MyStorePage;
