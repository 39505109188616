import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { useGetSubscriptionQuery } from "../../../redux/features/subscriptions/subscriptionsApi";
import SubscriptionForm from "./SubscriptionForm";

const EditSubscriptionPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSubscriptionQuery(id);

  return (
    <>
      {isLoading ? (
        <Loading height="80vh" />
      ) : (
        <SubscriptionForm data={data?.data} />
      )}
    </>
  );
};

export default EditSubscriptionPage;
