import { apiSlice } from "../api/apiSlice";

export const carsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCardsOptions: builder.query({
      query: () => ({
        url: "/cards/options",
        method: "GET",
      }),
      providesTags: ["cardsOptions"],
    }),

    getBrandOptions: builder.query({
      query: () => ({
        url: "/brands/options",
        method: "GET",
      }),
      providesTags: ["brandOptions"],
    }),

    getCars: builder.query({
      query: (arg) => ({
        url: `/cars?page=${arg?.page}&limit=${arg?.limit}`,
        method: "GET",
      }),
      providesTags: ["cars"],
    }),

    getSingleCar: builder.query({
      query: (id) => ({
        url: `/cars/${id}`,
        method: "GET",
      }),
    }),

    createCar: builder.mutation({
      query: (data) => ({
        url: "/cars",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["cars"],
    }),

    editCar: builder.mutation({
      query: ({ id, data }) => ({
        url: `/cars/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["cars"],
    }),

    deleteCar: builder.mutation({
      query: (id) => ({
        url: `/cars/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["cars"],
    }),
  }),
});

export const {
  useGetBrandOptionsQuery,
  useGetCardsOptionsQuery,
  useGetCarsQuery,
  useCreateCarMutation,
  useEditCarMutation,
  useGetSingleCarQuery,
  useDeleteCarMutation,
} = carsSlice;
