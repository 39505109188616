import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import Button from "../../../components/ui/Button";
import InputField from "../../../components/ui/InputField";
import SelectOptions from "../../../components/ui/SelectOptions";
import { useGetQrCategoryOptionsQuery } from "../../../redux/features/qrCategoryApi/qrCategoryApi";
import { useEditQrSubCategoryMutation, useGetSingleQrSubCategoryQuery } from "../../../redux/features/qrSubCategories/qrSubCategoryApi";
import DefaultCardLayout from "../../agents/components/dashboard/DefaultCardLayout";

const EditSubQrCategories = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [values, setValues] = useState({});
  const { data, isLoading } = useGetSingleQrSubCategoryQuery(id);
  const { data: categoryOptions } = useGetQrCategoryOptionsQuery();
  
  useEffect(() => {
    if(data?.success){
        setValues({
            name: data?.data?.name, 
            sortOrder: data?.data?.sortOrder,
            QRCategory: data?.data?.QRCategory?._id,
        })
    }
  }, [data])
  const [editSubCategory, {isLoading: subCategoryCreateLoading}] = useEditQrSubCategoryMutation();
  const onSubCategoryChange = (key, value, subKey) => {
    if (subKey) {
        setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
        setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  
  const handleSaveSubCategories = async () => {
    try {
        let result = await editSubCategory({id, ...values});
        if (result?.data?.success) {
          toast.success(result?.data?.message);
          setValues({});
          navigate('/qr-sub-categories')
        }
      } catch (error) {
          toast.error(error?.response?.data?.message);
      }
  }

  return (
    <>
      <DefaultCardLayout
        title={"Edit QR Sub Category"}

        action={
        <div className="flex gap-3">
          <button
            className="rounded bg-[#009ef7] px-4 py-2 text-white"
            onClick={() => navigate("/qr-sub-categories")}
          >
            Qr Sub Categories List
          </button>
        </div>
        }
      >
          {isLoading ? <Loading height="60vh" /> : 
        <div className="p-8 gap-16">

        <div className="space-y-5">
                <SelectOptions options={
                    categoryOptions?.data?.map(category => ({
                        value: category?.value,
                        label: category?.label,
                    }))
                }  name={'QRCategory'} onChange={onSubCategoryChange} value={values?.QRCategory} type="text" placeholder={'Category name'} isRequired={true} />
                <InputField name={'name'} onChange={onSubCategoryChange} value={values?.name} type="text" placeholder={'Sub category name'} isRequired={true} />
                <InputField name={'sortOrder'} onChange={onSubCategoryChange} value={values?.sortOrder} type="number" placeholder={'Sort order'} isRequired={true} />

                <Button className="mt-3 !bg-green-400" onClick={handleSaveSubCategories}>
                {subCategoryCreateLoading ? <Loading height="24px" /> : "Update"}
                </Button>
            </div>
        </div>
          }
      </DefaultCardLayout>
    </>
  );
};

export default EditSubQrCategories;