import React from "react";
import ClientForm from "./ClientForm";
import { useParams } from "react-router-dom";
import { useGetClientQuery } from "../../redux/features/clients/clientsApi";

const EditClient = () => {
    const { id } = useParams();

    const { data } = useGetClientQuery(id);

    function transformData(input) {
        return {
            userInformation: {
                // fullName: input?.fullName, //! needed
                firstName: input?.firstName,
                lastName: input?.lastName,
                password: input?.password || null, 
                email: input?.email,
                accountType: input?.accountType
            },
            orderInformation: {
                _id: input?.activity?.lastOrderId?._id,
                city: input?.activity?.lastOrderId?.city,
                state: input?.activity?.lastOrderId?.state,
                total: input?.activity?.lastOrderId?.total,
                street: input?.activity?.lastOrderId?.street,
                country: input?.activity?.lastOrderId?.country,
                // country: "MT",
                status: input?.activity?.lastOrderId?.status,
                paymentMethod: input?.activity?.lastOrderId?.paymentMethod,
                currency: input?.activity?.lastOrderId?.currency
            }
        };
    }

    const transformedData = transformData(data?.data);

    return <ClientForm data={transformedData} id={data?.data?._id} />;
};

export default EditClient;