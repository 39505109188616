// import { useState } from 'react';
// import ToggleSwitch from '../ToggleSwitch';

// const SettingsGroup = ({ title, settings }) => {
//     let titleColor = title.includes("Doesn't") ? 'text-[#EF4444]' : 'text-[#22C55E]';
//     let bgColor = title.includes("Doesn't") ? 'bg-[#ef44441a]' : 'bg-[#22c55e1a]';

//     return (
//         <div>
//             <h2 className={titleColor + ' ' +`text-lg mb-2`}>{title}</h2>
//             <div className={bgColor + ' ' + "rounded-lg p-4"}>
//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-2 text-[#22c55e54]">
//                 {settings.map((setting, index) => (
//                     <ToggleSwitch
//                     key={index}
//                     label={setting.label}
//                     isChecked={setting.value}
//                     onChange={() => setting.onChange(!setting.value)}
//                     />
//                 ))}
//                 </div>
//             </div>
//         </div>
//     )
// };

// const ExtraFilters = () => {
//   const [settings, setSettings] = useState({
//     facebookLink: false,
//     instagramLink: false,
//     websiteLink: false,
//     havePosts: false,
//     haveContacts: false,
//     haveExternalContacts: false,
//     haveProfileImage: false,
//     havePromoImage: false,
//   });

//   const [settings2, setSettings2] = useState({
//     facebookLink: false,
//     instagramLink: false,
//     websiteLink: false,
//     havePosts: false,
//     haveContacts: false,
//     haveExternalContacts: false,
//     haveProfileImage: false,
//     havePromoImage: false,
//   });


//   const updateSetting = (key) => (value) => {
//     setSettings((prev) => ({ ...prev, [key]: value }));
//   };
//   const updateSetting2 = (key) => (value) => {
//     setSettings2((prev) => ({ ...prev, [key]: value }));
//   };

//   const userHaveSettings = [
//     { label: 'Facebook Link', value: settings.facebookLink, onChange: updateSetting('facebookLink') },
//     { label: 'Instagram Link', value: settings.instagramLink, onChange: updateSetting('instagramLink') },
//     { label: 'Web site Link', value: settings.websiteLink, onChange: updateSetting('websiteLink') },
//     { label: 'Have Posts', value: settings.havePosts, onChange: updateSetting('havePosts') },
//     { label: 'Have Contacts', value: settings.haveContacts, onChange: updateSetting('haveContacts') },
//     { label: 'Have External Contacts', value: settings.haveExternalContacts, onChange: updateSetting('haveExternalContacts') },
//     { label: 'Have Profile Image', value: settings.haveProfileImage, onChange: updateSetting('haveProfileImage') },
//     { label: 'Have Promo Image', value: settings.havePromoImage, onChange: updateSetting('havePromoImage') },
//   ];

//   const userDoesntHaveSettings = [
//     { label: 'Facebook Link', value: settings2.facebookLink, onChange: (value) => updateSetting2('facebookLink')(!value) },
//     { label: 'Instagram Link', value: settings2.instagramLink, onChange: (value) => updateSetting2('instagramLink')(!value) },
//     { label: 'Web site Link', value: settings2.websiteLink, onChange: (value) => updateSetting2('websiteLink')(!value) },
//     { label: "Doesn't Have Posts", value: settings2.havePosts, onChange: (value) => updateSetting2('havePosts')(!value) },
//     { label: "Doesn't Have Contacts", value: settings2.haveContacts, onChange: (value) => updateSetting2('haveContacts')(!value) },
//     { label: "Doesn't Have External Contacts", value: settings2.haveExternalContacts, onChange: (value) => updateSetting2('haveExternalContacts')(!value) },
//     { label: "Doesn't Have Profile Image", value: settings2.haveProfileImage, onChange: (value) => updateSetting2('haveProfileImage')(!value) },
//     { label: "Doesn't Have Promo Image", value: settings2.havePromoImage, onChange: (value) => updateSetting2('havePromoImage')(!value) },
//   ];

//   return (
//     <div className="container mx-auto mt-5">
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//         <SettingsGroup title="User Have" settings={userHaveSettings} />
//         <SettingsGroup title="User Doesn't Have" settings={userDoesntHaveSettings} />
//       </div>
//     </div>
//   );
// };

// export default ExtraFilters;


import { useEffect, useState } from 'react';
import ToggleSwitch from '../ToggleSwitch';

const SettingsGroup = ({ title, settings, onToggle }) => {
    let titleColor = title.includes("Doesn't") ? 'text-[#EF4444]' : 'text-[#22C55E]';
    let bgColor = title.includes("Doesn't") ? 'bg-[#ef44441a]' : 'bg-[#22c55e1a]';

    return (
        <div>
            <h2 className={`${titleColor} text-lg mb-2`}>{title}</h2>
            <div className={`${bgColor} rounded-lg p-4`}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 text-[#22c55e54]">
                {Object.entries(settings).map(([key, value]) => (
                    <ToggleSwitch
                    key={key}
                    label={value.label}
                    isChecked={value.value}
                    onChange={() => onToggle(key)}
                    />
                ))}
                </div>
            </div>
        </div>
    )
};

const ExtraFilters = ({setExtraFilters}) => {
  const [settings, setSettings] = useState({
    // facebookLink: { label: 'Facebook Link', value: false },
    // instagramLink: { label: 'Instagram Link', value: false },
    // websiteLink: { label: 'Web site Link', value: false },
    // havePosts: { label: 'Have Posts', value: false },
    // haveContacts: { label: 'Have Contacts', value: false },
    // haveExternalContacts: { label: 'Have External Contacts', value: false },
    // haveProfileImage: { label: 'Have Profile Image', value: false },
    // havePromoImage: { label: 'Have Promo Image', value: false },
    isFacebook: { label: 'Facebook Link', value: false },
    isInstagram: { label: 'Instagram Link', value: false },
    isWebsite: { label: 'Web site Link', value: false },
    isPost: { label: 'Have Posts', value: false },
    isContact: { label: 'Have Contacts', value: false },
    isExternalContact: { label: 'Have External Contacts', value: false },
    isProfile: { label: 'Have Profile Image', value: false },
    isPromo: { label: 'Have Promo Image', value: false },
  });

  useEffect(() => {
    let obj = {};

    
    // for(const key in Object.keys(settings)){
    for(const key in settings){
        obj[key]= settings[key]?.value;
    }


    setExtraFilters(obj);
    
    // setExtraFilters(settings);
  }, [settings]);

//   currency: '',
//     isTiktok'',
//     startDate: '',
//     endDate: '',
//     q: '',
//     country: '',
//     role: '',
//     isActive: '',
//     isEmailVerified: '',
//     isDemoAccount: '',
//     isFacebook: '',
//     isWebsite: '',
//     isWhatsapp: '',
//     isTwitter: '',
//     isLinkedin: '',
//     isSnapchat: '',
//     isYoutube: '',
//     isInstagram: '',
//     isTelegram: '',
//     isStoreLink: '',
//     isLink: '',
//     isLinkText: '',
//     isTiktok: '',
//     isThread: '',
//     isVideoLink: '',
//     isResumeLink: '',
//     isProfile: '',
//     isPromo: '',
//     isContact: '',
//     isExternalContact: '',
//     isPost: ''

  const toggleSetting = (key) => {
    setSettings(prev => ({
      ...prev,
      [key]: { ...prev[key], value: !prev[key].value }
    }));
  };

  const getNegativeLabel = (label) => {
    return label.startsWith("Have") ? `Doesn't ${label}` : label;
  };

  const userHaveSettings = { ...settings };
  const userDoesntHaveSettings = Object.entries(settings).reduce((acc, [key, value]) => {
    acc[key] = { ...value, label: getNegativeLabel(value.label), value: !value.value };
    return acc;
  }, {});

  return (
    <div className="container mx-auto mt-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <SettingsGroup title="User Have" settings={userHaveSettings} onToggle={toggleSetting} />
        <SettingsGroup title="User Doesn't Have" settings={userDoesntHaveSettings} onToggle={toggleSetting} />
      </div>
    </div>
  );
};

export default ExtraFilters;