import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateAnalyticsMutation } from '../../redux/features/contacts/contactsApi';

const CarView = ({ data, link, variant }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createAnalytic] = useCreateAnalyticsMutation();

  const images = data?.coverImage || [];

  const handleNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    createAnalytic({ cardId: id, type: variant });
  }, []);

  return (
      <div className="mx-auto w-full max-w-[480px] space-y-6 p-6">
        <div className="mb-4 overflow-hidden rounded-xl shadow-lg">
          <div className="relative h-[300px] bg-gray-200">
            {images.length > 0 && (
                <img
                    className="h-full w-full object-cover cursor-pointer"
                    src={images[currentImageIndex]}
                    alt="Car"
                    onClick={openModal}
                />
            )}
            <button
                onClick={handlePrevImage}
                disabled={currentImageIndex === 0}
                className={`absolute left-2 top-1/2 -translate-y-1/2 transform ${
                    currentImageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                }`}
            >
              <FaChevronLeft size={24} />
            </button>
            <button
                onClick={handleNextImage}
                disabled={currentImageIndex === images.length - 1}
                className={`absolute right-2 top-1/2 -translate-y-1/2 transform ${
                    currentImageIndex === images.length - 1
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                }`}
            >
              <FaChevronRight size={24} />
            </button>
          </div>
        </div>

        {isModalOpen && (
            <div className="fixed  inset-0 flex items-center justify-center bg-black ">
              <div className="relative">
                <img
                    className="h-[80vh] w-[60vw]   object-contain"
                    src={images[currentImageIndex]}
                    alt="Full-size Car"
                />
                <button
                    onClick={closeModal}
                    className="absolute top-2 right-2  text-white text-2xl"
                >
                  <FaTimes />
                </button>
                <button
                    onClick={handlePrevImage}
                    disabled={currentImageIndex === 0}
                    className={`absolute left-4 top-1/2 -translate-y-1/2 transform text-white ${
                        currentImageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                >
                  <FaChevronLeft size={32} />
                </button>
                <button
                    onClick={handleNextImage}
                    disabled={currentImageIndex === images.length - 1}
                    className={`absolute right-4 top-1/2 -translate-y-1/2 transform text-white ${
                        currentImageIndex === images.length - 1
                            ? 'cursor-not-allowed opacity-50'
                            : ''
                    }`}
                >
                  <FaChevronRight size={32} />
                </button>
              </div>
            </div>
        )}

        <div className="space-y-4 rounded-lg bg-white p-6 shadow-lg">
          <h2 className="text-2xl font-bold text-gray-800">{data?.name}</h2>
          <p className="text-lg font-medium text-gray-500">Model: {data?.model}</p>
          <p className="text-lg font-semibold text-primary">Price: {data?.price}</p>

          <div className="mt-4 space-y-2">
            <h3 className="text-xl font-semibold text-gray-800">Details</h3>
            <p className="text-gray-600">Kilometers Average: {data?.position}</p>
            <p className="text-gray-600">Engine Size: {data?.phone}</p>
            <p className="text-gray-600">From: {data?.address}</p>
          </div>
        </div>

        {data?.youtubeVideo && (
            <div
                className="relative mt-4"
                style={{ paddingBottom: '56.25%', height: 0 }}
            >
              <iframe
                  title="YouTube video"
                  src={`https://www.youtube.com/embed/${data?.youtubeVideo}`}
                  className="absolute left-0 top-0 h-full w-full rounded-lg"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
              ></iframe>
            </div>
        )}
      </div>
  );
};

export default CarView;
