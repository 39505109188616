import React from 'react';
import { FaPenToSquare, FaPlus } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';

const ProductsPage = () => {
  const content = {
    header: [
      'Order Num.',
      'Image',
      'Category',
      'Title',
      'Description',
      'Price',
      'Promo Video Link',
      'Actions',
    ],

    body: [
      {
        rows: [
          {
            row: '2',
          },

          {
            row: (
              <div>
                <img className="h-[40px] w-[40px]" src="" alt="" />
              </div>
            ),
          },
          {
            row: 'test',
          },
          {
            row: 'Title',
          },
          {
            row: 'description',
          },
          {
            row: '$40',
          },
          {
            row: 'youtube',
          },
          {
            row: (
              <div className="flex items-center gap-3">
                <Button className="!bg-green-400 ">
                  <FaPenToSquare />
                </Button>
                <Button className="!bg-red-400">
                  <MdDelete />
                </Button>
              </div>
            ),
          },
        ],
      },
    ],
  };

  return (
    <>
      <Card
        title="Products"
        content={
          <Button
            link="/business/products/create"
            className="flex items-center gap-2 !bg-green-500 !py-1.5"
          >
            <FaPlus /> Add New Product
          </Button>
        }
      >
        <Table content={content} />
      </Card>
    </>
  );
};

export default ProductsPage;
