import { apiSlice } from "../api/apiSlice";

export const storeReviewApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStoreReviews: builder.query({
      query: (arg) => ({
        url: `/store-reviews/my-reviews?page=${arg?.page}&limit=${arg?.limit}`,
        method: "GET",
      }),
      providesTags: ["storeReviews"],
    }),

    getStoreReview: builder.query({
      query: (id) => ({
        url: `/store-reviews/${id}`,
        method: "GET",
      }),
      providesTags: ["storeReviews"],
    }),

    createStoreReview: builder.mutation({
      query: (data) => ({
        url: "/store-reviews",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["storeReviews"],
    }),

    editStoreReview: builder.mutation({
      query: ({ id, data }) => ({
        url: `/store-reviews/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["storeReviews"],
    }),

    deleteStoreReview: builder.mutation({
      query: (id) => ({
        url: `/store-reviews/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["storeReviews"],
    }),
  }),
});

export const {
  useGetStoreReviewsQuery,

  useGetStoreReviewQuery,
  useCreateStoreReviewMutation,
  useEditStoreReviewMutation,
  useDeleteStoreReviewMutation,
} = storeReviewApi;
