import { useState } from "react";
import ActionButton from "./components/dashboard/ActionButton";
import DefaultCardLayout from "./components/dashboard/DefaultCardLayout";
import ExtraFilters from "./components/dashboard/ExtraFilters";
import SearchFilterComponent from "./components/dashboard/SearchFilterComponent";

import toast from "react-hot-toast";
import { FaCarSide, FaUser } from "react-icons/fa";
import { IoIosMan } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import { useEditClientMutation, useGetClientsQuery } from "../../redux/features/clients/clientsApi";
import { formatRelativeTime } from "../../utils/formatRelativeTime";

function UserProfile({ url }) {
    return (
        <img
            className="h-14 w-14 rounded-full object-cover object-center"
            alt="image"
            src={url}
        />
    );
}

const ClientsAll = () => {
    const [ isLoadingActivation, setIsLoadingActivation ] = useState(null);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState({});
    const [extraFilters, setExtraFilters] = useState({});
    const [paginate, setPaginate] = useState({
        page: 1,
        limit: 10,
    });
    const { data, isLoading } = useGetClientsQuery({...paginate, ...filters, ...extraFilters});
    const [filteredOrders, setFilteredOrders] = useState();
    const [activation, { isLoading: isActiveLoading }] = useEditClientMutation();

    const handleSearch = (term) => {
        // console.log("Searching for:", term);
        // setSearchText(term);
        // setFilters({...filters, q: })
    };

    const handleFilter = (filters) => {
        // Implement filter logic here
        // console.log("Applying filters:", filters);
        setFilters(filters);
    };

    const handleEdit = (payload) => {
        navigate('/agent/edit-client/' + payload?._id);
    };

    const handleActivation = async (payload) => {
        const data = {
            userInformation: {
                isActive: !payload.isActive,
            }
        }

        try {
            setIsLoadingActivation(payload._id);
            let result = await activation({
                id: payload._id,
                data
            });
            if (result?.data?.success) {
                toast.info(result?.data?.message);
            } else {
                toast.error(result?.error?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message);
        }finally{
            setIsLoadingActivation(null);
        }
    };
    
    const content = {
        header: [
            "Name",
            "Email",
            "Posts",
            "Contacts",
            "Cars",
            "Created At",
            "Actions",
        ],

        body: data?.data?.results?.map((item) => ({
            rows: [
                {
                    row: (
                        <div className="flex items-center gap-4">
                            <UserProfile url={item?.avatar} />
                            <div>
                                <p>{item?.fullName || item?.email}</p>
                                <p className="text-gray-400">{item?.role}</p>
                            </div>
                        </div>
                    ),
                },
                {
                    row: item?.email,
                },
                {
                    row: item?.postCount || '--',
                },
                {
                    row: item?.contacts || 0,
                },
                {
                    row: !item?.card ? (
                        "--"
                    ) : (
                        <div>
                            <p>{item.card || 0} Card</p>
                            <p className="text-gray-400">{item || 0} Car</p>
                        </div>
                    ),
                },

                {
                    row: formatRelativeTime(item?.createdAt),
                },
                {
                    row: (
                        <div className="flex gap-3">
                            <ActionButton
                                onClick={() => console.log("clicked")}
                                startIcon={<FaCarSide className="text-black" />}
                                styles={"h-full px-3 bg-red-100"}
                            />
                            <ActionButton
                                onClick={() => console.log("clicked")}
                                startIcon={<FaUser className="text-black" />}
                                styles={"h-full px-3 bg-green-400"}
                            />
                            <ActionButton
                                onClick={() => console.log("clicked")}
                                startIcon={<IoIosMan size={17} className="text-black" />}
                                styles={"h-full px-3"}
                            />
                            <ActionButton
                                title={isLoadingActivation === item?._id ? <Loading variant="button" height="22px" /> : (item?.isActive ? "Deactivate" : 'Activate')}
                                onClick={() => handleActivation(item)}
                                styles={"bg-pink-500 !w-[80px] !justify-center"}
                            />
                            <ActionButton title={"Edit"} onClick={() => handleEdit(item)} />
                        </div>
                    ),
                },
            ],
        })),
    };

    return (
        <>
            <div className="mb-10">
                <DefaultCardLayout title={"Agent Clients List"} border={true}>
                    <div className="p-6">
                        <SearchFilterComponent
                            onSearch={handleSearch}
                            onFilter={handleFilter}
                            initialdata={filteredOrders}
                            extraFilters={<ExtraFilters setExtraFilters={setExtraFilters} />}
                            isExtraFilter={true}
                        />
                    </div>

                    <div className="px-5">
                        {isLoading ? (
                            <Loading height="70vh" />
                        ) : (
                            <Table
                                content={content}
                                pagination={data?.data?.paginate}
                                onPaginate={setPaginate}
                            />
                        )}
                    </div>
                    <br />
                    <br />
                </DefaultCardLayout>
            </div>
        </>
    );
};

export default ClientsAll;
