import React from "react";
import { BsDot, BsQrCodeScan } from "react-icons/bs";
import { FaShoppingBasket, FaUser } from "react-icons/fa";
import { MdAllOut } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { TbMessage2 } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import SidebarLinkGroup from "../SidebarLinkGroup";
import { FaMoneyBills } from "react-icons/fa6";
import { MdContactPhone } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { PiCardsFill } from "react-icons/pi";
import { FaEnvelopeOpenText } from "react-icons/fa";

const AgentSidebar = ({ pathname, setSidebarExpanded, sidebarExpanded, tinySidebarOpen }) => {
    const navLinkClass = `text-bodydark1 hover:bg-graydark dark:hover:bg-meta-4 group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium duration-300 ease-in-out`;

    const navItems = [
        { to: "/agent", icon: <RxDashboard size={20} />, text: "Dashboard", condition: "dashboard" },
        { to: "/agent/hot-map", icon: <MdAllOut size={20} />, text: "Hot Section", condition: "section" },
        { to: "/product-categories", icon: <MdAllOut size={20} />, text: "Product Categories", condition: "product-categories" },
        {
            to: "#",
            icon: <FaUser size={20} />,
            text: "Clients",
            condition: "forms",
            children: [
                { to: "/agent/all-clients", text: "All Clients" },
                { to: "/agent/demo-accounts", text: "Demo Accounts" },
                { to: "/agent/create-user", text: "Create new client" },
            ],
        },
        { to: "/agent/order-reports", icon: <FaShoppingBasket size={20} />, text: "Orders", condition: "clients" },
        {
            to: "/messages",
            icon: <FaEnvelopeOpenText size={20} />,
            text: "Messages",
            condition: "messages",
        },
        {
            to: "#",
            icon: <PiCardsFill size={20} />,
            text: "QR",
            condition: "qr",
            children: [
                {
                    to: "/qr-categories",
                    icon: <FaMoneyBills size={20} />,
                    text: "QR Categories",
                    condition: "qr-categories",
                },
                {
                    to: "/qr-sub-categories",
                    icon: <FaMoneyBills size={20} />,
                    text: "QR Sub Categories",
                    condition: "qr-sub-categories",
                },
            ],
        },
        {
            to: "#",
            icon: <MdContactPhone size={20} />,
            text: "Contacts",
            condition: "contacts",
            children: [
                {
                    to: "/friends/categories",
                    icon: <MdContactPhone size={20} />,
                    text: "Friends Categories",
                    condition: "/friends/categories",
                },
                {
                    to: "/friends",
                    icon: <FaUserFriends size={20} />,
                    text: "Friends",
                    condition: "friends",
                },
            ],
        },
        { to: "/agent/qr-links", icon: <BsQrCodeScan size={20} />, text: "QR Playground", condition: "clients" },
        { to: "/agent/web-messages", icon: <TbMessage2 size={20} />, text: "Web Messages", condition: "clients" },
    ];

    return (
        <ul className="mb-6 flex flex-col gap-1.5">
            {navItems.map((item, index) => (
                <li key={index}>
                    {item.children ? (
                        <SidebarLinkGroup activeCondition={pathname === "/cads" || pathname.includes("cads")}>
                            {(handleClick, open) => (
                                <React.Fragment>
                                    <NavLink
                                        to={item.to}
                                        className={`${navLinkClass} ${(pathname === item.condition || pathname.includes(item.condition)) &&
                                            "bg-graydark dark:bg-meta-4"
                                            } hover:text-white`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                        }}
                                    >
                                        {item.icon}
                                        {tinySidebarOpen ? item.text : null}
                                        {tinySidebarOpen ? <svg
                                            className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${open && "rotate-180"
                                                }`}
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                                                fill=""
                                            />
                                        </svg> : null}
                                    </NavLink>

                                    <div className={`translate transform overflow-hidden ${!open && "hidden"}`}>
                                        <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
                                            {item.children.map((child, childIndex) => (
                                                <li key={childIndex}>
                                                    <NavLink
                                                        to={child.to}
                                                        className={({ isActive }) =>
                                                            "text-bodydark2 group relative flex items-center gap-2.5 rounded-md px-4 font-medium duration-300 ease-in-out hover:text-white " +
                                                            (isActive && "!text-white")
                                                        }
                                                    >
                                                        <BsDot />
                                                        {child.text}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </React.Fragment>
                            )}
                        </SidebarLinkGroup>
                    ) : (
                        <NavLink
                            to={item.to}
                            className={`${navLinkClass} ${pathname.includes(item.condition) && "bg-graydark dark:bg-meta-4"
                                } hover:text-white`}
                        >
                            {item.icon}
                            {tinySidebarOpen ? <span className={`transition-opacity duration-1000 overflow-hidden`}>
                                {item.text}
                            </span> : null}
                        </NavLink>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default AgentSidebar;
