import React, { useEffect, useRef, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";


const DropdownNotification = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const timeoutRef = useRef(null);

    const trigger = useRef(null);
    const dropdown = useRef(null);
    const navigate = useNavigate();

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (
                !dropdownOpen ||
                dropdown.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setDropdownOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener("keydown", keyHandler);
        return () => document.removeEventListener("keydown", keyHandler);
    });

    const handleMouseEnter = () => {
        setDropdownOpen(true);
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      }
    
      const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
          setDropdownOpen(false);
        }, 200);
      }
    

    return (
        <li className="relative">
            <Link
                ref={trigger}
                // onClick={() => setDropdownOpen(!dropdownOpen)}
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave}
                to="#"
                className="dark:border-strokedark dark:bg-meta-4 relative flex h-11 w-11 items-center justify-center rounded-xl border-0 hover:text-[#009ef7] dark:text-white p-2"
            >
                <span className="absolute -top-1 left-1/2 -translate-x-1/2 z-1 h-2.5 w-2.5 rounded-full bg-[#009ef7] animate-pulse">
                </span>
                <span className="h-10 w-10 ">
                    <svg className="w-full h-full" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.2929 2.70711C11.6834 2.31658 12.3166 2.31658 12.7071 2.70711L15.2929 5.29289C15.6834 5.68342 15.6834 6.31658 15.2929 6.70711L12.7071 9.29289C12.3166 9.68342 11.6834 9.68342 11.2929 9.29289L8.70711 6.70711C8.31658 6.31658 8.31658 5.68342 8.70711 5.29289L11.2929 2.70711Z" fill="currentColor"></path>
                        <path d="M11.2929 14.7071C11.6834 14.3166 12.3166 14.3166 12.7071 14.7071L15.2929 17.2929C15.6834 17.6834 15.6834 18.3166 15.2929 18.7071L12.7071 21.2929C12.3166 21.6834 11.6834 21.6834 11.2929 21.2929L8.70711 18.7071C8.31658 18.3166 8.31658 17.6834 8.70711 17.2929L11.2929 14.7071Z" fill="currentColor"></path>
                        <path opacity="0.3" d="M5.29289 8.70711C5.68342 8.31658 6.31658 8.31658 6.70711 8.70711L9.29289 11.2929C9.68342 11.6834 9.68342 12.3166 9.29289 12.7071L6.70711 15.2929C6.31658 15.6834 5.68342 15.6834 5.29289 15.2929L2.70711 12.7071C2.31658 12.3166 2.31658 11.6834 2.70711 11.2929L5.29289 8.70711Z" fill="currentColor"></path>
                        <path opacity="0.3" d="M17.2929 8.70711C17.6834 8.31658 18.3166 8.31658 18.7071 8.70711L21.2929 11.2929C21.6834 11.6834 21.6834 12.3166 21.2929 12.7071L18.7071 15.2929C18.3166 15.6834 17.6834 15.6834 17.2929 15.2929L14.7071 12.7071C14.3166 12.3166 14.3166 11.6834 14.7071 11.2929L17.2929 8.70711Z" fill="currentColor"></path>
                    </svg>
                </span>
            </Link>

            <div
                ref={dropdown}
                // onFocus={() => setDropdownOpen(true)}
                // onBlur={() => setDropdownOpen(false)}
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave}
                className={`-right-27 h-90 w-75 shadow-default dark:border-strokedark dark:bg-boxdark absolute mt-2.5 flex flex-col sm:right-0 sm:w-96 ${dropdownOpen === true ? "block" : "hidden"
                    }`}
            >
                {/* <div className="px-4.5 py-3">
                    <h5 className="text-bodydark2 text-sm font-medium">Notification</h5>
                </div>
                {isLoading ? (
                    <div className="pt-[35%]">
                        <Loading height="auto" />
                    </div>
                ) : (
                    <>
                        <ul className="flex h-auto flex-col overflow-y-auto">Noting</ul>
                    </>
                )} */}
                <div className="max-w-md rounded-lg overflow-hidden shadow-lg bg-white">
                    <div className="bg-indigo-500 text-white px-4 py-5 flex justify-start items-center gap-5">
                        <h2 className="font-semibold">Notifications</h2>
                        <span className="text-sm">1 new updates</span>
                    </div>
                    <div className="p-3 my-3">
                    <div className="p-4 border border-slate-200 rounded-lg">
                        <div className="flex items-center justify-between">
                        <div className="">
                            <span className="inline-block bg-green-100 text-green-600 text-xs font-semibold px-2 py-1 rounded">
                                Feature
                            </span>
                        <p className="text-gray-500 text-base mt-2">
                            Now your visitors can add comment to your store.
                        </p>
                        </div>

                        <p className="text-gray-100 text-xs">9 months ago</p>
                        </div>
                    </div>
                    </div>
                    <div className="px-4 py-5 border-t border-slate-200">
                        <button className="text-zinc-400 font-semibold flex items-center justify-between gap-5 hover:underline">
                            View All
                            <FaArrowRightLong />
                        </button>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default DropdownNotification;
