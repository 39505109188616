import { apiSlice } from "../api/apiSlice";

export const notificationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecentBirthdayNotifications: builder.query({
      query: (arg) => ({
        url: `/users/my-friends/birth-days/recent`,
        method: "GET",
        params: {...arg}
      }),
      providesTags: ["birthdayNotifications"],
    }),

    getUpcomingBirthdayNotifications: builder.query({
      query: (arg) => ({
        url: `/users/my-friends/birth-days/upcoming`,
        method: "GET",
        params: {...arg}
      }),
      providesTags: ["birthdayNotifications"],
    }),

    createBirthdayWish: builder.mutation({
      query: (data) => ({
        url: "/users/my-friends/birth-days/wish",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["address"],
    }),
  }),
});

export const {
    useGetRecentBirthdayNotificationsQuery,
    useGetUpcomingBirthdayNotificationsQuery,
    useCreateBirthdayWishMutation,
} = notificationApi;
